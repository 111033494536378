.thumbnav {
    width: fit-content;
    @include flexbox(row, flex-start, unset, nowrap); 

    .thumb h2 {
        display: inline-block;
        width: auto;
        font-size: inherit;
        padding: 0.8rem 0;
        font-weight: inherit;

        &:after {
            display: none;
        }
    }
}

.thumbcnt {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .thumbslide {
        grid-column: 1;
        grid-row: 1;
        display: none;
        width: 100%;

        &.current {
            display: block;
            animation: shade .3s linear forwards;
        }
    }
}