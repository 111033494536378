/*  VENDORS */
@import "var/vars";
@import "components/product_tooltip";

@import "../../../_app/_src/scss/desktop/app";
@import "../../../_app/_src/scss/desktop/components/add_cart_product_tooltip";
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";

/* Prevent search_index_v2 loading bug */
@import "../../../_app/_src/scss/desktop/components/vue_js"; 

@import "var/mixins";
@import "var/sprite";

/* COMPONENTS */
@import "components/shade";
@import "components/buttons";
@import "components/header";
@import "components/burger_menu";
@import "components/footer";
@import "components/signup_module";
@import "components/achat_express";
@import "components/lightbox";
@import "components/forms";
@import "components/search";
@import "components/lightbox_selection_offerte";
@import "components/_swiper.scss";
@import "components/_nav_right.scss";

/* PAGES */
@import "pages/global";
@import "pages/page_inactive";
@import "pages/home";
@import "pages/satellite";
@import "pages/cms-page";
@import "pages/rayon";
@import "pages/account";
@import "pages/product";
@import "pages/faq";
@import "pages/cart";
@import "pages/lookbook";
@import "pages/tunnel";
@import "pages/store_loc";
@import "pages/formsatisfaction";

@import "components/w-form";

/* Plugins */
@import "plugin/swiper-bundle"; // Swiper js
@import "plugin/ill-js"; // Ill JS
@import "plugin/OverlayScrollbars"; // OverlayScrollbars

@import "pages/mac_only";