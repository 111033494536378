.home-module.image-image {
    padding: 0 2rem;

    .module-link {
        display: block;
        width: fit-content;
    }

    > .swiper-container {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
    }

    .swiper-wrapper {
        height: 120vh;
        
        @media screen and(max-width: 1590px){
            height: 100vh;
        }

        @media screen and 
        (min-device-width : 768px) and 
        (max-device-width : 1024px) and 
        (orientation : portrait) {
            height: 60vh;
        }
    }

    .swiper-slide .sliderContent {
        position: relative;
        overflow: hidden;
        height: 100% !important;        
        @include flexbox(row, space-between, center, unset);
    }

    .small_pic {
        position: relative;
        z-index: 2;
        width: 33%;
        margin-bottom: 3rem;
    }

    .big_pic {
        position: relative;
        width: 60%;
    }

    .text-content {
        margin-bottom: 2rem;
        padding-left: 2rem;

        h2 {
            @extend .home-module-title;
            white-space: nowrap;
            line-height: 1.2;
            
            @media screen and(max-width: 1430px) {
                font-size: 2rem;
            }
        }
    }

    .ill_img {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .small_pic img {
        padding-left: 2rem;
    }

    .module-cta span {
        display: inline-block;
        margin-top: 1rem;
        font-weight: 700;
        font-size: .875rem;
        border-bottom: 2px solid;
        text-transform: uppercase;
    }
}

.swiper-collection-button-container {
    transform: rotate(180deg);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 32%;
    width: 8%;
    height: 100%;
    @include flexbox(column, center, center, unset);

    .swiper-collection-button {
        @extend .swiper_arrow; 
        margin: .5rem 0;

        &.swiper-collection-button-prev {
            @extend .arrowTop; 
        }
        &.swiper-collection-button-next {
            @extend .arrowDown; 
        }
    }
}