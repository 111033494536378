/***** COLORS *****/
$primaryColor           :       #231f20;
$secondaryColor         :       #231f20;
$menuColor              :       rgba(35, 31, 32, 0.5);
$btnColor               :       #274265;
$fadeGreyline           :       #F5F5F5;

/***** NEUTRALS *****/
$white                  :       #fff; 
$lightWhite             :       rgba(255, 255, 255, 0.432); /* Less opacity */
$black:                         #231f20;
$darkGrey               :       #5e5b5b;
$lightGrey              :       #e5e5e5;
$shadeColor             :         rgba(rgb(0, 0, 0), .5);
$greyIcon               :       #f3f3f3;


/***** TEMPLATE *****/
$borderColor            :       #cccccc;
$errorColor             :       #d62d65;
$successColor           :       #52bf52;

$couleur_prix_remisee   :       rgba(35, 31, 32, 0.5);

$lightgrey              :       #d9d9d9;

$primaryPlaceHolder     :       #868686;

$navBar                 :       rgba(204, 204, 204, 0.80);
$navBar_active          :       rgba(35, 31, 32, 0.80);
$greyBank               :       #7B7979;