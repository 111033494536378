.pager_wrapper.bot_pager {
    .see_all_product,
    .precedent.vector_sprite,
    .suivant.vector_sprite {
        display: none;
    }

    .pagination {
        @include flexbox(row, center, center, unset); 
    }

    .pagination a, 
    .pagination span {
        width: 30px;
        height: 30px;
        @include flexbox(row, center, center, unset);
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: normal;
        cursor: pointer;
        padding-left: 1px;

        &.active {
            font-weight: 400;
            border: 1px solid $primaryColor;
        }
    }

    .pagination {
        span {
            margin: 0 0.95rem;
        }

        a,
        .hellip {
            margin: 0 0.25rem;
        }
    }
}

.wrap_flitre_pager {
    .loader_scroll {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 0.75rem;
        margin-bottom: 2.5rem;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
    
        &.infiniteScroll {
            opacity: 0 !important;
        }

        .button.primary {
            border: none;

            &:hover {    
                background-color: transparent;
                color: black;
            }

            span {
                color: $primaryColor;
                text-align: center;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                position: relative;
                text-transform: initial;

                &:after {
                    content: " ";
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0.05rem;
                    left: 0;
                    background-color: $primaryColor;
                }
            }

            &.loader {
                position: absolute;
                top: 0;
                left: 0;
            } 
        }
    }

    &:has(.loader_scroll[style="display: none;"]) {
        .pager_wrapper.bot_pager {
            margin-bottom: 2.5rem;
        }
    }
}