@keyframes fadeInVisited {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

body.product_page #gondole_derniers_articles_vus {
    opacity: 0;
    animation: fadeInVisited 1s ease-in-out 0.5s forwards;
    padding-bottom: 3rem;
    
    #derniers_articles_vus { // Title
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        white-space: wrap;
        margin-bottom: 15px;
        margin-left: 18px;

        @media screen and (max-width: 1299px) {
            margin-bottom: 2rem;
        }
    }
    
    #productVisitedSwiper_wrapper {
        width:  100%;
        position: relative;

        #productVisitedSwiper {
            width: 100%;

            .item {
                margin-right: 7px;
                max-width: 380px;
            }
        
            .imgWrapper {
                position: relative;
                max-width: 380px;
                height: auto;
        
                @media screen and (max-width: 1299px) {
                    max-width: none;
                }

                .swiper-wrapper.product_picture {
                    position: relative;
                }

                .productBigPictures img {
                    aspect-ratio: 323/388;
                    max-height: 456px;
                }

                .wrap_rolloverproduit {
                    position: absolute;
                    bottom: -100%;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    transition: all .3s ease-in-out;

                    @media screen and (max-width: 1024px) {
                        width: 99.9%;
                    }

                    form {
                        background-color: $white;
                        border: 1px solid $primaryColor;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;

                        .form_submit.bloc_add_color.hideAchat {
                            display: none !important;
                        }

                        .rollover_left.achat_express .productColorFieldset .choices_list {
                            .form_itm.check.color {
                                &::before {
                                    display: none;
                                }
                
                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .form_submit.bloc_add_color {
                            .w-submit-button {
                                &:after {
                                    margin: 0 9px 3px;
                                }

                               span {
                                    margin-bottom: 0px;

                                    @media screen and (max-width: 1615px) {
                                        display: none;
                                    }
                               } 
                            }
                        }

                        .rollover_left.achat_express {
                            width: 100%;
                            padding: 0.8rem;

                            .productSizeFieldset {
                                width: 70%;

                                @media screen and (max-width: 1615px) {
                                    width: 90%;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .wrap_rolloverproduit {
                        bottom: 3px;
                    }
                }

                .wrapper_description {
                    margin-top: 0.5rem;
                    margin-left: 20px;

                    .item_title {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;

                        strong {
                            font-weight: 400;
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 1rem;
                            height: 2px;
                            margin: 0.5rem 0;
                            background-color: $primaryColor;
                        }
                    }
                }

                .caract_container {
                    position: absolute;
                    right: -40px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 2;

                    .faitenfrance_pastille {
                        display: none;
                    }
                }

                .item_price {
                    margin-left: 20px;
                    flex-wrap: wrap;
                    align-items: flex-start;

                    .pricetag {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
        
                        +.pricetag {
                            color: rgba(35,31,32,.5);
                            position: relative;
                            
                            &:before {
                                content: '';
                                width: calc(100% - .875rem);
                                height: 1px;
                                background-color: rgba(35, 31, 32, .5);
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    .eclat_rond {
                        margin-left: 0.875rem;

                        @media screen and(max-width: 1024px) {
                            margin-left: 0;
                        }
                    }
                }
            }
        
            .item.assoc {
                .product_picture {
                    .wrap_video {
                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .swiper-slide {
                        img {
                            z-index: 1;
    
                            & + img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                z-index: 2;
                                transition: 0.3s ease-in-out;
                            }
                        }
    
                        &:hover {
                            img {
                                & + img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            .productVisualMulti {
                .rollover_left.achat_express {
                    width: 100%;

                    .productSizeFieldset {
                        width: 70%;

                        @media screen and (max-width: 1615px) {
                            width: 90%;
                        }
                    }
                }
                
                .bloc_add_color .w-submit-button {
                    position: absolute;
                    bottom: 1px;
                    right: 2%;
    
                    @media screen and (max-width: 1615px) {
                        bottom: 5px;
                    }

                    span {
                        @media screen and (max-width: 1615px) {
                            display: none;
                        }
                    }
                }
            }

            .product_rollover .choices_list .form_itm.check.color:hover:before {
                display: none;
            }

            .wrapper_description {
                margin-top: 0.5rem;
                margin-left: 20px;

                .product_titles {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    strong {
                        font-weight: 400;
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 1rem;
                        height: 2px;
                        margin: 0.5rem 0;
                        background-color: $primaryColor;
                    }
                }

                .product_prices {
                    height: 28px;
                }
            }

            .btnAddBasketWrapper {
                &:has(.expressAlertStock) {
                    .btnAddBasketWrapper {
                        padding: 10px;
                    }

                    .alert_return {
                        right: 14px;
                        top: 18px;
                    }
                }

                &:has(.noSizeAvailable) {
                    .btnAddBasketWrapper {
                        padding: 15px;
                    }
                }

                .alert_return {
                    position: absolute;
                    right: 12px;
                    top: 18px;
                }

                #size_is_not_available {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }

                .bloc_add_alert_confirmation  {
                    p {
                        margin-bottom: 0 !important;
                    }
                }
            }

            .caract_offrebox_container {
                display: none;
            }

            &:not(.swiper-container-initialized) {
                +.product_visited_prev {
                    opacity: 0;

                    +.product_visited_next {
                        opacity: 0;
                    }
                }
            }
        }
        
        .product_visited_button {
            position: absolute;
            z-index: 20;
            top: 35.5%;
            width: 16px;
            height: 16px;
            background-image: url('../svg/fp_cross.svg');
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
        
            &.product_visited_next {
                transform: rotate(180deg);
                left: calc(100% - 45px);
            }
        
            &.product_visited_prev {
                right: calc(100% - 45px);
            }
        
            &.swiper-button-disabled {
                opacity: 0;
            }
        }
    }
}

body.product_page { 
    .productVisualMulti{
        &:hover .product_rollover {
            bottom: 3px;
        }
    }

    .rollover_left.achat_express .ligne_form.productSizeFieldset .form_itm.check.size {
        &:hover {
            text-decoration: none;
        }

        label {
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                background-color: transparent;
                transition: background-color .3s;
                height: 2px;
                bottom: -2px;
                cursor: pointer;
            }

            &:hover::after {
                height: 2px;
                bottom: -2px;
            }
        }
    }
}