.product_infos_container {
    position: sticky;
    top: calc(3.9rem + #{$headerHeight});
    width: 47%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 75px 67px 0 5.6%;

    @include flexbox(column, center, center, nowrap);
    overflow: hidden;

    @media screen and (max-width: 900px) {
        height: calc(70vh - #{$headerHeight});
    }

    @media screen and (max-width: 900px) {
        padding: 0 5%;
        width: 40%;
    }

    @media screen and (max-width: 1024px) and (max-height: 768px) {
        height: 107vh;
    }

    // Fil d'ariane
    #breadcrumbs {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 6.306vw;

        &:before {
            content: none;
        }

        @media screen and (max-width: 1440px) {
            padding-left: 3.554vw;
        }

        @media screen and (max-width: 1024px) {
            padding-left: 2.98vw;
        }

        @media screen and (min-width: 1921px){
            padding-left: 121px;
        }

        .breadcrumbs {
            flex-wrap: wrap;
        }
    }

    .btnAddBasketWrapper {
        padding: 0;

        #keep_alive_stock {
            .bloc_add_alert_form {
                .w-form-line {
                    width: 100%;

                    &.w-submit {
                        position: relative;
                        width: 100%;

                        .w-submit-button {
                            background-color: transparent;
                            vertical-align: middle;
                            text-transform: uppercase;
                            cursor: pointer;
                            content: " ";
                            position: absolute;
                            top: -27px;
                            right: 0px;
                            width: .5rem;
                            height: .5rem;
                            border: 1px solid $primaryColor;
                            border-bottom: none;
                            border-left: none;
                            transform: rotate(45deg);
                            padding: 0;

                            span {
                                display: none;
                            }

                            &.w-loader {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        #size_is_not_available {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        &.inactiv {
            .btnAddBasket {
                color: $primaryColor;
                border: 1px solid $primaryColor;
                background-color: $white;
            }
        }

        .btnAddBasket {
            color: $primaryColor;
            border: 1px solid $primaryColor;
            background-color: $white;
            transition: all ease-in 0.5s;

            &:hover {
                color: $white;
                background-color: $primaryColor;
            }
        }
    }

    .userTooltip {
        border: none;
        top: 28px;
        left: -8px;
        padding: 0;
        text-align: left;

        &::before {
            border: none;
        }

        span {
            font-size: 16px;
        }

        @media screen and(max-width:1024px) {
            left: -20px;
        }
    }

    .itm_back_btn {
        padding: 15px 0 0 5.4vw;

        span {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: lowercase;

            &:before {
                margin-right: 10px;
                width: 14px;
                height: 14px;
                background-image: url('../svg/fp_cross.svg') !important;
                background-repeat: no-repeat;
                background-size: contain !important;
            }
        }

        @media screen and (max-width: 1440px) {
            padding: 15px 0 0 45px;
        }

        @media screen and (max-width: 1024px) {
            padding-left: 2.98vw;
        }

        @media screen and (min-width: 1921px){
            padding-left: 121px;
        }
    }

    .evaluation.desktop {
        position: relative;
        background: #F5F5F5;
        padding: 0.7rem 1.4rem .7rem 1rem;
        bottom: 0px;
        left: 40%;
        z-index: 5;

        @media  screen and(max-width: 1450px) {
            left: 35%;
        }

        .media_title {
            color: #797979;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        .wrap_rating {
            display: flex;

            span:first-child {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.0625rem;
                text-transform: uppercase;
            }

            .rating_stars {
                display: inline-block;
                width: 70px;
                height: 11px;
                background-image: url(../img/rating_stars.png);
                background-repeat: no-repeat;
                background-position: top left;
                background-size: cover;
                position: relative;
                top: 4px;
                left: 8px;
            }
        }
    }
}

@media (max-height: 820px) {
    .product_infos_container {
        height: fit-content;
        padding-top: 10%;
    }
}

.product_info,
.product_feats_wrapper,
#chez_vous_le,
.share_wrapper {
    width: 100%;
}

.product_feats_wrapper {
    position: relative;
    margin-bottom: 13px;

    .primary_navbar {
        margin-bottom: 0;

        &::before {
            display: none;
        }

        .navbar_tab {
            display: inline-block;
            width: auto;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            padding: 0;
            margin-right: 0 !important;

            &.active {
                .thumblnk {
                    span {
                        color: $primaryColor;
                    }
                }
            }

            &:not(:last-child) {
                padding-right: 40px;

                @media screen and (max-width: 1425px) {
                    padding-right: 30px;
                }
                @media screen and (max-width: 900px) {
                    padding-right: 15px;
                }
            }

            .thumblnk {
                display: block;

                span {
                    color: rgba(35, 31, 32, 0.5);
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }

            &:after {
                display: none;
            }
        }
    }

    .thumbcnt {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        li {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            padding-left: 16px;

            &.product_ref {
                span {
                    font-weight: 400;
                    padding-right: .5rem;
                }
            }

            &::before {
                content: "•";
                display: inline-block;
                position: absolute;
                left: -4px;
            }

            &:not(:last-of-type) {
                margin-bottom: 6px;
            }

            strong {
                font-weight: 400;
            }
        }

        .thumbslide {
            .txt,
            .full-text {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                margin-top: 10px;

                strong {
                    font-weight: 500;
                }

                .seeLess {
                    margin-left: 10px;
                }

                .seeLess,
                .seeMore {
                    cursor: pointer;
                    float: right;
                }

                .seeMore {
                    width: 100%;
                    max-width: 58px;
                }
            }

            .trimmed-text {
                opacity: 0;

                .seeMore,
                .seeLess {
                    font-weight: 400;
                }
            }
        }
    }

    .nextNavDesc,
    .prevNavDesc {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 14px;
        height: 46.59px;
        background: $white url(../svg/fp_cross.svg) center left no-repeat;
        transform: rotate(180deg);
        margin: auto;

        &:after {
            display: none;
        }
    }

    .prevNavDesc {
        right: auto;
        left: 0;
        transform: inherit;
    }

    #fpDescDetails {
        &.swiper-container-initialized,
        &.swiper-initialized {
            width: calc(100% - 48px);
            margin: 0 auto;

            & + .thumbcnt + .prevNavDesc,
            & + .thumbcnt + .prevNavDesc + .nextNavDesc {
                display: flex;
            }
        }
    }
}

#chez_vous_le {
    display: none;
}

.product_infos_content {
    position: relative;
    width: 100%;
    margin-bottom: 10px;


    h1 {
        padding: 0 5rem .5rem 0;
        @extend .home-module-title;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        span {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            display: block;
            padding-top: .5rem;

            &::after {
                content: "";
                display: block;
                width: 1rem;
                height: 2px;
                margin: 1.2rem 0 .85rem;
                background-color: $primaryColor;
            }
        }
    }

    #ligne_couleur,
    #ligne_pointure {
        margin-bottom: 17px;

        .disabledBtnTooltip {
            padding: .5rem 0.15rem;
            border: none;
            text-align: left;
            top: 58%;
            background-color: transparent;

            &:before {
                display: none;
            }

            .disabledBtnInfos {
                font-family: $primaryFont;
                color: $primaryColor;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }
        }

        #kleep-container {
            .kleep-underline {
                align-items: flex-end;
                padding-bottom: 0;

                svg {
                    width: 14px;
                }
            }

            .kleep-button .kleep-default, .kleep-texts .kleep-custom, .kleep-underline {
                font-family: $primaryFont !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                color: $primaryColor;
                white-space: nowrap;
            }

            .kleep-underline img {
                display: none;
            }

            .kleep-underline span {
                text-decoration-line: underline;
            }

            .kleep-underline:after {
                display: none;
            }
        }

        #showSimilarProductsButton {
            display: none;
        }
    }

    .product_prices {
        margin: 1rem 0 2rem;
        position: relative;
        align-items: flex-start;

        #link_paiement_3fois {
            margin-left: auto;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-decoration-line: underline;
            display: none;

            strong {
                font-weight: 400;
            }
        }

        .payez_en_3_fois_sans_frais {
            position: absolute;
            right: 0;
        }

        #alma-widget {
            ._TSkFv {
                width: auto;
                border: none;
                padding: 0;

                ._bMClc {
                    margin-left: auto;
                    
                    ._LJ4nZ {
                        margin-right: 9px;
                        width: 40px;
                    }

                    ._17c_S {
                        gap: 10px;

                        ._2Kqjn {
                            padding: 2px 5px;
                            font-weight: 400;
                            font-family: $primaryFont;
                        }
                    }
                }

                .almaAmount {
                    display: inline-flex;
                }

                ._25GrF {
                    line-height: 140%;
                    position: relative;
                    font-family: $primaryFont;
                    display: flex;
                    align-items: flex-start;
                }

                .almaIcon {
                    display: inline-flex;
                    margin-left: .6rem;
                    width: 15px;
                    height: 15px;
                    background-image: url('../svg/ellipse.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        .item_price {
            margin-top: .12rem;

            .price_tag {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                +.price_tag {
                    color: rgba(35,31,32,.5);
                    position: relative;

                    &:before {
                        content: '';
                        width: calc(100% - .875rem);
                        height: 1px;
                        background-color: rgba(35, 31, 32, .5);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    /* Sizes + sizeguide section */
    .prod_listes_sizes {
        @include flexbox(row, unset, center, unset);

        @media screen and (max-width: 1024px) {
            @include flexbox(row, unset, center, wrap);
        }

        .block_sizeguide {
            display: flex;
            height: 34px;
            align-items: center;
            justify-content: flex-end;
            white-space: nowrap;

            .sizeguide {
                white-space: nowrap;
            }
        }

        .choices_list {
            width: 100%;
        }

        #kleep-container {
            margin-top: 1px !important; //override inline style
            margin-right: 1rem;
        }
    }

    .size_title {
        margin-right: 5px;
        border-right: 1px solid $primaryColor;

        span {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.5px;
            padding-right: 11px;
            color: $primaryColor;
        }
    }

    .sizeguide {
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
    }

    .choices_list .form_itm.check:not(:last-child) {
        margin: .5rem 23px .5rem 0;
    }

    .prod_listes_sizes .choices_list .form_itm.check:not(:last-child) {
        margin: 4px 15px 0.25rem 4px;
    }

    .choices_list .form_itm.check label {
        cursor: pointer;
        background-position: center;
        background-size: cover;
    }

    .choices_list {
        @include flexbox(row, flex-start, center, wrap);

        .form_itm {
            position: relative;
            margin-bottom: 0;

            &.check.color {
                margin-bottom: 0;
                transform: translateX(30%);
            }

            &.size {
                > input.size {
                    cursor: pointer;
                    position: absolute;
                    margin: 0;
                    opacity: 0;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;

                    &.disabled {
                        + label {
                            position: relative;
                            text-decoration: none;

                            span {
                                &:after {
                                    content: '';
                                    height: 1px;
                                    background-color: $primaryColor;
                                    width: calc(100%);
                                    position: absolute;
                                    top: 50%;
                                    right: 0;

                                    // Specific Firefox
                                    @-moz-document url-prefix() {
                                        top: 44%;
                                        left: -12%!important;
                                    }
                                }
                            }
                        }
                    }
                }

                label span {
                    color: $primaryColor;
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }

                &:hover {
                    text-decoration: none;

                    label span {
                        color: $primaryColor;
                    }
                }
            }
        }

        &:has(input:checked),
        &:hover {
            .form_itm.size  input {
                & + label span {
                    color: rgba(35, 31, 32, 0.5);
                }

                &.disabled {
                    & + label span {
                        &:after {
                            background-color: rgba(35, 31, 32, 0.5);
                        }
                    }
                }

                &:checked,
                &:hover {
                    & + label span {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    .roll_preco {
        position: absolute;
        top: 120%;

        p {
            font-size: .75rem;
            margin-bottom: 1rem;
        }
    }

    // Add to cart
    .form_submit {
        position: relative;

        .btnAddBasket,
        .loader {
            max-width: 100%;

            &.loading {
                background-size: 7%;
            }
        }

        #btn_add_cart {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        #btn_add_alert span {
            text-align: center;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .btnAddBasket.error{
            color: $primaryColor;
            border-color: $primaryColor;
            background-color: $white;

            &:hover{
                background: transparent;

                span{
                    color: $black;
                }
            }
        }

        #btn_add_alert {
            cursor: default;

            &.cta {
                cursor: pointer;

                &:hover{
                    background: $black;

                    span {
                        color: $white;
                    }
                }
            }

            &.no_cta {
                span {
                    opacity: 0.6;
                }
            }
        }

        .loader {
            position: absolute;
            top: 0;
            left: 0;
        }

        .w-submit-button span {
            text-align: center;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .w-input .w-input-label {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }
}

/* WISHLIST + SHARE */
.product_infos_content .action_list {
    position: absolute;
    top: 5px;
    right: -5px;
    width: fit-content;
    height: 2.9rem;
    @include flexbox(row, unset, flex-start, unset);

    @media screen and(max-width: 1440px) {
        right: 7px;
    }

    .action_item {
        cursor: pointer;

        &:first-of-type {
            padding-right: .5rem;
        }
        &:last-of-type {
            padding-left: .5rem;
            border-left: 1px solid $borderColor;
        }

        &.action_item_wishlist .btnWishlist {
            width: 1.5rem;
            height: 1.5rem;
            @extend .bgSprite;
            &:not(.existToWishlistButton) {
                @extend .heart;

                &:hover {
                    @extend .heartFilled;
                    margin-right: .1rem;
                }
            }

            &.existToWishlistButton {
                @extend .heartFilled;
                margin-right: .1rem;
            }
        }

        &.action_item_share{
            position: relative;

            &:hover {
                .userTooltip {
                    opacity: 1;
                }
            }

            .share_title {
                width: 1.5rem;
                height: 1.5rem;
                @extend .bgSprite;
                @extend .share;
            }
        }

        a,
        span {
            width: 100%;
            height: 100%;
        }

    }
}

.disabledBtnTooltip {
    top: 104%;

    &::before {
        bottom: 80% !important;
    }
    span {
        @include wshopFont($primaryFont, .75rem, unset, unset, 900,unset, unset, $primaryColor);
    }
}

/* PRODUCT ALERT STOCK *******/
/*****************************/

.bloc_add_alert_form {
    @include flexbox(row, space-between, unset, wrap);

    &.w-alertestock-form {
        width: 100%;
        margin: 0;
    }

    .w-form-line {
        margin: .5rem;

        &:not(.w-submit) {
            width: 68.877%;
            height: 40px;
            margin: 0;

            #mail_alerte_stock {
                height: 40px;
            }

            .w-input-container {
                padding-right: 0;
            }
        }

        &.w-submit {
            width: 29.69%;
            height: 40px;
            margin: 0;

            .w-submit-button {
                padding: .5rem;
            }
        }
    }
}

body.product_page {
    .bloc_add_alert_confirmation {
        p {
            margin-bottom: 20px !important;

            @media screen and(max-width:1024px) {
                margin-bottom: 5px !important;
            }
        }

        span {
            line-height: normal;
        }
    }

    #sendfriend_alert_mail {
        width: 48%;
        margin: auto;
        margin-bottom: 36px;
    }

    #ligne_couleur {
        .form_itm.check.color {
            &::before {
                border: none;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: none!important;
                white-space: nowrap;
                bottom: 132%;
                padding: 0;
            }

            &::after {
                display: none;
            }
        }
    }

    .product_infos_content {
        .size_title span {
            text-transform: uppercase;
        }
    }

    #site_head_wrap.bandeau_on {
        + .main_wrapper {
            padding-top: 171px;

            .product_main_wrapper {
                .prod_top_wrapper {
                    .product_infos_container {
                        padding: 93px 55px 0 112px;

                        @media screen and(max-width: 1440px) {
                            padding: 6.5% 3% 0 3%;
                        }

                        .itm_back_btn {
                            padding: 35px 0 0 108px;

                            @media screen and(max-width: 1440px) {
                                padding: 34px 0 0 42px;
                            }

                            @media screen and(max-width: 1024px) {
                                padding: 34px 0 0 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Override w-submit style
.bloc_add_alert_confirmation {
    p {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;

        span {
            line-height: 1.55rem;
        }
    }

    button.w-submit-button {
        height: unset !important;
        max-width: 100% !important;
        padding: .5rem 2rem !important;
        color: $primaryColor !important;
        text-align: center;
        background-color: $white !important;
        border: 1px solid $black !important;

        &:hover {
            background-color: $black !important;
            color: $white !important;
        }
    }
}

.page_rayon.rayon_lookbook {
    & + #lightbox_achat_express {
        #keep_alive_stock + .bloc_add_alert_confirmation {
            p {
                margin-bottom: 7px !important;

                span {
                    line-height: 1rem;
                }
            }
        }

        #content_achat_express {
            #breadcrumbs {
                display: none;
            }

            form.product_info {
                #itm_name span {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }

                .disabledBtnTooltip.actif {
                    top: 58%;
                }
            }
        }

        #ligne_couleur {
            padding-left: 0.4rem;
        }

        // Override for CTA add to basket on lookbook only
        .page_produit .prod_top_wrapper .product_infos_container #bloc_add_basket {
            #btn_add_cart {
                &:disabled {
                    opacity: 0.6;
                }

                &:not(:disabled) {
                    color: white;
                }
            }
        }
    }
}

body.en {
    .product_feats_wrapper .thumbcnt .thumbslide {
        .full-text,
        .txt {
            .seeMore {
                max-width: 75px;
            }
        }
    }
}