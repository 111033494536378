.signup_module {
    width: 490px;

    h2,
    .title {
        @include wshopFont($primaryFont, 3.125rem, unset, .5px, 900, unset, unset, $primaryColor);
    }

    h3,
    .subtitle {
        @include wshopFont($primaryFont, .75rem, unset, .5px, 200, unset, unset, $primaryColor);
    }

    h2,
    h3,
    .title,
    .subtitle {
        margin-bottom: 1rem;
    }

    &.signup_module_img {
        .holder_img {
            width: auto;
            margin-left: .8rem;

            @media screen and (max-width: 1640px) {
                margin-left: 0;

                img {
                    width: 94%;
                    height: auto;
                }
            }
        }

        .holder_content {
            margin-top: 7%;
            
            h2 {
                @include wshopFont($primaryFont, 1rem, unset, .5px, 400, unset, unset, $primaryColor);
                margin: 0 0 0.2rem;
            }

            div {
                width: 97.6%;

                label.w-input.w-email-input {
                    p.w-input-label {
                        font-size: 14px!important;
                        left: 0;
                    }
                }
            }

            h3 {
                overflow: hidden;
                color: $black;
                text-overflow: ellipsis;
                text-transform: uppercase;
                font-family: $primaryFont;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: .5px;

                & + p {
                    font-size: 16px;
                    line-height: 1.3;
                    text-align: left;

                    em {
                        font-style: italic;
                    }

                    strong {
                        font-weight: 800;
                    }
                }
            }

            .w-newsletter-form {
                .w-input.w-email-input input.w-input-element {
                    height: 3rem;
                    border: none;
                    border-bottom: 1px solid $borderColor;
                    padding-left: 0;
                }

                .w-form-line.w-submit {
                    margin-top: 2rem;

                    .w-btn-wrapper {
                        cursor: pointer;
                        transition: all .2s ease-out;

                        &:hover {
                            background-color: $primaryColor;

                            span {
                                color: $white;
                            }
                        }

                        .w-submit-button {
                            span {
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: .5px!important;
                            }
                        }
                    }
                }

                .w-email-input.w-has-error {
                    input.w-input-element {
                        border-bottom: transparent;
                    }

                    &.w-focused {
                        input.w-input-element {
                            border-bottom: 1px solid $borderColor;
                        }
                    }
                }

                .w-btn-wrapper {
                    border: 1px solid $primaryColor;
                    padding: 12px 62px;
                    text-align: center;
                    max-width: 250px;
                    width: 100%;
                    font-size: 14px;

                    .w-submit-button span {
                        font-size: 15px;
                        letter-spacing: 2.85px !important;
                    }
                }
            }
        }
    }

    /* FORM */
    form.w-newsletter-form {
        width: 100%;

        .w-input-container {
            label.w-input.w-email-input {
                .w-input-label {
                    font-size: 16px!important;
                    color: $primaryPlaceHolder  ;
                }
            }
        }

        /* Input mail */
        .w-input.w-email-input .w-input-element {
            padding-right: 0;
            border-radius: 0;
        }

        /* Input cta submit */
        .w-form-line.w-submit {
            height: 100%;
            
            .w-submit-button {
                @extend .cta_span_underlined;
                padding: 0 !important;
                
                span {
                    text-transform: uppercase;
                }
            }
        }

    }
}

.signup_module form.w-newsletter-form .w-form-line.w-submit .w-submit-button.w-loader {
    width: 0;
    left: calc(-490px + 3rem);
    border: 1px solid $borderColor;
    
    &.loading {
        width: 490px;
    }
}

