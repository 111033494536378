@import '../../../../_app/_src/scss/desktop/mixins/spriter';

/***** MEDIA QUERIES *****/
@mixin medium {
    @media screen and (max-width: 1680px) {
        @content;
    }
}

@mixin small {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

/***** FLEXBOX *****/
@mixin flexbox($direction, $justify, $align, $wrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
    flex-wrap: $wrap;
}

/***** FONTS *****/
/*
Exemple :
@mixin wshopFont($primaryFont, 1rem, 1.5, .5px, 400, uppercase, unset, $primaryColor)
*/
@mixin wshopFont($family, $size, $lineHeight, $letterSpacing, $weight, $textTransform, $textDecoration, $color) {
	font-family: $family;
	font-size: $size;
	line-height: $lineHeight;
	letter-spacing: $letterSpacing;
    font-weight: $weight;
    text-transform: $textTransform;
    text-decoration: $textDecoration;
	color: $color;
}