/* Reusable style*/
.site-search-style {
    color: #868686;
    font-family: $primaryFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-indent: 0.3rem;
    letter-spacing: 0;
}

/* PAGE FAQ */
body.faq {
    .page_wrapper {
        min-height: auto;
    }

    /** HIDINGS ******************/
    .faq_notconnect,
    .faq_connect {
        span {
            display: none;
        }
    }
    // .cache is used by faq.js, don't forget to add it ! ;)
    .cache {
        display: none;
    }

    /** FAQ ***************************/
    /* remove padding from the block so that the newsletter registration is not too far apart from the FAQ block specifically on this page **/
    .wrapper_moncompte {
        padding-bottom: 0;

        .nav_moncompte {
            .title {
                font-family: $primaryFont;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                text-align: center;
                line-height: normal;
                letter-spacing: 0.5px;
                padding-bottom: 0;
            }
        }

        .site_search {
            width: 50%;
            /* The bottom margin of the site_search block must create a spacing similar to that of the newsletter module with the faq **/
            margin: 0 auto 4rem;
            position: relative;

            #faq_search {
                height: 3.15rem;
                max-width: 585px;
                border-color: #cccccc;

                &::placeholder {
                    color: #868686;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }

            .w-input.w-email-input {
                text-align: center;
            }
        }
        
        /* Following the same goal as the myaccount wrapper, reduce the top margin of the newsletter module in the footer **/
        .signup_module {
            margin: 4% auto 10%;
        }
        /* margin-top 10 rem to follow the principle of vertical alignment specifically on the FAQ page with the newsletter registration and the search field **/

        &.connected {
            .wrapper_moncompte_main.faq {
                .account__title {
                    display: block;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }
    }

    .faq {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
    }

    .site_search {
        .search_results {
            display: none;
            border: 1px solid $borderColor;
            border-top: none;
            background: #fff;
            position: absolute;
            top: 95%;
            width: 585px;
            min-height: 5rem;
            z-index: 2;
            font-size: .7rem;
            letter-spacing: .5px;
            transition: all 0.2s ease-out;
            max-height: 30rem;
            @extend .site-search-style;
            color: $black;
            margin-top: 3px;

            li {
                font-family: $primaryFont;
                min-height: 2.5rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid #aaa;
                cursor: pointer;
                margin-top: -1px;

                &:first-of-type {
                    border: none;
                }
            }
        }
    }

    /* Table ******************/
    .theme_global {
        @include flexbox(row, center, unset, wrap);
        max-width: 1024px;
        margin: 0 auto;
        visibility: hidden;
        height: 0;

        &.actif {
            visibility: visible;
            height: auto;
        }

        .theme {
            /* Clean Borders ***/
            border-left: 1px solid $lightgrey;
            border-top: 1px solid $lightgrey;
            max-width: 340px;
            width: 33%;
            height: 200px;
            @include flexbox(column, center, center, unset);
            text-align: center;
            padding: 2rem;
            cursor: pointer;
            
            /* Greater than 1521px */
            &:nth-child(n + 4) {
                border-bottom: 1px solid $lightgrey;
			}

            &:nth-child(3n),
            &:nth-child(6n) {
                border-right: 1px solid $lightgrey;
			}

            h2 {
                max-width: 120px;
                @include wshopFont($primaryFont, 1rem, 1.5, .5px, 900, unset, none, $primaryColor);
            }

            
        }
    }

    /* List ******************/
    .theme_page {
        width: 100%;
        max-width: 1440px;
        height: 0;
        opacity: 0;
        margin: 0 auto;
        overflow: hidden;
        transition: opacity .5s ease-in;

        &.actif {
            height: auto;
            opacity: 1;
        }

        .theme_menu {
            display: none;
        }
    }

    .faq_element {
        padding: 1.86rem 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $lightgrey;
        }

        .faq_title {
            position: relative;
            cursor: pointer;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            padding-right: 2rem;
            display: inline-block;
            text-transform: uppercase;

            &::after {
                content: " ";
                position: absolute;
                top: 18%;
                right: 0%;
                width: .5rem;
                height: .5rem;
                border-right: 1px solid $black;
                border-top: 1px solid $black;
                transform: rotate(135deg);
            }
        }

        &.active .faq_title::after {
            content: " ";
            position: absolute;
            top: 28%;
            right: 0%;
            width: .5rem;
            height: .5rem;
            border-right: 1px solid $black;
            border-top: 1px solid $black;
            transform: rotate(45deg);
        }

        .faq_questions {
            padding-top: 1.6rem;
        }

        .faq_question {
            width: fit-content;
            position: relative;
            cursor: pointer;
            padding-right: 1.6rem;
            margin-bottom: 1.8rem;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;

            &:after {
                content: " ";
                position: absolute;
                top: 18%;
                right: 0%;
                width: .5rem;
                height: .5rem;
                border-right: 1px solid $black;
                border-top: 1px solid $black;
                transform: rotate(135deg);
            }

            &.active {
                &:after {
                    content: " ";
                    position: absolute;
                    top: 28%;
                    right: 0%;
                    width: .5rem;
                    height: .5rem;
                    border-right: 1px solid $black;
                    border-top: 1px solid $black;
                    transform: rotate(45deg);
                }
            }
        }

        .faq_reponse {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-bottom: 1.5rem;
        }
    }

    .faq {
        width: 100%;
        max-width: 100%;
    }

    .wrapper_moncompte {
        padding: 1.8rem 2rem 3.9rem;
        width: 100%;
        min-width: 1007px;

        @media screen and (max-width: 1500px) {
            padding-bottom: 3.9rem;
            padding: 0 clamp(20px, 1.04167vw, 20px);
        }

        .nav_moncompte {
            .title {
                font-family: $primaryFont;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                text-align: center;
                line-height: normal;
                letter-spacing: 0.5px;
                padding-bottom: 0;
            }
        }

        .site_search {
            width: 585px;
            margin: 0 auto 3.2rem;

            #faq_search {
                height: 3rem;
                width: 100%;
                max-width: 585px;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                @extend .site-search-style;
                border: none;
                border-bottom: 1px solid #cccccc;

                &::placeholder {
                    @extend .site-search-style;
                    color: #868686;
                }
            }

            .w-input.w-email-input {
                text-align: center;
                display: flex;
                position: relative;
            }

            .search_icon_faq {
                height: 18px;
                width: 18px;
                background-image: url(../svg/searchv2.svg);
                background-repeat: no-repeat;
                position: absolute;
                right: 0.3%;
                bottom: 35%;
            }
        }

        .theme_global {
            width: 100%;
            max-width: 100%;

            .theme {
                height: 205px;
                width: 25.85%;
                max-width: unset;
            }

            .theme_title {
                span {
                    text-align: center;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

/* PAGE CONTACT */
body.contact_faq {
    .page_wrapper {
        min-height: unset;
    }

    .contactForm {
        width: 100%;
        max-width: 1024px;
        min-width: 1007px;
        margin: 0 auto 51px auto;
        padding: 28px 0 0 0;

        @media screen and (max-width: 1200px) {
            max-width: 900px;
            min-width: 800px;
        }
    }

    .w-contact-form {
        a {
            text-decoration: underline;
        }
    }

    .titre_contact {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        text-align: center;

        & + h2  {
            padding: 20px 0;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }

    .champs_requis {
        display: flex;
        font-size: .75rem;
        color: $darkGrey;
        display: none;

        &::before {
            content: "*";
            display: inline-block;
            padding-right: .5rem;
        }
    }

    #form_contact1 {
        padding-bottom: 0;

        input.w-input-element {
            border-radius: 0;
        }

        .w-form-line {
            margin-bottom: 30px;

            &:nth-child(4) {
                margin-bottom: 0;
            }
        }

        .w-input-label {
            color: #868686;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.5px;
            left: 0;

            &:before {
                width: 15px;
                height: 15px;
                border-color: $primaryColor;
            }
        }

        .w-input-element {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.5px;
            border-color: $borderColor;
            border-top: none;
            border-left: none;
            border-right: none;
            height: 50px;

            &.is-uppercased {
                text-transform: uppercase;
            }
        }

        select.w-input-element {
            width: 100%;
            display: block;
            padding-left: 10px !important;

            option {
                width: 100%;
                display: block;
            }
        }

        .w-dropdown.w-nonempty {
            select.w-input-element + .w-input-label {
                font-size: 16px;
                transform: translateY(-100%);
            }
        }

        .w-input {
            &.w-dropdown {
                height: 50px;
                box-shadow: inset 0 -1px 0 0 $borderColor;

                span.w-value {
                    color: $primaryColor;
                    font-family: $primaryFont; 
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    line-height: 21px;
                    text-indent: 1.5px;
                    padding-left: 0;
                }
            }

            &.w-text-input,
            &.w-email-input {
                .w-input-element {
                    padding-left: 0;
                }

                .w-input-label {
                    top: 51%;
                }

                &.w-nonempty {
                    .w-input-label {
                        top: 52%;
                        font-size: 16px;
                    }
                }
            }
        }

        label.w-textarea {
            padding-top: 1rem;

            textarea.w-input-element {
                height: 200px;
                padding-left: 0;
                padding-top: 0;
                padding: 0 0 0 0 !important;
                border: 0;
            }

            .w-input-label {
                line-height: 19px;
            }

            &.w-nonempty {
                .w-input-label {
                    transform: translateY(25%) !important;
                    font-size: 16px;
                    line-height: 13px;

                    span {
                        position: relative;
                        top: -6px;
                    }
                }

                textarea.w-input-element {
                    padding-bottom: 1.5rem !important;
                }
            }
        }

        #optinId {
            margin-bottom: 2rem;

            .w-form-line {
                margin: 0;
            }
            
            .w-input-label {
                left: 0;

                & + .w-input-label {
                    left: 0.65rem;
                }
                
                span {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }
            }

            .w-fieldset-label {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                margin-bottom: 15px;
            }

            .w-form-line .w-input-element {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            .w-radio-group {
                .w-input.w-radio-input + .w-input.w-radio-input {
                    margin-left: 1.35rem;
                }

                &.w-has-error .w-input-element + .w-input-label::before {
                    border-color: #ff6d6d;
                    box-shadow: none;
                }
            }
        }

        #recaptcha {
            margin-bottom: 4px;

            iframe {
                transform: scale(0.81);
                transform-origin: 0 0;
            }
        }

        .w-form-line.w-submit {
            button {
                border: 1px solid $primaryColor;
                width: 100%;
                max-width: 244px;
                height: 40px;

                span {
                    font-family: $primaryFont;
                    font-size: 16px;
                    color: $primaryColor;
                    text-align: center;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                &:hover span {
                    color: $white;
                }
            }
        }

        .w-has-error:not(.w-dropdown) {
            .w-input-element:not([type=radio]):not([type=checkbox]), 
            input[type=email].w-input-element, 
            input[type=password].w-input-element, 
            input[type=text].w-input-element, 
            textarea.w-input-element {
                border-color: #ff6d6d;
                box-shadow: unset;
            }
        }
    }
}

.wrapperContentFaqForm #contact_form {
    #mes_err_fieldset{
        margin-bottom: 29px;
        display: block;
    }
}