#selection-offer {
    .selection-offer-header {
        p {
            font-weight: 400!important;
            
            &.selection-offer-title {
                font-weight: 400;
                font-size: 25px;
                line-height: 32.51px;
                margin: 0 auto;
            }
        }
    }

    .selection-offer-content {
        .lb-v-scrollbar,
        .lb-v-scrollbar-slider {
            width: 1px!important;
        }
    }

    #wrap_lion {
        align-items: initial;

        .size_list {
            input[type=radio]:checked + label {
                font-weight: 400;
            }
        }

        .titleOfferedProd {
            font-weight: 400!important;
            font-size: 16px!important;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                display: block;
                font-size: 16px;
            }
        }
    }

    #errorLabel {
        margin-bottom: 13px;
    }

    .selection-offer-submit {
        .button {
            width: 450px;
            max-width: 450px;
        }
    }

    .js-form-selection-offer {
        min-height: auto!important;
        height: auto;
        border-color: #ebebeb!important;

        &:before{
            background: white!important;
            border-color: #ebebeb !important;
        }

        &.actif{
            border-color: #000!important;

            &:before{
                background: black!important
            }
        }
    }

}