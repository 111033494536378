/* Bandeau Haut */
#promoSwiper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    max-width: 100%;
    padding: 5px 0;
    background-color: $black;
    position: relative;
    z-index: 70;

    .swiper-slide {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;

        a {
            color: inherit;
        }
    }

    strong {
        font-weight: 700;
    }

    em {
        font-style: italic;
    }
}

header#site_head_wrap {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    z-index: 55;

    &.sticky,
    &.hover {
        background-color: $white;
    }
}

/* nav header */
.nav_header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 1007px;
    max-width: 1920px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 70px;
    z-index: 25;
    margin: auto;

    &.bgColor {
        background-color: $white;
    }

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
    }

    @media screen and (max-width: 820px) {
        max-width: 820px;
        min-width: unset;
    }

    .site_logo {
        display: flex;
        justify-content: flex-start;
        padding-top: 0.25rem;
        padding: 0 clamp(20px, fs-vw(20, 1920), 20px);
        
        .logo {
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .menu_top_wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.25rem;

        .univers {
            #maison {
                .sous_menu_wrapper {
                    width: 156.66px;

                    &:first-child {
                        .insideCat + .insideCat {
                            display: none;
                        }
                    }

                    &:nth-child(2) {
                        .ss_cat {
                            .objet {
                                li {
                                    &:nth-child(2),
                                    &:nth-child(4),
                                    &:last-child  {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (min-width: 1025px) {
            #leftMenu {
                width: 100%;

                .main_menu_itm {
                    width: 100%;
                    
                    .submenu_wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        .menu_categ_wrapper {
                            width: 100%;
                            display: flex;
                            justify-content: center;

                            .block_lnk {
                                text-transform: uppercase;
                                height: 70px;

                                &.promote span {
                                    font-weight: 700;
                                }

                                &:hover:not(.promote) span {
                                    color: var(--hovercolor, var(--textcolor, $primaryColor));
                                }
                            }

                            .submenu {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0 25px;

                                @media screen and (max-width: 1920px) {
                                    padding: 0 fs-vw(18.816, 1920);
                                }

                                @media screen and (max-width: 1441px) {
                                    padding: 0 fs-vw(20, 1920);
                                }

                                @media screen and (max-width: 1370px) {
                                    padding: 0 fs-vw(15, 1920);
                                }

                                @media screen and (max-width: 1280px) {
                                    padding: 0 fs-vw(10, 1920);
                                }

                                .cat_name {
                                    text-align: center;

                                    .current {
                                        color: $primaryColor;
                                        
                                        span {
                                            color: $primaryColor;
                                        }
                                    }

                                    & > .block_lnk {
                                        color: $primaryColor;
                                        transition: color ease-in-out 0.5s;
                                    }

                                    & > .default_color {
                                        & > span {
                                            color: $primaryColor;
                                            transition: color ease-in-out 0.5s;
                                        }
                                    }
                                }

                                & + .menu_img {
                                    display: none;
                                }

                                .ss_menu_categ_wrapper:first-child {
                                    display: flex;
                                    position: absolute;
                                    left: 0;
                                    width: 100%;
                                    background: grey;
                                    padding-left: 120px;
                                }

                                &:hover {
                                    .block_lnk span {
                                        color: var(--hovercolor, var(--textcolor, $primaryColor));
                                    }

                                    & > .cat_name > .block_lnk > span,
                                    & > .cat_name > .default_color > span {
                                        color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                                    }

                                    .ss_menu_categ_wrapper {
                                        display: flex !important;
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }

                                &.not-hovered {
                                    & > .cat_name > .block_lnk > span,
                                    & > .cat_name > .default_color > span {
                                        color: var(--textcolor, var(--textcolor, $menuColor));
                                    }
                                }

                                &:focus {
                                    .block_lnk span {
                                        color: var(--hovercolor, var(--textcolor, $primaryColor));
                                    }
                                }

                                &.univers {
                                    ul.ss_cat .ss_cat_name {
                                        text-transform: uppercase;
                                    }

                                    #maison {
                                        .bloc_submenu_wrapper {
                                            .sous_menu_wrapper + .sous_menu_wrapper {
                                                .ss_cat {
                                                    .objet {
                                                        span {
                                                            text-transform: lowercase;
                                                            line-height: 29px;
                                                            font-size: 16px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .ss_menu_categ_wrapper {
                                justify-content: space-between;
                                width: 100vw; 
                                height: auto;
                                min-height: 200px;
                                position: fixed;
                                left: 0;
                                right: 0;
                                top: 68px;
                                background: $white;
                                margin: auto;
                                z-index: 2;
                                opacity: 1;
                                visibility: visible;
                                transition: opacity 0.5s ease-in-out;

                                .ss_submenu_wrapper {
                                    position: relative;
                                    width: 100%;
                                    max-width: 1920px;
                                    display: flex;
                                    justify-content: space-between;
                                    margin: auto;
                                }

                                .empty_resizer {
                                    width: 100%;
                                    max-width: 120px;
                                }

                                .bloc_submenu_wrapper {
                                    display: flex;
                                    width: 100%;
                                    max-width: 1477px;
                                    padding: 0 clamp(20px, 1.04167vw, 20px) 20px;

                                    .sous_menu_wrapper {
                                        .ss_cat {
                                            padding-top: 20px;

                                            .ss_cat_name {
                                                color: $menuColor;
                                                font-family: $primaryFont;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                letter-spacing: 0.5px;
                                                z-index: 2;
                                                transition: color ease-in-out 0.5s;

                                                &.promote {
                                                    span {
                                                        font-weight: 700;
                                                    }
                                                }

                                                &.default_color {
                                                    color: $menuColor !important;
                                                }

                                                &.current {
                                                    color: $primaryColor;
                                                }

                                                &:hover,
                                                &.default_color:hover {
                                                    color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                                                }

                                                & + ul.objet {
                                                    a {
                                                        font-family: $primaryFont;
                                                        font-size: 16px;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        line-height: 26px;
                                                        letter-spacing: 0.5px;
                                                        transition: color ease-in-out 0.5s;

                                                        &.default_color {
                                                            color: $menuColor !important;
                                                        }

                                                        &:hover,
                                                        &.default_color:hover {
                                                            color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                                                        }
                                                    }
                                                }

                                                span.ss_cat_name {
                                                    padding-right: 3.645835vw;

                                                    @media screen and (max-width: 1875px) {
                                                        padding-right: 3.75vw;
                                                    }

                                                    @media screen and (max-width: 1440px) {
                                                        padding-right: 4vw;
                                                    }

                                                    @media screen and (max-width: 1280px) {
                                                        padding-right: 4.25vw;
                                                    }

                                                    &.current {
                                                        color: $primaryColor;
                                                    }
                                                }

                                                .maison_rodier_link {
                                                    color: inherit;
                                                }
                                            }

                                            .insideCat {
                                                margin-bottom: 8px;

                                                .wrap_ul {
                                                    ul.objet {
                                                        li {
                                                            a {
                                                                color: $menuColor!important;
                                                                padding-right: 3.645834vw;
                                                                transition: color ease-in-out 0.5s;
                                                                line-height: 26px;

                                                                &.current {
                                                                    color: $primaryColor!important;
                                                                }

                                                                &.promote {
                                                                    font-weight: 700;
                                                                }

                                                                &:hover {
                                                                    color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .img_push_wrapper {
                                    img {
                                        display: block;
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }

                            @media only screen and (min-width: 1025px) {
                                .ss_menu_categ_wrapper.cache {
                                    display: flex !important;
                                    opacity: 0;
                                    visibility: hidden;
                                }
                            }
                    
                        } 
                    }

                    a.block_lnk {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;

                        &:hover {
                            color: var(--hovercolor, var(--textcolor, $primaryColor));
                        }
                    }

                    a.block_lnk span {                        
                        height: 70px;
                        display: inline-flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                    }
                }

                #menu_maison,
                #menu_store,
                #redonner-rodier {
                    display: none;
                }
            }
        }

        @media screen and (max-width: 1280px) {
            width: 67%;
        }

        @media screen and (max-width: 1180px) {
            width: 66%;
        }

        @media screen and (max-width: 1065px) {
            width: 65%;
        }

        @media screen and (max-width: 1024px) {
            display: none;
            position: absolute;
            top: 0;
            left: unset;
            right: 0;
            transition: right 0.25s ease-in-out;
            width: 100%;
            max-width: 422px;
            height: 100vh;
            background-color: $white;
            z-index: 5;

            &.active_ipad {
                display: flex;
                right: 0;
                max-width: 442px;
                margin-right: -20px;
            }

            #leftMenu {
                width: 100%;
                height: 100vh;
                z-index: 5;
                background: $white;
                padding: 0 3rem;
                margin-top: 12.5rem;
                padding-bottom: 12.5rem;
                overflow-y: scroll;

                h2 a, 
                p, 
                a,
                span {
                    font-size: 16px;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    line-height: 2;
                    font-weight: 200;
                }

                .menu_img {
                    display: none;
                }

                .cat_name.is-clicked {
                    .block_lnk {
                        font-weight: 700;
                    }
                }

                .ss_menu_categ_wrapper {
                    padding-left: 0.8rem;
                    border-left: 1px solid #ccc;
                }

                span {
                    color: $menuColor;
                    transition: color ease-in-out 0.5s;

                    &:hover {
                        color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                    }
                }

                a.current {
                    span {
                        color: $primaryColor;
                    }
                }

                .wrap_ul {
                    .objet {
                        a {
                            transition: color ease-in-out 0.5s;

                            &.default_color {
                                color: $menuColor !important;
                            }

                            &:hover,
                            &.default_color:hover {
                                color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                            }
                        }
                    }
                }

                #menu_homme {
                    .sous_menu_wrapper {
                        .wrap_ul {
                            .objet {
                                a {
                                    transition: color ease-in-out 0.5s;

                                    &.default_color {
                                        color: $menuColor !important;
                                    }

                                    &:hover,
                                    &.default_color:hover {
                                        color: var(--hovercolor, var(--textcolor, $primaryColor)) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header_right_side {
        display: flex;
        justify-content: flex-start;
        padding: 0 clamp(20px, fs-vw(20, 1920), 20px);
        min-width: 246px;

        @media screen and (max-width: 1024px) {
            min-width: 0;
        }

        .header_right_wrapper {
            width: 100%;
            max-width: 231px;
            min-width: 140px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 1024px) {
                width: 100%;
                max-width: 139px;
                justify-content: space-between;
                gap: unset;
            }

            #search_bar,
            .lang_switch_ctn,
            .accountItem,
            #cart_top {
                .item_title,
                .lang_switch,
                a.item_link,
                &.cartItem {
                    z-index: 10;
                }
            }

            #search_bar {
                @media screen and (max-width: 1024px) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    content: none;

                    &:before {
                        content: " ";
                        display: block;
                        width: 33px;
                        height: 33px;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(3, 5, 5);
                        background-position: 50% -1%;
                        position: relative;
                        top: -2px;
                        left: -8px;
                        z-index: 55;
                        cursor: pointer;
                    }
                }

                a.item_link {
                    z-index: 50;
                }

                .item_title {
                    width: 84px;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    text-align: right;
                    cursor: pointer;
                    letter-spacing: 0.5px;
                    position: relative;
                    left: -2px;
                    top: 3px;
                    z-index: 55;

                    @media screen and (max-width: 1024px) {
                        font-size: 13px;
                        display: none;
                    }
                }

                &.actif .item_title {
                    font-weight: 400;
                }
            }

            .lang_switch_ctn {
                z-index: 55;
                width: 17px;
                height: 22px;

                @media screen and (max-width: 1024px) {
                    display: none;
                }

                a.item_link,
                .lang_itm {
                    z-index: 55;
                }

                .lang_switch {
                    position: relative;

                    li {
                        display: none;
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        top: 1.5rem;
                        left: 3px;

                        &.active {
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            padding-top: 0;
                            position: relative;
                            left: 3px;
                            top: 6px;
                        }
                    }

                    .lang_itm {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.5px;
                    }

                    &:hover {
                        li {
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            transition: 
                                opacity 0.2s ease-in-out,
                                visibility 0.2s ease-in-out;
                        }
                    }
                }
            }

            .accountItem,
            #cart_top {
                p.item_title:not(.nb_basket) {
                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }
                
                .item_title:not(.nb_basket) {
                    visibility: hidden;
                    opacity: 0;
                }

                &.hover .item_title:not(.nb_basket) {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity 0.25s ease-in-out;
                    position: relative;
                    top: 0;
                    left: -8px;
                    z-index: 9;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;

                    span {
                        margin-left: 0!important;
                    }
                }
            }

            .accountItem:hover {
                p.item_title:not(.nb_basket) {
                    span {
                        margin-left: -8px;
                        position: absolute;
                        z-index: 8;
                    }
                }
            }

            .nav_header_item {
                width: 20px;
                height: 20px;

                .sub_menu {
                    &:after {
                        content: '';
                        position: fixed;
                        top: 0;
                        right: 0;
                        background: $white;
                        height: 100%;
                        width: calc((100vw - 1920px) / 2);
                    }
                }

                @media screen and (max-width: 1024px) {
                    width: 30px;
                    height: 30px;

                    nav.icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .accountItem {
                a.item_link {
                    z-index: 50;
                }

                .item_link {
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(1, 5, 5);
                    position: relative;
                    top: -2.5px;
                    
                    @media screen and (max-width: 1024px) {
                        width: 30px;
                        height: 30px;
                        position: relative;
                        top: 1px;
                        left: -4px;
                    }

                    & + .item_title {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }

                .item_title.activate {
                    display: block!important;
                    visibility: visible!important;
                    opacity: 1!important;
                }

                &:hover .sub_menu {
                    display: block!important;
                    visibility: visible!important;
                    opacity: 1!important;
                    right: 0!important;
                }
            }

            #cart_top {
                p.item_title:not(.nb_basket) {
                    @media screen and (max-width: 1024px) {
                        left: 14px;
                    }
                }     
                
                @media screen and (max-width: 1024px) {
                    nav.icon {
                        position: relative;

                        .item_title.nb_basket {
                            position: absolute;
                            transform: translateX(-50%);
                            left: 14px;

                            &:empty + a.item_link {
                                top: 1px;
                            }
                        }
                    }
                }

                .item_title.nb_basket {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: center;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    position: relative;
                    top: -50%;
                    left: 50%;
                    height: 17px;
                    margin-top: -16px;
                    transform: translateX(-36%);

                    @media screen and (max-width: 1024px) {
                        left: 12.5px;
                        margin-top: 0;
                    }

                    & + a.item_link + .item_title {
                        left: -3px;
                    }
                }

                p.item_title {
                    position: relative;
                    top: -2px;
                }

                .item_link {
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(2, 5, 5);
                    z-index: 55;
                    position: relative;
                    top: -3px;

                    @media screen and (max-width: 1024px) {
                        width: 30.72px;
                        height: 30.72px;
                        position: relative;
                        top: 1px;
                        left: -2px;
                    }
                }

                &.nav_header_item.cartItem.hasSubMenu.hover {
                    nav.sub_menu {
                        transform: unset;

                        .cart.hover_border {
                            display: none;
                        }

                        #show_top_cart {
                            .sub_menu_title {
                                margin-bottom: 1.2rem;
                            }

                            .sub_menu_subtitle,
                            .title_panier {
                                display: none;
                            }

                            #cart_list {
                                width: 362px;

                                .lb-v-scrollbar {
                                    right: -3px;
                                }
                                
                                &.more_than_two_products {
                                    width: 390px !important;
                                }

                                &.more_than_three_products {
                                    width: 395px !important;

                                    .remove_prod {
                                        right: 22px;
                                    }
                                }

                                .product-link {
                                    padding-right: 1rem;
                                }
                            }

                            .cart_product {
                                padding-top: 0;
                                margin-top: -3.6rem;                                

                                .pricetag + .pricetag {
                                    opacity: 0.5;
                                }

                                .wrap_top_info {
                                    order: 1;

                                    .title {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;
                                    }

                                    .sub_title {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;
                                    }
                                }

                                .sub_taille {
                                    order: 5;
                                    position: relative;
                                    top: 4rem;
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;

                                    span {
                                        margin-right: 2px;
                                    }
                                }

                                .sub_couleur {
                                    order: 4;
                                    position: relative;
                                    display: inline-flex;
                                    top: 4rem;
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    letter-spacing: 0.5px;

                                    .label_color_name {
                                        margin-left: 0.4rem;
                                        width: auto;
                                    }
                                }

                                .sub_quantity {
                                    display: none;
                                }

                                .modify_prod {
                                    order: 3;
                                }

                                .wrapper_price {
                                    order: 2;
                                }
                            }

                            .wrap_tot_panier {
                                &:before {
                                    content: " ";
                                    display: block;
                                    width: 100%;
                                    height: 1px;
                                    background-color: rgba(204, 204, 204, .8);
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }

                                .cart_panier_total {
                                    display: flex;
                                    justify-content: space-between;

                                    label,
                                    .pricetag {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;

                                        .cents {
                                            display: none;
                                        }
                                    }
                                }

                                .form_submit {
                                    .button.dark,
                                    .loader {
                                        width: 388px;
                                        max-width: 388px;
                                    }
                                }

                                .form_submit.cart_btn2 {
                                    .button,
                                    .loader {
                                        width: 100%;
                                        max-width: 100%;

                                        span {
                                            color: $primaryColor;
                                            font-family: $primaryFont;
                                            text-align: center;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                            letter-spacing: 0.5px;
                                        }
                                    }

                                    .button, 
                                    .loader {
                                        width: 388px;
                                        max-width: 388px;
                                    }

                                    .button.dark:hover {
                                        span {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .item_link + .item_title {
                        display: block;
                        position: relative;
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }

                .item_title.activate {
                    display: block!important;
                    visibility: visible!important;
                    opacity: 1!important;
                }

                &:hover .sub_menu {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                    right: 0;
                    z-index: 5;

                    .sub_menu_content {
                        .hover_border {
                            display: none;
                        }

                        #show_top_cart {
                            .sub_menu_title {
                                margin-bottom: 1.2rem;
                            }

                            .sub_menu_subtitle,
                            .title_panier {
                                display: none;
                            }

                            #cart_list {
                                width: 362px;

                                .lb-v-scrollbar {
                                    right: -3px;
                                }
                                
                                &.more_than_two_products {
                                    width: 390px !important;
                                }

                                &.more_than_three_products {
                                    width: 395px !important;

                                    .remove_prod {
                                        right: 22px;
                                    }
                                }

                                .product-link {
                                    padding-right: 1rem;
                                }
                            }

                            .cart_product {
                                padding-top: 0;
                                margin-top: -3.6rem;                                

                                .pricetag + .pricetag {
                                    opacity: 0.5;
                                }

                                .wrap_top_info {
                                    order: 1;

                                    .title {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;
                                    }

                                    .sub_title {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;
                                    }
                                }

                                .sub_taille {
                                    order: 5;
                                    position: relative;
                                    top: 4rem;
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;

                                    span {
                                        margin-right: 2px;
                                    }
                                }

                                .sub_couleur {
                                    order: 4;
                                    position: relative;
                                    display: inline-flex;
                                    top: 4rem;
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    letter-spacing: 0.5px;

                                    .label_color_name {
                                        margin-left: 0.4rem;
                                        width: auto;
                                    }
                                }

                                .sub_quantity {
                                    display: none;
                                }

                                .modify_prod {
                                    order: 3;
                                }

                                .wrapper_price {
                                    order: 2;
                                }
                            }

                            .wrap_tot_panier {
                                &:before {
                                    content: " ";
                                    display: block;
                                    width: 100%;
                                    height: 1px;
                                    background-color: rgba(204, 204, 204, .8);
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }

                                .cart_panier_total {
                                    display: flex;
                                    justify-content: space-between;

                                    label,
                                    .pricetag {
                                        color: $primaryColor;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                        text-transform: uppercase;

                                        .cents {
                                            display: none;
                                        }
                                    }
                                }

                                .form_submit {
                                    .button.dark,
                                    .loader {
                                        width: 388px;
                                        max-width: 388px;
                                    }
                                }

                                .form_submit.cart_btn2 {
                                    .button,
                                    .loader {
                                        width: 100%;
                                        max-width: 100%;

                                        span {
                                            color: $primaryColor;
                                            font-family: $primaryFont;
                                            text-align: center;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                            letter-spacing: 0.5px;
                                        }
                                    }

                                    .button, 
                                    .loader {
                                        width: 388px;
                                        max-width: 388px;
                                    }

                                    .button.dark:hover {
                                        span {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .menu_burger {
                display: none;
                position: relative;
                z-index: 55;
                
                @media screen and (max-width: 1024px) {
                    display: block;
                    cursor: pointer;
                    width: 29.17px;
                    height: 29.17px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(4, 5, 5);
                    position: relative;
                    top: 1px;
                    left: 3px;
                }

                &.actif {
                    @include bgIconCoord(25, 5, 5);
                }
            }
        }
    }
}

#search_bar_form {
    width: 100%;
    height: 84px;
    display: none;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    form#form_recherche {
        background-color: white;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 66px;
        padding-bottom: 30px;
        transition: 
            opacity 0.2s ease-in-out,
            visibility 0.2s ease-in-out;

        .sub_menu.wrap_search_field {
            position: relative;
            width: 898px;
            margin: 0 auto;

            @media screen and (max-width: 820px) {
                width: 796px;
            }

            .sub_menu_title,
            .sub_menu_subtitle {
                display: none;
            }

            #search_home {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                border: none;
                border-bottom: 1px solid $primaryColor;
                width: 882px;
                height: 38px;
                padding: 0;
                margin: 0 auto;
                display: flex;
                justify-content: center;

                @media screen and (max-width: 820px) {
                    width: 782px;
                }

                &::placeholder {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }

            .close.btn {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 40px;
                height: 26px;
                position: absolute;
                top: 0.46rem;
                right: .4rem;
                cursor: pointer;

                &::before {
                    content: " ";
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    background-color: $black;
                    mask-image: url(../svg/spritesheet.svg);
                    @include maskIconCoord(25, 5, 5);
                }
            }

            #search_results {
                position: absolute;
                width: 100%;
                background: $white;
                z-index: 1;
                padding: 0 0.5rem;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    padding: 10px 0;
                    cursor: pointer;
                    margin-top: -1px;

                    span {
                        strong {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &.actif {
        z-index: 21;
        position: absolute;
        background: $white;
        opacity: 1;
        visibility: visible;
        display: flex;
        height: auto;
        transition: 
            opacity 0.2s ease-in-out,
            visibility 0.2s ease-in-out;
    }
}

header {
    &.scrolled,
    &.hovered {
        width: 100%;
        margin: auto;
        position: fixed;
        width: 100%;
        z-index: 65;
        top: 0;
        background-color: $white;
        transition: 
            position 0.25s ease-in-out,
            background-color 0.25s ease-in-out;
    }
}

body.homepage {
    #site_global_wrap {
        margin-top: -90px;
    }

    @media screen and (max-width: 1540px) {
        #site_global_wrap {
            margin-top: -81px;
        }
    }
}

body {
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    &::-webkit-scrollbar { 
        display: none;
    }

    #site_head_wrap + #site_global_wrap {
        padding-top: 90px;

        #rayon_sticky_block {
            .fake_space {
                background-color: $white;
            }
        }
    }

    #site_head_wrap.bandeau_on + #site_global_wrap {
        padding-top: 128px;

        @media screen and (max-width: 1400px) {
            padding-top: 121px;
        }

        //to be responsive on ipad air 4
        @media screen and (max-width: 820px) {
            max-width: 820px;
        }

        #rayon_sticky_block {
            .fake_space {
                background-color: transparent;
            }
        }
    }

    &.fixed {
        overflow: hidden;
        padding-right: 17px;
    }
}

.os-theme-dark>.os-scrollbar>.os-scrollbar-track,
.os-theme-light>.os-scrollbar>.os-scrollbar-track {
    background-color: transparent !important;
}

.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background-color: $primaryColor !important;
    border-radius: 4px !important;
}