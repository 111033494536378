/*****************************************************************************
//  Vue.js Transitions
*****************************************************************************/

.pagefade-enter-active, .pagefade-leave-active {
    transition: opacity 0.5s linear;
}

.pagefade-enter, .pagefade-leave-to {
    opacity: 0;
}

[v-cloak] {
    display:none;
}
