/********************************************************************************/
/****** TUNNEL   ****************************************************************/
/********************************************************************************/


/***************************************************************/
/** TUNNEL TRACK ***********************************************/
.pushpanier,
.in_tunnel   {
    .primary_navbar>a,
    .primary_navbar>div,
    .primary_navbar>li,
    .tunnel_track>a,
    .tunnel_track>div,
    .tunnel_track>li,
    .wrapper_moncompte .sub_menu_list>a,
    .wrapper_moncompte .sub_menu_list>div,
    .wrapper_moncompte .sub_menu_list>li {
        padding: 1rem 0 9px;
    }
}

.tunnel_track_pusher {
    padding: 8px 0 30px;
    max-width: 1400px;
    margin: auto;
}

.tunnel_track_wrapper {
    position: relative;

    .cart_main_title.basket {
        display: none;
    }
}

.tunnel_track {
    @extend .primary_navbar;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
    justify-content: space-between;

    .tunnel_step {
        text-transform: initial;

        span {
            color: rgba(35,31,32,.5);
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        &.on {
            span {
                font-weight: 400;
                color: $primaryColor;
            }
        }

        &:after {
            display: none;
        }
    }

    &:before {
        content: none;
    }
}

/***************************************************************/
/** CART + TUNNEL COMMON STYLE *********************************/
.recap_cart_title {
    color: $primaryColor;
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    order: 1;

    span {
        font-weight: 400;
    }
}

#cart_total {
    .mot_personnalise_form {
        display: none;
    }

    .cart_product_title {
        margin-bottom: 0;
    }
}

.w-coupon-form {
    .w-form-line.w-submit {
        height: 100%;

        // TODO : clean on hover
        .w-submit-button {
            @extend .cta_span_underlined;
            padding: 0 !important;

        }
    }
}

.cart {
    .w-coupon-form .w-form-line .w-input-element:not([type="radio"]):not([type="checkbox"]),
    .w-coupon-form .w-form-line input[type="text"].w-input-element {
        border-radius: 0;
    }
}

.cart,
.in_tunnel {
    .main_wrapper > div {
        width: 100%;
        margin: 0 auto;
    }

    /** TUNNEL MAIN TABLE (products recap + questions) *******************************************/
    .main_table {
        display: flex;
        flex-direction: column;

        .bill_line {
            justify-content: space-between;
            font-size: .75rem;

            &:not(.total_produit_dropdown), &:not(.total) {
                display: flex;
                padding: .625rem 0 0.8rem;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            &.total_produit {
                position: relative;
                cursor: pointer;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding-top: 15px;
                padding-bottom: 15px;
                order: 2;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    height: 1px;
                    background-color: $borderColor;
                    transition: opacity ease .5s;
                    opacity: 1;
                    left: 0;
                }

                &.open {
                    &::after {
                        opacity: 0;
                    }

                    .nb_products::before {
                        transform: rotate(90deg);
                        top: 30%;
                    }
                }

                >label {
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                }

                .nb_products {
                    display:inline-block !important;

                    &::before {
                        background-image: url(../svg/fp_cross.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        content: "";
                        width: 12px;
                        height: 12px;
                        display: block;
                        top: 10%;
                        transform: rotate(270deg);
                        margin-left: .5rem;
                        transition: all ease .1s;
                        right: 0;
                    }
                }
            }

            &.total_produit_dropdown {
                display: none;
                position: relative;
                margin-bottom: .625rem;
                order: 3;

                &::after {
                    position: absolute;
                    content: "";
                    display: block;
                    top: 100%;
                    width: 100%;
                    height: 1px;
                    background-color: $borderColor;
                }

                .cart_product_line {
                    margin-bottom: 5px;

                    &:only-child {
                        margin-bottom: 0;
                    }

                    .cart_product_desc {
                        .wrap_titre_prix {
                            margin-bottom: 39px;
                            padding-bottom: 0;

                            .line_product_desc {
                                line-height: 18px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 90%;
                            }

                            .wrapper_cart_product_price {
                                &:after {
                                    top: -3px;
                                }
                            }
                        }

                        .wrapper_cart_product_desc {
                            p.cart_product_sizecol {
                                &.color {
                                    label {
                                        display: none;
                                    }

                                    .color_bullet {
                                        margin-bottom: 3px;

                                        & + span {
                                            display: block;
                                            font-size: 16px;
                                            text-transform: none;
                                            margin-left: 4px;
                                        }
                                    }
                                }

                                &.size {
                                    padding-left: 10px;

                                    label {
                                        text-transform: none;
                                    }
                                }

                                &.quantity {
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                    margin-top: 4px;

                                    label {
                                        text-transform: none;

                                        span {
                                            font-size: 16px;

                                            &:last-child {
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            p.max_stock {
                                order: 5;
                                position: absolute;
                                bottom: -0.7rem;
                                margin-left: -0.5rem;
                            }
                        }

                        .cart_product_modify {
                            height: 0;

                            .product_mod {
                                top: 110%;
                                left: 0;
                            }

                            .product_towish {
                                top: 122%;
                                left: 0;
                            }

                            .product_del {
                                right: 0;
                                top: 0;
                            }

                            .product_quantity_change {
                                display: none;
                                position: absolute;
                                top: 87%;
                                left: 5rem;

                                &:not(:root:root){
                                    top: 100%;
                                }

                                form.qte_selector {
                                    .change_qte {
                                        height: 20px;
                                        width: 20px;
                                        background: $black;
                                        cursor: pointer;
                                        line-height: 20px;
                                        display: none;

                                        span {
                                            color: $white;
                                        }

                                        &.lessQte {
                                            span {
                                                display: block;
                                                margin-top: -3px;
                                            }
                                        }
                                    }

                                    .selected_qte {
                                        width: 20px;
                                        height: 20px;
                                        text-align: center;
                                        background-color: transparent;
                                        font-size: 12px;
                                        color: $black;
                                        text-indent: 0;
                                        border: 0;
                                        padding: 0;
                                        line-height: 20px;

                                        &:not(:root:root){
                                            height: 21px;
                                        }
                                    }
                                }

                                .load_qte {
                                    height: 20px;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }

            &.discount {
                padding-bottom: 0;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 0.9rem 0 0.8rem;
                order: 4;

                &.hide_shippingfees{
                    display: none !important;
                }
            }

            &.total {
                order: 6;
                padding: 6px 0 12px;
            }

            &.promo {
                order: 7;
                padding: 6px 0 12px;
            }

            &.used_cp {
                order: 8;
                padding: 6px 0 12px;

                .code_promo {
                    display: flex;
                    align-items: center;

                    .close {
                        @extend .remove_prod;
                        position: relative;
                        top: auto;
                        right: auto;
                        margin-left: 3px;
                    }
                }
            }
        }

        .upselling.price {
            background-color: #dfe0e5;
            padding: .5rem 0 .5rem .5rem;
            padding: 6px 0 12px;
            margin-bottom: .3rem;
            order: 5;

            p {
                font-size: .78rem;

                strong {
                    font-weight: 700;
                }
            }
        }

        .formPanier_container {
            margin-top: 9px;
            display: grid;
            order: 8;

            #formPanier {
                order: 2;

                #btn_cmd_valid {
                    border: 1px solid $primaryColor;
                }

                .checkCgv {
                    .w-checkbox-input {
                        height: auto;
                    }

                    .shipment_cgv_link {
                        align-items: flex-start;
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.51px;
                        letter-spacing: 0.5px;
                        color: $primaryColor;
                        text-transform: none;
                        margin-bottom: 0;

                        &:before {
                            width: 13px;
                            height: 13px;
                            border: solid 1px $black;
                            margin-top: 3px;
                        }

                        span {
                            width: 90%;
                            margin-left: 9px;

                            a {
                                text-decoration: underline;
                            }
                        }
                    }

                    & + #err_cgv_compulsory {
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.51px;
                        letter-spacing: 0.5px;
                        text-transform: lowercase;
                        text-align: left;
                        color: $errorColor;
                        padding-left: 23px;
                        margin-top: 5px;

                        & + .form_submit {
                            margin-top: 14px;
                        }
                    }
                }

                & + #alma-widget {
                    display: none;
                }
            }

            .form_submit{
                position: relative;
                margin-top: 7px;

                #btn_cmd_valid {
                    max-width: 449px;

                    span {
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px
                    }
                }

                .loader{
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        #keep_alive_coupon {
            text-transform: lowercase;

            .w-coupon-form {
                .w-input-label {
                    text-transform: initial;

                    span {
                        color: $primaryPlaceHolder;
                    }
                }

                .w-input {
                    .w-input-element {
                        height: 41px;
                        border: 1px solid #cfcfcf;
                        padding: 0;
                        padding-left: 10px !important;
                        text-transform: none;
                    }

                    .w-input-label {
                        left: 10px;
                    }

                    &.w-nonempty {
                        .w-input-label {
                            display: none;
                        }
                    }
                }

                .w-submit {
                    position: absolute;
                    right: 10px;
                    width: min-content;
                    max-width: 449px;

                    .w-submit-button {
                        height: 100%;
                        width: auto;

                        span {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            border-bottom: none;
                            display: none;
                            padding-bottom: 0;

                            &.show {
                                display: block;
                            }
                        }

                        &.w-loader,
                        &.w-loader.loading {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .bloc_question {
        font-size: .75rem;
        margin-top: 29px;

        .faq_theme_wrapper:last-child {
            .title_question {
                display: block;
                padding-bottom: 10px;
            }

            .texte_question {
                padding-top: 0px;
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 16px;
                line-height: 20.51px;
                letter-spacing: 0.5px;
            }
        }

        #title_question_basket,
        .title_question {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            padding-bottom: 10px;
        }

        #title_other_question {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            padding-bottom: 10px;

            .label_question {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
        }

        .intitule_question {
            cursor: pointer;
            transition: all ease .3s;
            border-bottom: 1px solid $white;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;

            &.actif {
                border-bottom: 1px solid $borderColor;
                padding-bottom: 10px;
                color: $primaryColor;
            }
        }

        .texte_question {
            padding: .5rem 0 0.16rem;
            font-size: 16px;
            line-height: 20.51px;
            letter-spacing: 0.5px;

            strong {
                font-weight: 400;
            }

            a {
                text-decoration: underline;
            }
        }

        .faq {
            &:not(:last-of-type) {
                padding-bottom: 9px;
            }

            p {
                text-align: justify;
            }
        }

        .questions_frequentes {
            padding-top: 1rem;

            >div {
                padding-bottom: 10px;
            }
        }

        .faq_theme_wrapper {
            margin-bottom: 1.8rem;
        }

        .title_question {
            &:first-child {
                display: none;
            }
        }
    }

    .right_bloc_btm_paiement {
        padding: 3rem;
        margin: 2rem 0;
        border: 1px solid $black;

        .tunel_paiement_right_description {
            font-size: .75rem;
        }
        @media screen and (max-width: 900px) {
            padding: 1rem;
        }
    }

    #modboxpromo {
        padding: 2rem 2.5rem;
    }

    #resell-widget-btn {
        display: none;
    }
}



/********************************************************************************/
/** CART + STEP PANIER + STEP LIVRAISON *****************************************/

.cart {
    /** FLEX ON "WRAPPER PANIER" / STEP BY STEP **********/
    .wrapper_panier {
        display: flex;
        justify-content: flex-start;
        max-width: 1375px;
        width: 96%;
        margin: 0 auto;
        margin-bottom: 80px;
    }

    /**  TUNNEL LEFT & RIGHT SIDES  ******************/
    .left_side,
    .wrapper_panier>.wrapper_mes_adresses>.edit_adresse .left_side {
        margin-right: 50px;
        width: 100%;
        max-width: 826px;
        padding-right: 0 !important;

        .cart_product_line {
            margin-right: 2rem;
            margin-bottom: 5rem;
            align-items: center;

            .cart_product_sizecol {
                display: flex;

                &.size {
                    padding-left: 10px;

                    label {
                        display: block;
                        padding-right: 6px;
                    }
                }

                &.quantity {
                    font-size: 0;
                    margin-top: 0.4rem;

                    label {
                        span {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                        }

                        span:last-child {
                            display: none;
                            left: 65%;
                            position: absolute;
                        }
                    }
                }

                &.color {
                    span,
                    div {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        align-self: center;
                        padding-left: 5px;
                    }

                    .color_bullet {
                        margin-bottom: 0;
                    }

                    label {
                        display: none;
                    }
                }
            }

            .cart_product_modify {
                height: 0;

                .wishToBasket {
                    bottom: 0;
                    left: 36%;

                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-decoration-line: underline;
                    }
                }

                .product_del {
                    right: -3px;
                    top: -3px;
                }

                .remove_wish {
                    right: -3px;
                    top: -3px;
                    z-index: 1;
                }

                .product_mod {
                    top: 119%;
                    left: 0;
                    display: none;

                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-decoration-line: underline;
                        display: block;
                    }
                }

                .product_towish {
                    position: relative;
                    left: 98%;
                    top: -25px;
                    display: block;
                    width: 27px;
                    height: 27px;
                    background-image: url('../svg/spritesheet.svg');
                    background-repeat: no-repeat;
                    background-size: 500% auto;
                    background-position: 25% 25%;

                    span {
                        display: none;
                    }

                    &.existToWishlistButton {
                        background-position: 75% 75%;
                    }
                }

                .product_quantity_change {
                    position: absolute;
                    top: 87%;
                    left: 13rem;

                    &:not(:root:root){
                        top: 87%;
                        width: 20%;
                    }

                    form.qte_selector {
                        .qteWrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .lessQte {
                                position: relative;
                            }

                            .moreQte {
                                position: relative;
                            }

                            .change_qte {
                                height: 20px;
                                width: 20px;
                                background: transparent;
                                cursor: pointer;
                                line-height: 20px;
                                margin: 0;

                                span {
                                    color: $black;
                                }

                                &.lessQte {
                                    span {
                                        display: block;
                                        margin-top: -3px;
                                    }
                                }
                            }

                            .selected_qte {
                                font-size: 16px;
                                line-height: 20.51px;
                                color: $primaryColor;
                                text-align: center;
                                padding: 0;
                                border: 0;
                                width: 30px;
                            }
                        }

                        .load_qte {
                            height: 20px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1400px) {
            padding-right: 3.25rem;
        }

        .cart_main_table .cart_product_line {
            position: relative;
            margin-top: 0;
            padding-bottom: 0;
        }

        #wrapper_cart_slider {
            display: none;
        }
    }

    .right_side,
    .wrapper_panier>.wrapper_mes_adresses>.edit_adresse .right_side {
        position: relative;
        max-width: 499px;
        width: 100%;
        padding-left: 50px;

        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            top: 0;
            right: 100%;
            background-color: $borderColor;
        }

        .tunnel_sticky{
            .w-coupon-form{
                .w-submit-button{
                    background-color:transparent;
                }
            }
        }
    }

    .tunnel_sticky{
        #cart_total{
            .disable{
                opacity: .6;
                cursor: default;
                pointer-events: none;
                border:2px solid $black;
            }

            & + #alma-widget {
                display: none;
            }
        }
    }

    /**************************************************/
    /* STEP PANIER  ***********************************/
    &.step_1 {
        #breadcrumbs {
            .breadcrumbs {
                .breadcrumb.arrowIcon:nth-child(2) {
                    font-weight: 700;
                }

                .breadcrumb.arrowIcon:nth-child(2)::after {
                    display: none;
                }
            }
        }

        #tunnel_right_col {
            #cart_total {
                .bill_line.total_produit>label {
                    width: auto;
                }
            }

            .pushBasketWrapperText {
                order: 3;
                margin-bottom: 30px;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                strong {
                    font-weight: 400;
                }
            }

            .push_container.pushBasketWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                order: 4;

                @media screen and (max-width:1024px) {
                    width: 100%;
                }
            }
        }

        /** Font *********/

        .cart_main_title {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0.5px;
            margin-bottom: 23px;
            text-transform: uppercase;
        }

        .cart_main_title.wishlist {
            margin-bottom: 22px;
            margin-top: 47px;
        }

        .precoSubtitle,
        .precoDeliveryDate {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .main_table {
            .bill_line {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                width: 100%;

                @media screen and (max-width:1024px) {
                    width: 100%;
                }

                &:first-of-type {
                    order: 1;
                }

                &:nth-of-type(2) {
                    order: 2;
                }

                &:nth-of-type(3):not(.promo) {
                    order: 3;
                    padding: 1rem 0 0;
                }

                &:nth-of-type(4) {
                    order: 4;
                    padding: 0.9rem 0 0.8rem;
                }

                &:nth-of-type(5) {
                    order: 5;
                    padding: 6px 0 12px;
                }

                &.used_cp {
                    order: 6;
                    padding: 6px 0 12px;
                }

                &.promo {
                    order: 7;
                    padding: 6px 0 0;
                }

                &.total_produit{
                    @media screen and (min-width:0\0) {
                        .price{
                            margin-left: auto;
                        }
                        &:after{
                            left:0;
                        }
                    }

                    .nb_products {
                        &:before {
                            opacity: 0;
                        }
                    }
                }
            }

            .error_text {
                order: 6;

                #bloc_info_offert {
                    font-size: 16px;
                    line-height: 20.51px;
                    background-color: $white;
                    padding: 15px;
                    border: solid 1px $primaryColor;
                    top: 11.6rem!important;
                    left: 5rem;
                }
            }

            .encart_3xcb_panier {
                order: 3;
                margin-top: 13px;
                width: 98%;
            }

            .formPanier_container {
                order: 8;
                width: 100%;

                #formPanier {
                    margin-top: 0.05rem;

                    .button.dark {
                        border: 1px solid $primaryColor;
                    }
                }
            }
        }

        /** Products holder ******/
        .cart_main_table {
            margin-bottom: 0.8rem;

            .empty_cart {
                padding-bottom: 2rem;
            }

            .dropdown_cart {
                width: 100%;
                margin-bottom: -10px;

                .dropdown_cart_link {
                    h3 {
                        display: none;
                    }
                }
            }

            .product_towish {
                top: 110%;
                left: 0;
            }

            &:not(.wishlist) {
                max-width: 836px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 20px;

                .product_line_basket {
                    align-items: flex-start;
                    width: calc(826px - 388px);
                    max-width: 388px;
                    margin-right: 0px;
                    margin-top: 32px;
                    margin-bottom: 0;

                    &:nth-child(even) {
                        margin-right: 15px;
                    }

                    .cart_product_pic {
                        max-width: 140px;

                        a {
                            display: flex;
                        }
                    }

                    & + .dropdown_cart {
                        margin-top: 32px;
                    }
                }
            }

            .wrap_titre_prix {
                position: relative;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 17px;

                .product_mod {
                    width: 100%;
                }

                .cart_product_title {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    width: 94%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media screen and (max-width: 1120px) {
                        width: 80%;
                    }

                    @media screen and (max-width: 1040px) {
                        width: 70%;
                    }
                }

                .cart_product_subtitle {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:has(.realised_economy) {
                    padding-bottom: 17px;
                }
            }

            .wrapper_cart_product_price {
                align-items: flex-start;
                position: relative;
                width: 100%;
                min-height: 47px;
                margin: 7px 0 0 0;

                &::after {
                    position: absolute;
                    content: "";
                    display: inline-block;
                    width: 1rem;
                    height: 2px;
                    background-color: $primaryColor;
                    top: -4px;
                    left: 0;
                }

                .pricetag {
                    margin-top: 0;

                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    .currency {
                        padding-left: 2px;
                    }

                    & + .pricetag.was,
                    +.pricetag {
                        padding: 0 0 0 10px;
                        margin-left: 0;
                        margin-right: 10px;

                        span {
                            color: rgba(35, 31, 32, .5);
                            opacity: 1;
                        }

                        &:before {
                            width: calc(100% - 10px);
                        }
                    }
                }

                .realised_economy {
                    color: rgba(35, 31, 32, .5);
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin-left: 4px;
                }
            }

            &.wishlist {
                max-width: 836px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 20px;

                &:first-of-type {
                    padding-top: 2rem;
                }

                .cart_product_desc {
                    max-width: 248px;
                    height: 100%;

                    &:has(.tunnel_wish_nostock) {
                        opacity: 0.5;
                    }
                }

                .cart_product_line {
                    align-items: flex-start;
                    justify-content: flex-start !important;
                    width: calc(826px - 388px);
                    max-width: 388px;
                    margin-top: 0;
                    margin-right: 0;
                    margin-bottom: 32px;

                    &:nth-child(odd) {
                        margin-right: 15px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .cart_product_pic {
                        max-width: 140px;

                        a {
                            display: flex;
                        }
                    }

                    .wrapper_cart_product_desc {
                        .wrap_titre_prix {
                            padding-bottom: 0;
                        }
                    }
                }

                .title_basket_tunnel {
                    padding-bottom: 2rem;
                    font-weight: bold;
                }

                >div:not(.title_basket_tunnel) {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                .wrapper_cart_product_price {
                    padding-bottom: 40px;
                }

                .tunnel_wish_nostock {
                    span {
                        position: absolute;
                        bottom: 0;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }

    form#formPanierCadeau {
        margin-bottom: 44px;

        .order_gift_form {
            .w-input-label{
                span {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                &:before {
                    border-radius: 0;
                }
            }
        }

        .mot_personnalise_form {
            display: none;
        }
    }

    // important: keep step_1 padding declaration below general for cascading override
    &.step_1 .left_side {
        padding-right: 3rem;
    }

    &.step_2{
        .cart_product_line .cart_product_modify .product_del {
            right: 0;
        }

        .main_table {
            .bill_line {
                &.total_produit{
                    @media screen and (min-width:0\0) {
                        .price{
                            margin-left: auto;
                        }
                        &:after{
                            left:0;
                        }
                    }
                }

                &.total_produit_dropdown .cart_product_desc .cart_product_modify .qte_selector .change_qte {
                    display: none;
                }
            }
        }

        .error_text {
            #bloc_info_offert {
                top: 11.2rem!important;
                left: 5rem;
                font-size: 15px;
            }
        }
    }

    // DETAILS DE LIVRAISON EN MODE INVITE
    .wrapper_panier > .wrapper_mes_adresses {
        width: 100%;
        margin: 0 auto;

        & > .edit_adresse {
            display: flex;
            justify-content: center;

            /***@ tunnel address*/
            #new_address {
                #adresseForm {
                    .w-form-line {
                        .w-input-container {
                            label.w-input {
                                img.valid_form,
                                img.img_error {
                                    position: absolute;
                                    top: 50%;
                                    right: .5rem;
                                    transform: translateY(-50%);
                                }

                                .w-has-error {
                                    .w-input-element {
                                        border: none;
                                        border-bottom: 1px solid $borderColor;
                                    }
                                }

                                .w-input-label {
                                    color: $primaryPlaceHolder;
                                }
                            }
                        }

                        &.w-has-tel {
                            .w-input-container {
                                .errormsgadd {
                                    color: $primaryPlaceHolder;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                }

                                .w-input {
                                    &.w-tel-input {
                                        &.w-nonempty {
                                            .w-input-element {
                                                padding-left: 68px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .w-radio-group {
                            p.w-input-label {
                                span {
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                }
                            }
                        }

                        &.w-submit {
                            width: 100% !important;
                            max-width: 333px;
                            margin-top: 35px;
                        }
                    }

                    .w-input-group {
                        .w-group-label {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                        }
                    }

                    .w-password-input~.w-class-pwd-creation h3:first-of-type {
                        margin: 24px 0 4px;

                        & + ul {
                            gap: 10px;

                            & + h3 {
                                margin-top: 1rem!important;
                                margin-bottom: 0.2rem;
                            }
                        }
                    }

                    progress {
                        margin-bottom: 0;

                        & + p {
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    // DETAILS DE LIVRAISON EN MODE CONNECTED
    #adresseForm,
    .wrapper_mes_adresse #adresseForm {
        &.w-form fieldset.w-input-group>legend {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 16px;
            line-height: 20.51px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    .itm_back_btn {
        display: none;
    }

    /** LIVRAISON ******************************/
    .bloc_livraison {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &.active {
            .choix_livraison::after {
                background-color: #000000;
            }
        }
    }

    // These items are aligned on same row
    .title_type_livraison,
    .transporteur_info {
        width: auto;
        padding-bottom: 13px;
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-left: 6px;
    }

    .transporteur_info {
        margin-left: 3px;
        @extend .insideParentheses;
    }

    // These items take whole block width
    .choix_delivery,
    .adresse_change {
        width: 100%;
        border: 1px solid $borderColor;
    }

    .choix_delivery {
        position: relative;
        padding: 25px 30px;
        margin-left: 6px;
    }

    .adresse_change {
        position: relative;
        border-top: 0;
        // Box shadow to visually prevent upper-border to callapse once animation is done
        box-shadow: 0px -10px 0px -1px rgba(255, 255, 255, 1);
        padding: 0 30px 25px;
        margin-left: 6px;

        .delivery_form_title {
            text-overflow: ellipsis;
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            padding-bottom: 6px;
        }

        >.wrapper_select_adr {
            padding-bottom: 0;

            .w-form-line {
                .w-input-container {
                    .w-input.w-dropdown {
                        box-shadow: none;
                        border: none;
                        border-bottom: 1px solid $borderColor;

                        .w-input-label {
                            left: 0;
                        }

                        .w-input-element {
                            padding: 0 .85714em!important;
                        }

                        &:after {
                            right: .5rem;
                        }
                    }
                }
            }
        }

        .wrapper_form_adresse {
            .choix_type_adresse {
                margin-bottom: 18px;
            }

            .delivery {
                .w-input-group {
                    margin-bottom: 0;
                }

                .w-form-line {
                    margin-bottom: 15px;
                }

                .w-radio-group {
                    .w-radio-input {
                        .w-input-element {
                            width: 13px;
                            height: 13px;
                        }

                        .w-input-label {
                            font-size: 16px;

                            span {
                                margin-left: 4px;
                            }
                        }

                        & + .w-radio-input {
                            margin-left: 19px;
                        }
                    }
                }

                .w-has-tel {
                    .w-tel-input {
                        .w-input-element {
                            padding-left: 66px!important;
                        }

                        & + .errormsgadd {
                            display: block;
                            font-size: 16px!important;
                            line-height: 20.51px;
                            letter-spacing: 0.5px;
                            color: #868686!important;
                            width: 90%;
                            margin-left: auto;
                            margin-top: 15px;
                        }
                    }
                }

                .w-input-note {
                    font-size: 16px;
                }
            }

            .w-form-line.w-submit {
                @media screen and (max-width: 1020px) {
                    margin-top:3rem;
                }
            }
        }
    }

    // Radio Input
    .choix_livraison {
        width: .8rem;
        height: .8rem;
        z-index: 1;
        margin: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        -webkit-appearance: none;
        border: none;
        padding: 0;


        &::after {
            display: block;
            content: "";
            width: .8rem;
            height: .8rem;
            border-radius: 50%;
            border: 1px solid $black;
            background-color: #ffffff;
            transition: all ease .3s;
            box-shadow: inset 0 0 0 .125em #fff;
        }
    }

    .choix_delivery {
        &:hover {
            .choix_livraison::after {
                background-color: #000000;
            }

        }
    }

    // Delivery price on same line as type and transporteur
    .right_delivery {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding-bottom: 1rem;

        .price_tag {
            font-weight: 400;

            span {
                font-weight: 400;
            }
        }

        .fdp_offered {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    }

    // Delivery choice content
    .left_delivery {
        .civilite {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .adresse {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .show_postal {
            max-width: unset;
            width: auto;
            letter-spacing: unset;
            margin: .65rem 0 .75rem;
            font-weight: normal;
            @extend .cta_underlined;
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.5px;
            text-decoration: none;
            padding-bottom: 0;
            border-bottom: 1px solid $primaryColor;
        }

        .transporteur_delay {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }

    .choix_delivery_relais {
        .valid_form {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    /* LIVRAISON POINT RELAIS ******/
    /*Dropdown choix relais + map */
    .choix_relais_relaischronopost_relais {
        width: 100%;
        margin-left: 6px;
        border: 1px solid $borderColor;
        border-top: 0;
        box-shadow: 0px -10px 0px -1px rgba(255, 255, 255, 1);
        z-index: 9;

        .sidebar-right {
            height: 350px;
            overflow: hidden;

            .list-right {
                max-height: 350px;
                overflow: auto;
            }
        }
    }

    .w-form-line.w-has-tel {
        display: flex;
        align-items: flex-start;

        .w-input-container:last-child {
            .w-input-label {
                left: 4.2rem;
            }
        }
    }
}

.in_tunnel {
    .sameBillAddress,
    .accountCreate {
        .w-input-label {
            span {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                color: $primaryColor;
            }
        }
    }

    #cookiesLightbox .w-checkbox-input .w-input-element+.w-input-label::after,
    .w-newuser-form .w-checkbox-input .w-input-element+.w-input-label::after {
        top: unset;
        width: 11px;
        height: 11px;
    }

    #cookiesLightbox .w-checkbox-input .w-input-element:checked+.w-input-label::after,
    .w-newuser-form .w-checkbox-input .w-input-element:checked+.w-input-label::after {
        transform: translateX(-11px);
    }

    // Login
    &.body_login {
        .tunnel_track_pusher {
            padding: 8px 0 10px;
        }

        .newlogin_page{
            .login_blocs_wrapper {
                .login_bloc {
                    width: 880px;

                    &.oneClick {
                        margin-bottom: 60px;
                    }
                }

                .knowMoreOptin {
                    display: none;
                }

                .w-newlogin-form {
                    .intro {
                        color: #231F2080;
                    }
                }

                .optin_container {
                    .w-input-note.w-input-error {
                        margin-top: 12px;
                    }
                }

                .w-form-line.w-submit {
                    max-width: 19.2rem;
                    width: 100%!important;
                    margin-bottom: 12px;

                    button.w-submit-button {
                        border: 1px solid $primaryColor!important;

                        span {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            letter-spacing: 0.5px;
                        }
                    }
                }

                .login_bloc.oneClick {
                    .title:not(.my_account) {
                        margin-bottom: 1rem;
                    }

                    .w-oneclick-connect {
                        margin-bottom: 17px;
                    }

                    .login_cgv_msg {
                        font-size: 15px;
                    }
                }

                .login_cgv_msg {
                    display: block!important;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    color: $primaryColor;
                }
            }
        }
    }

    // Payement page
    &.checkout {
        .payment_wrapper {
            width: 96%;
            max-width: 1376px;
            margin: 0 auto;

            #bank_div {
                width: 64%;
                max-width: 867px;
                padding-right: 2.8%;
                border-right: solid 1px #CCCCCCCC;

                .bank_title {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20.51px;
                    color: $primaryColor;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                    margin-left: 7px;

                    strong {
                        font-weight: 400;
                    }
                }

                .wrapper_bloc_banque {
                    position: relative;
                    margin-left: 0.875%;
                    margin-bottom: 30px;
                    padding: 30px 25px 30px 23px;
                    border: solid 1px #B1B1B1;

                    .radio {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $white;
                        width: 15px;
                        height: 15px;
                        border: solid 1px #B1B1B1;
                        border-radius: 50%;
                        position: absolute;
                        left: -1%;
                        top: 50%;
                        transform: translateY(-50%);
                        box-shadow: inset 0 0 0 2px $white;
                    }

                    .title_wrapper_fold,
                    .title_wrapper {
                        .bank_title {
                            display: none;
                        }

                        .bank_subtitle {
                            margin-bottom: 4px;
                        }
                    }

                    .cards-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        margin: 12px 0px 8px;

                        img {
                            width: auto;
                            height: 25px;

                            &:not(:last-child) {
                                margin-right: 5px;
                            }
                        }
                    }

                    // Credit card payment
                    &.credit_card {
                        #flex {
                            min-height: 260px;

                            @media screen and (max-width: 1425px) {
                                min-height: 310px;
                            }
                        }
                    }

                    // Paypal patment
                    &.paypal {
                        padding: 25px 25px 17px 23px;

                        .bloc_bank_contents {
                            justify-content: center;

                            .bank_subtitle {
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20.51px;
                                color: $primaryColor;
                                letter-spacing: 0.5px;
                                text-decoration: underline;
                                cursor: pointer;

                                strong {
                                    font-weight: 400;
                                }

                                &.loader_paypal {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &:before {
                                        width: 30px;
                                        margin-left: 0;
                                    }

                                    strong,
                                    span {
                                        display: none;
                                    }
                                }
                            }

                            #inputPAYPAL {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                margin-top: -10px;
                                padding: 0;
                                border: 0;
                            }
                        }
                    }

                    // Cofidis3x payment
                    &.cofidis3x {
                        padding: 29px 25px 13px 23px;
                        display: none;

                        .schedule,
                        .fees,
                        .total,
                        .wrapper_warning_3xcb {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20.51px;
                            color: $primaryColor;
                            letter-spacing: 0.5px;
                            display: none;

                            strong {
                                font-weight: 400;
                            }
                        }

                        #recapAdresse {
                            #mobile_3xcb {
                                width: 100%;
                                border: 0 none;
                                border-bottom: solid 1px #B1B1B1;
                                padding: 10px 0 10px 52px;

                                &.inputErr {
                                    border-color: $errorColor;
                                }

                                & + img {
                                    display: none;
                                }
                            }

                            .tel_msg_error {
                                position: absolute;
                            }

                            .form_submit {
                                position: relative;
                                width: 300px;
                                display: block;
                                margin: 25px 0;

                                .btn_tunnel {
                                    display: block;
                                    background-color: $white;
                                    font-family: $primaryFont;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20.51px;
                                    color: $primaryColor;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                    padding: 15px;
                                    border: solid 1px $primaryColor;
                                    transition: all 0.3s ease-in-out;

                                    &:hover {
                                        background-color: $primaryColor;
                                        color: $white;
                                    }

                                    &.loader {
                                        background-color: $white;
                                        position: absolute;
                                        top: 0;
                                        left: 0;

                                        img {
                                            display: none;
                                        }

                                        span {
                                            font-size: 0;
                                        }
                                    }
                                }
                            }
                        }

                        .bloc_bank_contents {
                            .bank_subtitle {
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20.51px;
                                color: $primaryColor;
                                letter-spacing: 0.5px;
                                text-decoration: underline;
                                text-align: center;
                                cursor: pointer;

                                strong {
                                    font-weight: 400;
                                }
                            }

                            #inputCOFIDIS3XCB {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                margin-top: -10px;
                                padding: 0;
                                border: 0;
                            }
                        }

                        &.actif {
                            .schedule,
                            .fees,
                            .total {
                                display: block;
                            }

                            .bank_subtitle {
                                display: none;
                            }
                        }
                    }

                    &.alma {
                        text-align: center;
                        padding: 21px 25px 21px 23px;

                        .bank_subtitle {
                            margin-top: .2rem;
                            
                            strong {
                                font-weight: 400;
                                font-size: 1rem;
                                font-family: $primaryFont;
                                color: $black;
                                line-height: 20.51px;
                                letter-spacing: .5px;
                                text-decoration: underline;
                                text-align: center;
                                cursor: pointer;
                            }
                        }

                        &::before {
                            position: absolute;
                            top: -.8rem;
                            left: 43.8%;
                            content: '';
                            width: 53px;
                            height: 24px;
                            padding: 0 3%;
                            background-color: $white;
                            background-image: url(../svg/Alma_logo.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    } 

                    &.actif {
                        border-color: $primaryColor;

                        .radio {
                            background: $primaryColor;
                            border: solid 1px $primaryColor;
                        }
                    }

                    &.hosted_worldline  {

                        iframe {
                            margin-left: -1.5rem;
                            border: none;
                            min-width: 60%;
                            min-height: 460px;

                            /* @media screen and (max-width: 1425px) {
                                min-width: 80%;
                                // margin-left: 0;
                            } */
                        }
                    }

                    &.bancontact {
                        padding: 10px 25px;

                        .bloc_bank_contents {
                            justify-content: center;
                        }

                        .bank_subtitle {
                            margin-right: 20px;
                            margin-bottom: 0;

                            strong {
                                font-weight: 400;
                            }

                            em {
                                font-style: normal;
                            }
                        }
                    }
                }
            }

            #tunnel_right_col {
                width: 36%;
                max-width: 449px;
                margin-left: 4%;

                #cart_total {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    & > * {
                        width: 100%;
                    }

                    .wrapper_payment {
                        margin-bottom: 7px;
                        order: 1;

                        .payment_addr_wrapper {
                            position: relative;
                            margin-bottom: 14px;

                            .title {
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20.51px;
                                color: $primaryColor;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                                margin-bottom: 7px;
                            }

                            .address {
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20.51px;
                                color: $primaryColor;
                                letter-spacing: 0.5px;
                            }

                            .address_mod {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                text-decoration: underline;
                            }

                            &.billing {
                                .address {
                                    .name {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .recap_cart_title {
                        order: 2;
                        font-family: $primaryFont;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.51px;
                        color: $primaryColor;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }

                    .bill_line.total_produit.toggle {
                        padding-top: 16px;
                        order: 3;
                    }

                    .bill_line.total_produit_dropdown {
                        order: 4;

                        .cart_product_line {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .bill_line.discount {
                        order: 5;
                    }

                    .bill_line.coupon {
                        padding: 0;
                        order: 6;
                    }

                    .upselling.price {
                        background-color: transparent;
                        padding: 0;
                        margin: 10px 0 5px;
                        order: 7;

                        p {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20.51px;
                            color: $primaryColor;
                            letter-spacing: 0.5px;
                            text-decoration: underline;

                            strong {
                                font-weight: 400;
                            }
                        }
                    }

                    .encart_3xcb_panier {
                        margin: 7px 0 3px;
                        order: 7;
                    }

                    .bill_line.total {
                        order: 6;
                        padding: 6px 0 12px;
                    }

                    .bill_line.promo {
                        padding: 6px 0 12px;
                    }

                    .formPanier_container {
                        display: none;
                    }
                }
            }
        }
    }
}

/*****************************************/
/* STEP REMERCIEMENTS ********************/
.checkout {
    // MAP BLOC
    .thanksWrapper .bankThanksTop {
        width: 100%;
        max-width: 570px;
        margin: 0;

        #around_me_map {
            display: none;
            width: 100%;
            height: 684px;
            margin: 0;

            .infoWindowContent {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                strong {
                    font-weight: 400;
                }
            }
        }

        #videoRemerciements {
            video {
                width: 100%;
                height: 684px;
            }
        }
    }

    // REST
    .thanksWrapper {
        max-width: 1201px;
        width: 100%;
        margin: 0 auto 80px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .bankThanksTop>h1 {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.8125rem;
            letter-spacing: 0.03125rem;

            &::before {
                display: none;
            }

            span {
                padding-top: .15rem;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
            }
        }

        .thanksRecap {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 105px;
            margin-left: 100px;
            width: 100%;
            max-width: 531px;

            .thanksRight {
                width: 100%;
                text-align: left;

                p {
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin-bottom: 5px;

                    span,
                    strong {
                        font-weight: 400;
                    }

                    &.txt_thanks_transaction {
                        padding-top: 25px;
                        text-transform: initial;
                        color: $greyBank;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        margin-bottom: 0;
                    }
                }

                .thanksRightWrapper {
                    margin-top: 30px;

                    br {
                        display: none;
                    }

                    .main_amount_transaction {
                        b {
                            font-weight: 400;
                        }
                    }
                }
            }

            .thanksLeft {
                width: 100%;

                .thanksLeftWrapper {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid $borderColor;
                }

                .seeOrderEvo {
                    order: 1;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin-bottom: 20px;

                    strong {
                        font-weight: 400;
                    }
                }

                .createPwdTxt {
                    order: 2;
                    margin-bottom: .75rem;
                    font-size: .75rem;
                    display: none;
                }

                .w-newpass-form {
                    order: 3;
                    margin-bottom: 2.5rem;

                    .w-password-input~.w-class-pwd-creation h3:first-of-type {
                        margin: 24px 0 4px;

                        & + ul {
                            gap: 10px;

                            & + h3 {
                                margin-bottom: 0.35rem;
                            }
                        }
                    }

                    progress {
                        margin-bottom: 0.25rem;

                        & + p {
                            line-height: normal;
                        }
                    }
                }

                .thanksTrust {
                    order: 5;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin: 5px 0 30px;

                    strong {
                        font-weight: 400;
                    }
                }

                .bankThanksBtn {
                    order: 4;
                    border: 1px solid $primaryColor;

                    span {
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }
                }
            }

            h1 {
                order: -1;
                color: $primaryColor;
                width: 100%;
                font-family: $primaryFont;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                span {
                    font-size: 16px;
                    text-transform: initial;
                    width: auto;
                    display: block;
                    margin-bottom: 30px;
                }
            }
        }

    }

    .top {
        display: none;
    }

    .bloc_question {
        margin-top: 0;

        .questions_frequentes {
            padding-top: 0;

            & > div {
                padding-bottom: 5px;
            }
        }

        .label_question {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 16px;
            line-height: 20.51px;
            letter-spacing: .5px;
            padding-top: 0;

            a {
                span {
                    text-transform: none;
                }
            }

            span {
                text-transform: uppercase;
            }
        }

        #other_question_3 {
            .label_question {
                text-transform: uppercase;
            }
        }
    }

    .wrap_bottom_rightcol {
        display: none;
    }

    .method_wrapper.banque_erreur_txt {
        opacity: 1;
        border: 1px solid $primaryColor;
        padding: 20px;
        margin-left: .875%;
        margin-bottom: 30px;

        #bloc_erreur_order {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-left: 7px;

            .rose.displayNone {
                display: none;
            }

            > p.rose {
                display: none;
            }
        }
    }

    .txt_recommencer_paiement.displayNone,
    .txt_recommencer_paiement {
        display: none;
    }

    .error_text {
        order: 9;

        #bloc_info_offert {
            top: 29.4rem!important;
            left: 5rem;
            font-size: 15px;
        }
    }

    .product_line_basket {
        .cart_product_line:last-child {
            margin-bottom: 1.3rem;
        }

        .cart_product_line {
            .wrap_titre_prix {
                margin-bottom: 2rem;
            }
        }
    }

    #tunnel_right_col {
        #cart_total {
            .total_produit  {
                .price {
                    display: none;
                }
            }
        }
    }

    .payment_wrapper {
        .txt_recommencer_paiement {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin: 0 0 10px 7px;
        }
    }
}

/***** Livraison magasin *****/
.choix_delivery_relais {
    margin-top: 28px;

    .transporteur_info {
        display: none;
    }

    .delivery_form_title.title_choix_relais {
        margin-bottom: .25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .shop_name {
        margin: 0;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    .choix_relais_relais_mag {
        transform: translateY(-1rem);
        width: 100%;
        padding-top: 1rem;
        background-color: white;
        border: 1px solid $borderColor;
        border-top: 0;
    }

    .choix_delivery {
        border: 1px solid $borderColor;
    }

    .wrapper_relais_top {
        padding: 0 25px;

        .content_form {
            .w-input-container {
                &:after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    content: '';
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-image: url(../svg/pin.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    #livr_relais {
        display: block !important;
    }
}

.displayed_result {
    display: flex;

    .map_canvas {
        width: 60%;
        margin: 1.25rem;
    }

    .sidebar-right {
        width: 40% !important;
        margin: 2.5rem 1.25rem 1.25rem 0;

        .kp {
            margin-bottom: 2rem;
            @include flexbox(row, flex-start, flex-start, wrap);
            font-size: .75rem;
            letter-spacing: .4px;

            .name {
                order: -1;
                text-transform: capitalize;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.08px;
            }

            .relayLink {
                margin-top: .5rem;
                padding-bottom: 0;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.08px;
            }

            .address {
                width: 100%;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.08px;
            }

            .btn_container {
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.08px;
            }

            .distance {
                margin: 0 .2rem;
                display: flex;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                &::before,
                &::after {
                    display: block;
                    color: $primaryColor;
                }

                &::before {
                    content: "(";
                }
                &::after {
                    content: ")";
                }
            }
        }

        tr:last-of-type {
            .kp {
                margin-bottom: 5px;
            }
        }
    }
}

.gm-style .gm-style-iw-c {
    border-radius: unset;
    padding: 1rem !important;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}

.info_popup {
    @include flexbox(column, flex-start, flex-start, wrap);
    font-size: .75rem;
    letter-spacing: .4px;

    .name {
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: capitalize;
    }

    .country {
        display: block;
    }

    .name,
    .indication {
        width: fit-content;
    }

    .indication,
    .adresse,
    .city,
    .relayLink,
    p {
        font-family: $primaryFont !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: 0.5px !important;
        text-transform: capitalize !important;
    }

    .indication +p,
    .horaire_title {
        width: 100%;
    }

    .horaire_title {
        font-size: .75rem;
        font-weight: 900;
    }

    .button.secondary.dark {
        @extend .cta_underlined;
        width: fit-content;
        font-weight: normal;
        text-align: left;
    }
}

.button.secondary.show_modif_info.show_relais.button.secondary.dark,
.relayLink {
    @extend .cta_underlined;
}

.button.secondary.show_modif_info.show_relais.button.secondary.dark {
    width: auto;
    max-width: unset;
    margin: 2rem 0;
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    @extend .cta_underlined;
    margin: 0.8rem 0 0.9rem;
    padding-bottom: 0;
}

.edit_adresse #tunnel_right_col {
    display: none;
}

#main_cart_wrapper {
    margin-bottom: 31px;

    #tunnel_right_col {
        #cart_total {
            visibility: visible !important;
        }

        #right_col_sticky {
            display: flex;
            flex-direction: column;

            #cart_total {
                order: 1;
            }

            #alma-widget {
                ._TSkFv,
                .alma-payment-plans-container {
                    border: none;
                    padding: 10px 12px 18px 0;

                    ._bMClc {
                        ._LJ4nZ {
                            margin-right: 9px;
                            width: 40px;
                        }

                        ._17c_S {
                            gap: 10px;

                            ._2Kqjn {
                                padding: 2px 5px;
                                font-weight: 400;
                                font-family: $primaryFont;
                            }
                        }
                    }

                    ._25GrF {
                        line-height: 130%;
                        position: relative;
                        font-family: $primaryFont;

                            &:after {
                            content: '';
                            display: inline-flex;
                            position: absolute;
                            top: -.04rem;
                            margin-left: .6rem;
                            width: 15px;
                            height: 15px;
                            background-image: url('../svg/ellipse.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }

            .wrap_bottom_rightcol {
                order: 3;
                margin-bottom: 0.2rem;

                .faq_theme_wrapper {
                    &:last-child {
                        margin-bottom: 28px;

                        .title_question {
                            span {
                                color: $primaryColor;
                                font-family: $primaryFont;
                                font-size: 25px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    &:hover {
                        h3 {
                            opacity: 0.5;

                            &.actif,
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .bill_line.discount {
            table#bloc_info_offert {
                top: -42px !important;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }
        }
    }
}

// swiper - vous aimerez aussi
body.pushpanier  {
    .left_side {
        #wrapper_cart_slider {
            display: none!important;
        }
    }

    &.cart {
        #wrapper_cart_slider {
            #cart_slider {
                .imgWrapper {
                    .eclat_rect_img {
                        width: 28px;
                        height: 137px;
                    }

                    .product_picture {
                        .swiper-slide {
                            &:nth-child(2) {
                                position: relative;
                                top: auto;
                                right: auto;
                                bottom: auto;
                                left: auto;
                            }

                            .swiper-slide {
                                img {
                                    z-index: 1;

                                    & + img {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        opacity: 0;
                                        z-index: 2;
                                        transition: 0.3s ease-in-out;
                                    }
                                }

                                &:hover {
                                    img {
                                        & + img {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wrapper_description {
                margin-top: 0;

                .product_titles {
                    .sub {
                        display: none;
                    }
                }

                .eclat_rond {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 56px;
                        height: 23px;
                    }
                }
            }

            .product-assoc-button {
                &.product-assoc-button-prev {
                    left: 25px;
                    right: auto;
                    top: 38%;
                }

                &.product-assoc-button-next {
                    right: 25px;
                    left: auto;
                    top: 38%;
                }
            }
        }
    }
}

.cart{
    &.step_2{
        .choix_delivery_relais{
            .wrapper_locate_relais{
                .store_no_result{
                    color: red;
                    font-size: 12px;
                    padding: 13px 22px 12px;
                }
            }
        }
    }

    &.step_2_0 {
        #adresseForm {
            .delivery {
                .choix_type_adresse {
                    margin-bottom: 18px;
                }

                .w-form-line {
                    margin-bottom: 15px;

                    &.w-has-tel {
                        margin-bottom: 20px;
                    }
                }

                .w-input-note {
                    margin-top: 0;
                }

                .w-dropdown {
                    box-shadow: none;
                    border-bottom: solid 1px;

                    .w-input-element {
                        padding-left: 0.85714em;
                    }

                    &.w-has-error {
                        border-bottom: solid 1px #ff6d6d;
                        box-shadow: none;
                    }
                }

                .errormsgadd {
                    display: block;
                    font-size: 16px!important;
                    line-height: 20.51px;
                    letter-spacing: 0.5px;
                    color: #868686!important;
                    width: 90%;
                    margin-left: auto;
                    margin-top: 15px;
                }
            }

            .bill {
                .w-form-line {
                    margin-bottom: 15px;

                    &.w-has-tel {
                        margin-bottom: 20px;

                        #billtelephone {
                            padding-left: 67px !important;
                        }
                    }
                }

                .w-input-note {
                    margin-top: 0;
                }

                .w-dropdown {
                    box-shadow: none;
                    border-bottom: solid 1px;

                    &.w-has-error {
                        border-bottom: solid 1px #ff6d6d;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

// GIFT WRAP + CUSTOM MESSAGE

#formPanierCadeau {
    > * {
        margin-bottom: 1rem;
    }

    .w-input-label {
        color: $primaryColor;
        &::before {
            border-radius: 50%;
        }
    }

    #mot_personnalise {
        margin-top: .5rem;
        padding-left: 1rem;

        textarea {
            max-width: 290px;
            width: 100%;
        }
    }
}

.encart_3xcb_panier {
    order: 4;

    .txt_3x_cb.f_left {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        text-align: left!important;
        cursor: pointer;
    }

    .price_x, .big_x {
        font-weight: 400;
    }

    td{
        height: 25px;
        vertical-align: middle;
    }
}

.cart {
    &.step_2 {
        .encart_3xcb_panier {
            order: 5;
            margin: 6px 0 12px;
        }

        .wrap_bottom_rightcol {
            display: none;
        }

        .lb-v-scrollbar,
        .lb-v-scrollbar-slider {
            width: 1.5px !important;
        }

        #tunnel_right_col {
            .bill_line.total_produit {
                .price {
                    display: none;
                }

                .nb_products::before {
                    right: 0;
                }
            }

            .bill_line.total_produit,
            .bill_line.discount,
            .bill_line.total {
                padding-left: 0;
            }

            .bill_line.promo {
                padding-bottom: 12px;
            }
        }
    }
}

#popup_3xcb {
    display: none;
}

.cart_wrapper {
    .wrapper_description {
        strong {
            width: 100%;
            display: block;
            line-height: 2rem;
        }
    }
}

.cart.step_1 .main_table #keep_alive_coupon .w-coupon-form {
    .w-has-error {
        .w-input-element:not([type=radio]):not([type=checkbox]) {
            border-color: #ff6d6d;
            box-shadow: none;
        }
    }
}

/* Cart share block */
.share_cart {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 28px;

    a {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
    }

    .share_cart,
    .share_cart_icon {
        display: block;
        background-image: url('../svg/share_icon.svg');
        background-position: left;
        background-color: transparent;
        background-size: auto;
        background-repeat: no-repeat;
        width: 28px;
        height: 18px;
    }
}

.copied-text-notification {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 10px));
    z-index: 1000;
    animation: slideIn 0.3s forwards;
    transition: transform 0.3s ease-in-out;
    margin: 10px auto;
    background-color: $primaryColor;
    padding: 10px 0;
    width: fit-content;
    min-width: 30ch;
    color: $white;
    font-weight: 400;
    font-size: 15px;
    font-family: $primaryFont;
    text-align: center;

    &.slide-out {
        animation: slideOut 0.3s forwards;
    }
}

#wrapper_cart_slider {
    margin-bottom: 2rem;

    .full_w_sldr_title { // Title
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        white-space: nowrap;
        margin-bottom: 14px;
        margin-left: 22px;
    }

    #cart_slider {
        position: relative;
        width: 100%;

        .imgWrapper {
            position: relative;
            height: auto;
            width: 100%;

            .eclat_rect_img {
                position: absolute;
                z-index: 10;
                top: 0;
                left: -1px;
            }

            @media screen and (max-width: 1299px) {
                max-width: none;
            }

            .product_picture {
                position: relative;
            }

            .product_rollover {
                z-index: 10;
                position: absolute;
                transition: all .3s ease-in-out;
                width: 100%;

                .form_submit.bloc_add_color {
                    display: none;
                    width: 25%;
                }

                .showAchat {
                    display: block!important;
                }

                .hideAchat {
                    display: none;
                }

                .rollover_left.achat_express {
                    @media screen and (max-width: 1615px) {
                        width: 90%;
                    }

                    .ligne_form.productSizeFieldset .form_itm.check.size label::after {
                        bottom: -2px;
                        height: 2px;
                    }

                    .choices_list {
                        .form_itm.check.color::before {
                            content: none;
                        }

                        .form_itm.check.size label:hover::after {
                            bottom: -2px;
                            height: 2px;
                        }

                        .form_itm.check.size:hover {
                            text-decoration: none;
                        }
                    }
                }

                .w-submit-button:before {
                    margin: 0 9px 3px 0;

                    @media screen and (max-width: 1550px) {
                        margin: 0 0 3px 0;
                    }
                }
            }
        }

        .wrapper_description {
            margin-top: 0.5rem;
            padding-left: 20px;

            .product_titles {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                strong {
                    font-weight: 400;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .item.assoc {
            .product_picture {
                .wrap_video {
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .swiper-slide {
                    img {
                        z-index: 1;

                        & + img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 2;
                            transition: 0.3s ease-in-out;
                        }
                    }

                    &:hover {
                        img {
                            & + img {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .productVisualMulti {
            .bloc_add_color {
                .w-submit-button {
                    position: absolute;
                    bottom: 0;
                    right: 2%;

                    @media screen and (max-width: 1615px) {
                        bottom: 5px;
                    }

                    span {
                        margin-bottom: 0;

                        @media screen and (max-width: 1550px) {
                            display: none;
                        }
                    }
                }
            }

            .btnAddBasketWrapper {
                #size_is_not_available {
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    & + .alert_return {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .product_prices {
            .pricetag+.pricetag {
                text-decoration: none !important;
            }
        }
    }

    .product_visited_button {
        @extend .swiper_arrow;
        position: absolute;
        top: calc(50% - 7px);

        @media screen and (min-width: 1300px){
            height: 20px;
            width: 20px;
            top: calc(50% - 10px);
        }

        &.product_visited_prev {
            @extend .arrowRight;
            right: -2.5rem;

            @media screen and (max-width: 1800px){
                right: -1.8rem;
            }
        }

        &.product_visited_prev {
            @extend .arrowLeft;
            left: -2.5rem;

            @media screen and (max-width: 1800px){
                left: -1.8rem;
            }
        }

        &.swiper-button-disabled{
            opacity: 0;
        }
    }

    .product-assoc-button.product-assoc-button-next {
        left: 37rem;

        @media screen and (max-width: 1669px){
            left: 30vw;
        }

        @media screen and (max-width: 1460px){
            left: 27rem;
        }

        @media screen and (max-width: 1299px){
            left: 8rem;
        }
    }

    .product-assoc-button.product-assoc-button-prev {
        right: 10rem;

        @media screen and (max-width: 1669px){
            right: 0rem;
        }

        @media screen and (max-width: 1299px){
            right: 8rem;
        }
    }

    .product-assoc-button-next,
    .product-assoc-button-prev {
        display: none;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(calc(100% + 10px));
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(calc(100% + 10px));
    }
}

.in_tunnel {
    .menu_top_wrapper {
        display: none !important;
    }

    #search_bar,
    .lang_switch_ctn {
        opacity: 0;
        pointer-events: none;
    }
}