.home-module {
    overflow: hidden;
    padding: 0 $mainPadding;

    @include medium {
        padding: 0 $mainPaddingMedium;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $borderColor;
    }

    @media screen and (min-width:0\0) {
        &.social-module{
                max-height:600px;
        }
    }


    &.image-image {
        #collectionSwiper, .collectionSwiper {
            .home-columns-container {
                .swiper-slide {
                    .sliderContent {

                        .small_pic {
                            .slide_video {
                                padding-left: 2rem;
                                a {
                                    display: block;
                                    position: relative;

                                    iframe {
                                        position: relative;
                                        z-index: -1;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }

                        .big_pic {
                            .slide_video {
                                a {
                                    display: block;
                                    position: relative;

                                    iframe {
                                        position: relative;
                                        z-index: -1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.home-module-title {
    @include wshopFont($primaryFont, 3rem, unset, .5px, 900, unset, unset, $primaryColor);
}

.home-column-content {
    width: 40%;
    border-bottom: 1px solid $borderColor;

    @media screen and (max-width: 1600px) {
        margin-right: 1rem;
    }

    .home-module-text {
        max-width: 460px;
    }
}

.home-module-text {
    margin: 1rem 0;
    @include wshopFont($primaryFont, .75rem, 1.5, unset, 400, unset, unset, $primaryColor);
}

.home-module-cta {
    @extend .cta_underlined;
    text-transform: uppercase;
}
@import '../components/home/cover-module';
@import '../components/home/reassurance';
@import '../components/home/text-image';
@import '../components/home/slider-module';
@import '../components/home/image-image';
@import '../components/home/image-image-image';
@import '../components/home/social-module';
@import '../components/home/push-produit-module';

body.homepage {
    .home-module-body.reassurance_wrapper .home-column {
        width: auto;
        height: auto;
        padding: 2rem 2rem;
        flex: 1;
    }

    // Condition pour EN
    &.en {
        .home-module-body.reassurance_wrapper .home-column {
            padding: 2rem 1.4rem;
        }
    }

    #site_global_wrap section {
        @media screen and (max-width: 1024px) {
            margin-bottom: 8px!important;
        }

        &.tg-module.tg_2 {
            @media screen and (max-width: 1440px) {
                margin-bottom: 45px!important;
            }
        }

        &.tg-module.tg_3 {
            @media screen and (max-width: 1440px) {
                margin-bottom: 35px!important;
            }
        }
    }
}

.footerCgv .copyright a {
    display: block;
    width: 40px;
    height: 30px;
    background: url(../svg/wshop.svg) center center no-repeat;
    background-size: contain;
    margin-left: 10px;
}

.signup_module {
    .w-submit-button {
        background-color: transparent;
    }
}