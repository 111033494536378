/* W-FORMS OVERRIDE ****************/
/***********************************/

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,  // TODO:
input[type=password].w-input-element,   // Get rid
input[type=text].w-input-element,   // of these
textarea.w-input-element {  // override rules
    font-size: 16px;
    font-family: $primaryFont;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 0;

    .w-input:not(.w-dropdown) & {

        &:focus {
            border: none;
            border-bottom: 1px solid $borderColor;
            box-shadow: unset;
        }
    }
}

.w-dropdown {
    &::after {
        border: none;
        right: 0;
        width: 13px;
        height: 6px;
        background: #fff url(../img/arrow_d.png) no-repeat 92% center;
        background-size: 13px 6px;
    }

    .w-value {
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: .5px;
        padding-left: 0;
    }
}

.w-input .w-input-label {
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    left: 0;
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element,
.w-dropdown {
    height: 3rem;
}

.newlogin_page {
    .w-newlogin-form .w-pwd-reset {
        cursor: pointer;
        margin: 0;
        color: $primaryColor;
        font-size: 16px;
        font-weight: 400;
    }

    .login_bloc {
        .w-lostpass-form {
            .w-email-input.w-nonempty {
                .w-input-label {
                    transform: translateY(-107%);
                }
            }

            .w-form-line.w-submit {
                max-width: none;
            }
        }
    }
}

.newlogin_page .login_bloc .title:not(.my_account) .subtitle {
    color: $primaryColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.w-input.w-tel-input {
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 49px;
        width: 1px;
        height: 59%;
        background-color: $borderColor;
    }

    .selected-flag {
        height: 100%;
        background-color: transparent;
        border-right: none;
        padding-left: 0;

        &::after {
            border-top: .35em solid $primaryPlaceHolder;
            transform: translateX(5px);
        }
    }
}

// Such as radio buttons in account
.w-radio-input .w-input-element {
    &+.w-input-label {
        span {
            color: $primaryColor;
        }
    }

    &:checked+.w-input-label::before {
        background-color: $primaryColor;
    }
}

// Je souhaite recevoit les offres... label
.w-fieldset-label {
    font-size: .75rem;
    color: $primaryColor;
    font-family: $primaryFont;
}

.w-dropdown {
    border-radius: 0;
    border: none;
    box-shadow: inset 0 0 0 1px $lightgrey;
}

.w-dropdown.w-focused {
    border: none;
    box-shadow: inset 0 0 0 1px $black;
}

.w-radio-input .w-input-element+.w-input-label {
    font-size: .75rem;
}

.w-radio-input .w-input-element:checked+.w-input-label::before {
    box-shadow: inset 0 0 0 .19rem $white;
}

.w-input.w-tel-input .w-input-element:not([type=radio]):not([type=checkbox]) {
    padding-left: 4rem;
}

.customer .w-radio-group.w-has-error .w-radio-input .w-input-element+.w-input-label::before {
    border-color: #ff6d6d;
    box-shadow: none;
}

.newlogin_page .w-newlogin-form .optin_container .w-input-note.w-input-error {
    text-align: left;
}

.w-nonempty .w-input-label {
    transform: translateY(-100%);
}

.w-input-note,
.w-input-note.w-input-error {
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element,
textarea.w-input-element {
    border: none;
    border-bottom: 1px solid $borderColor;
    padding-left: 0 !important;
}

.w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-has-error:not(.w-dropdown) input[type=email].w-input-element,
.w-has-error:not(.w-dropdown) input[type=password].w-input-element,
.w-has-error:not(.w-dropdown) input[type=text].w-input-element,
.w-has-error:not(.w-dropdown) textarea.w-input-element {
    border-bottom: 1px solid #ff6d6d;
    box-shadow: none;
}

body:has(input[type="password"]),
body:has(#clientPwd),
body:has(#clientPwdv),
body:has(#clientPwdw) {
    button[disabled="disabled"]:not(.w-loader) {
        background-color: $white !important;
        border-color: rgba($primaryColor, 0.3) !important;
        color: rgba($primaryColor, 0.3) !important;

        span {
            color: rgba($primaryColor, 0.3) !important;
        }
    }
}