.home-module.social-module {
    padding: 8.75rem 0 0 0;
    max-width: 1490px;
    margin-left: auto;
    @include flexbox(row, unset, center, unset);

    ul.home-column {
        @include flexbox(row, space-between, center, unset);
        padding-right: 4rem;
        width: 60%;
        justify-content: space-between;

        @include medium {
            padding: 0 2rem;
        }
        // Module insta
        li {
            &:first-child {
                width: 55%;
            }
            &:nth-child(2) {
                width: 40%;
            }

            img {
                width: 100%;
                height: auto;
            }

            &.insta-video {
                position: relative;
                display: flex;
                justify-content: center;
                align-content: center;

                a {
                    padding-bottom: 125.25%; 
                    height: 0;

                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .home-column-content {
        .home-module-title {
            &:after {
                content: "";
                display: inline-block;
                width: 43px;
                height: 43px;
                background-image: url(../svg/spritesheet.svg);
                background-repeat: no-repeat;
                background-size: 800% auto;
                background-position: 4% 26.6%;
            }
        }
    }
}
