@keyframes fadeInAssoc {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.product_assoc_container {
    padding-bottom: 41px;
    animation: fadeInVisited 1s ease-in-out 0.5s forwards;
}

.product_assoc_content {

    .assoc_title {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        white-space: nowrap;
        margin-bottom: 14px;
        margin-left: 18px;

        @media screen and (max-width: 1299px) {
            white-space: wrap;
        }
    }

    p.assoc_desc {
        display: none;
    }
}

.product_assoc_swiper {
    position: relative;
    width:  100%;

    .imgWrapper {
        position: relative;
        height: auto;
        width: 100%;

        @media screen and (max-width: 1299px) {
            max-width: none;
        }

        .caract_container {
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;

           .faitenfrance_pastille {
                max-width: 80px;
            } 
        }

        .product_rollover {
            @media screen and (max-width: 1024px) {
                width: 99.9%;
            }

            .choices_list .form_itm.check.color {
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }

            .rollover_left.achat_express .ligne_form.productSizeFieldset .form_itm.check.size {
                label::after {
                    height: 2px;
                    bottom: -3px;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            form {
                .rollover_left.achat_express {
                    width: 100%;
                    padding: 0.8rem;

                    .productSizeFieldset {
                        width: 70%;

                        @media screen and (max-width: 1615px) {
                            width: 90%;
                        }
                    }
                }
            }
        }
    }

    .productVisualMulti .bloc_add_color .w-submit-button {
        position: absolute;
        bottom: 1px;
        right: 2%;

        @media screen and (max-width: 1615px) {
            bottom: 5px;
        }

        &:before {
            margin: 0 9px 3px;
        }

        span {
            margin-bottom: 0px;

            @media screen and (max-width: 1615px) {
                display: none;
            }
        }
    }

    .item.assoc {
        .product_picture {
            .wrap_video {
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            
            .swiper-slide {
                img {
                    z-index: 1;

                    & + img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 2;
                        transition: 0.3s ease-in-out;
                    }
                }

                &:hover {
                    img {
                        & + img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .wrapper_description {
        margin-left: 19px;
        margin-top: 5px;

        .product_titles {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;

            strong {
                font-weight: 400;
            }

            &::after {
                content: "";
                display: block;
                width: 1rem;
                height: 2px;
                margin: 0.5rem 0;
                background-color: $primaryColor;
            }
        }

        .product_prices {
            margin-top: 14px;

            .price_ctn {
                .pricetag {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
    
                    +.pricetag {
                        color: rgba(35,31,32,.5);
                        position: relative;
                        
                        &:before {
                            content: '';
                            width: calc(100% - .875rem);
                            height: 1px;
                            background-color: rgba(35, 31, 32, .5);
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }

    .btnAddBasketWrapper {        
        &:has(.expressAlertStock) {
            .btnAddBasketWrapper {
                padding: 10px;
            }

            .alert_return {
                right: 14px;
                top: 18px;
            }
        }

        &:has(.noSizeAvailable) {
            .btnAddBasketWrapper {
                padding: 15px;
            }
        }

        .alert_return {
            position: absolute;
            right: 12px;
            top: 18px;
        }

        #size_is_not_available {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .bloc_add_alert_confirmation  {
            p {
                margin-bottom: 0 !important;
            }
        }
    }

    .swiper-container {
        .swiper-wrapper {
            .item {
                max-width: 380px;
            }
        }

        &:not(.swiper-container-initialized) {
            +.product-assoc-button-next {
                opacity: 0;

                +.product-assoc-button-prev {
                    opacity: 0;
                }
            }
        }
    }

}

.product-assoc-button {
    position: absolute;
    z-index: 20;
    top: 35.5%;
    width: 16px;
    height: 16px;
    background-image: url('../svg/fp_cross.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    &.product-assoc-button-next {
        transform: rotate(180deg);
        left: calc(100% - 45px);
    }

    &.product-assoc-button-prev {
        right: calc(100% - 45px);
    }

    &.swiper-button-disabled {
        opacity: 0;
    }
}