#content_obj.in_lookbook {
    width: 100%;

    & + #push_rayon {
        display: none;
    }

    .nav-container {
        width: 100%;
        position: relative;
        margin-bottom: 30px;
        padding: 0 clamp(20px, 1.04167vw, 20px);

        #lookbook_cat_list_swiper {
            display: flex;
            align-items: center;
            width: 89.7%;
            height: 46px !important;
            box-sizing: content-box;

            &:not(.swiper-container-initialized) {
                justify-content: center;

                .lookbook_cat_list {
                    justify-content: center;
                }
            }
        }

        .lookbook_cat_list {
            display: flex;
            align-items: center;
            width: 100%;
            height: 46px !important;
            margin: 0 auto;

            .swiper-slide{
                position: relative;
                width: fit-content;
                height: auto;
                padding-right: 60px;

                &:last-child {
                    margin-right: 0 !important;

                    &:after {
                        min-width: 100%;
                    }
                }
            }

            .bloc_itm {
                opacity: 0.5;

                &.currentNav,
                &:hover {
                    opacity: 1;
                }

                .itm_name{
                    white-space: nowrap;
                    font-size: .75rem;
                    text-transform: uppercase;
    
                    &.btn_select {
                        position: relative;
                        margin: 0;
                    }
    
                    a {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }
                }
            }

        }

        .lookbook-head-button-prevv,
        .lookbook-head-button-nextt {
            position: absolute;
            z-index: 1;
            background-color: #FFFFFF;
            top: 0rem;
            cursor: pointer;

            &:before {
                content: " ";
                width: .5rem;
                height: .5rem;
                border-right: 1px solid $primaryColor;
                border-top: 1px solid $primaryColor;
            }

            &.swiper-button-disabled {
                opacity: .3;
                cursor: default;
            }
        }

        .lookbook-head-button-prevv {
            left: clamp(20px, 1.04167vw, 20px);

            &:before {
                transform: rotate(225deg);
            }
        }

        .lookbook-head-button-nextt {
            right: clamp(20px, 1.04167vw, 20px);

            &:before {
                transform: rotate(45deg)
            }
        }
    }
}

.lookbook_head {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 30px;

    #lookbookHeadSwiper {
        width: 100%;

        a {
            cursor: url(../img/grab_hand.png), auto;
        }

        .wrapper_lazy {
            display: block;
        }
    }
    
    .lookbook_head_swiper-pagination {
        text-align: center;

        .swiper-pagination-bullet {
            margin: 7px;
        }
    }

    .lookbook-head-button-prev {
        position: absolute;
        top: 46%;
        left: -5px;
        width: 1.25rem;
        height: 1.25rem;
        display: none;
    }

    .lookbook-head-button-next {
        display: none;

        &:after {
            width: 1.25rem;
            height: 1.25rem;
            top: 45%;
            right: -6px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.page_rayon.rayon_lookbook {
    opacity: 0;

    .txt,
    .lookbook_cat_desc p {
        font-size: .75rem;
        line-height: 1.5;
    }

    .lookbook_cat_desc {
        width: 100%;
        text-align: center;

        h2 {
            font-size: 25px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .5px;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .5px;
        }
    }
}

/* Swiper navigation */
.lookbook-head-button {
    height: 46px;
    display: flex;
    align-items: center;

    &.swiper-button-disabled {
        background-color: transparent !important;
    }

    &.lookbook-head-button-prev {
        @extend .swiper_arrow;
        top: 45%;
        left: 0;
        @extend .arrowLeft;
        opacity: 1 !important;
        z-index: 5;

        @include medium {
            left: calc(#{$mainPaddingMedium} - 1.5rem);
        }

        &.swiper-button-disabled {
            opacity: 0.3 !important;
        }
    }
    
    &.lookbook-head-button-next {
        z-index: 5;
        top: 0;
        right: 0;
        height: 100%;
        width: 2rem;
        background-color: $white;
        transition: background-color .2s ease;

        &::after {
            @extend .swiper_arrow;
            @extend .arrowRight;
            content: "";
            display: block;
            position: absolute;
            top: 44%;
            right: 0;
        }
    }
}

.titre_lookbook {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 25px auto 29px;
    padding-left: clamp(20px, 1.04167vw, 20px);
}

.rayon_lookbook {
    button#btt_button {
        display: none;
    }
}

.lookbook_cat_desc {
    width: 50%;
    margin: 0 auto;
    padding: 2rem $mainPadding;

    @include medium {
        padding: 2rem $mainPaddingMedium;
    }

    h2, .title, .title_seo {
        margin-bottom: 1rem;
        font-size: 1.125rem !important;

        p {
            font-size: 1.125rem;
        }
    }
}

.page_rayon.rayon_lookbook .items_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    meta[itemprop="priceRange"],
    meta[itemprop="name"] {
        display: none;
    }


    .item_look,
    .item.push {
        position: relative;
        width: calc(50% - 3.5px);
        margin-bottom: 7px;

        img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }

        .block_push img {
            max-width: 373px;
        }

        .tag {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid white;
            border-radius: 50%;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 13px;
                height: 13px;
                background: white;
                border-radius: 50%;

                @supports (-moz-appearance:none) {
                    width: 12px;
                    height: 12px;
                }
            }

            .tag_tooltip {
                z-index: 1;
                opacity: 0;
                position: absolute;
                left: 50%;
                bottom: 150%;
                transform: translateX(-50%);
                background: white;
                padding: 1.2rem;
                width: 10rem;
                transition: opacity .2s ease-in-out;

                strong {
                    font-size: 16px;
                    font-weight: 400;
                }

                .price_wrapper{
                    display: flex;

                    .pricetag {
                        display: block;
                        font-size: $price_font_size_desktop;
                        margin-top: 0.4rem;

                        &.old_price{
                            padding-left: 6px;
                            text-decoration: line-through;
                            color: rgba(35,31,32,.5);
                        }
                    }
                }
            }

            &:hover .tag_tooltip {
                opacity: 1;
            }
        }
    }

    .item.push {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28%;
        max-width: 373px;
        margin-bottom: 0;

        .imgWrapper {
            width: 100%;
        }

        .vimeo_video_ctn {
            position: relative;
            padding-bottom: calc((466 / 373) * 100%);

            iframe {
                height: unset;
                width: unset;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}

// Only for Edits pages
body.page-edits {
    #btt_button.back_to_top,
    #lookbookHeadSwiper .txt_seo,
    .page_rayon.rayon_lookbook .items_wrapper .item.push {
        display: none;
    }
    
    .titre_lookbook {
        margin: 25px auto 20px;
    }
    
    .page_rayon.rayon_lookbook {
        padding: 0;

        #content_obj.in_lookbook { 
            max-width: 1920px;

            .nav-container {
                margin-top: 27px;
                margin-bottom: 31px;
            }

            .lookbook_head {
                #lookbookHeadSwiper {
                    width: 100%;
                    margin-bottom: 0;
                }

                .lookbook-head-button {
                    display: none;
                }

                .lookbook_head_swiper-pagination {
                    text-align: center;

                    .swiper-pagination-bullet {
                        margin: 7px;
                    }
                }
            }

            .lookbook_cat_desc {
                display: block;
                width: 100%;
                text-align: center;
    
                h2 {
                    font-size: 25px !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
    
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }
            }

            #scroll_items {
                #list_item.items_wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 7px;

                    .item_look {
                        margin-bottom: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}