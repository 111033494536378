/**********************************/
/* BOX MAILLE  ********************/
/**********************************/
.caract_offrebox_container {
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid $borderColor;
	line-height: 1.3;

	.offrebox_lib {
		font-weight: bold;
	}

	.offrebox_val {
		font-size: .875rem;

		.twinset-promo {
			font-weight: 900;
		}
	}
}
.page_rayon.rayon_box {
	#content_obj {
		#list_item {
			justify-content: space-between;
			align-items: flex-start;
		}

		.items_wrapper.view_per_three  {
			.item,
			.item:nth-child(5n+2),
			.item:nth-child(5n+4) {
				width: calc(50% - 5px);
			}

			.item:nth-child(5n+2),
			.item:nth-child(5n+4) {
				margin: 0 0 30px;
			}

			.item:nth-child(5n+4) {
				margin: 0 0 30px !important;
			}

			.item + .item + .item + .item + .push,
			.item + .item + .item + .item + .push + .item {
				display: none;
			}
		}
	}

	img {
		max-width: 100%;
	}
	.sub_filter {
		display: none;
	}

	#list_item {
		align-items: center;

		.caract_offrebox_container {
			margin-top: 1rem;
			padding-top: 1rem;
			border-top: 1px solid $borderColor;
			line-height: 1.3;
			margin-left: 0;
			width: 100%;

			.offrebox_lib {
				font-weight: bold;
			}

			.offrebox_val {
				font-size: .875rem;

				.twinset-promo {
					font-weight: 900;
				}
			}
		}
	}

	.box_top_push {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 1.1rem;
	}

	/* BOUTIQUE BOX STEPS **********/
	.wrapper_box_steps {
		padding: 6.25rem 1rem 6.25rem 4rem;
		margin: 0 auto;
		display: flex;
		max-width: 1400px;
		justify-content: space-between;

		&.box_maille_old {
			padding: 0;
			margin: 6.25rem auto;
			max-width: 100%;
		}

		.box_step {
			margin-right: 3rem;
			width: 25%;
			position: relative;
			display: flex;
			flex-direction: column;

			&::before {
				content: attr(data-step);
				font-size: 15rem;
				position: absolute;
				bottom: -6%;
				left: -26%;
				font-weight: 900;
				color: #fff;
				-webkit-text-stroke: 1.5px #ccc;
				z-index: 1;
			}

			.wrapper_txt {
				flex-grow: 1;
			}
		}

		.wrapper_img {
			width: 100%;
			border: 1px solid $borderColor;
			border-bottom: 0;
			// padding for gifs with white background
			padding: 1.5rem;
			
			img {
				vertical-align: bottom;
				width: 100%;
			}
		}

		.wrapper_txt {
			padding: 2rem;
			font-size: .75rem;
			border: 1px solid $borderColor;

			p{
				position: relative;
				background-color: $white;
				z-index: 5;
				padding: .5rem;
			}
		}
	}

	.box_presentation {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-left: 20px;
		margin: 0 auto  6.25rem;

		.wrapper_txt {
			width: 45.8%;
			max-width: 870px;

			h2 {
				font-family: $primaryFont;
				font-size: 25px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $primaryColor;
				margin-bottom: 20px;
			}

			p {
				font-family: $primaryFont;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: 0.5px;
				color: $primaryColor;
				margin-bottom: 20px;

				&.presentation_txt_pre_title {
					margin-bottom: 28px;
				} 
			}

			strong {
				font-weight: 700;
			}

            .box_categ_container_1 {
                margin-bottom: 30px;
            }

            .box_categ_container {
                margin-bottom: 20px;

                .box_categ_title {
                    margin-bottom: 10px;
                }

                .box_categ_ol,
                .box_categ_ul {
                    padding-left: 23px;

                    .box_categ_li {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0.5px;
                        color: $primaryColor;

                        .box_mail_link {
                            text-decoration: underline;
                        }
                    }
                }

                .box_categ_ol {
                    .box_categ_li {
                        list-style: decimal;
                    }
                }

                .box_categ_ul {
                    .box_categ_li {
                        &::before {
                            content: "•";
                            display: inline-block;
                            position: absolute;
                            left: 23px;
                        }
                    }
                }

            }
        }

		.wrapper_img {
			width: 48.95%;
			max-width: 930px;
		}
	}

	/* BOUTIQUE CONTENT PUSHS WITHOUT SLIDER */
	.boxes_pushs {
		display: flex;
		padding: 2rem 0;
		justify-content: center;
		align-items: center;

		.box_push {
			display: flex;
			justify-content: center;

			&:first-of-type {
				padding-left: 2rem;
				justify-content: center;
			}
			&:last-of-type {
				padding-right: 2rem;
				justify-content: center;
			}
		}
	}

	/* BOUTIQUE CONTENT PUSHS WITH SLIDER */
	.wrapper_push {
		position: relative;
		margin-right: 4rem;

		&:after {
			display: block;
			content: attr(data-title);
			position: absolute;
			transition: all .3s ease-in-out;
			top: 42.5%;
			left: 60%;
			font-size: 25px;
			font-weight: 900;
			white-space: nowrap;
			transform: rotate(-90deg);
			text-align: right;
			width: 100%;
		}
	}

	.boxes_push_slider {
		padding: 0 0 2rem 2rem;
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		position: relative;

		#pushSlider {
			position: relative;

			* {
				cursor: url(../img/grab_hand.png), auto;
			}

			.wrapper_push{
				width: fit-content;
				padding-top: 2rem;
			}

			.boxes_pushs {
				border: none;
			}
		}

		.pushs-button {
			position: absolute;
			height: 100%;
			width: 2rem;
			top: 0;
			z-index: 20;


			&::after {
				@extend .swiper_arrow;
				content: "";
				display: block;
				width: 1.4rem;
				height: 1.4rem;
				position: absolute;
				transform: translateY(-50%);
			}

			&.disabled {
				background-color: transparent !important;
			}
		}

		.pushs-button-prev {
			left: 1px;

			&::after {
				@extend .arrowLeft;
				top: 50%;
				left: 0;
			}
		}
		.pushs-button-next {
			background-color: $white;
			right: 0;

			&::after {
				@extend .arrowRight;
				top: 50%;
				right: 0;
			}
		}
	}

	#push_rayon.txt_bandeau {
		margin-top: 5.1rem;
	}

	&.rayon_lookbook {
		#scroll_items {
			padding-top: 1.1rem;
		}
		
		#list_item {
			.item {
				position: relative;
				width: calc(50% - 5px);
				padding-top: 1.1rem;

				&.push {
					max-width: unset;

					.block_push {
						img {
							max-width: unset;
						}
					}
				}
			}

			.imgWrapper {
				position: relative;

				.caract_container {
					position: absolute;
					display: flex;
					flex-direction: column;
					top: 1.5rem;
					right: 3.3rem;
					z-index: 15;
					transform: translateX(50%);

					img.eclat_caracteristique {
					    width: 60px;
    					height: auto;
					}
				}
			}
		}
	}
}