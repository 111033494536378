/* ROLLOVER */
.productVisualMulti {
    position: relative;
    overflow: hidden;

    .product_rollover {
        z-index: 10;
        position: absolute;
        bottom: -100%;
        width: 100%;
        transition: all .3s ease-in-out;

        form {
            background-color: $white;
            border: 1px solid $primaryColor;
            display: flex;
            flex-wrap: wrap;
        }

        /* ALERTE STOCK */
        .btnAddBasketWrapper {
            width: 100%;
            
            .bloc_add_alert_form {
                .w-input-container {
                    .inputErr {
                        border-bottom: 1px solid $errorColor;           
                    }                           
                }
            }
        }

        .hideAchat {
            display: none!important;
        }
    }
 
    .bloc_add_color {
        display: none;
        align-items: flex-end;
        width: 25%;

        .w-submit-button {
            height: 94%;
            width: auto;
            background-color: transparent;
            border: none;
            position: absolute;
            display: flex;
            margin-top: 2px;
            align-items: flex-end;
            top: 0;
            right: 1%;
            line-height: 1.8;

            span {
                font-family: $primaryFont;
                font-size: 13px;
                font-weight: 400;
                line-height: normal;
                color: $primaryColor;
                text-transform: initial;
                margin-bottom: 5px;
            }

            &:before {
                content: "";
                width: 13px;
                height: 13px;
                background-image: url(../svg/spritesheet.svg);
                background-repeat: no-repeat;
                background-size: 700%;
                background-position: 26% 2.5%;
                display: block;
                margin: 0 9px 4px;
            }

            &:hover {
                background-color: transparent !important;
            }

            & + .loader {
                display: none !important;
            }

            @media (max-width: 1650px) and (min-width: 1024px) {
                &:before {
                    margin: 0 4px 3px;
                }
            }
        }

        @media (max-width: 1650px) and (min-width: 1024px) {
            .w-submit-button {
                height: fit-content;
                bottom: 0px;
                top: unset;
            }
        }
    }
    /* NO SUBMIT CTA */
    .titre_choices_list {
        display: none;
    }

    &:hover .product_rollover {
        bottom: 0;
    }
}

/* STYLE */
.productVisualMulti .rollover_left.achat_express {
    padding: .8rem;
    width: 75%;


}

.choices_list {
    width: 100%;
    @include flexbox(row, flex-start, center, wrap);

    .remaining_colors {
        display: none;
    }

    .form_itm.check:not(:last-child) {
        margin-right: 1rem;
    }

    .form_itm.check.color,
    .form_itm.check.size {
        position: relative;

        .input_check.radio {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;

            &.disabled {
                + label {
                    position: relative;

                    span {
                        &:after {
                            content: '';
                            height: 1px;
                            background-color: rgba(35, 31, 32, 0.5);
                            width: calc(100%);
                            position: absolute;
                            top: 50%;
                            right: 0;
                        }
                    }
                }
            }
        }

        &:has(input:checked),
        &:hover {
            .form_itm.size input {
                & + label span {
                    color: rgba(35, 31, 32, 0.5);
                }
                
                &.disabled {
                    & + label span {
                        &:after {
                            background-color: rgba(35, 31, 32, 0.5);
                        }
                    }
                }

                &:checked,
                &:hover {
                    & + label span {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    .form_itm.check {
        /* taille sur le container ****/
        &.color {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include flexbox(row, center, center, unset);
            position: relative;
            margin-bottom: .8rem;

            /* Input prend la taille de son container et s'adaptera à la taille de son container ****/
            [type=radio] {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: -1;

                /* une fois la pastille cliquée, entourer la couleur à l'aider d'un after plus large que la pastille ****/
                &:checked + label,
                &.checked + label {
                    &::after {
                        content: "";
                        width: 1.5rem;
                        height: 1.5rem;
                        position: absolute;
                        border: 1px solid $primaryColor;
                        border-radius: 50%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            label {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                position: relative;
                cursor: pointer;
                background-position: center;
                background-size: cover;

                &[title="Blanc"],
                &[title="Denim"] {
                    border: 1px solid #ccc!important;
                }
            }

            &:before,
            &:after {
                display: none;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &:before {
                content: attr(data-color);
                border: none;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: .5px;
                bottom: 132%;
                padding: 0;
                text-wrap: nowrap;
            }

            &.firstElem::before {
                left: 0;
                transform: none;
            }

            &:hover {
                &:before,
                &:after {
                    display: block;
                }
            }
        }

        &.size {
            // La taille prend la même taille que les pastilles de couleurs et est centré dans son container pour qu'il soit visuellement aligné avec les pastilles
            font-size: 16px;
            min-width: 0;
            text-align: center;

            @media screen and (max-width: 1615px) {
                font-size: 14px;
            }

            &:hover {
                text-decoration: underline;
            }

            &:not(:last-child) {
                @media screen and (max-width: 1550px) {
                    margin-right: 7px;
                }
            }
        }
    }

    &:has(input:checked),
    &:hover {
        .form_itm.size  input {
            & + label span {
                color: rgba(35, 31, 32, 0.5);
            }
            
            &.disabled {
                & + label span {
                    &:after {
                        background-color: rgba(35, 31, 32, 0.5);
                    }
                }
            }

            &:checked,
            &:hover {
                & + label span {
                    color: $primaryColor;
                }
            }
        }
    }
}

body.category {
    #search_page {
        .page_upper_text {
            margin-bottom: 1.2rem;
        }
    }

    .page_rayon, 
    #search_page {
        .productVisualMulti {
            .rollover_left.achat_express {
                width: 70%;
            }

            .bloc_add_color {
                width: 30%;

                button.w-submit-button {
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin: 0;
                    position: absolute;
                    top: 0;
                    right: 1%;

                    &::before {
                        position: relative;
                        top: -4px;
                    }
                }

                .w-submit-button:not(.w-loader):hover,
                .button.w-submit-button:not(.w-loader):hover {
                    background-color: transparent;
                }
            }

            &:hover .product_rollover {
                bottom: 0;
            }

            &:hover .product_picture:has(.wrap_video) + .product_rollover {
                bottom: 0;
            }
        }
    }

    .rollover_left.achat_express {
        .choices_list .form_itm.check.color:before {
            bottom: 190%;
            content: none;
        }
    }
}