body.category {
    .signup_module_img {
        width: 100%;
        max-height: 684px;
        padding: 0 13.2rem;
        margin: 0;
        @include flexbox(row-reverse, space-evenly, normal, unset);
        
        @include small {
            padding: 5rem $mainPaddingMedium 5rem $mainPaddingMedium;
        }

        @media screen and (max-width: 1640px) {
            padding: 1.1rem 0 0;
        }
    }

    .holder_content {
        width: 42%;
        @include flexbox(column, normal, flex-start, unset);

        > p {
            font-size: .75rem;
            line-height: 1.5;
            text-align: left;
            width: 100%;
        }

        h2 {
            max-width: 70%;
            margin: 0 0 1.5rem;
        }
        
        h3 {
            margin: 0 0 .2rem;
            font-weight: 400;
        }

        .w-newsletter-form {
            margin: 1.2rem 0 0;
            width: 100%;

            .w-form-line {
                margin: 0;

                .w-input-note.w-input-error {
                    margin: 0;
                }

                .w-input-note.w-input-valid {
                    font-family: $primaryFont;
                    font-size: 16px;
                    margin: 0;
                }

                &.w-submit {
                    height: unset;
                    position: unset;
                    top: unset;
                    right: unset;
                    width: unset;
                    max-width: unset;
                    margin-top: 1rem;

                    .w-submit-button {
                        width: unset;
                        height: unset;
                        text-align: left;

                        span {
                            letter-spacing: 1px;
                            border-width: 0;
                        }
                    }

                    .w-loader {
                        display: none;
                    }
                }
            }
        }
    }

    .holder_img {
        width: 36%;

        .ill_img {
            max-width: 100%;
            img {
                width: 100%;
            }
        }
    }

    .reassurance_wrapper {
        clear: both;
        border-top: 1px solid $borderColor;
        margin-bottom: 6.75rem;
    }

    .home-module.social-module {
        padding: 4rem 0;
    }
}