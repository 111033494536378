.nav_header {
    /* MON COMPTE / PANIER DROPDOWN */
    .header_right_wrapper {
        .nav_header_item {
            &.accountItem.hasSubMenu,
            &.cartItem.hasSubMenu {
                nav.sub_menu {
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    width: 422px;
                    height: 100vh;
                    position: absolute;
                    top: 0;
                    right: -100%;
                    z-index: -1;
                    background-color: $white;
                    transition: opacity 0.5s ease-in-out;

                    .sub_menu_content {
                        margin-top: calc(3rem - 1px);
                        padding: 3.4rem 1.0625rem;

                        .icon {
                            display: none !important;

                            .item_link,
                            .item_title {
                                display: none !important;
                            }
                        }
                    }

                    .sub_menu_title {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: 29px;
                        letter-spacing: 0.5px;
                        margin-bottom: 0.6rem;
                    }

                    .sub_menu_intro {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;

                        span {
                            text-transform: lowercase;
                        }
                    }

                    .account__title {
                        display: none;
                    }

                    .sub_menu_list {   
                        margin: 2rem 0 2.9rem;
                        flex-direction: column;

                        .item {
                            a {
                                color: rgba(35, 31, 32, 0.50);
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: 0.5px;
                                display: block;
                                margin-bottom: 14px;
                                text-transform: uppercase;
                            }

                            &.actif {
                                a {
                                    letter-spacing: 0.5px;
                                    color: $primaryColor;
                                }
                            }
                        }
                    }

                    .btn_container {
                        #btn-disconnect {
                            letter-spacing: 3px;
                            max-width: 388px;
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            text-align: center;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;

                            &:hover {
                                color: white;
                            }
                        }

                        .loader {
                            max-width: 388px;
                        }
                    }
                }

                &.hover {
                    nav.sub_menu {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        right: 0;
                        z-index: 5;
                    }      
                }  
            }

            &.accountItem {
                #accountSwiper {
                    .item {
                        a {
                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }
                }
            }
        }
    }

    /* CART DROPDOWN */
    #cart_top {
        nav.sub_menu {
            .rolloverTxt {
                span {
                    display: none;
                }

                &.sub_menu_title {
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}

/* EN version */
body.en {
    .nav_header .header_right_side .header_right_wrapper #search_bar .item_title {
        left: 2px;
    }
}