// Code for MAC only
body.mac {
    //BY DEFAULT
    font-size: 15px;
    font-weight: 300;

    strong {
        font-weight: 400;
    }

    // BUTTONS
    .btnAddBasket,
    .button.dark,
    .button.primary,
    .button.secondary,
    .rodierButton,
    .w-contact-form .file-input-row .w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit label,
    .w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit .w-contact-form .file-input-row label,
    .w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit .w-submit-button,
    .w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit button.w-submit-button {
        font-size: 15px;
        font-weight: 300;
    }

    // Ariane
    #breadcrumbs {
        font-size: 15px;
        font-weight: 300;

        .breadcrumbs .breadcrumb.current {
            font-weight: 300;
        }
    }

    // Price
    .wrapper_description .product_prices,
    .bill_line .price_tag,
    .bill_line .pricetag,
    .item_price .price_tag,
    .item_price .pricetag,
    .product_prices .price_tag,
    .product_prices .pricetag,
    .wrapper_cart_product_price .price_tag,
    .wrapper_cart_product_price .pricetag,
    .wrapper_price .price_tag,
    .wrapper_price .pricetag {
        font-size: 15px;
        font-weight: 300;
    }

    // FORMULAIRE
    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element,
    input[type=password].w-input-element,
    input[type=text].w-input-element,
    textarea.w-input-element,
    .w-input-group .w-group-label,
    .wrapper_moncompte .w-input-label span,
    .w-input-note, .w-input-note.w-input-error,
    .w-dropdown .w-value,
    .w-form-line .noaddr,
    .wrapper_form_adresse .choix_type_adresse > div {
        font-family: inherit;
        font-size: 15px;
        font-weight: 300;
    }

    //HEADER
    .nav_header {
        .menu_top_wrapper #leftMenu .main_menu_itm a.block_lnk {
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 300;
        }

        .header_right_wrapper {
            .nav_header_item.accountItem.hasSubMenu,
            .nav_header_item.cartItem.hasSubMenu {
                nav.sub_menu .sub_menu_title {
                    font-size: 24px;
                    font-weight: 300;
                }
            }

            .accountItem .item_link + .item_title,
            #cart_top p.item_title:not(.nb_basket) span,
            .accountItem p.item_title:not(.nb_basket) span {
                font-weight: 300;
            }

            .nav_header_item.accountItem.hasSubMenu nav.sub_menu .btn_container #btn-disconnect,
            .nav_header_item.cartItem.hasSubMenu nav.sub_menu .btn_container #btn-disconnect,
            .nav_header_item.accountItem.hasSubMenu nav.sub_menu .sub_menu_list .item a,
            .nav_header_item.cartItem.hasSubMenu nav.sub_menu .sub_menu_list .item a,
            .nav_header_item.accountItem.hasSubMenu nav.sub_menu .sub_menu_intro,
            .nav_header_item.cartItem.hasSubMenu nav.sub_menu .sub_menu_intro {
                font-size: 15px;
                font-weight: 300;
            }

            #search_bar .item_title,
            .lang_switch_ctn .lang_switch .lang_itm {
                font-size: 15px;
                font-weight: 300;
            }
        }

        .header_right_side {
            .header_right_wrapper .accountItem .item_link {
                width: 23px;
                height: 23px;
                mask-image: url(../svg/spritesheet.svg);
                mask-size: 500% auto;
                mask-position: 0 0;
                mask-repeat: no-repeat;
                background-color: #231f20ad;
                background-image: none;
                background-size: 0;
                background-position: 0;
            }

            .header_right_wrapper #cart_top .item_link {
                width: 23px;
                height: 23px;
                mask-image: url(../svg/spritesheet.svg);
                mask-size: 500% auto;
                mask-position: 25% 0;
                mask-repeat: no-repeat;
                background-color: #231f20ad;
                background-image: none;
                background-size: 0;
                background-position: 0;
            }

            .header_right_side .header_right_wrapper #cart_top .item_title.nb_basket {
                font-weight: 300;
            }

            .header_right_wrapper #cart_top:hover .sub_menu .sub_menu_content #show_top_cart {
                .cart_product {
                    .wrap_top_info .title,
                    .wrap_top_info .sub_title,
                    .sub_couleur,
                    .sub_taille {
                        font-size: 15px;
                        font-weight: 300;
                    }
                }

                .wrap_tot_panier {
                    .cart_panier_total {
                        .pricetag,
                        label {
                            font-size: 15px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    #search_bar_form form#form_recherche .sub_menu.wrap_search_field {
        #search_home,
        #search_home::placeholder,
        #search_results li {
            font-size: 15px;
            font-weight: 300;
        }
    }

    #show_top_cart {
        .cart_product {
            .title,
            .sub_title,
            .wrapper_price span,
            .sub_couleur,
            .sub_taille {
                font-size: 15px;
                font-weight: 300;
            }
        }

        .wrap_tot_panier .form_submit a span {
            font-size: 15px !important;
            font-weight: 300 !important;
        }
    }

    &.customer #adresseForm .w-has-tel .errormsgadd,
    &.customer .wrapper_form form.w-form.w-info-form.w-login-form .w-has-tel .errormsgadd,
    &.customer .wrapper_form_adresse #adresseForm .w-has-tel .errormsgadd,
    &.step_2.in_tunnel #adresseForm .w-has-tel .errormsgadd,
    &.step_2.in_tunnel .wrapper_form form.w-form.w-info-form.w-login-form .w-has-tel .errormsgadd,
    &.step_2.in_tunnel .wrapper_form_adresse #adresseForm .w-has-tel .errormsgadd,
    &.step_2_0 #adresseForm .w-has-tel .errormsgadd,
    &.step_2_0 .wrapper_form form.w-form.w-info-form.w-login-form .w-has-tel .errormsgadd,
    &.step_2_0 .wrapper_form_adresse #adresseForm .w-has-tel .errormsgadd {
        font-weight: 300;
    }

    // HOME
    .tg-module.tg_2 .swiperTg_2 .swiper-wrapper,
    .tg-module.tg_3 .swiperTg_3 .swiper-wrapper {
        a.block_lnk .wrapper_description h3.product_titles strong,
        .item .productVisualMulti .product_rollover .alert_stock span,
        .item .productVisualMulti .product_rollover .expressAlertStock .bloc_add_alert_confirmation p span,
        a.block_lnk .wrapper_description h3.product_titles strong,
        .item .productVisualMulti .product_rollover .alert_stock span,
        .item .productVisualMulti .product_rollover .expressAlertStock .bloc_add_alert_confirmation p span,
        .item .productVisualMulti .product_rollover .btnAddBasketWrapper.expressAlertStock .alert_stock span,
        .item .productVisualMulti .product_rollover .btnAddBasketWrapper.expressAlertStock .bloc_add_alert_confirmation p {
            font-size: 15px;
            font-weight: 300;
        }
    }

    //PANIER
    &.cart,
    &.in_tunnel {
        .tunnel_track {
            .tunnel_step,
            .tunnel_step.on {
                span {
                    font-size: 15px;
                    font-weight: 300;
                }
            }
        }

        &.step_1 .precoDeliveryDate,
        &.step_1 .precoSubtitle,
        &.step_1 .cart_main_table .wrap_titre_prix .cart_product_title,
        &.step_1 .cart_main_table .wrap_titre_prix .cart_product_subtitle,
        &.step_1 .cart_main_table .wrapper_cart_product_price .pricetag span,
        &.step_1 .cart_main_table .wrapper_cart_product_price .realised_economy,
        &.step_1 .main_table .bill_line,
        form#formPanierCadeau .order_gift_form .w-input-label span,
        .left_side .cart_product_line .cart_product_sizecol.color span,
        .left_side .cart_product_line .cart_product_sizecol.quantity label span,
        .left_side .cart_product_line .cart_product_modify .product_quantity_change form.qte_selector .qteWrapper .selected_qte,
        .wrapper_panier > .wrapper_mes_adresses > .edit_adresse .left_side .cart_product_line .cart_product_modify .product_quantity_change form.qte_selector .qteWrapper .selected_qte,
        .cart_product_line .cart_product_sizecol.size,
        .left_side .cart_product_line .cart_product_sizecol.color div,
        .left_side .cart_product_line .cart_product_modify .wishToBasket span,
        .wrapper_panier > .wrapper_mes_adresses > .edit_adresse .left_side .cart_product_line .cart_product_modify .wishToBasket span,
        .title_type_livraison,
        .transporteur_info,
        .left_delivery .civilite,
        .left_delivery .adresse,
        .left_delivery .transporteur_delay,
        .adresse_change .delivery_form_title,
        .wrapper_form_adresse .choix_type_adresse > div,
        .adresse_change .wrapper_form_adresse .delivery .w-radio-group .w-radio-input .w-input-label,
        .main_table .bill_line.total_produit,
        .cart_product_line .cart_product_desc .wrap_titre_prix .line_product_desc,
        .cart_product_line .wrapper_cart_product_price .pricetag .price,
        .main_table .bill_line.total_produit_dropdown .cart_product_line .cart_product_desc .wrapper_cart_product_desc p.cart_product_sizecol.color .color_bullet + span,
        .main_table .bill_line.total_produit_dropdown .cart_product_line .cart_product_desc .wrapper_cart_product_desc p.cart_product_sizecol.quantity label span,
        .main_table .bill_line.discount,
        .main_table .bill_line:not(.total),
        .main_table .bill_line:not(.total_produit_dropdown),
        .main_table .formPanier_container #formPanier .checkCgv .shipment_cgv_link,
        .main_table .formPanier_container #formPanier .checkCgv + #err_cgv_compulsory,
        .main_table .formPanier_container .form_submit #btn_cmd_valid span,
        .right_delivery .fdp_offered,
        .choix_delivery_relais .shop_name,
        .button.secondary.show_modif_info.show_relais.button.secondary.dark,
        .choix_delivery_relais .delivery_form_title.title_choix_relais,
        .displayed_result .sidebar-right .kp,
        .displayed_result .sidebar-right .kp .name,
        .displayed_result .sidebar-right .kp .distance,
        .displayed_result .sidebar-right .kp .address,
        .displayed_result .sidebar-right .kp .btn_container,
        &.checkout .payment_wrapper #bank_div .bank_title,
        &.checkout .payment_wrapper #bank_div .wrapper_bloc_banque.paypal .bloc_bank_contents .bank_subtitle strong,
        &.checkout .payment_wrapper #bank_div .wrapper_bloc_banque.cofidis3x .bloc_bank_contents .bank_subtitle strong,
        &.checkout .payment_wrapper #tunnel_right_col #cart_total .wrapper_payment .payment_addr_wrapper .title,
        &.checkout .payment_wrapper #tunnel_right_col #cart_total .wrapper_payment .payment_addr_wrapper .address,
        &.checkout .payment_wrapper #tunnel_right_col #cart_total .recap_cart_title,
        &.checkout .payment_wrapper #bank_div .wrapper_bloc_banque .title_wrapper .bank_subtitle,
        &.checkout .payment_wrapper #bank_div .wrapper_bloc_banque .title_wrapper_fold .bank_subtitle,
        &.checkout .thanksWrapper .thanksRecap h1 span,
        &.checkout .thanksWrapper .thanksRecap .thanksLeft .seeOrderEvo,
        &.checkout .thanksWrapper .thanksRecap .thanksLeft .bankThanksBtn span,
        &.checkout .thanksWrapper .thanksRecap .thanksLeft .thanksTrust,
        &.checkout .thanksWrapper .thanksRecap .thanksRight p,
        &.checkout .thanksWrapper .thanksRecap .thanksRight p span,
        &.checkout .thanksWrapper .thanksRecap .thanksRight p strong {
            font-size: 15px;
            font-weight: 300;

            strong,
            b {
                font-weight: 300;

                span {
                    font-weight: 300!important;
                }
            }
        }

        .info_popup {
            font-weight: 300;

            .adresse,
            .city,
            .indication,
            .relayLink,
            p {
                font-size: 15px !important;
                font-weight: 300 !important;
            }
        }

        .bloc_question .intitule_question {
            font-size: 15.7px;
            letter-spacing: 1px;
            font-weight: 300;
        }

        .bloc_question .faq_theme_wrapper:last-child .texte_question {
            font-weight: 300;
        }

        .bloc_question #popin_trigger,
        .in_tunnel .bloc_question #popin_trigger {
            font-weight: 300;
        }

        #tunnel_right_col .faq_theme_wrapper:last-child .title_question span {
            font-size: 24px;
            font-weight: 300;
        }
    }

    &.cart.step_1 .cart_main_title,
    &.checkout .thanksWrapper .thanksRecap h1 {
        font-size: 24px;
        font-weight: 300;
    }

    &.pushpanier #wrapper_cart_slider .full_w_sldr_title,
    &.pushpanier #wrapper_cart_slider #cart_slider .wrapper_description .product_titles,
    .share_cart a,
    .recap_cart_title,
    .encart_3xcb_panier .txt_3x_cb.f_left,
    &.cart .wrapper_panier > .wrapper_mes_adresses > .edit_adresse #new_address #adresseForm .w-input-group .w-group-label,
    &.cart .wrapper_panier > .wrapper_mes_adresses > .edit_adresse #new_address #adresseForm .w-form-line .w-radio-group p.w-input-label span,
    &.in_tunnel .accountCreate .w-input-label span,
    &.in_tunnel .sameBillAddress .w-input-label span,
    &.in_tunnel .accountCreate .w-input-label span,
    &.in_tunnel .sameBillAddress .w-input-label span {
        font-size: 15px;
        font-weight: 300;

        strong,
        span {
            font-weight: 300;
        }
    }

    &.pushpanier #wrapper_cart_slider #cart_slider .productVisualMulti .bloc_add_color .w-submit-button {
        bottom: 1px;
    }

    &.step_1 #tunnel_right_col .pushBasketWrapperText strong {
        font-weight: 300;
    }

    // Achat express
    .productVisualMulti .btnAddBasketWrapper .alert_stock,
    .bloc_add_alert_confirmation p {
        font-size: 15px;
        font-weight: 300;
    }

    #search_page .bloc_sub_categ .choices_list .form_itm.size[type=checkbox]:checked,
    .choices_list #search_page .bloc_sub_categ .form_itm.size[type=checkbox]:checked,
    .choices_list .form_itm.check.size {
        font-size: 15px;

        @media screen and (min-width: 1615px) {
            font-size: 13px;
        }
    }

    //RAYON
    .page_rayon {
        .tg-module.tg_3 .swiperTg_3 {
            .swiper-wrapper {
                a.block_lnk {
                    .wrapper_description {
                        h3.product_titles {
                            strong {
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }

        .filter_wrapper {
            .bloc_sub_categ {
                .views h2,
                .views ul li,
                .views ul li.is-active {
                    font-size: 15px;
                    font-weight: 300;
                }

                .sub_categ_wrapper {
                    #slideCateg .swiper-wrapper .swiper-slide {
                        .sub_cat_name_title {
                            font-size: 15px;
                            letter-spacing: 1px;
                            font-weight: 300;
                        }
                    }
                }
            }

            #rayon_sticky_block {
                .title_wrapper .title {
                    font-size: 24px;
                    font-weight: 300;
                }

                #filter_sticky .filter_name,
                #filter_sticky .selected_options,
                #filter_caract .selected_options .container span,
                #filter_caract .selected_options .form_itm.charact span,
                #filter_trier .selected_options .container span,
                #filter_trier .selected_options .form_itm.charact span,
                #filter_sticky #filter_caract .selected_options .container span,
                #filter_sticky #filter_caract .selected_options .form_itm.charact span,
                #filter_sticky #filter_trier .selected_options .container span,
                #filter_sticky #filter_trier .selected_options .form_itm.charact span,
                #filter_sticky #filter_caract .filter_name span,
                #filter_sticky #filter_trier .filter_name span,
                #filter_sticky #filter_price .wrapper_filtre .vue-slider-dot .vue-slider-dot-tooltip-inner span,
                #filter_sticky #filter_price .wrapper_filtre .ui-slider .ui-slider-handle .handler_price,
                .block-btn-filter .form_submit + .form_submit button.button span,
                #filter_sticky #filter_trier .selected_options .container span,
                .block-btn-filter .form_submit button.button span,
                .block_selected_filter .form_submit button.button span {
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            #trigger_filtre,
            .block-btn-filter .form_submit button.button span,
            .block_selected_filter .form_submit button.button span {
                font-size: 15px;
                font-weight: 300;
            }
        }

        .btnAddBasketWrapper #size_is_not_available {
            font-size: 15px;
            font-weight: 300;
        }
    }

    // RECHERCHE
    #search_page {
        .search_recap .search_term {
            font-size: 24px;
            font-weight: 300;
        }

        .wrapper_description .product_titles {
            font-size: 15px;
            font-weight: 300;

            strong {
                font-weight: 300;
            }
        }
    }

    .wrap_flitre_pager {
        .loader_scroll {
            .button.primary {
                span {
                    font-weight: 300;
                }
            }
        }
    }

    .wrapper_bandeau .bandeau_nav {
        h1 .collection_name {
            font-size: 24px;
            font-weight: 300;
        }
    }

    &.category #scroll_items .wrapper_description {
        .product_titles,
        .product_prices,
        .pricetag,
        .pricetag .price {
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 300!important;

            strong {
                font-weight: 300;
            }
        }
    }

    // FP
    &.product_page {
        .product_infos_container {
            .itm_back_btn span,
            .userTooltip span {
                font-size: 15px;
                font-weight: 300;
            }
        }

        .product_feats_wrapper {
            .primary_navbar .navbar_tab .thumblnk span,
            .tunnel_track .navbar_tab .thumblnk span,
            .thumbcnt li,
            .thumbcnt li.product_ref span,
            .thumbcnt .thumbslide .full-text,
            .thumbcnt .thumbslide .txt,
            .thumbcnt .thumbslide .trimmed-text .seeLess,
            .thumbcnt .thumbslide .trimmed-text .seeMore {
                font-size: 15px;
                font-weight: 300;
            }

            .thumbcnt .thumbslide .full-text strong,
            .thumbcnt .thumbslide .txt strong {
                font-weight: 400;
            }
        }

        .product_infos_content {
            h1 {
                font-size: 24px;
                font-weight: 300;

                span {
                    font-size: 19px;
                    font-weight: 300;
                }
            }

            #ligne_couleur .disabledBtnTooltip .disabledBtnInfos,
            #ligne_pointure .disabledBtnTooltip .disabledBtnInfos,
            .product_prices #link_paiement_3fois,
            .product_prices #link_paiement_3fois strong,
            .size_title span,
            .choices_list .form_itm.size label span,
            .sizeguide,
            .form_submit #btn_add_cart,
            .form_submit #btn_add_alert span,
            .product_prices .item_price .price_tag {
                font-size: 15px;
                font-weight: 300;
            }

            #ligne_pointure #kleep-container {
                .kleep-button .kleep-default,
                .kleep-texts .kleep-custom,
                .kleep-underline {
                    font-size: 15px !important;
                    font-weight: 300 !important;
                }
            } 
        }

        #gondole_derniers_articles_vus {
            #derniers_articles_vus,
            #productVisitedSwiper_wrapper #productVisitedSwiper .imgWrapper .wrapper_description .item_title,
            #productVisitedSwiper_wrapper #productVisitedSwiper .imgWrapper .item_price .pricetag,
            #productVisitedSwiper_wrapper #productVisitedSwiper .btnAddBasketWrapper #size_is_not_available {
                font-size: 15px;
                font-weight: 300;

                strong {
                    font-weight: 300;
                }
            }
        }
    }

    // TG ASSOC
    .product_assoc_content {
        .assoc_title {
            font-size: 15px;
            font-weight: 300;
        }
    }

    .product_assoc_swiper {
        .wrapper_description {
            .product_titles {
                font-size: 15px;
                font-weight: 300;

                strong {
                    font-weight: 300;
                }
            }

            .product_prices .price_ctn .pricetag {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    //CMS
    .page_upper .page_upper_title,
    .cms_page .three_text h1,
    .cms_page .cms_page_content h2,
    .cms_page .cms_page_content h3,
    .cms-page-module.image-text .cms-page-module-contents .cms-page-module-title,
    .cms-page-module.image-droit .cms-page-module-contents .cms-page-module-title {
        font-size: 24px;
        font-weight: 300;
    }

    .cms_page .image-image-image .home-module-body .insideImg .home-column .button {
        font-size: 24px;
        font-weight: 400;
    }

    .cms_page .primary_navbar .tabsatellite,
    .cms_page .tunnel_track .tabsatellite,
    .cms_page .wrapper_moncompte .sub_menu_list .tabsatellite,
    .wrapper_moncompte .cms_page .sub_menu_list .tabsatellite,
    .page_upper .page_upper_desc,
    .cms_category .cms_page_previsu_title,
    .cms_category #content_previsu_cms_page .cms_page_previsu_title,
    .cms-page-module.image-text .cms-page-module-contents .cms-page-module-text,
    .cms-page-module.image-droit .cms-page-module-contents .cms-page-module-text,
    .cms_page .text-image-4 .cms-page-column .cms-page-module-title {
        font-size: 15px;
        font-weight: 300;
    }

    .cms_page .cms_page_content strong {
        font-weight: 400;
    }

    // FOOTER
    #copyright {
        .footer_cats_newsletter .footer_ctn .newsletter_bloc .signup_module .w-newsletter-form {
            .w-input-label span,
            input.w-input-element {
                font-size: 15px;
                font-weight: 300;
            }
        }

        .reassurance_wrapper {
            .rea_ctn .sub_txt,
            .rea_ctn .txt {
                font-size: 15px;
                letter-spacing: 1px;
                font-weight: 300;
            }
        }

        .footer_cats_newsletter .footer_ctn {
            .footer_cats {
                nav ul li,
                nav ul > p {
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 300;
                }
            }

            .newsletter_bloc .signup_module {
                .title {
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 300;
                }

                .subtitle {
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 300;
                }
            }
        }
    }

    // PLAN DU SITE
    .page_satellite.sitemap {
        .satellite_title {
            font-size: 24px;
            font-weight: 300;
        }

        .satellite_content {
            .sitemap_section {
                .big_title_charte {
                    font-size: 24px;
                    font-weight: 300;
                }
            }
        }
    }

    // Mon compte
    &.body_login {
        .login_bloc {
            .title.my_account h2 {
                font-size: 24px;
                font-weight: 300;
            }
        }

        .newlogin_page {
            .login_bloc {
                .title h3,
                .title h4,
                .title:not(.my_account) .subtitle {
                    font-weight: 300;
                    font-size: 15px;
                }
            }

            .w-newlogin-form {
                .w-pwd-reset,
                .w-fieldset-label,
                .optin_container,
                .w-input .w-input-label span {
                    font-weight: 300;
                    font-size: 15px;
                }
            }
        }
    }

    .nav_moncompte .title span {
        font-size: 24px;
        font-weight: 300;
    }

    .wrapper_moncompte .sub_menu_list .item a,
    .filtres_commandes > select[name=year],
    .table_mes_messages th,
    .table_mes_messages thead tr td,
    .table_mon_compte th,
    .table_mon_compte thead tr td,
    .table_mes_messages tbody tr td,
    .table_mon_compte tbody tr td,
    .conteneur_adresse .adresse .addr_label .addr_titre {
        font-size: 15px;
        font-weight: 300;
    }

    &.customer .intro,
    &.newsletter .intro,
    &.order .intro,
    &.wishlist .intro {
        font-size: 15px !important;
        font-weight: 300;
    }

    &.order {
        .order_detail_head .wrapper_delivery_addr > p,
        .order_detail_head .wrapper_infos_cmd > p,
        .order_detail_head .cmd_date span,
        .order_detail_head .num_suivi a span,
        .order_detail_head .cmd_status span,
        .cmd_detail_btm .titre,
        .cmd_detail_btm .clearfix .montant_total.montants,
        .cmd_items .sub_title,
        .cmd_detail_btm .titre span {
            font-size: 15px !important;
            font-weight: 300 !important;
        }
    }

    &.newsletter {
        .w-form-line.optin-select-line label.w-input.w-checkbox-input p.w-input-label .multiline strong,
        .w-form-line.optin-select-line label.w-input.w-checkbox-input p.w-input-label .multiline strong + span {
            font-size: 15px;
            font-weight: 300;
        }
    }

    &.wishlist {
        .product_ctn {
            .wrapper_product {
                .product_desc {
                    .subtitle,
                    .price span,
                    .wrapper_details .product_size {
                        font-size: 15px;
                        font-weight: 300;
                    }

                    .title {
                        span {
                            font-size: 15px;
                            font-weight: 300;
                        }
                    }
                }
            }

            .mail_share,
            .wishToBasket,
            .report.error.no_stock {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    &.customer.customer_stock_alert {
        .account__title {
            font-size: 24px;
            font-weight: 300;
        }

        .right-container.wrapper_content_stock_alert .wrapper_form .product-link .cart_detail_box .cart_product {
            .sub_title,
            .sstitre,
            .sub_couleur .sub_couleur_name,
            .sub_taille {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    &.newsletter {
        .unsub_txt {
            font-size: 15px;
            font-weight: 300;

            b {
                font-weight: 300;
            }
        }
    }

    // Contact
    &.contact_faq {
        .titre_contact {
            font-size: 24px;
            font-weight: 300;

            & + h2 {
                font-size: 15px;
                font-weight: 300;
            }
        }

        #form_contact1 {
            #optinId {
                .w-fieldset-label,
                .w-input-label span {
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            .w-input-element,
            .w-form-line.w-submit button span {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    // FAQ
    &.faq {
        .wrapper_moncompte.connected .wrapper_moncompte_main.faq .account__title,
        .wrapper_moncompte .nav_moncompte .title {
            font-size: 24px;
            font-weight: 300;
        }

        .wrapper_moncompte .theme_global .theme_title span {
            font-size: 15px;
            font-weight: 300;
        }

        .faq_element {
            .faq_title,
            .faq_question,
            .faq_reponse {
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    // SATELLITE
    .page_satellite {
        .satellite_title span {
            font-size: 24px;
            font-weight: 300;
        }

        .satellite_nav_bar.no_text .tabsatellite h2 {
            font-size: 15px;
            font-weight: 300;
        }

        p,
        &.guide_tailles table tr td,
        table tr td {
            font-size: 15px;
            font-weight: 300;

            strong {
                font-weight: 300;
            }
        }

        &.conseil {
            .page_upper_text .satellite_title span,
            .satellite_content.satellite_lower .sat_block_text h2 {
                font-size: 23px;
                font-weight: 600;
            }

            .page_upper_text .nav_holder .satellite_nav_bar .tabsatellite.navbar_tab {
                font-size: 15px;
            }

            .page_upper_text .nav_holder .navbar_cnt_wrapper .navbar_cnt,
            .satellite_content.satellite_lower .sat_block_text h3,
            .satellite_content.satellite_lower .sat_block_text h4,
            .satellite_content.satellite_lower .sat_block_text li,
            .satellite_content.satellite_lower .sat_block_text p {
                font-size: 15px;
                font-weight: 300;
            }

            .page_upper_text .nav_holder .satellite_nav_bar .tabsatellite.navbar_tab h2 {
                font-weight: 500;
            }
        }
    }

    &.satellite_lexique_maille .content_sat .content_inside {
        font-size: 15px;
        line-height: 20.51px;
        font-weight: 300;
    }

    // CMS
    .cms_page .primary_navbar .tabsatellite,
    .cms_page .tunnel_track .tabsatellite,
    .cms_page .wrapper_moncompte .sub_menu_list .tabsatellite,
    .wrapper_moncompte .cms_page .sub_menu_list .tabsatellite {
        font-weight: 300;
    }

    .cms_category #content_previsu_cms_page .cms_page_previsu_title,
    .cms_page .cms_page_content .cms-page-module-text,
    .cms_page .cms_page_content .sub_categ_desc,
    .cms_page .cms_page_content h4,
    .cms_page .cms_page_content li,
    .cms_page .cms_page_content p,
    .cms_page .cms_page_content td {
        font-size: 15px;
        font-weight: 300;

        span {
            &[style*="font-size"],
            &[style*="font-size: 16px;"] {
                font-size: 15px !important;
            }

            &[style*="font-size: 25px;"] {
                font-size: 24px !important;
            }
        }
    }

    // STORELOCATOR
    &.store_locator {
        .page_upper .page_upper_text .page_upper_title {
            font-size: 24px;
            font-weight: 300;
        }

        input[type=text]#search_cp,
        input[type=text]#search_cp::placeholder,
        div.ou,
        .liste_recherche_distributeur .primary_navbar .navbar_tab,
        .liste_recherche_distributeur .tunnel_track .navbar_tab,
        .liste_recherche_distributeur .wrapper_moncompte .sub_menu_list .navbar_tab,
        .liste_recherche_distributeur .sub_menu_list .navbar_tab,
        .detail_store,
        .store_more span,
        .info_popup,
        .info_popup .name,
        .info_popup .adresse,
        .info_popup .city,
        .info_popup .indication,
        .info_popup .relayLink,
        .info_popup p,
        .content_store .puce .open_hour,
        .content_store .puce .open_hour span,
        .details_dropdown .horaire,
        .elem_list_contents.with_filter::after {
            font-size: 15px !important;
            font-weight: 300 !important;
        }
    }

    // Lookbook
    .titre_lookbook {
        font-size: 24px;
        font-weight: 300;
    }

    #content_obj.in_lookbook .nav-container .lookbook_cat_list .bloc_itm .itm_name a {
        font-size: 15px;
        font-weight: 300;
    }

    .page_rayon.rayon_lookbook {
        .lookbook_cat_desc h2 {
            font-size: 24px !important;
            font-weight: 300;
        }

        .lookbook_cat_desc p {
            font-size: 15px;
            font-weight: 300;
        }
    }

    .page_rayon.rayon_box .box_presentation .wrapper_txt p {
        font-size: 15px;
        font-weight: 300;
    }

    .page_rayon.rayon_box .box_presentation .wrapper_txt h2 {
        font-size: 24px !important;
        font-weight: 300;
    }

    .page_rayon.rayon_box .box_presentation .wrapper_txt .box_categ_container .box_categ_ol .box_categ_li,
    .page_rayon.rayon_box .box_presentation .wrapper_txt .box_categ_container .box_categ_ul .box_categ_li {
        font-size: 15px;
        font-weight: 300;
    }

    // Responsive
    @media screen and (min-width: 1025px) {
        .nav_header {
            .menu_top_wrapper {
                #leftMenu {
                    .main_menu_itm {
                        .submenu_wrapper {
                            .menu_categ_wrapper {
                                .ss_menu_categ_wrapper {
                                    .bloc_submenu_wrapper {
                                        .sous_menu_wrapper {
                                            .ss_cat {
                                                .ss_cat_name,
                                                .ss_cat_name + ul.objet a,
                                                .insideCat .wrap_ul ul.objet li a.promote {
                                                    font-size: 15px;
                                                    font-weight: 300;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Lightbox
    .lightbox {
        &#popup_numero_retour {
            .intro_return_lightbox,
            .intro_return_lightbox p,
            .order_detail_head .num_suivi a span,
            .mainWrapper .productReturnWrapper .productReturnListWrapper .formWrapper .titre,
            .js-select-motif .optionsWrapper label,
            .js-select-motif .optionsWrapper .date_select select,
            .js-select-motif .optionsWrapper .date_select select.return_choice {
                font-size: 15px;
                font-weight: 300;
            }
        }

        &#abox {
            .light_title {
                font-size: 24px;
                font-weight: 300;
            }

            p {
                font-size: 15px;
                font-weight: 300;
            }
        }

        &#exit_overlay_OVI {
            .title_popup_inscription_home {
                font-size: 24px;
                font-weight: 300;

                small {
                    font-size: 24px;
                    font-weight: 300;
                }
            }

            .common_txt,
            .detail,
            .pop_cgv_listing a span {
                font-size: 15px;
                font-weight: 300;
            }
        }

        &#popup_savoir_plus #bottom_savoir_plus {
            font-size: 15px;
            font-weight: 300;
        }

        &#popup_cofidis3cb {
            .popup_title_3xcb {
                font-size: 24px;
                font-weight: 300;
            }

            .popup_subtitle_3xcb,
            .popup_content_3xcb .form_calc_3xcb button#calculCofidis3Cb span,
            .popup_content_3xcb #tab_mensualites .tab,
            .popup_info_validity_3xcb,
            .popup_info_financement_3xcb {
                font-size: 15px;
                font-weight: 300;
            }
        }

        &#sizeguideBox {
            h2:first-of-type {
                font-size: 24px;
                font-weight: 300;
            }

            .tab_content p,
            table tr td,
            .primary_navbar li,
            .tab_content > :not(table):first-child strong {
                font-size: 15px;
                font-weight: 300;

                strong {
                    font-weight: 300;
                }
            }
        }

        &#sendfriendbox,
        &#sendwishlistbox {
            #sendfriendform #content_sendfriendbox .popup_title {
                font-size: 24px;
                font-weight: 300;
            }

            #sendfriendform #content_sendfriendbox .popup_subtitle,
            #sendfriendform #content_sendfriendbox .formContent #sendfriend_form_to .form_title,
            #sendfriendform #content_sendfriendbox .formContent #sendfriend_form_to .w-form-line .w-input-container .w-input.w-textarea .w-input-label
            #sendfriendform #content_sendfriendbox .formContent #sendfriend_form_to div:nth-of-type(2) .w-input-note.w-input-error,
            .form_title,
            #sendfriendform #content_sendfriendbox .formContent #sendfriend_form_froms div:nth-of-type(4) .w-input-container label > p span {
                font-size: 15px;
                font-weight: 300;
            }

            #sendfriendform #content_sendfriendbox .formContent #sendfriend_form_to div:nth-of-type(2) .w-input-note {
                font-weight: 300;
            }
        }
    }

    #cookiesLightbox {
        h3 {
            font-size: 24px;
            font-weight: 300;
        }

        .cookiesLightbox_content,
        .cookiesLightbox_custom_btn .toggleCookiePrefs span,
        .cookiesLightbox_custom_btn .toggleCookiePrefs.opened span,
        .cookiesLightbox_refuse_btn #js_cookie_refuse span,
        .cookiesLightbox_accept_btn #js_cookie_accept span {
            font-size: 15px;
            font-weight: 300;
        }

        ul li h4 {
            font-weight: 500;
        }
    }

    .w-visibility-toggle {
        top: 67%;
        right: 31px;
    }
}