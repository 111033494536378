#search_page {
    margin-top: 1.5rem;

    // HIDDINGS
    &:not(.no_results_wrapper){
        .go_back {
            display: none;
        }
    }

    .nbr_result {
        display: none;
    }

    /* NO RESULTS *****/
    &.no_results_wrapper {
        padding-top: 1.1rem;

        >*:not(.image-image-image) {
            padding: 0;
        }

        .returnBox {
            display: flex;

            .space {
                width: 6.1065%;

                @media only screen and (max-width: 1669px) {
                    width: 6.5065%;
                }

                @media only screen and (max-width: 1441px) {
                    width: 6.4%;
                }

                @media only screen and (max-width: 1440px) {
                    width: 3.4%;
                }

                @media only screen and (max-width: 1200px) {
                    width: 3%;
                }

                @media only screen and (max-width: 1024px) {
                    width: 2.8%;
                }
            }

            .go_back {
                width: 100%;
                padding: 0 clamp(20px, 1.04167vw, 20px);
                margin-bottom: 1.5rem;

                span {
                    display: block;
                    width: 875px;
                    margin: 0 auto;
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: .5px;
                    text-transform: lowercase;

                    &:before {
                        position: relative;
                        top: 3px;
                        margin-right: 10px;
                        width: 14px;
                        height: 14px;
                        background-image: url('../svg/fp_cross.svg') !important;
                        background-repeat: no-repeat;
                        background-size: contain !important;
                    }
                }
            }
        }
        
        .search_term,
        .no_result_block {
            max-width: 875px;
            width: 100%;
            margin: 0 auto;
        }

        .no_result_block {
            font-size: 1rem;
        }

        .title_no_result {
            padding-bottom: 2rem;
        }

        .title_no_result,
        .tips_title {
            display: block;
        }

        .tips_title {
            font-size: 1rem;
        }

        .recap {
            padding-bottom: 2rem;
            margin-top: -1.4%;

            @media only screen and (max-width: 1200px) {
                margin-top: -1.8%;
            }
        }

        .search_tips {
            >* {
                padding-bottom: 1rem;
            }
        }

        .tip {
            position: relative;
            display: flex;
            
            &::before {
                content: "-";
                display: inline-block;
                padding-right: .5rem;
            }

            span {
                font-size: .75rem;
                padding: 0 .5rem;
                font-style: italic;
            }
        }


        .image-image-image {
            margin-top: 8.75rem;
            padding: 0 9.375rem;
            border-top: 1px solid $borderColor;
            border-bottom: 1px solid $borderColor;
        }
    }

    /**  RESULTS   ******************/
    
    /* Page upper **********/
    .go_back {
        span {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: .75rem;
            
            &::before {
                content: "";
                display: inline-block;
                width: 1rem;
                height: 1rem;
                @extend .bgSprite;
                @extend .arrowLeft;
            }
        }
    }

    .search_recap {
        .recap {
            display: flex;
            align-items: baseline;
        }

        .search_term {
            font-size: 25px;
            letter-spacing: .5px;
            font-weight: 400;
            padding-bottom: 0;
            text-transform: uppercase;

            .searched_txt {
                font-size: 25px;
                position: relative;
                align-items: center;
                text-transform: uppercase;

                &::before {
                    content: "«";
                    display: inline-block;
                    font-size: 3rem;
                    padding-right: .5rem;
                }
                &::after {
                    content: "»";
                    display: inline-block;
                    font-size: 3rem;
                    padding-left: .5rem;
                }
            }
        }
    }

    .filters {

        /* Hidings ****/
        #trigger_filtre {
            display: none;
        }
        >span.title {
            display: none;
        }

        /* Hide input ******/
        #filter_trier {
            [type="radio"] {
                position: absolute;
                opacity: 0;
                left: -9999px;
            }
            [type="radio"]+label {
                cursor: pointer;
            }
            [type="radio"]:checked+label {
                font-weight: bold;
            }

            .selected_options {
                display: flex;
                flex-direction: column;
            }
        }
        

        /* COLOR FILTERS ********/
        #filter_color {

            label.couleur_Multicolore span{
                background: url(/img/couleur/118.jpg) center center repeat scroll transparent;
            }

            .color {
                position: relative;
                width: 1.25rem;
                height: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-of-type) {
                    margin-right: .3rem;
                }
                margin-bottom: .3rem;
                
                input[type="checkbox"] {
                    opacity: 0;
                    position: absolute;
                    left: -9999px;
                }
                
                >label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 90%;
                    height: 90%;
                    border: 2px solid white;
                    border-radius: 50%;

                    &[title="BLANC"] {
                        width: 73%;
                        height: 73%;
                        border: 1px solid $borderColor;
                    }

                    
                    &::after {
                        position: absolute;
                        content: "";
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                        background: none;
                        border-radius: 50%;
                        cursor: pointer;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }
            
            [type="checkbox"]:checked+label::after {
                border: 1px solid $borderColor;
            }
        }

        form {
            .wrapper_filtre {
                padding-bottom: 1rem;
            }

            .form_submit {
                &:not(.margBot) {
                    margin-left: 2rem;

                    .button{
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .bloc_sub_categ {
        margin: 0 auto;

        .bloc_itm {
            display: flex;
            flex-wrap: wrap;
        }

        .categ {
            cursor: pointer;
            display: flex;
            align-items: center;

            &:not(:last-of-type) {
                padding-right: 2rem;
            }
        }

        .sub_categ {
            display: none;
        }

        [type=checkbox] {
            -webkit-appearance: none;
            outline: 0;
            border: 1px solid $borderColor;
            @extend .bfr;
            width: 1rem;
            height: 1rem;
            
            &::before {
                width: 1rem;
                height: 1rem;
            }

            &:checked {
                border: 1px solid $primaryColor;
                @extend .bgSprite, .check;
                width: 1rem;
                height: 1rem;
            }
        }
    }



    // Filters
    #block_selected_filter {
        font-size: .75rem;
        padding: 1.5rem $mainPadding 0;

        @include medium {
            padding: 1.5rem $mainPaddingMedium 0;
        }

        @include flexbox(row, flex-start, center, wrap);

        >div {
            display: flex;
            align-items: center;
        }

        .selected_filter {
            cursor: pointer;
            display: flex;
            flex-direction: row-reverse;
            margin-right: 3rem;

            &::before {
                width: 10px;
                height: 10px;
                margin-left: .5rem;
            }
        }
    }

    .choices_list {
        .form_itm.check.color:hover:before {
            display: none;
        }
    }

    .wrapper_description {
        margin-top: .5rem;
        padding-left: 1.23rem;

        .product_titles {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .5px;
            text-transform: uppercase;

            strong {
                font-weight: 400;
            }

            &::after {
                margin: .5rem 0 .85rem;
            }
        }
    }

    .pager_wrapper_rayon {
        display: none;
    }
}

.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 100;

    &:before {
        content: '';
        display: block;
        width: 5rem;
        height: 5rem;
        background: transparent url(../svg/three_dots.svg) center no-repeat;
        background-size: contain;
    }
}