
.satisfaction_main_wrapper {
    padding: 2rem;
    margin: 0;

    .title_satisfaction {
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 1.85rem;
        text-transform: uppercase;
        letter-spacing: .02em;
        margin: 2rem auto;
        text-align: center;
    }

    .satisfaction_page_content {
        font-size: 1rem;
        font-family: $primaryFont;
        font-weight: 400;
        color: $primaryColor;
        text-align: center;

        .page_satisfaction_title {
            width: 800px;
            max-width: 100%;
            text-align: center;
            margin: auto;

            #satisfaction_intro {
                margin: 0 0 1rem 0;

                h3 {
                    font-weight: 900;
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    margin-bottom: 1.5rem;
                }

                div {
                    p {
                        line-height: 1.1;
                        margin-bottom: 1rem;
                    }
                }
            }
            .cadre_question {
                width: 800px;
                max-width: 100%;
                margin-bottom: 24px;
                td {
                    height: 38px;
                }

                .satisfaction_answer_holder, .wrapper_list {
                    margin: auto;

                    tbody {
                        tr {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            td {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                label {
                                    font-size: .75rem;
                                }

                                &.labelForm {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    

                                    &.smiley_wrapper {
                                        width: 100%;
                                        max-width: 445px;
                                        position: relative;
                                        left: 10px;
                                        justify-content: space-between;
                                    }

                                    .customChecker {
                                        margin-left: 1rem;
                                        display: flex;
                                        align-items: flex-end;
                                        position: relative;
                                        cursor: pointer;

                                        label {
                                            position: relative;
                                            padding-left: 22px;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                left: 0;
                                                top: -1px;
                                                margin: auto;
                                                border: 1px solid rgba($primaryColor,.5);
                                                width: 12px;
                                                height: 12px;
                                                border-radius: 100%;

                                            }

                                            &:after {
                                                content: '';
                                                position: absolute;
                                                left: 2px;
                                                top: 1px;
                                                margin: auto;
                                                border: 1px solid rgba($primaryColor,1);
                                                width: 8px;
                                                height: 8px;
                                                background-color: $primaryColor;
                                                border-radius: 100%;
                                                opacity: 0;
                                                transition: opacity ease-in-out .3s;

                                            }
                                        }

                                        input {
                                            opacity: 0;
                                            display: none;

                                            &:checked + label {
                                                &:before {
                                                    content: '';
                                                    position: absolute;
                                                    left: 0;
                                                    top: -1px;
                                                    margin: auto;
                                                    border: 1px solid rgba($primaryColor,1);
                                                    width: 12px;
                                                    height: 12px;
                                                    border-radius: 100%;

                                                }

                                                &:after {
                                                    opacity: 1;

                                                }
                                            }
                                        }


                                    }

                                    &.smileyform {
                                        justify-content: space-between;
                                        width: 100%;
                                        max-width: 443px;
                                        position: relative;
                                        left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .explication_range_slider {
                    text-align: center;
                    font-style: italic;
                    font-size: 12px;
                }

                #slider_recommandation,
                #slider_serviceclient {
                    position: relative;
                    top: 4px;
                    left: 8px;
                    width: 420px;
                    height: 37px;
                    border: none;
                    background: none;
                    cursor: pointer;
                }

                &.satisfaction_title_11 tr td.graduationForm,
                &.satisfaction_title_08 tr td.graduationForm {
                    position: relative;
                    float: left;
                    left: 40px;
                    width: 486px;
                    height: 35px;
                    background-repeat: no-repeat;
                }



                .satisfaction_smiley {
                    position: relative;
                    &.libmiddle {
                        position: relative;
                    }
                    &.libright {
                        position: relative;
                    }
                }
                #slider_recommandation a,
                #slider_serviceclient a {
                    width: 30px;
                    height: 37px;
                    margin-left: -13px;
                    background-color: transparent;
                    border-radius: 0;
                    border: none;
                    cursor: pointer;
                }
                .ui-state-default,
                .ui-widget-content .ui-state-default {
                    border: 0;
                    background: #fff 50% 50% repeat-x;
                    font-weight: bold;
                    color: #020202;
                    outline: none;
                    border: 1px solid #bcc2c7;
                }
                .ui-slider-horizontal .ui-slider-handle {
                    top: -3px;
                }
                .ui-slider .ui-slider-handle {
                    position: absolute;
                    z-index: 2;
                    width: 1px;
                    height: 1px;
                    cursor: pointer;
                }

            }

            /* ** ERREUR ** */

            #mes_err_fieldset {
                display: none;
                width: 640px;
                margin: 20px auto;
                font-size: 12px;
                color: #000;
                letter-spacing: 1px;
                padding: 10px;
            }

            #satisfactionForm .labelErr {
                color: red !important;
            }
            #mes_err {
                width: 730px;
                margin: 10px auto 20px 0px;
                padding: 5px 0;
                color: red;
                border: 0;
                font-size: 1rem;
            }
            .erreur_txt {
                font-size: 1rem;
                color: red;
                line-height: 18px;
                height: auto;
                padding: 9px 0;
            }
            ul.erreur_tab.satisfaction {
                padding-top: 14px;
            }
            .erreur_tab.satisfaction li {
                list-style: none;
                color: #f00;
                background: transparent url(../img/picto_panneau.png) no-repeat left 2px;
                text-indent: 26px;
                line-height: 25px;
            }
            table.cadre_question.satisfaction_title_08 {
                display: none;
            }
            .bandeau_top_compte.page_satisfaction_title {
                min-height: 487px;
            }

            /* ** FIN ERREUR ** */

            #satisfactionForm {
                margin-top: 3.5rem;

                .subtitles_questionnaire {
                    width: 100%;
                    margin: 0 auto 1rem;
                    text-align: center;
                    height: 36px;
                    background: transparent url(../img/filet_question_satisfaction.png) repeat-x left -7px;
                    display: inline-block;

                    span {
                        background-color: #f9f9f9;
                        padding: 8px 50px;
                        font-weight: 700;
                        font-size: .75rem;
                        text-transform: uppercase;
                        font-family: $primaryFont;
                        font-weight: 600;
                        position: relative;
                    }
                }

                #valider_question {
                    .form_submit {
                        width: 300px;
                        margin: 1rem auto 3rem;
                    }
                }
            }
        }
    }
}