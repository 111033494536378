.page_rayon:not(.rayon_lookbook) {
    /* ATTENTION A NE PAS ETRE OVERKILL A REPASSER SUR LES PROPRIETES:
        LA VOLONTE SUR LA PAGE RAYON EST D'ALIGNER TOUT CONTENU VERS LA GAUCHE DE LA PAGE : TEXTE SEO, BANDEAU, FILTRES
        - Le bandeau ici garde la structure commune présente sur quasiment tout le site mais il n'a pas de max-width sur son texte pour qu'il soit centré et est donc positionné via un padding
        - Les filtres ...
    */

    .achat_express {
        .productColorFieldset {
            margin-left: .4rem;
        }

        .productSizeFieldset {
            margin-left: .4rem;

            .form_itm.check.size {
                position: relative;
                text-align: left;

                .disabled+label {
                    &::after {
                        content: none;
                        position: absolute;
                        left: 0;
                        bottom: 7.9px;
                        height: 1px;
                        width: 100%;
                        background-color: $primaryColor;
                        opacity: .5;
                    }
                }
            }
        }
    }

    #btt_button {
        &.back_to_top {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: $white;
            position: fixed;
            bottom: 9rem;
            z-index: 20;
            right: 1.2rem;
            cursor: pointer;

            &:before {
                content: " ";
                width: 8px;
                height: 8px;
                border: solid black;
                border-width: 0 1px 1px 0;
                display: inline-block;
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                margin-top: 0.4rem;
            }

            span {
                display: none;
            }
        }
    }

    .items_wrapper {
        padding-top: 8px;
        width: 100%;

        .imgWrapper {
            position: relative;

            .caract_container {
                position: absolute;
                display: flex;
                flex-direction: column;
                top: 1.5rem;
                right: 3.3rem;
                z-index: 15;
                transform: translateX(50%);

                img {
                    width: 100%;
                    height: auto;
                }

                >img.eclat_caracteristique {
                    width: 60px;
                    height: auto;

                    &:not(:last-child) {
                        margin-bottom: .5rem;
                    }
                }
            }

            .btnAddBasketWrapper.noSizeAvailable {
                .alert_return {
                    top: 1.2rem;
                }
            }

            .vimeo_video_ctn {
                position: relative;
            }
        }

        .item {
            margin: 0 0 30px;
            background-image: url(../svg/three_dots.svg);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: unset;
            background-size: 10%;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: 50% 45%;

            .productVisualMulti {
                .swiper-slide:not(:has(.wrap_video)) {
                    aspect-ratio: 5 / 6;
                }
            }

            &:last-of-type {
                margin: 0 auto 30px;
            }

            &:only-of-type {
                margin: 0 0 30px !important;
            }
        }

        .block_push img,
        .item.push img {
            cursor: pointer;
            width: 100%;
            height: auto;
        }
    }
}

.page_rayon:not(.rayon_lookbook) .items_wrapper:not(.view_per_three) {
    @include flexbox(row, space-between, flex-start, wrap);

    .item {
        width: calc(50% - 5px);

        &:last-of-type {

            &:nth-child(2),
            &:nth-of-type(even) {
                margin: 0 0 30px !important;
            }
        }
    }

    .item.centered_item {

        .imgWrapper,
        .wrapper_description {
            width: 45%;
            margin: .5rem auto 0;
        }

        .caract_offrebox_container {
            margin: 1rem auto 0;
            width: 45%;
        }
    }

    &.box_items {
        align-items: flex-start !important;
    }
}

.page_rayon:not(.rayon_lookbook) .items_wrapper.view_per_three {
    @include flexbox(row, flex-start, flex-start, wrap);

    &:not(.box_items) {
        .item {
            width: calc(100% / 3 - 4.99px);
            margin: 0 0 30px;

            /* Target only the second item of each row */
            &:nth-of-type(3n+2) {
                margin: 0 7px 30px;
            }
        }
    }

    &.box_items {
        column-gap: 10px;
    }
}

.page_rayon:not(.rayon_lookbook) .items_wrapper .imgWrapper {
    width: 100%;

    .wrap_video {
        display: flex;
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 120.01%;
        border-left: 1px solid $primaryColor;

        img.hiddenPic {
            opacity: 0;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

body.category {
    overflow-x: hidden;
    position: relative;

    .shad.shad_menu {
        width: 100%;
    }

    .wrapper_bandeau.page_upper {
        .page_upper_text {
            display: flex;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            padding: 0 clamp(20px, 1.04167vw, 20px);

            nav.bandeau_nav {
                margin-top: 1.7rem;
                margin-bottom: 0.1rem;

                h1 {
                    margin-bottom: .8rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    #scroll_items {
        .wrapper_description {
            margin-top: 0.5rem;
            padding-left: 1.23rem;
            display: flex;
            flex-wrap: wrap;

            .product_titles {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                width: 100%;

                strong {
                    font-weight: 400;
                }

                &::after {
                    margin: .5rem 0 .85rem;
                    margin-left: 1px;
                }
            }

            .product_prices {
                display: flex !important;
                width: auto;

                span.price {
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                .pricetag+.pricetag {
                    opacity: 0.5;
                }

                .eclat_rond {
                    margin-top: 0;
                }
            }

            .caract_offrebox_container {
                width: calc(100% - 100px);
                border-top: none;
                margin-top: -4px;
                margin-left: auto;
                padding-top: 0;
                text-align: right;
                padding-right: 1.23rem;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                top: -1px;

                @media screen and (max-width: 1645px) {
                    width: calc(100% - 160px);
                }

                @media screen and (max-width: 1145px) {
                    top: -10px;
                }

                .offrebox_val br {
                    display: none;

                    @media screen and (max-width: 1625px) {
                        &:nth-child(2) {
                            display: block;
                        }
                    }

                    @media screen and (max-width: 1145px) {
                        &:nth-child(2) {
                            display: none;
                        }

                        &:nth-child(1),
                        &:nth-child(3) {
                            display: block;
                        }
                    }
                }
            }
        }

        .view_per_three .item:nth-child(5n + 4),
        .view_per_three .item:nth-child(5n + 5) {
            @media screen and (max-width: 1625px) {
                .wrapper_description {
                    .caract_offrebox_container {
                        @media screen and (max-width: 1145px) {
                            top: -2px;
                        }

                        @media screen and (max-width: 1145px) {
                            top: -1px;
                        }

                        .offrebox_val br {
                            &:nth-child(2) {
                                display: none;
                            }

                            &:nth-child(1),
                            &:nth-child(3) {
                                display: none;
                            }

                            @media screen and (max-width: 1081px) {
                                &:nth-child(2) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #push_rayon {
        margin-bottom: 5.1rem;

        .txtWrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: center;
        }

        .txt {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.5px;

            &.trimed,
            &.full {
                display: none;
                width: 68%;
                min-width: 990px;
                margin: 0 auto;
                transition: height 0.2s ease-in-out;

                ul {
                    padding-left: 2rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    li {
                        list-style: disc;
                    }
                }

                &.displayed {
                    display: block;

                    h3 {
                        margin-bottom: 19px;
                    }
                }
            }

            .txt {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.5px;
            }

            .seeMore,
            .seeLess {
                cursor: pointer;
                text-decoration: underline;
                text-underline-offset: 3px;
                display: block;
                width: 100%;
                margin-top: .3rem;
                font-size: 16px;
            }
        }
    }
}