.cms_page {
    // Hiding default ariane and btn back - moved below bandeau haut
    #cms_page_btn_back {
        display: none;
    }

    .ill_img > img {
        opacity: 1;
        width: 100%;
    }

    .page_title {display: none;}

    .cms_page_content {
        @include flexbox(row, center, unset, wrap);

        /* General styling ****/
        h2, h3 {
            @include wshopFont($primaryFont, 25px, normal, .5px, 400, uppercase, none, $primaryColor);
            padding-bottom: 1.25rem;
        }

        p,
        td,
        li,
        h4,
        .cms-page-module-text,
        .sub_categ_desc  {
            @include wshopFont($primaryFont, 16px, normal, 0.5px, 400, unset, none, unset);
        }

        .cms-page-module-text  {
            line-height: 1.3;

            .sub_categ_desc {
                margin-top: 0;
            }

            p {
                line-height: 1.3;
            }
        }

        h4 {
            font-weight: bold;
            font-style: italic;
        }

        p {
            text-align: left;
            padding-bottom: 1.25rem;
        }

        strong {
            font-weight: bold;
        }
    }
    .main_wrapper.special{
        .cms_page_content {
            .cms-page-module-text{
                @media screen and (min-width:0\0) {
                    max-width: 530px;
                }
            }
        }
    }

    /* WHOLE CMS PAGE IS FLEXED SO GIVE RATIO TO CMS MODULES *****/
    .image-text-1,
    .text-image-5,
    .text-image-3,
    .image-image-image {
        width: 100%;
    }

    /* Each module padded to keep sapce with content bloc sides *****/

    .cms-page-module {
        margin-top: 180px;

        &:last-child {
            margin-bottom: 4.2rem;
        }

        &:first-of-type {
            margin-top: 40px;
        }

        .cms-page-module-contents {
            .cms-page-module-text {
                text-align: left;
            }

            &.align-right {
                .cms-page-module-text,
                .cms-page-module-text p {
                    text-align: right;
                }
            }

            &.align-center {
                .cms-page-module-text,
                .cms-page-module-text p {
                    text-align: center;
                }
            }
        }
    }

    .text-image-5,
    .image-text-1 {
        .cms-page-module-contents {
            padding-left: 5rem;
        }
    }

    .text-image-3 {
        .cms-page-module-contents {
            padding-right: 5rem;
        }
    }

    /* CMS MODULE WITH HORIZONTAL CONTENT ******/
    .text-image-5,
    .text-image-3,
    .image-text-1 {
        /* Vertical flex centered content for responsibe *****/
        .cms-page-module-contents {
            @include flexbox(column, center, unset, unset);
        }
    }

    .text-image-3,
    .image-text-1 {
        .cms-page-column {
            /* each first level bloc = 50% *******/
            >* {
                max-width: 50%;
                width: 100%;
            }
            @include flexbox(row, center, unset, unset);
        }
    }

    .image-text-1:first-of-type {
        padding-top: 8.75rem;
    }

    .text-image-5 {
        .cms-page-column {
            @include flexbox(row, flex-start, unset, unset);

            img.cms-page-module-img {
                @media (max-width: 1440px) {
                    width: 65%;
                    height: 65%;
                }
            }
        }
        .cms-page-module-contents {
            max-width: 500px;
            width: 100%;

            @media (max-width: 1440px) {
                padding-right: 1.2rem;
            }

            @media (max-width: 1300px) {
                padding-right: unset;
            }
        }
    }

    .image-1 {
        .ill_img {
            width: 100%;
        }
    }

    /* CMS MODULE HORIZONTALY ALIGNED WITH ITS SAME TYPE SIBLINGS *******/
    .text-image-4 {
        display: inline-block;
        max-width: 561px;
        width: 100%;
        height: auto;
        margin: 180px 2.45rem 0;

        .cms-page-column {
            .cms-page-module-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                &:first-of-type {
                    padding: 0;
                }
            }

            .ill_img {
                width: 100%;
                margin-bottom: .5rem;
            }

            .cms-page-module-contents {
                margin-top: 1.2rem;

                a {
                    color: inherit;
                }
            }

            .slide_video {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                padding-bottom: 124.956%;
            }

            .block_redirect {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 2;
                top: 0;
            }

            iframe {
                display: flex;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
            }
        }

        .cms-page-module-body {
            max-width: 561px;
            width: 100%;
            height: auto;
        }
    }

    /* 3 IMG MODULE - OWN WRAPPER + PADDING *******/
    .image-image-image {
        margin-top: 180px;

        /* Module's wrapper - slightly larger than above content ***/
        .home-module-body {
            width: 100%;

            .insideImg {
                .home-column {
                    max-width: 595px;
                    width: 100%;
                    max-height: 668px;
                    height: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    &:nth-child(2) {
                        margin: 0;
                    }

                    img {
                        width: 90%;
                        margin-top: 3rem;
                    }

                    .home-module-cta {
                        padding-bottom: 0;
                        display: flex;
                    }

                    .slide_video {
                        margin-top: 3rem;
                        position: relative;
                        display: block;
                        width: 90%;
                        height: auto;
                        padding-bottom: 101.04%;

                        .block_redirect {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            z-index: 2;
                            top: 0;
                        }

                        iframe {
                            display: flex;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            z-index: 1;
                        }

                        .home-module-cta {
                            display: none;
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        padding: 0;
                        top: 8rem;
                        right: -8.2rem;
                        transform: rotate(-90deg);
                        font-weight: 700;
                        font-size: 25px;
                        text-transform: none;
                        width: 300px;
                        height: 10%;
                    }
                }
            }
        }

        .home-module-cta {
            display: inline-block;
            margin-bottom: 0;
            border: 0;
            max-width: 595px;
            width: 100%;
            height: auto;

            .slide_video {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                padding-bottom: 112.27%;

                iframe {
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }

            h2 {
                display: none;
            }
        }
    }

    /* TEXT MODULE - WITH SLIDER *******/
    .three_text {
        margin-top: 40px;
        width: 100%;
        padding: 0;

        .home-column p {
            display: block !important;
            padding-bottom: 0 !important;
            margin-top: 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            letter-spacing: 0.5px;
        }

        .home-columns-container >div >*:not(.swiper-container) {
            padding-bottom: 1.25rem;

            h2 {
                padding-bottom: 0;
            }
        }

        h1 {
            @include wshopFont($primaryFont, 1.6rem, 1.5, .5px, 400,unset, none, inherit);
        }

        .swiper-slide .home-column {
            p {
                @include wshopFont($primaryFont, 1.6rem !important, 1.5, .5px, 400,unset, none, $primaryColor);
                padding-bottom: 1.25rem !important;
            }
        }
    }

    .text-slider {
        overflow: initial;
        padding-left: 4rem;

        * {
            cursor: url(../img/grab_hand.png), auto;
        }

        .swiper-slide {
            padding: 2rem 3.8rem;
            height: auto;
        }

        .home-column {
            width: 100%;

            p {
                display: none;
            }

            h3 {
                line-height: unset;
                padding-bottom: .5rem;

                span {
                    font-size: 25px !important;
                }
            }

            a {
                color: inherit;
                cursor: pointer!important;
            }
        }

        p, strong, div {
            line-height: 1.2;
        }
    }


    /* Handle category Mag ****************/
    .cms_category_2 {

        .page_upper_text {
            position: relative;
        }
        .navigationTop {
            position: absolute;
            top: 2rem;
            left: 2rem;
        }
    }
    .cms_page {
        .nav_holder+span,
        .nav_holder+div{
            line-height: 1.3;
        }
    }

    .primary_navbar {
        width: 100%;
        display: flex;
        justify-content: center;

        .tabsatellite {
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
        
            &::after {
                content: none;
            }

            a {
                color: rgba(35, 31, 32, 0.5)
            }

            &.active {
                a {
                    color: $primaryColor!important;
                }
            }
        }

        &:has(input:checked),
        &:hover {
            .tabsatellite {
                a {
                    color: rgba(35, 31, 32, 0.5);
                }

                &:checked,
                &:hover {
                    a {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    .primary_navbar>li:not(:last-child) {
        margin-right: 5rem;
    }

    .page_upper {
        @media screen and (max-width: 1366px) {
            justify-content: space-between;
        }

        .holder_img {
            @media screen and (max-width: 1366px) {
                max-width: 45%;
            }
        }
    }
}

.cms_page,
.cms_page_new {
    .nav_holder {
        .sub_categ_desc {
            display: none;
        }
    }
}

/* PAGE MAG */
.cms_category {
    /* Hidings */
    #cms_subcategory_menu {
        display: none;
    }
    .categoryNav {
        display: none;
    }
    .page_title {
        display: none;
    }

    #content_previsu_cms_page {
        @include flexbox(row, space-between, flex-start, wrap);
        column-gap: 1.04167vw;
        padding: 2rem 1.04167vw;
    }

    .previsu_cms_page {
        width: 47.5vw;

        a {
            display: inline-block;
            width: auto;
            max-width: 100%;

            > img {
                width: auto;
                max-width: 100%;
                margin-bottom: .5rem;
            }
        }

        &:nth-child(even) {
            display: flex;
            justify-content: flex-end;
        }
    }

    .cms_page_previsu_title {
        @include wshopFont($primaryFont, 1.6rem, normal, .5px, 400, unset, none, $primaryColor);
        padding-bottom: 1.25rem;
    }

    .cms_page_previsu_btn {
        display: none;
    }

    #content_previsu_cms_page {
        .cms_page_previsu_title {
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    #cms {
        .page_upper {
            .maison_rodier_pg {
                max-width: calc(880px - 1.04167vw);
                margin-right: 1.04167vw;
            }
        }
    }
}

.three_text_aside {
    padding: 3px 0 2rem 0;

    p {
        text-transform: lowercase !important;
    }
}

body.category{
    .wrapper_bandeau.rayon,
    .wrapper_bandeau.page_upper{
        .page_upper_text{
            width: 50%;
            max-width: 50%;
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
       
            @media screen and (max-width: 1374px){
                width: 46%;
                max-width: 46%;
            }
            nav.bandeau_nav {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
        .vimeo_video{
            width: 50%;
            max-width: 50%;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 1374px){
                width: 54%;
                max-width: 54%;
            }
            iframe{
                border: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: 103.5%;
                height: 100%;
                padding-bottom: 0%;
                // mozilla
                @-moz-document url-prefix() {
                    width: 180%;
                    top: calc(50% - 1px);
                }
                @media all and (-ms-high-contrast:none){
                    h: 180%;
                }
                @media screen and (max-width: 1874px){
                    width: 105%;
                    @-moz-document url-prefix() {
                        width: calc(105% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(105% + 80%);
                }
                @media screen and (max-width: 1851px){
                    width: 110%;
                    @-moz-document url-prefix() {
                        width: calc(110% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(110% + 80%);
                }
                @media screen and (max-width: 1779px){
                    width: 115%;
                    @-moz-document url-prefix() {
                        width: calc(115% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(115% + 80%);
                }
                @media screen and (max-width: 1713px){
                    width: 125%;
                    @-moz-document url-prefix() {
                        width: calc(125% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(125% + 80%);
                }
                @media screen and (max-width: 1580px){
                    width: 135%;
                    @-moz-document url-prefix() {
                        width: calc(135% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(135% + 80%);
                }
                @media screen and (max-width: 1518px){
                    width: 146%;
                    @-moz-document url-prefix() {
                        width: calc(146% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(146% + 80%);
                }
                @media screen and (max-width: 1374px){
                    width: 155%;
                    @-moz-document url-prefix() {
                        width: calc(155% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(155% + 80%);
                }
                @media screen and (max-width: 1297px){
                    width: 183%;
                    @-moz-document url-prefix() {
                        width: calc(183% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(183% + 80%);
                }
                @media screen and (max-width: 950px){
                    width: 210%;
                    @-moz-document url-prefix() {
                        width: calc(210% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(210% + 80%);
                }
                @media screen and (max-width: 872px){
                    width: 250%;
                    @-moz-document url-prefix() {
                        width: calc(250% + 80%);
                    }
                }
                @media all and (-ms-high-contrast:none){
                    width: calc(250% + 80%);
                }
            }
        }
    }
    .vimeo_video_ctn{
        iframe{
            height: 759.719px;
            width: 100%;
        }
    }
    .view_per_three .vimeo_video_ctn{
        iframe{
            height: 623.047px;
            width: 100%;
        }
    }
}

/* LIEN SUR IFRAME*/
.video_redirect_url {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5;
}

/* MODULE 2 IMAGES */
.home-module.images-2 {
    padding: 0 1px;
    margin-bottom: 8px;
    max-height: 1146px;

    @media only screen and (max-width: 1918px) {
        padding: 0;
    }

    .images-2-pagination.swiper-pagination.swiper-pagination-bullets {
        display: none;
        max-width: 1920px;
        width: 100%;
        margin-top: 0.4rem;

        span.swiper-pagination-bullet {
            margin: 0 8px;
        }
    }
}

.home-module-body.images-2 {
    position: relative;

    .home-columns-container {
        .swiperimages-2 {
            .slide_video {
                display: flex;
                width: 100%;
                height: 0;
                padding-bottom: 120%;

                iframe {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .block_redirect {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 2;
                    top: 0;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            .slide_video + .home-column.boxText {
                display: none;
            }

            .swiper-slide {
                .item-link {
                    img.home-module-bg {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }

                &:hover {
                    .home-column.boxText {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
            }

            article.home-column.boxText {
                position: absolute;
                z-index: 2;
                width: 100%;
                text-align: center;
                word-break: break-all;
                bottom: 5.6%;
                opacity: 0;

                a.home-module-contents,
                .home-module-pretitle {
                    display: inline-block;
                    text-align: center;
                    font-family: $primaryFont;
                    font-style: normal;
                    line-height: 29px;
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: .4px;
                    text-decoration: underline;
                    width: auto;
                }

                a.home-module-contents {
                    border-color: transparent;
                }

                .home-module-pretitle {
                    span {
                        display: inline-block;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-align: center;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

/* MODULE 3 IMAGES */
.main_wrapper .home-module:not(:last-child) {
    border: none;
}

.home-module.images-3 {
    padding: 0;
    max-height: 760px;
    margin-bottom: 7px;

    .images-3-pagination.swiper-pagination.swiper-pagination-bullets {
        display: none;
        max-width: 1920px;
        width: 100%;
        margin-top: 0.4rem;

        span.swiper-pagination-bullet {
            margin: 0 8px;
        }
    }
}

.home-module-body.images-3 {
    position: relative;

    .home-columns-container {
        .swiperimages-3 {
            .slide_video {
                display: flex;
                width: 100%;
                height: 0;
                padding-bottom: 120%;

                iframe {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .block_redirect {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 2;
                    top: 0;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            .slide_video + .home-column.boxText {
                display: none;
            }
            
            .swiper-slide {
                .item-link {
                    img.home-module-bg {
                        width: 100%;
                        height: auto;
                    }
                }

                &:hover {
                    .home-column.boxText {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
            }

            article.home-column.boxText {
                position: absolute;
                z-index: 2;
                width: 100%;
                text-align: center;
                word-break: break-all;
                bottom: 5.6%;
                opacity: 0;

                a.home-module-contents,
                .home-module-pretitle {
                    display: inline-block;
                    width: auto;
                    text-align: center;
                    font-family: $primaryFont;
                    font-style: normal;
                    line-height: 29px;
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: .4px;
                }

                a.home-module-contents {
                    border-color: transparent;
                }

                .home-module-pretitle {
                    span {
                        display: inline-block;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-align: center;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

/* MODULE TG 2 IMAGES */
.tg-module.tg_2 {
    margin-bottom: 5.18rem;
    background-color: white;
    position: relative;

    .swiperTg_2 {
        background-color: white;

        .swiper-wrapper {
            background-color: white;
            
            .swiper-slide {
                max-width: 955px;
                margin-right: 30px;

                .swiper-slide {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: auto;
                    z-index: 1;

                    & + img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 2;
                        transition: 0.3s ease-in-out;
                    }
                }

                &:hover {
                    img {
                        & + img {
                            opacity: 1;
                        }
                    }
                }
            }

            .item {
                .productVisualMulti {
                    .product_rollover {
                        .hideAchat {
                            display: none!important;
                        }

                        .product_picture {
                            display: none;
                        }

                        .rollover_left.achat_express {
                            width: 70%;

                            .ligne_form.productSizeFieldset {
                                .form_itm.check.size {
                                    position: relative;
                                    text-align: left;

                                    input[type="radio"] {
                                        display: none;
                                    }

                                    label {
                                        position: relative;
                                        cursor: pointer;
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            bottom: 2px;
                                            width: 100%;
                                            height: 1px;
                                            background-color: transparent;
                                            transition: background-color 0.3s;
                                        }
                                    }
                                }
                            }

                            .productColorFieldset {
                                .prod_listes.right_element_prod {
                                    .choices_list {
                                        .form_itm.check.color {
                                            & + [title="Blanc"],
                                            & + [title="Denim"] {
                                                border: 1px solid #ccc!important;
                                            }

                                            &::before {
                                               content: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bloc_add_color {
                            width: 30%;
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;

                            .w-submit-button {
                                height: 100%;
                                margin: unset;
                                align-items: flex-end;
                                margin-left: 21%;
                                position: absolute;
                                top: 0;
                                right: 2%;

                                @media screen and (max-width: 1669px) {
                                    margin-left: unset;
                                }

                                &::before {
                                    margin-bottom: 5%;
                                }

                                span {
                                    margin-bottom: 1%;
                                }
                            }

                            .w-submit-button:not(.w-loader):hover {
                                background-color: transparent;
                            }
                        }

                        .btnAddBasketWrapper.expressAlertStock {
                            .alert_stock {
                                span {
                                    font-family: $primaryFont;
                                    color: $black;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                }
                            }

                            .bloc_add_alert_confirmation {
                                p {
                                    padding-bottom: 0;
                                    margin-top: 5px;
                                    font-size: 16px;

                                    span {
                                        line-height: normal;
                                    }
                                }
                            }

                            .bloc_add_alert_form {
                                .w-form-line {
                                    .form_submit {
                                        position: relative;
                                        border: none;
                                        width: unset;
                                        height: unset;

                                        button {
                                            content: " ";
                                            position: absolute;
                                            top: -7px;
                                            right: 9px;
                                            width: .5rem;
                                            height: .5rem;
                                            border-right: 1px solid $primaryColor;
                                            border-top: 1px solid $primaryColor;
                                            transform: rotate(45deg);
                                            padding: 0;

                                            span {
                                                display: none;
                                            }
                                        }
                                    }

                                    input[type=text].w-input-element {
                                        border: none;
                                        border-bottom: 1px solid $borderColor;
                                        padding: 0;

                                        &.inputErr {
                                            border-bottom: 1px solid $errorColor;
                                        }
                                    }
                                }
                            }
                        }

                        #size_is_not_available {
                            text-transform: uppercase;
                        }
                    }

                    &:hover .product_rollover {
                        bottom: 0.2%;
                    }
                }
            }

            img.imgProd {
                width: 100%;
                height: auto;
            }

            a.block_lnk {
                .wrapper_description {
                    margin-top: 0.6rem;
                    padding: 0 2%;

                    h3.product_titles {
                        font-size: 16px;
                        text-transform: uppercase;

                        strong {
                            font-weight: 400;
                        }

                        &:after {
                            margin: .65rem 0 .8rem;
                        }
                    }

                    .product_prices {
                        .price_ctn{
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    .tg-2-module-pagination.swiper-pagination.swiper-pagination-bullets {
        max-width: 1920px;
        width: 100%;
        bottom: -1.5rem;

        span.swiper-pagination-bullet {
            margin: 0 8px;

            &.swiper-pagination-bullet-active {
                background: $primaryColor;
            }
        }
    }
}

/* MODULE TG 3 IMAGES */
.tg-module.tg_3 {
    margin-bottom: 60px;
    background-color: white;
    position: relative;

    .swiperTg_3 {
        background-color: white;

        .swiper-wrapper {
            background-color: white;

            .swiper-slide {
                max-width: 635px;
                margin-right: 7px;

                .swiper-slide {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: auto;
                    z-index: 1;

                    & + img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 2;
                        transition: 0.3s ease-in-out;
                    }
                }

                &:hover {
                    img {
                        & + img {
                            opacity: 1;
                        }
                    }
                }
            }

            .item {
                .productVisualMulti {
                    .product_rollover {
                        .hideAchat {
                            display: none!important;
                        }

                        .product_picture {
                            display: none;
                        }

                        .rollover_left.achat_express {
                            width: 70%;

                            .ligne_form.productSizeFieldset {
                                .form_itm.check.size {
                                    position: relative;
                                    text-align: left;
                                    
                                    input[type="radio"] {
                                        display: none;
                                    }

                                    label {
                                        position: relative;
                                        cursor: pointer;
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            bottom: 2px;
                                            width: 100%;
                                            height: 1px;
                                            background-color: transparent;
                                            transition: background-color 0.3s;
                                        }
                                    }
                                }
                            }

                            .productColorFieldset {
                                .prod_listes.right_element_prod {
                                    .choices_list {
                                        .form_itm.check.color {
                                            & + [title="Blanc"],
                                            & + [title="Denim"] {
                                                border: 1px solid #ccc!important;
                                            }

                                            &::before {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bloc_add_color {
                            width: 30%;
                            opacity: 0;
                            transition: opacity 0.3s ease-in-out;

                            .w-submit-button {
                                height: 100%;
                                margin: unset;
                                align-items: flex-end;
                                margin-left: 18%;
                                position: absolute;
                                top: 0;
                                right: 2%;

                                @media screen and (max-width: 1669px) {
                                    margin-left: unset;
                                }

                                &::before {
                                    margin-bottom: 5%;
                                }

                                span {
                                    margin-bottom: 1%;
                                }
                            }

                            .w-submit-button:not(.w-loader):hover {
                                background-color: transparent;
                            }
                        }
                        
                        .alert_stock {
                                span {
                                    font-family: $primaryFont;
                                    color: $black;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                }
                            }

                        .expressAlertStock {
                            .bloc_add_alert_confirmation {
                                p {
                                    padding-bottom: 0;
                                    margin-top: 5px;
                                    font-size: 16px;
                                    
                                    span {
                                        line-height: 0.5rem;
                                    }
                                }
                            }

                            .bloc_add_alert_form {
                                .w-form-line {
                                    .form_submit {
                                        position: relative;
                                        border: none;
                                        width: unset;
                                        height: unset;

                                        button {
                                            content: " ";
                                            position: absolute;
                                            top: -6px;
                                            right: 8px;
                                            width: .5rem;
                                            height: .5rem;
                                            border-right: 1px solid $primaryColor;
                                            border-top: 1px solid $primaryColor;
                                            transform: rotate(45deg);
                                            padding: 0;

                                            span {
                                                display: none;
                                            }
                                        }
                                    }

                                    input[type=text].w-input-element {
                                        border: none;
                                        border-bottom: 1px solid $borderColor;
                                        padding: 0;

                                        &.inputErr {
                                            border-bottom: 1px solid $errorColor;
                                        }
                                    }
                                }
                            }
                        }

                        #size_is_not_available {
                            text-transform: uppercase;
                        }
                    }

                    &:hover .product_rollover {
                        bottom: 0.3%;
                    }
                }
            }

            img.imgProd {
                width: 100%;
                height: auto;
            }

            a.block_lnk {
                .wrapper_description {
                    margin-top: 0.6rem;
                    padding: 0 3.3%;

                    h3.product_titles {
                        font-size: 16px;
                        text-transform: uppercase;

                        strong {
                            font-weight: 400;
                        }

                        &:after {
                            margin: .65rem 0 .8rem;
                        }
                    }

                    .product_prices {
                        .price_ctn{
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    .tg-3-module-pagination.swiper-pagination.swiper-pagination-bullets {
        max-width: 1920px;
        width: 100%;
        bottom: -1rem;

        span.swiper-pagination-bullet {
            margin: 0 8px;

            &.swiper-pagination-bullet-active {
                background: $primaryColor;
            }
        }
    }
}

/* MODULE IMAGE + TEXTE */
.cms-page-module.image-text {    
    height: auto;
    width: 100%;
    margin: auto;

    .cms-page-module-body {
        width: 100%;
    }
    
    .cms-page-column {
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
    }

    .cms-page-module-contents {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 0 0 0 6%;
        padding-right: 5.5rem;
        max-height: 755px;

        .cms-page-module-title {
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            text-transform: unset;
            line-height: normal;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
            padding-bottom: 0;

            em {
                font-style: italic;
            }
        }

        .cms-page-module-text {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;

            em {
                font-style: italic;
            }
        }
    }

    .cms-page-module-img {
        max-width: 1007px;
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }

        .slide_video {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            padding-bottom: 74.976%;
        }

        iframe {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

/* MODULE text-image-3 */
.cms_page .text-image-3 {
    margin-left: 1rem;

    .cms-page-module-body {
        max-width: 1900px;
        width: 100%;
        margin: 0;

        .cms-page-column .cms-page-module-contents {
            max-width: unset;

            .cms-page-module-title {
                padding-bottom: 1.15rem;
            }

            .cms-page-module-text {
                .sub_categ_desc {
                    text-align: unset;
                }
            }
        }

        .cms-page-module-img {
            max-width: 600px;
            width: 100%;
            height: auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        .slide_video {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            padding-bottom: 66.667%;

            .block_redirect {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 2;
                top: 0;
            }

            iframe {
                display: flex;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
            }
        }
    }

    &.text-image-2 {
        .slide_video {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 125%;

            .block_redirect {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 2;
                top: 0;
            }

            iframe {
                display: flex;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
            }
        }
    }
}

/* MODULE IMAGE DROIT */
.cms-page-module.image-droit {
    max-width: 1650px;
    height: auto;
    width: 100%;

    .cms-page-module-body {
        width: 100%;
    }
    
    .cms-page-column {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .cms-page-module-contents {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .cms-page-module-title {
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            text-transform: unset;
            line-height: normal;
            letter-spacing: 0.5px;
            margin: 0 .5rem 20px;

            em {
                font-style: italic;
            }
        }

        .cms-page-module-text {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            margin: 0 .5rem;

            em {
                font-style: italic;
            }
        }
    }

    .cms-page-module-img {
        max-width: 600px;
        width: 100%;

        .slide_video {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            padding-bottom: 127%;
        }

        iframe {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    & + section {
        margin-top: 130px;
    }
}

/* MODULE 1 IMAGE */
.cms-page-module.image-1 {
        max-width: 1920px;
        width: 100%;
        height: auto;

    .cms-page-module-body {
        max-height: 302px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .slide_video {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 15.7299%;
    }

    .block_redirect {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
    }

    iframe {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}

/* MODULE TEXTE IMAGE 1 IMAGE */
.cms-page-module.text-image-5 {
    height: auto;
    width: 100%;
    margin: 180px auto 0;

    .cms-page-module-body {
        width: 100%;
    }

    .cms-page-column {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-right: 2.1rem;
    }
    
    .cms-page-module-img {
        max-width: 936px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    .cms-page-module-contents {
        max-width: 950px;
        padding-left: 6rem;
    }

    .slide_video {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 66.667%;
    }

    .block_redirect {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
    }

    iframe {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}

/* MODULE IMAGE TEXTE 1 IMAGE */
.cms-page-module.image-text-1 {
    margin-right: 1rem;

    .cms-page-module-body {
        max-width: 1900px;
        width: 100%;
        margin: 0;
    }

    .cms-page-column {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: unset;
        flex-wrap: unset;
    }

    .cms-page-module-contents {
        max-width: unset;
        width: 100%;
        padding-left: 6.1rem;
    }

    .cms-page-module-img {
        max-width: 600px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    .slide_video {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 125%;
    }

    .block_redirect {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
    }

    iframe {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}

.three_text {
    margin: 19px 1.6rem 0;

    .home-columns-container > div > :not(.swiper-container) {
        padding-left: 0;
        padding-bottom: 6px;

        h2 {
            padding-bottom: 1rem;
            color: inherit;
        }
    }

    .text-slider {    
        padding-left: 0;

        .swiper-slide {
            padding: 2rem 0;

            .home-column {
                ul {
                    margin-left: 1.2rem;

                    li {
                        list-style: disc;
                    }
                }
            }
        }

        div {
            font-size: 16px !important;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }
}