
.home-module.text-image {
    padding: 0 0 $mainPadding;
    @include flexbox(row, space-between, center, unset); 

    @include medium {
        padding: 0 0 $mainPaddingMedium;
    }

    .home-column-img {
        margin-right: 2rem;

        img {
            width: 100%;
        }
    }
}