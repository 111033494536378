@keyframes grow {
    from {
        width: 0;
    } to {
        width: 100%;
    }
}

@keyframes shade {
    0% {
        opacity: 0;
    } 
    50% {
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }
}


