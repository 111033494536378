.lightbox {
    padding: 2rem 2.5rem 2rem 5rem;
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    background: #fff;

    .tab_content {
        color: $black;
        font-size: .75rem;
        line-height: 1.2;

        &.actif {
            padding: 0 4rem 0 0;
        }
    }

    .close_pop {
        position: absolute;
        z-index: 5;
        top: 23px;
        right: 38px;
        cursor: pointer;
        font-size: 0;
        appearance: none;
        background-color: white;
        width: 16px;
        height: 16px;
        @extend .bgSprite;
        @extend .cross;
        @include bgIconCoord(25, 5, 5);

        span {
            width: 15px;
            height: 15px;

            @extend .bgSprite;
            @extend .cross;
        }
    }

    &#exit_overlay_OVI {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 723px;
        height: auto;
        max-height: 423px; 
        padding: 0;
        margin: auto;
        transform: inherit;
        overflow: hidden;

        .close_pop {
            top: 16px;
            right: 16px;

            span {
                display: none;
            }
        }

        .pop_contents:not(#formu_inscription_news) {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .wrapper_img {
                width: 323px;
                height: 423px;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }
            }

            .wrapper_newspopup_home {
                width: calc(100% - 323px);
                padding: 0 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        .title_popup_inscription_home {
            font-family: $primaryFont;
            font-size: 25px;
            font-weight: 400;
            letter-spacing: .5px;
            line-height: 29px;
            text-transform: uppercase;
            text-align: center;
            color: $primaryColor;

            small {
                display: block;
                font-size: 25px;
                letter-spacing: .5px;
            }
        }

        .detail,
        .common_txt {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            color: $primaryColor;
            text-align: center;
        } 

        .pop_txt_listing:not(.pop_cgv_listing) {
            margin: 25px 0 18px;
            font-size: 15px;
            font-weight: 100;
            line-height: 1.2;

            strong {
                font-weight: 900;
            }
        }

        .w-form-line {
            margin-bottom: .5rem;
        }

        input[type="email"].w-input-element {
            height: 2.5rem;
        }

        .w-form-line.w-submit {
            max-width: 100%;
        }

        .w-submit-button:hover {
            background-color: $primaryColor !important;
        }

        .pop_cgv_listing {
            text-align: center;
            font-size: 12px;
            font-weight: 100;
            letter-spacing: initial;
            margin: 12px 0 0 0 !important;

            a span {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 0.5px;
                color: $menuColor;
                text-decoration: underline;

                a {
                    color: $menuColor;
                    text-decoration: none;
                }
            }
        }

        #conf_inscription_news {
            .pop_txt_listing {
                margin: 0;
            }
            
            #exit_overlay_OVI_voucher {
                display: block;
                font-size: 15px;
                font-weight: 100;
                line-height: 1.2;
                margin-bottom: 18px;
            }
        }
    }

    /* LOOKBOOK EXPRESS BUY */
    &#lightbox_achat_express {
        width: 95vw;
        max-width: 1175px;
        height: auto;
        padding: 0;

        .close_pop {
            top: 1.75rem;
            right: 1.72rem;
            width: 17px;
            height: 17px;
        }

        .page_produit {
            .prod_top_wrapper {
                align-items: stretch;
                margin-bottom: 0;

                .product_infos_container {
                    width: 540px;
                    order: 2;
                    height: auto;
                    padding: 0 64px 0 65px;
                    overflow: hidden;
        
                    @media screen and (max-width: 1230px) {
                        padding: 0 44px 0 45px;
                    }
        
                    @media screen and (max-width: 1090px) {
                        width: 500px;
                    }
        
                    @media screen and (max-width: 1050px) {
                        width: 480px;
                    }

                    .breadcrumbs {
                        display:none;
                    }

                    .itm_back_btn,
                    .action_list,
                    .product_feats_wrapper {
                        display: none;
                    }

                    .product_infos_content {
                        #itm_name, 
                        #itm_name span {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                            padding: 0;
                        }

                        #itm_name {
                            margin-bottom: 26px;
                        }

                        h2 {
                            font-size: 18px;
                            font-weight: 400;
                        }

                        .product_prices {
                            margin-bottom: 0.9rem;
                        }

                        h1 span::after {
                            margin: 6px 0 0;
                        }
                        
                        #ligne_couleur {
                            padding: 0;
                            margin-bottom: 18px;
                        }

                        .form_itm.size {
                            span:after {
                                left: 0;
                            }
                        }

                    }

                    .eclatProd {
                        margin-left: 0.85rem;
                    }

                    .choices_list {
                        width: 72%;
                    }

                    .block_sizeguide {
                        width: 28%;
                        justify-content: flex-end;
                    }

                    #bloc_add_basket {
                        height: 40px;

                        #btn_add_cart {
                            height: 40px;
                            border: 1px solid $primaryColor;
                            text-align: center;
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            opacity: 1;

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }

                .product_galery_container {
                    width: 60%;
                    max-width: 635px;
                    height: auto;
                    order: 1;
                    
                    #buyNowSwiper {
                        width: 100%;
                        height: 100%;
                    }

                    .swiper-button-prev,
                    .swiper-button-next {
                        display: none;
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                                cursor: unset !important;
                            }
                        }
                    }
                }
            }

            #product_addcart {
                margin-top: 0.3rem;
            }

            #gondole_derniers_articles_vus {
                display: none;
            }
        }
        
        #sizeguideBox {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            background-color: $white;

            .container {
                background-color: $white;
                max-width: 880px;
                width: 100%;
                min-width: 880px;
                max-height: 600px;
                height: 100%;
                padding: 2.5rem 2rem 2rem;
                position: relative;
                margin: auto;

                @media screen and (max-width: 1090px) {
                    padding-top: 8px;
                }
            }

            .sizeguide_tbl {
                @media screen and (max-width: 1440px) {
                    padding-left: 3.554%;
                }
            }
        }
    }

    /* Lightbox Send Friend *******/
    /******************************/
    &#sendfriendbox, &#sendwishlistbox {
        max-width: 723px;
        width: 100%;
        max-height: 750px;
        height: auto;

        .popup_title,
        #sendfriend_prodinfos {
            text-align: center;
        }

        .popup_title {
            @include wshopFont($primaryFont, 25px, 1.5, .5px, 400, unset, none, $primaryColor);
        }

        #sendfriendform {
           #content_sendfriendbox {
                width: 100%;

                .popup_subtitle {
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-align: center;
               }

               .popup_title {
                    color: $primaryColor;
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    margin-bottom: 5px;
               }
               
               .formContent {
                    padding: 26px 19px 0;

                    #sendfriend_form_to {
                        // For Email
                        div:nth-of-type(2) {
                            .w-input-note {
                                display: block !important;
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: .5px;
                                margin-top: 19px;
                            }

                            .w-input-note.w-input-error {
                                font-size: 16px;
                            }
                        }

                        // For message text-area
                        div:nth-of-type(3) {
                            .w-input-label {
                                position: relative;
                                top: 0;
                                margin-bottom: 5px;
                            }

                            .w-input {
                                display: flex;
                                flex-direction: column-reverse;

                                &.w-nonempty {
                                    .w-input-element {
                                        padding-top: 0 !important;
                                    }
                                }
                            }

                            textarea.w-nonempty textarea.w-input-element+.w-input-label, 
                            .w-textarea.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox])+.w-input-label {
                                transform: translateY(5%);
                                width: 94.6%;
                                background-color: white;
                                padding-top: 3px;
                            }
                        }

                        .form_title {
                            text-transform: uppercase;
                        }

                        .w-form-line {
                            margin-bottom: 20px;

                            .w-input-container {
                                .w-input {
                                    .w-input-element {
                                        height: 3.2rem;

                                        &::placeholder {
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            color: $primaryPlaceHolder;
                                        }
                                    }

                                    .w-input-label {
                                        color: $primaryPlaceHolder;
                                    }

                                    &.w-textarea {
                                        &::placeholder {
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            color: $primaryPlaceHolder;
                                        }

                                        .w-input-element {
                                            height: 6.75em;
                                        }

                                        .w-input-label {
                                            top: 13px;
                                            font-family: $primaryFont;
                                            color: $primaryPlaceHolder;
                                            font-size: 16px;
                                            transform: translateY(100%);
                                        }
                                    }

                                    &.w-nonempty {
                                        .w-input-element {
                                            margin-top: 0;
                                        }

                                        .w-input-label {
                                            top: 55%;
                                        }

                                        &.w-textarea {
                                            .w-input-element {
                                                margin-top: 15px;
                                            }

                                            .w-input-label {
                                                top: 0;
                                            }
                                        }
                                    }
                                }

                                .w-input-note.w-input-error {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    #sendfriend_form_froms {
                        div:nth-of-type(4) {
                            .w-input-container {
                                label > p {
                                    span {
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.5px;
                                    }
                                }
                            }
                        }

                        .w-form-line {
                            margin-bottom: 19px;

                            .w-input-container {
                                .w-input {
                                    .w-input-label {
                                        color: $primaryPlaceHolder;

                                        &:before {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }

                                    &.w-checkbox-input {
                                        margin-top: 10px;

                                        .w-input-element {
                                            &:checked {
                                                +.w-input-label {
                                                    &:before {
                                                        box-shadow: inset 0 0 0 0.15em $white;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .w-input-note.w-input-error {
                                    top: 83%;
                                }
                            }
                        }
                    }

                    #sendfriend_form_buttons {
                        max-width: 413px;
                        margin-top: 23px;

                        .w-btn-wrapper {
                            width: 100%;
                            max-width: 413px;

                            .w-loader,
                            .w-submit-button {
                                width: 100%;
                                max-width: 413px;
                            }
                        }

                        .w-form-line {
                            margin-bottom: 20px;

                            .w-input-container {
                                .w-input {
                                    .w-input-label {
                                        color: $primaryPlaceHolder;
                                    }
                                }
                            }
                        }
                    }
                }
           } 
        }

        .close_pop  {
            top: 19px;
            right: 17px;
        }

        #sendfriend_prodinfos {
            font-size: .875rem;
        }

        // wishlist share subtitle
        .subtitle {
            font-weight: normal;
            font-size: 1rem;
        }

        .form_title {
            @include wshopFont($primaryFont, 1rem, 1.5, .5px, 400, unset, none, $primaryColor);
            vertical-align: middle;
        }

        .plusieur_add {
            padding-top: .5rem;
            font-size: .75rem;
        }

        .plusieur_add {
            font-size: .75rem;
            text-align: left;
        }
        #sendfriend_form_froms,
        #wishlist_form_froms {
            margin-top: 20px;
        }

        #sendfriend_form_to div:nth-of-type(2) {
            margin-bottom: 2.5rem;
        }

        #sendfriend_form_froms div:nth-of-type(4) {
            .w-input-container {
                label > p {
                    span {
                        color: $black;
                        font-weight: 400;
                    }
                }
            }
        }

        #sendfriend_form_froms{
            .w-input-label span{
                color: $primaryPlaceHolder;
            }
        }

        #sendfriendform,
        #content_sendfriendbox {
            height: 100%;
        }
        #sendfriendform {
            @include flexbox(column, center, unset, unset);
        }

        #sendfriend_alert_mail {
            padding-top: 1rem;

            >div {
                text-transform: uppercase;
                font-family: $primaryFont !important;
                font-weight: 400 !important;
                font-size: 16px !important;
            }
        }

        /* INPUTS ***/
        .inputFormSite {
            width: 100%;
        }

        [type="submit"] {
            max-width: 100%;
        }

        #sendFriendCopy {
            position: absolute;
            left: -9999px;
        }
        [for="sendFriendCopy"] {
            font-size: .75rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            cursor: pointer;
            position: relative;
            @include flexbox(row, unset, center, unset);
        }

    }

    &#sendwishlistbox{
        #content_sendwishlistbox {
            #wrapper_sendwishlist{
                #sendfriend_form_buttons{
                    .w-submit{
                        margin-left: 8.5rem;
                    }
                }
            }
            #sendfriend_alert_mail
            {
                display: block;
            }
            .w-submit{
                margin-left: 8.5rem;
                margin-top: 23px;
            }
        }

    }

    /* Lightbox Size Guide ********/
    /******************************/
    &#sizeguideBox {
        max-width: 890px;
        width: 100%;
        max-height: 600px;
        height: auto;

        h2:first-of-type {
            font-size: 25px;
            font-weight: 400;
            letter-spacing: .5px;
            text-align: center;
            padding-bottom: 2rem;
            padding-right: 4rem;
            text-transform: uppercase;
        }

        #wrap_itable {
            max-height: 400px;
            height: 100%;
            overflow: hidden;
        }

        .nav_holder {
            margin-bottom: 2rem;
            padding-right: 4rem;
        }

        .primary_navbar {
            width: fit-content;
            margin: 0 auto;

            &::before {
                display: none;
            }

            li {
                padding: 0;
                padding-bottom: .5rem;
                margin: 0;
                font-size: 16px;
                opacity: 0.5;

                &:not(:first-child) {
                    margin-left: 3rem;
                }

                &.active {
                    opacity: 1;
                    font-weight: 400;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        .tab_content {
            >*:not(table){
                width: 100%;
                padding: 0;
            
                &:first-child {
                    margin-bottom: 1rem;

                    strong {
                        font-weight: 400;
                    }
                }

            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: .5px;

                strong {
                    font-weight: 400;
                }
            }
        }

        .taille_content_wrapper,
        .tablesWrapper {
            height: 100%;
        }
        table {
            max-width: 100%;
            width: 100%;
            margin-top: 1.25rem;

            &:not(:last-of-type) {
                margin: 2rem 0;
            }

            tr {
                td {
                    padding: .5rem;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: .5px;

                    &:first-of-type {
                        text-align: left;
                        border-left: 1px solid $borderColor;
                    }

                    border-right: 1px solid $borderColor;
                    border-bottom: 1px solid $borderColor;
                }

                &:first-of-type {
                    td {
                        border-top: 1px solid $borderColor;
                    }
                }
            }
        }

        .close_pop {
            span {
                display: none;
            }
        }
    }

    /* Handle Zoombox */
    &#zoomBox {
        padding: 0 11rem 2rem 11rem;
        width: 100%;
        max-height: 100vh;
        transform: none;
        top: 0;
        left: 0;
        background: #FFF;

        .img_large {
            cursor: url(../img/dezoom_cursor.png), auto;
        }

        .zoom_main_wrapper {
            .close_pop {
                span {
                    display: none;
                }
            }
        }

        /* Product galery related **/
        #zoom_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
            height: 100%;
            margin: 0 auto;

            .swiper-slide {
                text-align: center;
                opacity: 0 !important;
                height: 100vh !important;
                transition: opacity .2s ease-in-out;

                &.swiper-slide-active {
                    opacity: 1 !important;
                }

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }

            @media screen and (max-width: 1024px), 
            screen and (min-width: 1025px) and (max-width: 1366px) {
                .swiper-slide {
                    display: flex;
                }

                .product_galery_item {
                    padding-bottom: 0;

                    img {
                        margin: auto;
                    }
                }
            }

            @media screen and (min-width: 1025px) and (max-width: 1366px) {
                .product_galery_item {
                    img {
                        width: auto;
                        height: 80vh;
                    }
                }
            }
        }
        .galery_text {
            display: none;
        }

        .swiper-container-vertical>.swiper-pagination-bullets {
            position: fixed;
            z-index: 2;
            top: 50%;
            left: 0;
            bottom: unset;
            right: unset;
            width: unset;
        }

        .swiper-pagination-bullet {
            position: relative;
            border-radius: 0;
            height: 5rem;
            width: auto;
            padding: 0 1rem 0 2.5rem;
            margin: 0;
            color: transparent;
            background-color: transparent;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                transition: all ease-out .3s;
            }
            &::before {
                top: 0;
                height: 100%;
                background-color: $darkGrey;
            }
            &::after {
                top: auto;
                bottom: auto;
                height: 0;
            }

            &.swiper-pagination-bullet-active {
                &::after {
                    height: 100%;
                    bottom: 0;
                    top: 0;
                    background-color: $black;
                }
            }
        }
    }

    &#abox {
        max-width: 540px;
        width: 100%;
        max-height: 290px;
        height: 100%;
        text-align: center;
        padding-left: 2.5rem;

        .header_lightbox {
            padding-bottom: 1.25rem;
        }

        .holder_abox {
            height: 100%;
            @include flexbox(column, center, unset, unset);
        }

        .light_title {
            font-size: 25px;
            font-weight: 400;
            letter-spacing: .5px;
            padding-bottom: 1rem;
            text-transform: uppercase;
        }

        p {
            text-align: center;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .button {
            margin: 0 auto;
            max-width: 360px;
        }

        .box_wishlist {
            p {
                width: 80%;
                margin: 0 auto;
            }
        }
    }

    /* BASKET PROMO CODE */
    &#modboxpromo, &#modboxpromoinitialprice {
        width: 550px;

        .texte {
            text-align: center;
        }

        #btns_panier {
            width: 150px;
            margin: 2rem auto 0 !important;
        }
    }

    &#popup_numero_retour {
        width: 800px;
        height: 550px;
        padding: 0;
        overflow: hidden;

        @media screen and (max-height: 568px) {
            height: 450px;
        }

        @media screen and (max-height: 470px) {
            height: 350px;
        }

        .close_pop {
            span {
                background: none;
            }
        }

        .intro_return_lightbox {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            letter-spacing: .5px;
            text-transform: uppercase;
            margin: 2.5rem 0 1rem;

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: none;
            }
        }

        .mainWrapper {
            max-height: 320px;
            justify-content: space-between;
            align-items: flex-start;
            width: 690px;
            margin: 0 auto;
            overflow-y: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
              display: none;
            }

            @media screen and (max-height: 568px) {
                max-height: 250px;
            }

            @media screen and (max-height: 470px) {
                max-height: 160px;
            }

            .product-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 1rem 0 0;
            }

            .productReturnWrapper {
                width: 318px;

                .productReturnListWrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: stretch;
                    width: 100%;
                    height: 224px;
                    border: 1px solid #cccccb;
                    padding: 30px 40px;
                    box-sizing: border-box;
                    font-size: 16px;

                    .formWrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 15px;
                        box-sizing: border-box;

                        .titre {
                            font-size: 16px;
                            font-weight: 400;
                            margin-bottom: .2rem;
                            line-height: normal;
                        }

                        .sstitre {
                            margin-bottom: .5rem;
                        }

                        .prodFeats {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .color_bullet {
                                margin-right: 1rem;
                            }
                        }

                        .pricetag {
                            position: relative;
                            margin-top: .5rem;
                            padding-top: .5rem;
                        }
                    }
                }
            }
        }

        .js-select-motif {
            margin-top: .5rem;

            .optionsWrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: .6rem;

                label {
                    font-weight: 400;
                    white-space: nowrap;
                }

                .date_select {
                    position: relative;
                    border-bottom: 1px solid #cccccb;
                    margin-left: .5rem;

                    &.qte {
                        .return_quantity {
                            width: 35px;
                            padding-left: 3px;

                            &.error {
                                border: 1px solid $errorColor;
                            }
                        }
                    }

                    select {
                        appearance: none;
                        border: none;
                        height: 1.5em;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;

                        &.return_choice {
                            max-width: 193px;
                            padding: 0 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    &:after {
                        content: '\203A';
                        font-family: sans-serif;
                        font-size: 18px;
                        font-weight: 100;
                        color: $primaryColor;
                        transform: rotate(90deg);
                        position: absolute;
                        right: 5px;
                        top: 0;
                        pointer-events: none;
                    }
                }
            }
        }

        .btn_container {
            margin: 2rem 0;

            @media screen and (max-height: 568px) {
                margin: .8rem 0;
            }

            @media screen and (max-height: 470px) {
                margin: .5rem 0;
            }

            button {
                width: 270px;
                margin: 0 auto;
            }
        }
    }

    &#lightbox_preco {
        width: 850px;
        height: 340px;
        padding: 2rem;

        .t_lightbox_preco {
            text-transform: uppercase;
            text-align: center;
            line-height: 1.2;
        }

        .blocPrecoWrapper {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin: 3rem 0;

            .bloc_lightbox_preco {
                width: 49%;
                padding: 1rem;
                box-sizing: border-box;
                border: 1px solid #cccccb;
                text-align: center;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, .05);
                }

                &.actif {
                    border-color: $primaryColor;
                }

                p{
                    font-size: 13px;
                    line-height: 1.2;

                    span {
                        display: block;
                        font-size: .75rem;
                        text-align: center;
                        text-transform: uppercase;
                        margin-bottom: .5rem;
                    }
                }
            }
        }

        button:not(.close_pop) {
            width: 240px;
            margin: 0 auto;
        }
    }

    &#popup_savoir_plus {
        text-align: center;
        width: 39rem;
        padding: 4rem;
        top: 40%;
        bottom: auto;
        display: none;
        &.open {
            display: block;
        }

        .title {
            text-transform: uppercase;
            font-size: 25px;
            font-family: $primaryFont;
            font-weight: 400;
            letter-spacing: .7px;
            margin-bottom: 2rem;
        }

        .close:after {
            content: "\00d7";
            right: 5%;
            top: 5%;
            font-size: 1.7rem;
            position: absolute;
            cursor: pointer;
            color: #252525;
        }

        #bottom_savoir_plus {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;

            br {
                content: " ";
                display: block;
                margin-top: 1rem;
            }
        }
    }
}

.lightbox#exit_overlay_OVI #formu_inscription_news form.w-adwords-form {
    margin: 0 10px;

    .w-input-note {
        font-size: 13px;
        margin: 0;
    }

    .w-form-line.w-submit {
        margin: 10px auto 0;
    }
}

#sendfriendbox {
    padding: 30px 20px;

    @include medium {
        padding: 1rem;
    }

    #wrapper_sendfriendform {
        max-height: 700px;

        @include medium {
            max-height: 550px;
        }

        #sendfriendform {
            @include medium {
                padding-right: 12px;
            }
        }
    }

    // .popup_title, 
    // #sendfriend_prodinfos,
    #sendfriend_alert_mail .form_submit 
    {
        display: none;
    }
     
    .msg_poperror {
        font-size: .845rem;
        text-align: left;
    }

    #sendfriend_alert_mail {
        padding: 0;
    }
}

//LIGHTBOX 3X CB
.shade_cofidis {
    z-index: 70;
}

#popup_cofidis3cb {
    position: fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    width: 90%;
    max-width: 900px;
    padding: 35px;

    .close_pop {
        @extend .remove_prod;
        top: 15px !important;
        right: 15px !important;
    }

    // For overlayScrollbars script
    .popup_container {
        height: 100%;
        max-height: 800px;
        width: 95%;
        padding-right: 5px;
    }

    .logo3x {
        width: 12%;
        align-self: flex-end;
    }

    .popup_title_3xcb {
        display: flex;
        text-align: left;
        justify-content: space-between;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin: 0 auto 1.563vw;
    }

    .popup_subtitle_3xcb {
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.5px;
        margin: 0 0 1.5vw;
        width: 90%;
        line-height: 20.51px;
        width: 65%;
    }

    .popup_content_3xcb{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 79.896vw;
        margin: 2.667vw auto 35px auto;


        .popup_steps_3xcb {
            text-align: left;
            padding: 0 4rem;
        }
        
        .popup_calculator_3xcb{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 60%;
            padding-left: 4vw;
            border-left: 1px solid #000;
        }

        .form_calc_3xcb {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            
            .form_input_counter{
                display: flex;
                align-items: center;
                justify-content: flex-start;

                span {
                    font-weight: 400;
                    font-size: 16px;
                }

                // Amount input
                #montantCalcul {
                    border: 1px solid #ccc;
                    max-width: 83px;
                    height: 50px;
                    padding: 1.25rem 1rem;
                    outline: none;
                    margin-right: 1.563vw;
                    font-size: 1rem;
                    letter-spacing: 0.039vw;
                    color: #8e8d8d;
                }
            }
    
            // Btn Calculer
            .btn_container {
                max-width: 280px;
                height: 50px;
                margin-left: 1.302vw;
                flex-grow: 1;
            }

            button#calculCofidis3Cb {
                background-color: $white;
                color: $primaryColor;
                border: 1px solid $primaryColor;
                cursor: pointer;
                padding: 10px;

                span{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
        }
    
        #tab_mensualites {
            display: flex;
            flex-direction: column;
            margin: 2.604vw auto 0;
            width: 100%;
    
            .tab {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: .5rem;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
    
                &.montant_du {
                    margin-top: .823vw;
                    padding-top: .823vw;
                    border-top: 1px solid $primaryColor;
                    color: #000000;
                    font-weight: 400;
                }

                &.frais {
                    .tab_label {
                        text-align: left;
                        width: 60%;
                    }
                }
            }
        }
    }

    .popup_info_financement_3xcb {
        color: #8e8d8d;
        margin: 15px auto 0 auto;
        font-family: $primaryFont;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20.51px;
        width: 100%;
        max-width: 70.677vw;
    }

    .popup_info_validity_3xcb {
        font-family: $primaryFont;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.5px;
        width: 100%;
        text-align: center;
        margin: auto;
    }

    /* Page lookbook cofidis3cb */
    &.cofidis3cb_lookbook {
        width: 1080px;
        height: 648px;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%);
        
        @media screen and (max-width: 1120px) {
            width: 1000px;
            height: 600px;

            .popup_title_3xcb {
                max-width: 79.896vw;
            }
        }
        
        @media screen and (max-width: 1050px) {
            width: 980px;
            height: 576px;
        }
    }
}

.product_page #popup_cofidis3cb {
    position: fixed;
    height: 100vh;
    min-height: 869px;
    width: 472px;
    -webkit-transform: none;
    transform: none;
    top: 0;
    left: auto;
    padding: 0;
    padding-top: 10vh;
    .popup_title_3xcb {
        padding: 0 4rem;
        flex-direction: column;

        .logo3x{
            margin-top: 1rem;
            align-self: flex-start;
            width: 25%;
        }
    }
    .popup_content_3xcb {
        flex-direction: column;
        margin: 1rem 0;
        max-width: 100%;
    }
    .popup_calculator_3xcb {
        margin: 2rem 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 2rem 4rem;
        border-left: none;
        width: 100%;
    }
    #tab_mensualites {
        margin-top: 1rem;
    }
    .popup_calculator_3xcb .form_calc_3xcb {
        .form_input_counter #montantCalcul {
            max-width: 70px;
            max-height: 23px;
            padding: 1rem;
            font-size: 0.8rem;
        }
        span {
            font-size: 16px;
        }
        .btn_container {
            height: 35px;
            max-width: 220px;
        }
    }
    .popup_info_validity_3xcb,
    .popup_info_financement_3xcb {
        padding: 0 4rem;
        text-align: justify;
        width: 100%;
        max-width: 100%;
    }

    .popup_info_financement_3xcb {
        font-family: $primaryFont;
        font-size: 16px;
        line-height: normal;
        width: 100%;
    }
}

#shad.lightbox_achat_express {
    z-index: 25;
}

