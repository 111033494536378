body {
    font-family: $primaryFont;
    letter-spacing: .5px;
    transition: all .5s ease-out;
    max-width: 100%;

    @media screen and (min-width: 788px){
        min-width: 768px;
    }

    @media screen and (min-width: 1920px) {
        margin: auto;
    }

    &.noscroll {
        overflow-y: hidden;
    }

    #resell-widget-btn {
        padding: 0!important;

        &[data-state="close"] {
            z-index: 2 !important;
        }
    }

    &:has(#promoSwiper) {
        .nav_header .menu_top_wrapper #leftMenu .main_menu_itm .submenu_wrapper .menu_categ_wrapper .ss_menu_categ_wrapper.cache {
            top: 108px;
        }
    }
}

body,
a {
    color: $primaryColor;
}

// Used with scripts
.cache {
    display: none;
}

.appContainer {
    min-height: 100vh;
    max-width: 1920px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        max-width: 1024px;
        overflow: hidden;
    }
}

.page_wrapper {
    overflow: hidden;
    min-height: 100vh;
}

@include small {
    header,
    .page_wrapper,
    .footer  {
        width: 100%;
    }
}

// Top and Bot pseudo element (use with @extend)
.absoluteBorder {
    position: relative;
    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        background-color: $borderColor;
    }

    &::before {
        bottom: 100%;
    }

    &::after {
        top: 100%;
    }
}

// shrinked content for not-max-width modules
// .text-image-5 .cms-page-module-body,
.text-image-3 .cms-page-module-body,
.image-text-1 .cms-page-module-body,
.wrapper_content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.wrapper_content {
    padding: 0 2rem;
}

/***** Swiper arrows default style *****/
/* @extend .swiper_arrow */
.swiper_arrow {
    cursor: pointer;
    width: $arrowSize;
    height: $arrowSize;
    @extend .bgSprite;
    display: inline-block;

    &.swiper-button-disabled {
        opacity: .3;
        cursor: inherit;
    }
}

.ill_img img {
    opacity: 1 !important;
}

/***** PRODUCTS ITEMS *****/

/* Product picture Rayon, TG */
.product_picture {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;

    .swiper-slide {
        grid-column: 1;
        grid-row: 1;

        .block_lnk {
            display: block;
            height: fit-content;
            position: relative;
        }

        img {
            z-index: 1;

            & + img {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 2;
                transition: 0.3s ease-in-out;
            }
        }

        &:hover {
            img {
                & + img {
                    opacity: 1;
                }
            }
        }

        .swiper-slide {
            img {
                z-index: 1;

                & + img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: 2;
                    transition: 0.3s ease-in-out;
                }
            }

            &:hover {
                img {
                    & + img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .imgProd {
        width: 100%;
        height: auto;
    }
}

.page_rayon {
    #list_item {
        .no_results_wrapper {
            padding: 0 clamp(20px, 1.04167vw, 20px);
        }
    }

    #content_obj {
        .product_picture {
            .productBigPictures {
                .swiper-slide {
                    width: 100%!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        z-index: 1;

                        & + img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 2;
                            transition: 0.3s ease-in-out;
                        }
                    }

                    &:hover {
                        img {
                            & + img {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    #push_rayon {
        .full-text{
            .seeLess {
                cursor: pointer;
            }
        }

        .txtWrapper {
            flex-wrap: wrap;
            width: calc(100% - 40px);
            max-width: 1340px;
            margin: 0 auto;
            opacity: 0;

            u,
            a {
                text-underline-offset: 3px;
            }
        }

        .seeMore,
        .seeLess {
            display: block;
            width: 100%;
            line-height: 20.51px;
            text-decoration: underline;
            text-underline-offset: 3px;
            margin-top: 8px;
            cursor: pointer;

            &:first-letter {
                text-transform: capitalize;
            }
        }
    }

    .btnAddBasketWrapper {
        #size_is_not_available {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    }
}

.product_prices {
    display: flex;
    justify-content: space-between;

    .price_ctn {
        display: flex;
    }

    .pricetag + .pricetag {
        color: $couleur_prix_remisee !important;

        &::after {
            content: none !important;
        }
    }
}

.product_rollover {
    .rollover_left.achat_express {
        .ligne_form.productSizeFieldset {
            .form_itm.check.size {
                position: relative;
                text-align: left;

                input[type="radio"] {
                    display: none;
                }

                &:hover {
                    text-decoration: none;
                }

                label {
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        height: 2px;
                        width: 100%;
                        background-color: transparent;
                        transition: background-color 0.3s;
                    }
                }
            }

            .form_itm.check.size .disabled + label {
                color: $primaryColor;
            }
        }

        .productColorFieldset {
            .prod_listes.right_element_prod {
                .choices_list {
                    .form_itm.check.color {
                        & + [title="Blanc"],
                        & + [title="Denim"] {
                            border: 1px solid #ccc!important;
                        }
                    }

                    .form_itm.check.color {
                        label {
                            background-size: cover !important;
                        }
                    }
                }
            }
        }
    }

    .bloc_add_color {
        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .expressAlertStock {
        .bloc_add_alert_form {
            .w-form-line{
                .form_submit {
                    button {
                        padding: 0.5rem 4.5rem;
                    }
                }
            }
        }
    }
}

/* Description */
.wrapper_description {
    margin-top: .5rem;
    strong {
        font-weight: 400;
    }

    .product_titles {

        h2 {
            font-weight: 400;
        }

        h3 {
            font-size: .75rem;
        }

        &::after {
            content: "";
            display: block;
            width: 1rem;
            height: 2px;
            margin: .5rem 0;
            background-color: $primaryColor;
        }
    }

    .product_prices {
        font-size: 16px;
    }
}

.eclat_gondole_home img{
    width: 60px!important;
}

.eclat_rect_img,
.eclat_rect {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
}

.eclat_rect_img,
.eclat_rect,
.eclat_rect img {
    width: 2.063rem;
    height: 10rem;
}

.tg-module .swiper-wrapper .swiper-slide {
    .eclat_rect_img,
    .eclat_rect,
    .eclat_rect img {
        width: 2.063rem !important;
        height: 10rem !important;
        z-index: 10 !important;
    }
}

/******************************************************/
/* COMMON PAGE UPPER BLOC = BANDEAU *******************/

.page_upper {
    position: relative;
    @include flexbox(row, stretch, flex-start, unset);

    >* {
        max-width: 100%;
        width: 100%;
    }

    .page_upper_title {
        @include wshopFont($primaryFont, 25px, normal, .5px, 400, uppercase, none, $primaryColor);
        padding-bottom: 1rem;
    }

    .page_upper_desc {
        @include wshopFont($primaryFont, 16px, normal, .5px, 400, unset, none, $primaryColor);
    }

    // Subtitle
    h3 {
        @include wshopFont($primaryFont, 16px, 1, .5px, 900,unset, none, $primaryColor);
        padding-bottom: .5rem;
    }

    .page_upper_text {
        padding: 0 clamp(20px,1.04167vw,20px);
        @include flexbox(row, flex-start, center, unset);
        margin-top: 1rem;

        // content_wrapper
        >div {
            width: 100%;
            margin: 0 auto 0 0;
        }
    }

    // flex bandeau img center mode for small screen rendering
    .holder_img {
        min-height: 495px;
        img {
            height: 100%;
        }
    }
}



/******************************************/
/** PRIMARY NAV BAR ***********************/

.primary_navbar {
    position: relative;
    margin-bottom: 1rem;
    @include flexbox(row, flex-start, center, unset);

    >li,
    >div,
    >a {
        text-transform: capitalize;
        position: relative;
        padding: 1rem 0;
        cursor: pointer;
        font-size: .875rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &::after {
            position: absolute;
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background-color: $navBar_active;
            left: 0;
            top: 100%;
            z-index: 1;
            transition: all ease .3s;
        }

        &.active,
        &.actif,
        &.current,
        &.on,
        &.btn_select {
            font-weight: bold;

            &::after {
                width: 100%;
            }
        }
    }

}

.tab_content {
    display: none;

    &.actif {
        display: block;
    }
}


/****************************************************/
/* NAV HOLDER ***************/
.nav_holder {
    p {
        font-size: .75rem;
    }
}

/****************************************************/
/* Frequent font style (ex: articles) ***************/

.articleFontStyle {
    h2 {
        @include wshopFont($primaryFont, 1.6rem, 1.5, .5px, 900,unset, none, $primaryColor);
        padding-bottom: 1.25rem;
    }

    td,
    li:not(.tabsatellite):not(.sidenav_tab),
    h3,
    h4 {
        @include wshopFont($primaryFont, .75rem, 1.3, unset, normal, unset, none, $primaryColor);
    }

    h3,
    h4 {
        font-weight: bold;
    }

    h4 {
        font-style: italic;
    }

    p {
        text-align: left;
        padding: .5rem 0;
    }

    td {
        line-height: 1;
    }

    strong {
        font-weight: 900;
    }

    li:not(.tabsatellite):not(.sidenav_tab) {
        margin-bottom: .5rem;
    }

}

:focus {
    outline: none;
}

/****************************************************/
/* RODIER CUSTOM SCROLLBARS *************************/
.lb-v-scrollbar,
.lb-v-scrollbar-slider {
    width: 6px !important;
}

.lb-v-scrollbar-slider {
    width: 6px !important;
}

/************************************************************************/
/**                    USER TOOLTIP                         *************/
/** Turn opacity -> 1 when needed (ex: product color hover) *************/
.userTooltip {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    opacity: 0;
    text-align: center;
    padding: .5rem;
    border: 2px solid $primaryColor;
    background-color: $white;
    transition: opacity ease .3s;
    min-width: 100px;

    &::before {
        content: '';
        border: solid $primaryColor;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-135deg);
        background-color: #fff;
        position: absolute;
        bottom: 90%;
        left: .6rem;
    }

    span {
        font-family: $primaryFont;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        color: $primaryColor;
        text-transform: capitalize;
    }
}




/***** PRODUCT w/ achat express *****/
.productBigPictures {
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    -ms-grid-rows: auto;
    -ms-grid-columns: auto;

    div {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }

    img {
        width: 100%;
        height: auto;
    }
}



/************************************************************************/
/**                    INSIDEPARENTHESES                        *********/
/** Don't forget to add spacement to pseudo-elems after @extend *********/
/************************************************************************/

.insideParentheses {
    display: inline-flex;

    &::before {
        content: "(";
        display: inline-block;
        padding-right: .1rem;
    }
    &::after {
        content: ")";
        display: inline-block;
        padding-left: .1rem;
    }
}

/************************************************************************/
/**                    ALERT STOCK                              *********/
/************************************************************************/

.productVisualMulti .btnAddBasketWrapper {
    position: relative;
    padding: 15px;
    display: block;

    .alert_stock {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: .5px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .bloc_add_alert_form {
        > .w-form-line:not(.w-submit) {
            width: 100%;
            margin-right: unset;

            .w-input-container {
                input {
                    width: 100%;
                    height: 40px;

                    &::placeholder {
                        color: #BBB;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }

                    &.inputErr {
                        border-bottom: 1px solid $errorColor;
                    }
                }
            }

            .form_submit {
                position: relative;
            }

            button {
                background-color: transparent;
                vertical-align: middle;
                text-transform: uppercase;
                cursor: pointer;
                content: " ";
                position: absolute;
                top: -7px;
                right: 2px;
                width: .5rem;
                height: .5rem;
                border-right: 1px solid $primaryColor;
                border-top: 1px solid $primaryColor;
                transform: rotate(45deg);
                padding: 0;

                span {
                    display: none;
                    text-align: center;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .alert_return {
        position: absolute;
        right: .8rem;
        top: 1rem;
        cursor: pointer;

        &:after {
            content: " ";
            width: 16px;
            height: 16px;
            display: block;
            background-image: url(../svg/spritesheet.svg);
            @include bgIconCoord(25, 5, 5);
        }

        span {
            display: none;
        }
    }
}

.red,
.error.red {
    font-size: .75rem;
    color: $lightWhite;
}

.itm_back_btn {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    position: absolute;
    top: 0;
    left: 0;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;


        @extend .bfr;

        &::before {
            width: 1rem;
            height: 1rem;
            @extend .bgSprite;
            @extend .arrowLeft;
        }
    }
}




.ui-slider {
	position: relative;
	text-align: left;
	z-index: 0;
}

.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: 0.7em;
	display: block;
	border: 0;
}

.ui-slider-horizontal {
	height: 3px;
}

#satisfactionForm .ui-slider-horizontal .ui-slider-handle {
	margin-top: auto;
	top: -3px;
	background: none;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 1px;
	margin: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: 0.8em;
	height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

.ui-widget-header {
	background-color: $black;
}

[title="Blanc"],
[title="Denim"] {
    border: 1px solid #ccc!important;
}

.eclat_rond img,
.eclatProd img {
    width: 56px;
    height: 23px;
}

.error_msg {
    color: red;
}

body.checkout{
    #iconErrmobile_3xcb,
    #iconErrtelephone_3xcb{
        position: relative;
        top: 0.25rem;
    }
}

// Fil d'ariane
#breadcrumbs {
    display: flex;
    padding: 0 clamp(20px, 1.04167vw, 20px);
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: lowercase;
    opacity: 0;

    @media (orientation: portrait) {
        white-space: nowrap;
    }

    .breadcrumbs {
        display: flex;

        .breadcrumb {
            opacity: 0.5;

            &.arrowIcon {
                display: flex;
                margin-right: 3px;

                &::after {
                    content: "/";
                    display: inline-block;
                    margin-left: 5px;
                }
            }

            &.current {
                font-weight: 400;
                opacity: 1;
            }
        }
    }
}

#cookiesLightbox {
    position: fixed;
    max-width: 430px;
    left: 19px;
    margin: 0 auto;
    padding: 35px 30px 30px;
    font-size: 16px;
    min-height: 452px;
    height: auto;
    font-family: $primaryFont;
    color: $primaryColor;

    .w-submit-button {
        font-weight: 900;
        border: 2px solid $primaryColor;

        span {
            font-family: $primaryFont;
        }
    }

    #js_cookie_refuse,
    #js_cookie_refuse + .w-loader {
        background-color: $primaryColor;

        span {
            color: $white;
        }

        &:hover {
            background-color: $white;

            span {
                color: $primaryColor;
            }
        }
    }

    #js_cookie_refuse + .w-loader {
        background-color: $white;
    }

    #js_cookie_accept {
        background-color: $white;

        span {
            color: $primaryColor;
        }

        &:hover {
            background-color: $primaryColor;

            span {
                color: $white;
            }
        }
    }
}

b,
strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

body.homepage .dynasearchwrapper {
    margin-top: 6rem;
}

/** MOBILE PAGINATION *******/
#search_page {
    .pagination {
        .active {
            background-color: $white;
            color: $primaryColor;
        }

        > a {
            &.suivant,
            &.precedent {
                display: none;
            }
        }
    }
}

body.cms_category {
    #cms {
        .page_upper_text {
            .nav_holder {
                .page_upper_desc {
                    display: none;
                }

                .page_upper_desc + .page_upper_desc {
                    display: block;
                }
            }
        }

        &.cms_category_1 {
            .categoryNav {
                display: block;

                .breadcrumb + .breadcrumb {
                    color: $primaryColor;
                    opacity: 1;

                    &::after{
                        content: none;
                    }
                }
            }
        }
    }
}

body.category {
    .page_rayon.rayon_box {
        .filter_wrapper {
            .bloc_sub_categ {
                width: 100%;

                &.views {
                    display: none;
                }
            }

            #trigger_filtre {
                display: none;
            }
        }

        #content_obj {
            .wrapper_box_steps.box_maille_old {
                margin: 1.1rem auto 3.05rem;
            }
        }
    }
}