.shad {
    z-index: 20;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shadeColor;
    overflow-y: auto;

    &.shad_menu {
        width: 100%;
        animation: shade .4s ease-out alternate forwards;
    }

    &.filter {
        z-index: 50;
    }

    &.actif {
        display: block;
    }

    &.activate {
        display: block;
    }
}