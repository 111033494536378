.home-module.push-product-module {
    padding: 0 $mainPadding;
    border: none !important;
    position: relative;
    
    @include medium {
        padding: 0 $mainPaddingMedium;
    }

    .swiper-pushProduct-button {
        @extend .swiper_arrow; 
        margin: .5rem 0;
        position: absolute;
        top: 50%; 
        
        &.swiper-pushProduct-button-prev {
            @extend .arrowRight; 
            right: 5rem;

            @include medium {
                right: .5rem;
            }
        }
        &.swiper-pushProduct-button-next {
            @extend .arrowLeft; 
            left: 5rem;

            @include medium {
                left: .5rem;
            }
        }
    }
}

.pushProductHomeWrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 3rem) !important;

    h2 {
        position: absolute;
        z-index: 10;
        top: 13%;
        left: 0;
        width: 50%;
        font-size: 3rem;
        font-weight: 900;
        letter-spacing: .5px;
        line-height: 1;

    }

    .pushProductImgWrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .smallVisus,
        .bigVisu {
            position: absolute;
        }

        .smallVisus {
            z-index: 5;
            top: 42%;
            left: 0;
            width: 50%;
            max-width: 704px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            div {
                position: relative;
                width: 32%;
                max-width: 214px;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }

                .infosProd {
                    position: absolute;
                    top: 105%;
                    left: 0;

                    h3 {
                        font-size: 16px;
                        font-weight: 900;
                        white-space: nowrap;

                        span {
                            font-size: .75rem;
                            font-weight: 400;
                        }

                        &::after{
                            content: "";
                            display: block;
                            width: 1rem;
                            height: 2px;
                            margin: .5rem 0;
                            background-color: $primaryColor;
                        }
                    }

                    .pricetag {
                        position: relative;
                        font-size: $price_font_size_desktop;
                        margin: -.5rem 0 0;
                        padding: .5rem 0 0;

                        &+.pricetag {
                            opacity: 0.5;
                            font-size: 1rem !important;
                            display: flex;
                            align-items: center;
                            font-weight: 400;
                            text-decoration: line-through;
                            color: rgba(35,31,32,.5);
                            margin-left: 2.2rem;
                        }
                    }
                }
            }
        }

        
        .bigVisu {
            height: 100%;
            width: 55%;
            max-width: 764px;
            z-index: 4;
            top: 0;
            right: 0;
            
            .ill_img {
                height: 100%;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                vertical-align: bottom;
            }
        }
    }
}