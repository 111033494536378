.site_title_burger {
    display: none;
} 

@include small {

    .site_title_burger {
        cursor: pointer;
        width: 224px;
        min-width: 224px;
        @include flexbox(row, space-between, flex-start, unset); 

        .burger_menu_container {
            display: flex;
            width: 30px;
            height: 20px;
        }

        .burger_menu {
            position: relative;
            width: 30px;
            height: 3px;
            margin-top: 8px;
            background-color: $primaryColor;

            &::before, 
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 30px;
                height: 3px;
                background-color: $primaryColor;
                transition: all .2s ease-out;
            }

            &::before {
                top: 8px;
            }

            &::after {
                bottom: 8px;
            }

            &.is-clicked {
                margin-top: 4px;
                background-color: transparent;

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    bottom: -8px;
                    transform: rotate(-50deg);
                }
            }
        }

        .logo {
            width: 70%;
        }
    } 
}