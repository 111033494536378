#copyright {
    width: 100%;

    .review_rs_wrapper {
        display: flex;
        flex-direction: column;

        .evaluation {
            width: 115px;

            .wrap_rating {
                display: flex;
                align-items: center;
                position: relative;
                top: -6px;

                .evaluation_rating {
                    span {
                        display: inline-block;
                        color: $primaryColor;
                        font-family: $secondaryFont;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }

                .rating_stars {
                    display: inline-block;
                    width: 70px;
                    height: 11px;
                    background-image: url(../img/rating_stars.png);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: cover;
                    position: relative;
                    top: 1px;
                    left: 8px;

                    &.one {
                        width: 14px;
                    }

                    &.two {
                        width: 28px;
                    }

                    &.three {
                        width: 42px;
                    }

                    &.four {
                        width: 56px;
                    }

                    &.five {
                        width: 70px;
                    }
                }
            }

            .media_title {
                color: #797979;
                font-family: $primaryFont;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                letter-spacing: 0px;
                position: relative;
                top: -4px;
            }
        }

        .rs_ctn {
            display: flex;
            margin-left: -3px;

            ul {
                display: flex;

                p {
                    display: none;
                }

                li {
                    &:not(:first-of-type) {
                        margin-left: 4.5px;
                    }

                    a {
                        .icon {
                            display: block;
                            width: 28px;
                            height: 28px;
                            background-image: url(../svg/spritesheet.svg);

                            &.instagram {
                                @include bgIconCoord(6, 5, 5);
                            }

                            &.facebook {
                                @include bgIconCoord(16, 5, 5);
                            }

                            &.pinterest {
                                @include bgIconCoord(17, 5, 5);
                            }
                        }
                    }
                }
            }
        }
    }

    .reassurance_wrapper {
        border: none;
        width: 100%;
        margin-bottom: 0;

        @media screen and (max-width: 1024px) {
            margin: 3% 0 3.4%;
        }

        .rea_ctn {
            width: 69.7%;
            min-width: 963px;
            height: 159px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 1.4% 0 2.7%;

            /* Maquette 1024 refont */
            @media screen and (max-width: 1440px) {
                width: 82.9%;
                padding-right: .4%;
                height: 127px;
            }

            /* Maquette 1024 refont */
            @media screen and (max-width: 1024px) {
                min-width: 1014px;
                height: 93px;
                padding-right: .64rem;
                padding-left: 1rem;
            }

            .txt,
            .sub_txt {
                overflow: hidden;
                text-overflow: ellipsis;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2rem;
                letter-spacing: 0.5px;
                text-align: center;
                display: block;
                position: relative;

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    letter-spacing: 0.6px;
                }

                span.placement {
                    position: relative;
                    left: 2px;
                }
            }

            .txt {
                text-transform: uppercase;
                margin-bottom: 2px;
            }

            .sub_txt {
                font-weight: 400;
                line-height: 1.3rem;

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    top: 0;
                    span {
                        display: none;
                    }
                }

                .pop_in_confidis {
                    display: block;
                }

                .gap_small_res {
                    display: inline-flex;

                    @media screen and (max-width: 1024px) {
                        display: block;
                    }
                }
            }

            .move_besoin_aide {
                position: relative;

                .sub_txt {
                    span {
                        display: block;
                        width: 100%;
                    }
                }

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    top: 0;
                }
            }

            .pop_in_confidis {
                cursor: pointer;
            }

            div:first-child {
                margin-top: 2px;
            }
        }
    }

    .footer_cats_newsletter {
        width: 100%;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        /* Maquette 1024 refont */
        @media screen and (max-width: 1024px) {
            border-bottom: none;
            margin-bottom: .9rem;
        }

        .footer_ctn {
            width: 100%;
            max-width: 100%;
            min-height: 232px;
            margin: 0 auto;
            display: flex;
            border: none;
            padding: 0 clamp(20px, 1.04167vw, 20px);

            /* Maquette 1024 refont */
            @media screen and (max-width: 1024px) {
                order: 1;
                width: 98%;
                max-width: 100%;
                min-height: auto;
                flex-wrap: wrap;
            }

            .footer_cats {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                max-width: 1427px;

                &.desktopIpad {
                    display: none;
                }

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    order: 1;
                    width: 100%;
                    max-width: 100%;

                    &.desktop_large {
                        display: none;
                    }

                    &.desktopIpad {
                        display: block;
                    }
                }

                nav {
                    width: 32.6%;
                    padding-top: 1.44%;

                    &:not(:first-child){
                        padding-left: 1%;
                        width: 33.7%;

                        /* Maquette 1024 refont */
                        @media screen and (max-width: 1024px) {
                            width: 98%;
                        }
                    }

                    /* Maquette 1440 refont */
                    @media screen and (max-width: 1440px) {
                        width: 31.9%;
                    }

                    /* Maquette 1024 refont */
                    @media screen and (max-width: 1024px) {
                        width: 98%;
                        margin: 0 auto;
                        padding: 1.9% 0 2.1%;
                        border-top: 1px solid rgba(35, 31, 32, 0.10);
                        position: relative;
                        cursor: pointer;

                        &:not(:first-child) {
                            padding-left: 0;
                        }

                        &:after {
                            content: " ";
                            position: absolute;
                            top: 40%;
                            right: 0.5%;
                            width: 10px;
                            height: 10px;
                            border-right: 1px solid $black;
                            border-top: 1px solid $black;
                            transform: rotate(45deg);
                        }

                        &.active {
                            &:after {
                                @include bgIconCoord(11, 5, 5);
                                transform: rotate(135deg);
                                top: 8.5%;
                            }
                        }
                    }

                    &:nth-child(2) {
                        border-left: 1px solid $borderColor;
                        border-right: 1px solid $borderColor;

                        /* Maquette 1024 refont */
                        @media screen and (max-width: 1024px) {
                            border-left: none;
                            border-right: none;
                        }
                    }

                    &:nth-child(3) {
                        border-right: 1px solid $borderColor;

                        /* Maquette 1024 refont */
                        @media screen and (max-width: 1024px) {
                            border-right: none;
                            border-bottom: 1px solid $borderColor;


                        &.active {
                                &:after {
                                    top: 17%;
                                }
                            }
                        }
                    }

                    ul {
                        & > p,
                        li {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                        }

                        & > p {
                            text-transform: uppercase;
                            font-weight: 400;
                        }
                    }

                    li {
                        font-weight: 400;
                        margin-top: 4px;

                        /* Maquette 1024 refont */
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    .review_rs_wrapper {
                        width: 98%;
                        margin: 1.25rem auto 0;
                        justify-content: flex-start;

                        .rs_ctn {
                            border: none;
                            width: 116px;

                            ul {
                                margin-left: 14px;

                                li {
                                    display: block;
                                    margin-top: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .newsletter_bloc {
                display: block;
                max-width: 458px;
                width: 100%;
                padding: 1% 0 0 15px;

                /* Maquette 1440 refont */
                @media screen and (max-width: 1440px) {
                    padding: .8% 0 0 1%;
                }

                /* Maquette 1024 refont */
                @media screen and (max-width: 1024px) {
                    order: 0;
                    max-width: 99%;
                    width: 100%;
                    padding: 31px .5% 0;
                    margin: auto;
                }

                .signup_module {
                    width: 100%;
                    overflow-x: hidden;

                    .title {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        margin-bottom: 0;
                    }

                    .subtitle {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        margin-bottom: 0.7rem;
                        width: 100%;
                    }

                    .w-newsletter-form {
                        width: 100%;

                        input.w-input-element,
                        .w-input-label span {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0.5px;
                            padding: 0;
                            padding-top: .4rem;
                            border-top: 0;
                            border-right: 0;
                            border-left: 0;
                        }

                        .w-input-label {
                            left: 0;
                        }

                        .w-form-line.w-submit {
                            width: 1.2rem;
                        }

                        .w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
                        .w-has-error:not(.w-dropdown) input[type=email].w-input-element {
                            box-shadow: unset;
                        }

                        .w-input-label span {
                            color: $primaryPlaceHolder;
                            font-size: 16px;
                        }

                        .w-form-line.w-submit {
                            .w-submit-button {
                                width: 1rem;
                                height: 2rem;

                                &::after {
                                    content: " ";
                                    position: absolute;
                                    top: 40%;
                                    right: 3.5px;
                                    width: 10px;
                                    height: 10px;
                                    border-right: 1px solid $black;
                                    border-top: 1px solid $black;
                                    transform: rotate(45deg);

                                    @media screen and (max-width: 1024px) {
                                        top: 38%;
                                    }
                                }

                                span {
                                    display: none;
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    text-align: center;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                    border: none;
                                    position: relative;
                                    padding-bottom: 0;

                                    &::after {
                                        content: " ";
                                        position: absolute;
                                        bottom: 2px;
                                        left: 0;
                                        width: 100%;
                                        height: 1px;
                                        background-color: $black;
                                    }
                                }

                                 &.w-loader {
                                    display: none;
                                }
                            }

                            .w-submit-button.w-loader.loading {
                                border: none;
                                width: calc(5rem - 1px);
                                height: calc(2rem - 3px);
                                top: 10px;
                                left: -170%;
                                background-size: 40%;

                                /* Maquette 1024 refont */
                                @media screen and (max-width: 1024px) {
                                    width: 100%;
                                    left: 0;
                                    background-size: 75%;
                                    top: 3px;

                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }

                        .w-nonempty .w-input-label {
                            transform: translateY(-130%);
                        }

                        .w-input-note.w-input-valid {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: .5px;
                        }
                    }
                }
            }

            .w-input.w-email-input .w-input-element {
                border: none;
                border-bottom: 1px solid $borderColor;
                padding: 0;
            }
        }
    }

    .footerCgv {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            padding-left: 15px;
            padding-right: 10px;
            position: relative;
            top: -1px;

            &:first-child {
                padding-left: 0;
            }

            .separator {
                position: relative;
                left: 14px;
            }
        }

        p, a {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 12px;
            letter-spacing: 0.06px;
        }

        .copyright {
            display: flex;
            align-items: center;
            height: 40px;

            a {
                background: url(../svg/wshop.svg) center center no-repeat;
                margin-left: 5px;
                width: 48px;
            }
        }
    }

    &.footer {
        .footerCgv + .evaluation.desktop {
            position: fixed;
            background: $fadeGreyline;
            padding: 0.7rem 1.4rem .7rem 1rem;
            bottom: 1rem;
            right: 0;
            z-index: 5;

            .media_title {
                color: #797979;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            }

            .wrap_rating {
                display: flex;

                span:first-child {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.0625rem;
                    text-transform: uppercase;
                }

                .rating_stars {
                    display: inline-block;
                    width: 70px;
                    height: 11px;
                    background-image: url(../img/rating_stars.png);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: cover;
                    position: relative;
                    top: 4px;
                    left: 8px;
                }
            }
        }
    }
}

/* Bloc cookies bottom site */
.cookies_block_footer {
    z-index: 100;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 330px;
    padding: 1rem;
    @include flexbox(row, space-between, unset, wrap);
    color: $white;
    font-size: .75rem;
    line-height: 1.3;
    background-color: $black;

    p {
        margin-bottom: .5rem;
    }

    a,
    button {
        cursor: pointer;
        color: $white;
        font-size: .75rem;
        text-decoration: underline;
        background-color: transparent;
    }

    button {
        font-weight: 900;
        text-transform: uppercase;
    }
}

body.customer .footer .reassurance_wrapper {
    display: block;
}

#resell-widget-btn > div {
    padding: 0 !important;
}