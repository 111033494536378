$col: 5;
$row: 5;

.bfr {
    &:before {
        content: '';
        display: block;
        overflow: hidden;
    }
}

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include spriter('../svg/spritesheet.svg', 5, 5, (1: 'account',
    2: 'cart',
    3: 'search',
    4: 'burgerMenu',
    5: 'location',
    6: 'instagram',
    7: 'heart',
    8: 'share',
    9: 'swiperHand',
    10: 'eye',
    11: 'arrowDown',
    12: 'arrowRight',
    13: 'arrowTop',
    14: 'arrowLeft',
    15: 'cross',
    16: 'facebook',
    17: 'pinterest',
    18: 'check',
    19: 'heartFilled'
    ));
}
