/******************************************************************************************/
/* HIDINGS *********************************************************************************/
.newsletter,
.wishlist {
    #popup_savoir_plus {
        display: none;
    }
}

/******************************************************************************************/
/* CONNEXION *********************************************************************************/
.body_login {
    .page_wrapper {
        min-height: 61vh;
    }

    .login_bloc {
        align-items: unset;
        justify-content: flex-start;

        .bandeau_container {
            order: -1;
        }

        .subtitle span {
            display: block;
        }

        .title {
            // Titre "Mon Compte" - haut de page

            &.my_account {
                margin-top: 21px;

                h2 {
                    @include wshopFont($primaryFont, 25px, normal, .5px, 400, unset, none, $primaryColor);
                    text-align: center;
                    text-transform: uppercase;
                    margin-left: 0.4rem;
                }

                .subtitle {
                    display: none;
                }
            }
        }

        .w-nonempty:not(.w-radio-group) .w-input-label {
            transform: translateY(-133%);
        }

        .login_form_wrapper {
            .title {
                max-width: 660px;
            }

            h3 {
                margin-top: 1.3rem;
                margin-bottom: 0;
            }

            span.colon {
                display: none;
            }

            .email-form-line {
                margin-bottom: 23px;

                .w-email-input {
                    margin-left: 0;
                }
            }

            .w-newlogin-form {
                .pwd-form-line {
                    margin-bottom: 1.8rem;
                }

                .pwd-reset-line {
                    margin-bottom: 0;
                }

                .w-lines-group {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .newlogin_page {
        padding-top: 0;
        margin: .3rem 0 0;
        min-height: 0;

        .existing_user_disclaimer {
            padding-top: 1.1rem;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            display: none;
        }

        /* INTRO ON GUEST MODE ON WEBSITE ****/
        .w-newlogin-form {
            width: 100%;

            &.w-client-form {
                align-items: flex-start;

                .w-form-line.w-submit {
                    width: 47.4%;

                    .w-submit-button {
                        border: 1px solid $primaryColor;

                        span {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }

            .w-input {
                .w-input-label {
                    left: 0;
                    top: 65%;

                    span {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }

                // For everything except Mail registration radio buttons
                &:not(.w-radio-input) {
                    .w-input-label span {
                        color: $primaryPlaceHolder;
                    }
                }
            }

            .w-nonempty .w-input-label span {
                font-size: 16px;
                color: $primaryPlaceHolder;
            }

            .intro {
                font-size: .75rem;
                font-style: normal;
            }

            /* INTRO ON GUEST MODE ON WEBSITE ****/
            .w-pwd-reset {
                margin: 0;
                height: 1.1rem;
                margin-bottom: 1.5rem;
                margin-top: 7px;
            }

            .optin_container {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;

                .w-input-label {
                    color: $black;
                    font-size: 16px;
                }

                .knowMoreOptin .info_site a {
                    font-size: 16px;
                }

                .w-radio-group {
                    .w-input-element+.w-input-label {
                        &::before {
                            width: .9em;
                            height: .9em;
                        }

                        span {
                            margin-left: .3em;
                        }
                    }
                }

                .w-radio-input+.w-radio-input {
                    margin-left: 1.23em;
                }
            }

            .w-fieldset-label {
                margin-left: 0;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                margin: 0 0 .4rem;
            }

            .w-form-line.w-submit {
                max-width: unset;
            }

            .knowMoreOptin {
                margin: 1.7rem 0 0;

                .info_site a {
                    font-size: .75rem;
                    color: $black;
                    height: 19px;
                    display: block;
                }
            }

            .w-form-line.w-submit {
                margin-bottom: 22px;
            }

            #AuthInactifErr {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            .w-input-element {
                border: none;
                border-bottom: 1px solid #E7E2DE;

                &:not([type=radio]):not([type=checkbox]), input[type=email].w-input-element {
                    padding-top: 1.15em;
                    padding-left: 0;
                }
            }
        }

        /* No Separation with 1-click connexion ******/
        .login_bloc {
            max-width: 873px;
            width: 100%;
            padding: 0 7rem;
            line-height: 1.3;

            &.oneClick {
                .title {
                    margin-top: 0;
                    padding-top: 11px;
                }

                .fb-connect,
                #googleConnect {
                    height: 36px;
                    width: 36px;
                    background-color: transparent;
                }

                .fb-connect {
                    & > div {
                        width: 74%;
                        height: 74%;
                    }
                }
            }

            &:first-of-type {
                padding-bottom: 0;
                border: 0;
            }

            .w-oneclick-connect {
                display: flex;
                flex-direction: row-reverse;
                justify-content: left;

                #googleConnect {
                    background: none;
                    background-color: $greyIcon;
                    height: 36px;
                    width: 36px;
                    margin-left: 0;
                    margin-right: 1.3rem;
                    background-color: transparent;

                    #googleConnectLabel {
                        &::before {
                            position: absolute;
                            width: 68%;
                            height: 66%;
                        }
                    }
                }

                .fb-connect {
                    & > div {
                        width: 74%;
                        height: 74%;
                    }
                }
            }

            /* Account - Titles *******/
            .title {
                text-align: left;

                h3,
                h4 {
                    @include wshopFont($primaryFont, 16px, 29px, .5px, 400, uppercase, none, $primaryColor);
                }

                h4 {
                    margin-bottom: 0;
                }
            }

            &:not(.oneClick) {
                max-width: none;
                width: 873px;

                .wrapper_bandeau_connexion {
                    @include flexbox(row, center, center, unset);
                }

                .login_form_wrapper,
                .login_form_wrapper {
                    padding: 0;
                    max-width: 870px;
                    margin: 0 auto;
                    width: 100%;
                }
            }
        }
    }

    // Form fields
    .optin_container .w-input
    .w-input-element:not([type="radio"]):not([type="checkbox"]),
    input[type="email"].w-input-element,
    input[type="password"].w-input-element,
    input[type="text"].w-input-element,
    textarea.w-input-element {
        border-radius: 0;
    }

    input[type="password"].w-input-element {
        font-family: Arial, Helvetica, sans-serif;
    }

    // Submit btns
    button.w-submit-button,
    button.w-submit-button.w-loader {
        width: 100%;
        background-color: $white;
        border: 2px solid $black;
        color: $black;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 2.25rem;

        span {
            @include wshopFont($primaryFont, 16, 1.5, .5px, 400,uppercase, none, $primaryColor);
        }
    }
    button.w-loader.loading {
        span {
            display: block;
        }
    }


    .signup_module .w-submit-button {
        padding: 0;
    }
}

/******************************************************************************************/
/* MOT DE PASSE OUBLIE *******************************************************************/
.customer .mdpSentEmail,
.newlogin_page .login_bloc .w-lostpass-form .mdpSentEmail {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

body.script_renderpwdforget,
body.pwdforget {
    #wrapper_moncompte {
        .title {
            margin-bottom: 20px;

            span {
                font-size: 25px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                color: $primaryColor;
            }
        }

        .w-password-input:not(.w-has-error) {
            .w-input-element {
                border-bottom: 1px solid $borderColor;
            }
        }

        .w-input-element {
            border-radius: 0;
        }

        .w-input-label span {
            color: $primaryPlaceHolder;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .5px;
        }

        .w-nonempty .w-input-label span {
            font-size: 16px;
        }

        input[type="password"].w-input-element {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 16px;
        }
    }
}


/******************************************************************************************/
/* MON COMTPE PAGES ***********************************************************************/
/******************************************************************************************/
body.customer {
    .page_wrapper {
        min-height: 61vh;
    }

    .accountBannerTop {
        display: none;
    }
}

/******************************************************************************************/
/* ALERT STOCK - MON COMPTE ***********************************************************************/
/******************************************************************************************/
body.customer.customer_stock_alert {

    #breadcrumbs .breadcrumbs .breadcrumb.current span {
        font-weight: 400;
    }

    .wrapper_moncompte {
        padding-bottom: 3rem;
    }

    .account__title {
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    .right-container.wrapper_content_stock_alert {
        display: block;

        @media screen and (max-width: 1237px) {
            max-width: 760px;
            margin: auto;
        }

        .wrapper_form {
            max-width: 1177px;
            margin-top: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 23px;

            @media screen and (max-width: 1237px) {
                grid-template-columns: 1fr 1fr;
            }

            .product-link {
                display: flex;
                width: 355px;

                &:nth-child(even) {
                    @media screen and (max-width: 1237px) {
                        margin-right: 0;
                    }
                }

                .cart_thumb_holder {
                    min-width: 130px;
                    max-width: 130px;
                    padding-top: 4px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .cart_detail_box {
                    padding: 0 0 3px 9px;

                    .cart_product {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        height: 100%;
                        display: grid;
                        grid-template-columns: auto 1fr;
                        grid-template-rows: auto auto auto 1fr;

                        .sub_title {
                            font-weight: 400;
                            line-height: normal;
                            grid-column: 1 / 3;
                            grid-row: 1;

                            // Limit to two lines
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .sstitre {
                            grid-column: 1 / 3;
                            grid-row: 2;

                            // Limit to two lines
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                            &::after {
                                content: '';
                                display: block;
                                height: 2px;
                                width: 16px;
                                background-color: $primaryColor;
                                margin-top: 5px;
                                margin-bottom: 5px
                            }
                        }

                        .wrapper_price {
                            grid-column: 1 / 3;
                            grid-row: 3;
                        }

                        .sub_taille {
                            text-transform: none;
                            display: flex;
                            align-items: flex-end;
                            grid-column: 2;
                            grid-row: 4;
                            margin-left: 2px;
                        }

                        .sub_couleur {
                            font-size: 0; // Hide wording "Couleur :"
                            line-height: 0; // Hide wording "Couleur :"
                            white-space: nowrap;
                            display: flex;
                            align-items: flex-end;
                            grid-column: 1;
                            grid-row: 4;

                            .sub_couleur_name,
                            .form_itm.check.color {
                                font-size: 16px; // Display the color name and color badge
                                line-height: normal; // Display the color name and color badge
                            }

                            .sub_couleur_name {
                                order: -1;
                                text-transform: none;
                            }

                            .form_itm.check.color {
                                order: -2;
                                display: block !important;

                                .prodColor {
                                    display: none;
                                }

                                label {
                                    display: block;
                                    width: 16px;
                                    height: 16px;
                                    border: 1px solid #D1D1CF;
                                    border-radius: 50%;
                                    margin-bottom: 3px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.customer .wrapper_form form.w-form.w-info-form.w-login-form .w-has-tel .errormsgadd,
body.customer .wrapper_form_adresse #adresseForm .w-has-tel .errormsgadd {
    font-size: 16px;
    color: $primaryPlaceHolder;
    display: block;
    transform: translateY(5px);
}

// New adress form
body.customer .wrapper_form_adresse #adresseForm {
    .w-input-element[name='pays'] {
        padding-left: 10px !important;
    }
}

/********************************************/
/* COMMMON **********************************/
/********************************************/
// add padding to prevent borders touch
.wrapper_moncompte {
    padding: 27px clamp(20px, 1.04167vw, 20px) 5rem;

    // Mon compte nav
    .sub_menu_list {
        @extend .primary_navbar;
        max-width: 1120px;
        width: 100%;
        margin: 22px auto 7px auto;
        @include flexbox(row, space-between, center, unset);
        line-height: 0;

        &::before {
            display: none;
        }

        & > div {
            text-transform: none;
        }

        .item {
            padding: .5rem 0;
            font-size: .845rem;

            &:not(:last-child) {
                margin-right: 3rem;
            }

            a {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                display: flex;
                opacity: 0.5;
                white-space: nowrap;

                span.highlight {
                    margin-left: .5rem;
                }
            }


            &:not(:last-child) {
                @media screen and (max-width: 1500px) {
                    margin-right: 3rem;
                }

                @media screen and (max-width: 1150px) {
                    margin-right: 2.5rem;
                }

                @media screen and (max-width: 1050px) {
                    margin-right: 2rem;
                }
            }

            &.actif {
                &::after {
                    display: none;
                }

                a {
                    opacity: 1;
                }
            }
        }

        div.actif {
            padding: 0.5rem 0;
            position: relative;

            a{
                font-weight: 400;
            }

            &::after {
                position: absolute;
                content: "";
                display: block;
                height: 1px;
                width: 100%;
                background-color: $primaryColor;
                left: 0;
                top: 100%;
                z-index: 1;
            }
        }
    }

    .w-nonempty .w-input-label span {
        font-size: 16px;
    }

    .w-input.w-date-input {
        .w-input-label span {
            font-size: 16px;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }

    .w-input.w-dropdown {
        box-shadow: none;
        border-bottom: 1px solid $borderColor;

        .w-has-error {
            border-color: #ff6d6d;
            box-shadow: 0 0 0 2px #ff6d6d;
        }
    }

    .wrapper_content_mes_infos,
    .wrapper_mes_adresses {
        .w-submit {
            width: 251px !important;
            margin-top: 5px;
        }
    }

    .wrapper_content_mes_infos {
        .w-password-input~.w-class-pwd-creation {
            h3:first-of-type {
                margin: 24px 0 4px;
    
                & + ul {
                    gap: 10px;
    
                    & + h3 {
                        margin-bottom: 2px !important;
                        margin-top: 17px !important;
                    }
                }
            }
    
            progress {
                margin-bottom: 0;

                & + p {
                    line-height: normal;
                    margin-top: 0;
                }
            }
        }

        .w-dropdown {
            select {
                padding-left: 10px !important;
            }
        }

        .w-tel-input {
            .country-list {
                .os-scrollbar-vertical {
                    width: 2px;
                    height: 100%;
                }

                .os-scrollbar-handle {
                    background-color: $primaryColor;
                }
            }

            img.valid_form {
                position: absolute;
                top: 50%;
                right: 0;
            }
        }

        input[type=password].w-input-element {
            font-family: Arial, Helvetica, sans-serif;
        }

        &:has(.w-password-input.w-focused) {
            .w-submit {
                margin-top: 15px;
            }
        }
    }

    .w-input-label span {
        color: $primaryPlaceHolder;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: .5px;
    }

    .wrapper_mes_adresses {
        .w-input-label span {
            color: unset;
        }

        .wrapper_form_adresse .choix_type_adresse {
            > div {
                line-height: 10px;
                padding: 0.5rem 0;

                &.active,
                &.actif,
                &.current {
                    &::after {
                        width: calc(100% - 1px);
                    }
                }
            }
        }

        .w-tel-input {
            .country-list {
                .os-scrollbar-vertical {
                    width: 2px;
                    height: 100%;
                }

                .os-scrollbar-handle {
                    background-color: $primaryColor;
                }
            }
        }
    }
}

.nav_moncompte {
    .account_name {
        display: none;
    }

    .title {
        text-align: center;

        span {
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    }
}

.customer,
.newsletter,
.wishlist,
.order {
    // No bandeau
    .right-container {
        display: none;
    }

    .w-radio-input .w-input-element+.w-input-label::before,
    .w-radio-input .w-input-element:checked+.w-input-label::before {
        border-color: $primaryColor;
    }

    .intro {
        font-family: $primaryFont;
        line-height: 1;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
}

/** content_wrapper :
* informations form
* address form
* addresses list
* messages
* newsletter
**/
.customer .wrapper_form,
.customer .wrapper_form_adresse,
.customer .wrapper_mes_adresses,
.customer .wrapper_mes_commandes,
.customer .content_messages,
.newsletter .content_newsletter {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    .w-input.w-tel-input .w-input-element:not([type=radio]):not([type=checkbox]) {
        padding-left: 3.76rem !important;
    }

    @supports (-moz-appearance:none) {
        .w-checkbox-input .w-input-element:checked+.w-input-label::before, .w-radio-input .w-input-element:checked+.w-input-label::before {
            box-shadow: inset 0 0 0 .1em $white;
            aspect-ratio: 1;
        }
    }

    @supports (-webkit-hyphens:none) {
        .w-checkbox-input .w-input-element:checked+.w-input-label::before, .w-radio-input .w-input-element:checked+.w-input-label::before {
            box-shadow: inset 0 0 0 .1em $white;
            aspect-ratio: 1;
        }
    }
}

.customer .wrapper_mes_commandes {
    margin-top: 14px;
}

.wrapper_mes_commandes {
    .wrapper_msg_bounce {
        padding-bottom: 1rem;
        font-size: 12px;

        h3 {
            font-weight: 600;
        }
    }

    .filtres_commandes {
        justify-content: flex-start;
        position: relative;

        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 221px;
            height: 1px;
            background-color: $borderColor;
        }

        & > select[name=year] {
            border: none;
            padding-left: 20px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            background: #fff url(../img/arrow_d.png) no-repeat 100% center;
            margin-right: 19px;
            transform: translateX(-20px);
        }

        .intro {
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }
}

.color_bullet {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $borderColor;
}

.left-container .unsub_txt,
.nav_moncompte .unsub_txt {
    padding-bottom: 1.5rem;
    max-width: 1024px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

/********************************************/
/* MES ADRESSES *****************************/
/********************************************/

.cart.step_2_0.in_tunnel {
    .elem_global {
        margin-bottom: 0.75rem;

        .w-form-line.sameBillAddress {
            margin-bottom: 0;
        }

        .w-form-line.w-has-tel {
            input#telephone {
                padding-left: 4rem !important;
            }
        }
    }

    #tunnel_right_col {
        display: block;

        .wrap_bottom_rightcol {
            display: none;
        }

        .cart_product_line {
            margin-bottom: 1.4rem;
        }

        .encart_3xcb_panier {
            order: 0;
            margin-bottom: 0.9rem;
        }
    }

    .w-input-group.accountCreate {
        .w-input-container{
            label.w-input.w-password-input.w-nonempty {
                input.w-input-element {
                    font-family: revert;
                }
            }
        }
    }

    fieldset.w-input-group {
        legend.w-group-label {
            font-family: $primaryFont;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0.5px;
        }
    }

    #tunnel_right_col {
        #cart_total {
            .total_produit {
                .nb_products::before {
                    right: 0;
                }

                .price {
                    display: none;
                }
            }
        }
    }
}

.wrapper_form_adresse .choix_type_adresse {
    position: relative;
    margin-bottom: 1.34rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div {
        text-transform: uppercase;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: rgba(35, 31, 32, .5);
        position: relative;
        padding: .2rem 0;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 2.5rem;
        }

        &.active,
        &.actif,
        &.current {
            opacity: 1;
            color: $primaryColor;
        }
    }
}

.conteneur_adresse {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 3.125rem;
    column-gap: 25px;
    row-gap: 55px;

    .adresse {
        width: 100%;

        .addr_label {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-bottom: 1rem;

            .addr_titre {
                font-weight: 400;
                padding-right: .5rem;
            }

            .addr_type {
                @extend .insideParentheses;
            }
        }

        .infos {
            font-size: 16px;
            line-height: 1.1;
            display: flex;
            flex-wrap: wrap;

            .addr_line {
                width: 100%;

                &.address,
                &.zipcity {
                    width: auto;
                }

                &.address {
                    padding-right: .5rem;
                }
            }

        }

        .modlinks_wrapper {
            padding-top: .5rem;
            font-size: 16px;

            .modlink {
                text-decoration: underline;

                &:not(:last-of-type) {
                    margin-right: .5rem;
                }
            }
        }
    }
}

.customer {

    .form_submit {
        width: 100%;

        .button {
            display: inline-block;
        }
    }
}

/********************************************/
/* MES COMMANDES ****************************/
/********************************************/

/* Commandes intro + date select **/
.filtres_commandes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.125rem;
}

// Year select - Mes commandes
.filtres_commandes>select[name="year"] {
    cursor: pointer;
    max-width: 15rem;
    width: 100%;
    height: 3rem;
    border: 1px solid $borderColor;
    font-weight: 400;
    font-size: 16px;
    font-family: $primaryFont;
    letter-spacing: 1px;
    padding-left: 16px;
    margin-right: 2rem;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;

    background: #fff url(../img/arrow_d.png) no-repeat 92% center;
    background-size: 13px 6px;
}
.filtres_commandes{
    select[name="year"]{
        &::-ms-expand {
            display: none;
        }
    }
}

/* Commandes table + Messages table **/
.table_mon_compte,
.table_mes_messages {
    width: 100%;

    .header,
    thead {
        border-bottom: 1px solid rgba(204, 204, 204, 0.5);

        >tr {
            height: 30 / 16 * 1rem;
        }
    }

    th,
    thead tr td {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: left;
    }

    tbody tr td {
        padding-top: 1.25rem;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
    }
}

.table_mon_compte {
    .content {
        .link_fact_wrap {
            margin: 0 0 0.7rem;
        }

        .facture {
            a {
                text-decoration: underline;
            }

            .link_fact {
                a + span {
                    padding: 0 0 0 2.5rem;
                    @media screen and (max-width: 1225px) {
                        padding: 0 0 0 1rem;
                    }
                }

                &.refund {
                    span {
                        & + a {
                            padding: 0 0 0 2.5rem;
                            @media screen and (max-width: 1225px) {
                                padding: 0 0 0 1rem;
                            }
                        }
                    }
                }

                &.two_link {
                    a + a {
                        text-decoration: none;
                        padding: 0 0 0 2.5rem;
                        @media screen and (max-width: 1225px) {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
            }
        }

        .remboursement {
            @media screen and (max-width: 1115px) {
                margin-right: 10px;
            }

            .refund {
                text-decoration: underline;
            }
        }

        .last.link_detail {
            text-decoration: underline;
        }
    }
}


/* ORDER DETAILS *****/

.detailCmdTop {
    display: none;
}
// wrappers
// .detailCmdTop,
.wrapper_content_cmd_detail {
    max-width: 1400px;
    width: 100%;
    margin: 24px auto 0;
}
.wrapper_content_cmd_detail {
    display: flex;
    justify-content: center;

    .wrapper_left {
        max-width: 735px;
        width: 100%;
        padding-right: 6.25rem;
    }
    .wrapper_right{
        position: relative;
        max-width: 385px;
        width: 100%;
        padding-left: 3rem;

        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            top: 0;
            right: 100%;
            background-color: $borderColor;
        }
    }
}
// Order detail head
body.order {
    .order_detail_head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;

        // Head blocs
        .wrapper_delivery_addr,
        .wrapper_infos_cmd {
            // Width = Product link img width + desc width = 361px
            max-width: 261px;
            width: 100%;
            margin-right: 3rem;

            > * {
                padding-bottom: .2rem;
                font-size: 16px;
            }
        }
        // Heads - font-sizing
        .cmd_txt,
        .addr_title {
            font-size: 16px;
        }

        .cmd_number,
        .addr_company {
            font-size: 16px;
        }
        // Heads - font styling
        .cmd_txt,
        .cmd_number,
        .addr_title {
            font-weight: 400;
            padding-bottom: .5rem;
        }
        .cmd_txt,
        .cmd_number {
            display: inline-block;
            width: auto;
        }

        .cmd_date,
        .cmd_status {
            font-weight: 400;

            span {
                font-weight: normal;
            }
        }
        .cmd_date {
            text-transform: capitalize;
        }

        // Delivery addr2
        .addr_title_2 {
            display: none;
        }

        .cmd_date,
        .cmd_status,
        .addr_name,
        .addr_address,
        .addr_zipcity,
        .num_suivi {
            font-size: 16px;
        }

        .num_suivi {
            font-weight: 400;

            a span {
                font-weight: 400;
            }
        }
    }

    // Order product details
    .cmd_items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .product-link {
            display: flex;
            margin: 0 3rem 3rem 0;
        }
        .cart_thumb_holder {
            max-width: 131px;

            img {
                width: 100%;
                height: auto;
            }
        }
        .cart_detail_box {
            max-width: 130px;
            width: 100%;
            padding-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .cart_product {
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;

            > * {
                &:not(.sub_taille),
                &:not(.sub_couleur) {
                    width: 100%;
                }
                &:not(.wrapper_price),
                &:not(.sub_title) {
                    padding-bottom: .5rem;
                }
            }

            .sub_taille,
            .sub_couleur {
                width: auto;
            }
            .sub_qte {
                display: flex;
                order: 6;
            }
        }
        .sub_title {
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            order: 1;
        }
        .sstitre {
            order: 2;
        }
        .wrapper_price {
            padding-top: .5rem ;
            order: 5;
        }
        .sub_taille {
            order: 4;
            padding-top: .15rem;
        }
        .sub_couleur {
            order: 3;
            position: relative;
            margin-right: 1rem;
            margin-top: 2px;


            .productReturnWrapper {
                &.none {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            .selectWrapper.js-select-motif {
                &.none {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }

    #return_products_form {
        .error_retours {
            font-size: 16px;
            display: none;
            margin: 10px 0;

            &.actif {
                display: block;
            }
        }

        .lb-v-scrollbar,
        .lb-v-scrollbar-slider {
            width: 2px !important;
        }
    }

    // Right part with order totals
    .cmd_detail_btm {
        .titre {
            font-weight: 400;
            padding-bottom: 1rem;

            span {
                font-weight: normal;
                font-size: 16px;
            }
            .error_retour_back {
                margin-bottom: 20px;
            }
        }

        .clearfix {
            font-size: 16px;
            display: flex;
            justify-content: space-between;

            &:not(.wrapper_bottom_cmd) {
                margin-bottom: .5rem;
            }

            &.wrapper_bottom_cmd {
                #comment_retourner_cmd {
                    margin-bottom: 2rem;

                    .content {
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .montant_total.montants {
                font-weight: 400;
            }
        }
    }

    #comment_retourner_cmd {
        p {
            &.intro {
                line-height: 20px;
                margin-bottom: 1rem;
            }

            &.links_comment_retourner_cmd {
                a {
                    letter-spacing: initial;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        #liste_motif_retour {
            margin-bottom: .5rem;
        }

        #motif_retour_etape {
            margin-top: 2rem;

            .print-return {
                padding-bottom: .5rem;
            }

            #btnPrintPdf,
            .btn_container {
                width: 100% !important;

                &.two {
                    margin-bottom: .5rem;
                }
            }

            .button.dark {
                letter-spacing: 0;
            }

            .retour_numero {
                font-weight: 400;
                letter-spacing: .5px;
                text-align: center;
                margin: 1rem 0;
            }

            .colis,
            .adresse {
                line-height: 20px;
            }

            .colis {
                margin: 1rem 0 .5rem;
            }
        }
    }
}


/********************************************/
/* MES MESSAGES *****************************/
/********************************************/
// btw, Messages table handled with Commandes table

.content_messages {

    .intro,
    .form_submit {
        display: inline-block;
    }

    .intro {
        padding-right: 2rem;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;

        @media only screen and (max-width:1346px) and (min-width:1020px) {
            padding-right: 1rem;
        }
    }

    .form_submit {
        margin: 1rem 0 3.125rem;
        width: auto;
        min-width: 275px;

        @media only screen and (max-width:1346px) and (min-width:1271px) {
            min-width: 200px;
        }

        @media only screen and (max-width:1270px) and (min-width:1020px) {
            width: auto;
            min-width: 210px;
        }
    }

    .btn_ouvrir_message {
        text-decoration: underline;
        cursor: pointer;
    }

    .read {
        color: $darkGrey;
    }
}

// Mark separation when message displayed
.message_account_container {
    &.appear {
        .wrapper_message_detail {
            border-bottom: 1px solid $borderColor;
        }
    }
}

.details_msg_content {
    padding: 0 2rem;

    p {
        padding-bottom: .5rem;

    }

    .infos_message p label {
        text-transform: capitalize;
    }

    .detail_message .detail_msg_label label {
        text-transform: capitalize;
    }

    .message_details_holder {
        padding: 1rem 2rem;
    }
}


.customer .table_mes_messages tbody tr td,
.customer .table_mon_compte tbody tr td {
    text-align: left;
}

/********************************************/
/* NEWSLETTER *******************************/
/********************************************/
.newsletter  {
    .unsub_txt {
        margin-top: 15px;
        padding-bottom: 20px;
        max-width: 1120px;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;

        b {
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .w-email-input {
        max-width: 500px;
    }

    .w-form-line {
        margin-bottom: 33px;

        &.optin-select-line {
            margin-bottom: 17px;

            label.w-input.w-checkbox-input {
                height: auto;

                p.w-input-label {
                    align-items: baseline;

                    &::before {
                        border-color: #B1B1B1;
                        transform: translateY(3px);
                    }

                    .multiline {
                        strong {
                            color: $primaryColor;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;

                            &+span {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                letter-spacing: 0.5px;
                                color: $primaryColor;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        &.w-submit {
            margin-bottom: 0;
            margin-top: 28px;
            max-width: 271px;
        }
    }
}

/********************************************/
/* WISHLIST *********************************/
/********************************************/

.wishlist_container {
    display: flex;
    flex-direction: column;
    margin-top: 11px;

    .w-right-bloc {
        order: -1;

        .wrapper_btn_add {
            .input_container {
                #bloc_btn_loader {
                    top: 19px;
                    left: 4px;
                    max-width: 350px;
                    height: 42px;
                    width:100%;

                    @media only screen and (max-width:1024px) {
                       left: 0;
                       max-width: 330px;
                    }
                }
            }

            .form_submit {
                display: flex;
                justify-content: center;
                width: 37%;
            }
        }
    }

    .bloc_price {
        display: flex;
    }

    .userTooltip {
        top: -22px;
        left: 44%;
        transform: translateX(-50%);
        padding: 0;
        min-width: unset;
        border: none;

        &::before {
            display: none;
        }

        span {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }
    }
}

body.wishlist {

    .disconnected {
        .nav_moncompte {
            .title {
                margin-bottom: 77px;
            }
        }
    }

    /** Wishlist Displaying *****/
    .wish_liste_product {
        max-width: 1225px;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 29px;
        row-gap: 20px;

        @media screen and (max-width: 1270px) {
            max-width: 814px;
            grid-template-columns: 1fr 1fr;
        }
    }

    .wrapper_btn_add {
        position: relative;
        display: flex;
        padding: 1.25rem 0;

        .loader {
            position: absolute;
            top: 0;
            left: 0;
        }

        .form_submit:not(:last-of-type) {
            padding-right: 1.25rem;
        }
    }

    /** Product Displaying *****/
    .product_ctn {
        width: 388px;
        position: relative;

        &:nth-child(even) {
            @media screen and (max-width: 1216px) {
                margin-right: 0;
            }
        }

        .remove {
            position: absolute;
            top: 2px;
            right: -0.199rem;
            font-size: 0;
            width: 1.2rem;
            height: 1.2rem;
            background-image: url(../svg/spritesheet.svg);
            background-size: 450% auto;
            background-position: 100% 101.5%;
            border: none;
            z-index: 10;
            cursor: pointer;
        }

        .wrapper_product {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 5px;

            .product_desc {
                height: 163px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title span,
                .subtitle {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    width: calc(100% - 13px);

                    /* Limit to two lines */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .subtitle {
                    &::after {
                        content: '';
                        display: block;
                        height: 2px;
                        width: 16px;
                        background-color: $primaryColor;
                        margin-top: 2px;
                        margin-bottom: 8px;
                    }
                }

                .price span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                }

                .wrapper_details {
                    .product_color {
                        padding-bottom: 0;
                        margin-right: 8px;

                        &::after {
                            display: none;
                        }

                        .color_bullet {
                            margin-right: 3px;
                        }
                    }

                    .product_size {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }
            }

            .photo_product .wrapper_img {
                width: 130px;
                padding-top: 4px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .wishToBasket,
        .mail_share {
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-decoration-line: underline;
            cursor: pointer;
        }

        .share_wl.cta {
            margin-top: 8px;
        }

        /* Product Details ****/
        .product_desc_bottom {
            font-size: 16px;
            justify-self: flex-end;

            .wrapper_details {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 0 5px 0;
            }
        }

        .report.error.no_stock {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .product_color {
            position: relative;
            margin-right: 1rem;
            padding-bottom: .5rem;

            .color_bullet:hover+.userTooltip {
                opacity: 1;
            }

            .color_bullet+.userTooltip {
                display: none;
            }

            &::after {
                position: absolute;
                content: "";
                display: block;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primaryColor;
            }
        }

        .photo_product {
            img {
                width: 100%;
                height: auto;
            }
        }

        .product_desc {
            padding-left: 11px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        // cta-wrapper ralative to .wrapper_product
        .cta-wrapper {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 0 2rem;
            display: flex;
            justify-content: space-between;

            .cta {
                font-size: .75rem;
                padding: .5rem 0;
                text-decoration: underline;
            }
        }
    }

    .loader_container {
        max-width: 1225px;
        margin: 23px auto 0;
        position: relative;

        @media screen and (max-width: 1270px) {
            max-width: 814px;
        }

        .button {
            width: 388px;
            max-width: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            border: 1px solid $primaryColor;
        }

        .loader {
            max-width: none;
            width: 388px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    #sendwishlistbox {
        .w-textarea {
            padding-top: 21px;

            textarea {
                padding-top: 0 !important;

                &.w-input-element+.w-input-label {
                    transform: translateY(92%);
                }
            }

            &.w-nonempty .w-input-element:not([type=radio]):not([type=checkbox]) + .w-input-label,
            &.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]) + .w-input-label {
                transform: translateY(-11%);
            }
        }

        .w-checkbox-input .w-input-element + .w-input-label {
            font-size: 16px;
        }
    }
}

.wrapper_message {
    .w-reply-form {

        .ajout_pj_label {
            border: 2px solid $primaryColor;
            height: 2rem;
            min-width: 13rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            letter-spacing: .5px;
            font-size: 0.85rem;
            cursor: pointer;

            &:before {
                content: '+';
                font-size: 1.3rem;
                margin-right: 0.5rem;
                margin-bottom: 0.35rem;
            }
        }

        #filepj1 {
            display: none;
        }
    }
    .w-reply-form {
        .ajout_pj_label {
            padding: 0.15rem 1rem 0;
        }

        .file-list {
            margin: .5rem 0;
            padding: .2rem 0;

            .rowList {
                display: flex;
                align-items: center;
            }

            .uploadedFile {
                background-color: rgba($primaryColor, $alpha: .1);
                display: block;
                max-width: 250px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding: 0.2rem 0.5rem;
            }

            .closePj {
                @extend .bgSprite;
                @extend .cross;
                width: 20px;
                height: 20px;
                background-color: white;
            }
        }
    }
}

/********************************************/
/* PASSWORD RESET ***************************/
/********************************************/
.wrapper_password {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    .cache {
        display: none;
    }


    .content_input {
        margin-bottom: 2rem;

        input {
            width: 100%;
        }
    }

    .w-newpass-form {
        .w-password-input~.w-class-pwd-creation h3:first-of-type {
            margin: 24px 0 4px;

            & + ul {
                gap: 10px;

                & + h3 {
                    margin-bottom: 0.35rem;
                }
            }
        }

        progress {
            margin-bottom: 0.25rem;

            & + p {
                line-height: normal;
            }
        }

        .w-form-line.w-submit {
            margin-top: 35px;
        }
    }
}

.w-info-form {
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;

    .w-tel-input {
        #iconErrclientTel {
            opacity: 1;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    .mail-pass-1,
    .mail-pass-2 {
        width: calc((100% - 1rem) / 2);
        flex-direction: column;

        .w-input-container + .w-input-container {
            margin-left: 0;
            margin-top: 1.875rem;
        }

        .w-class-pwd-creation {
            ul {
                gap: 11px;

                & + h3 {
                    margin-top: 1.25rem !important;
                    margin-bottom: 0 !important;
                }
            }

            p {
                margin-top: 10px;
            }
        }
    }

    .mail-pass-1 {
        margin-right: 1rem;
        margin-bottom: 20px;
    }
}

.update_success {
    font-size: 16px;
    color: #bbb;
    font-weight: 400;
    margin-bottom: 1rem;
}

.login_bloc .w-client-form {
    align-items: flex-start;
    margin-bottom: .13rem;
}

.account__title {
    display: none;
}

.body_login .wrapper_bandeau_connexion {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: none !important;
}

.w-address-form {
    .w-input.w-text-input.w-nonempty {
        position: relative;
    }

    .valid_form,
    .img_error {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: .5rem;
    }

    .valid_form {
        display: none;
    }
}

.pwd_forget_title.uppercase_title {
    font-size: 3.75rem;
    letter-spacing: .5px;
    font-weight: 900;
    text-align: center;
    padding-bottom: 2.5rem;
}

#clientForm {
    .error_password {
        color: $errorColor;
        font-size: .875rem;
        margin-top: .5rem;
    }

    #btn_pwd {
        margin-top: 2rem;
    }
}

.wrapper_password .btn_container .button.light {
    height: 3rem;
    width: 250px;
    border: 1px solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    transition: background-color .3s ease;

    &:hover {
        background-color: $white;

        span {
            color: $black;
        }
    }

    span {
        text-transform: uppercase;
        font-size: .75rem;
        color: $white;
        letter-spacing: .4px;
    }
}

.wrapper_tunnel {
    #outsite_ue_notice {
        max-width: 1120px;
        width: 100%;
        margin: 0 auto 2rem;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
        text-align: left;
    }
}

body.bandeauDelivery {
    .w-form-line.w-has-tel {
        display: flex;
        align-items: flex-start;
    }

    .errormsgadd {
        color: #888c8d;
        font-size: 16px;
        font-weight: 400;
    }
}

/********************************************/
/* VENTES PRIVILEGES ************************/
/********************************************/
body.customer.body_login {
    .newlogin_page.vp {
        flex-direction: row;
        padding: 28px 11.2%;
        min-height: 400px;
        margin: 0;

        @media screen and (max-width: 1200px) {
            padding: 20px 5%;
        }

        // Rule if no image is present, to center the page
        &:not(:has(.wrapper_bandeau_connexion)) {
            justify-content: center;
        }

        .w-input-note.w-input-error {
            margin: 0;
        }

        .login_blocs_wrapper {
            @media screen and (max-width: 1200px) {
                margin-left: 0;
            }

            .login_bloc{
                .title.my_account {
                    margin: 0 0 .45rem;
                }

                .login_form_wrapper {
                    padding: 0;

                    h3 {
                        margin-top: 14px;
                    }
                }
            }
        }
    }
}