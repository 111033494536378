.home-module.cover-module {
    position: relative;
    max-height: 960px;
    height: 100%;
    padding: 0;
    border-bottom: none !important;
    margin-bottom: 8px;

    @media screen and (max-width: 1138px) {
        width: 100%;
    }

    .home-module-cta {
        line-height: 1.9;
        margin: 0px;
        border-bottom: 2px solid $black;

        span {
            display: inline;
            font-size: 1.5rem;
        }
    }

    .home-module-cta:hover span {
        color: inherit;
    }

    img.home-module-bg {
        width: 100%;
        height: auto;
    }

    .boxText {
        position: absolute;
        top: 48%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        text-align: center;
    }

    h3.home-module-subtitle {
        @extend .home-module-title; 
        margin-bottom: 20px;
    }

    .home-module-body {
        position: relative;

        .slide_video {
            overflow: hidden;
            padding-top: 50%;
            position: relative;
            margin-bottom: 3px;
            
            .block_redirect {
                position: absolute; 
                height: 100%; 
                width: 100%; 
                z-index: 2;
                top: 0;
            }

            iframe {                
                border: 0;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;

                @include queriesWshop(1540px, max) {
                    top: 2px;
                }
            }

            + img {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
        }
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }
}

.home-module.cover-module .home-columns-container #coverSwiper .swiper-wrapper .swiper-slide .slide_video:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.swiper-cover-button {
    @extend .swiper_arrow; 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    margin: .5rem 0;
    display: none;

    &.swiper-cover-button-prev {
        @extend .arrowLeft;
        left: 2rem;
    }

    &.swiper-cover-button-next {
        @extend .arrowRight;
        right: 2rem;
    }
}