/***** SIMPLE CTA W/ TEXT + UNDERLINE *****/
/* @extend .cta_underlined */
.cta_underlined {
    padding: 0;
    padding-bottom: .2rem;
    @include wshopFont($primaryFont, .75rem, unset, .5px, 900, none, unset, $primaryColor);
    background-color: unset;
    border: none;
    border-bottom: 1px solid $primaryColor;

    &:hover, 
    &:hover span {
        background-color: unset;
        color: $primaryColor;
    }
}

/***** CTA CONTAINING SPAN W/ TEXT + UNDERLINE *****/
/* @extend .cta_span_underlined */

.cta_span_underlined {
    background-color: unset;
    border: none;

    span {
        @extend .cta_underlined
    }
}

.rodierButton {
    display: inline-block;
    cursor: pointer;
    max-width: 350px;
    width: 100%;
    padding: .5rem .5rem;
    color: $primaryColor;
    text-align: center;
    background-color: $white;
    border: 1px solid $black;
    transition: all .2s ease-out;
    @include wshopFont($primaryFont, 16px, 1.5, .5px, 400, uppercase, none, $primaryColor);
    
    &:hover {
        background-color: $black;
        color: $white;
        
        span {
            color: white;
        }
    }
}

.rodierButtonActive {
    display: inline-block;
    cursor: pointer;
    max-width: 350px;
    width: 100%;
    padding: .5rem 2rem;
    text-align: center;
    border: 1px solid $black;
    transition: all .2s ease-out;
    @include wshopFont($primaryFont, 16px, 1.5, .5px, 400,uppercase, none, $primaryColor);
    color: $white;
    background-color: $black;
}

.btnAddBasket,
.w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit .w-submit-button,
.w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit button.w-submit-button,
.button.primary,
.button.secondary,
.button.dark {
    @extend .rodierButton;
}

.loader,
.w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit button.w-submit-button.w-loader {
    @extend .rodierButtonActive;
}

.btnAddBasket.error {
    color: $errorColor;
    border-color: $errorColor;
    &:hover {
        background-color: $errorColor;
        color: $white;
    }
}

.loader.loading,
button.w-loader.loading,
.w-loader.loading {
    opacity: 1;
    z-index: 1;
    span {
        display: block;
    }
}

.btn_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row: 1fr;

    .button, 
    .loader {
        grid-column: 1;
        grid-row: 1;
    }

    .loader {
        transition: .2s ease-out;
    }

    &.loading .loader {
        opacity: 1;
        z-index: 1;
    }
}

.w-form-line.w-submit {
    .w-submit-button,
    .w-submit-button.w-loader {
        height: 100%;
        padding: .5rem 2rem;
    }

    .w-submit-button {
        transition: all .2s ease-out;
    }
}

.w-form:not(.w-newsletter-form):not(.w-coupon-form) .w-form-line.w-submit button.w-submit-button.w-loader,
.w-form-line.w-submit button.w-submit-button.w-loader,
.signup_module form.w-newsletter-form .w-form-line.w-submit .w-submit-button.w-loader {
    background-size: 12%;
    background-image: url(../svg/three_dots_w.svg);
    background-color: $primaryColor;

    span {
        display: none;
    }
}

.loader {
    opacity: 0;
    z-index: -1;
    height: 100%;
    @include flexbox(column, center, center, unset);
    background-size: 12%;
    background-image: url(../svg/three_dots_w.svg);
    background-color: $primaryColor;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
}