.swiper-slide .swiper-lazy-preloader {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	transform: none;
	animation: none;
	z-index: 0;
	background: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;

	&:after {
		background-image: url(../svg/cloud.svg);
		background-repeat: no-repeat;
		background-size: 15%;
		background-position: center;
	}
}

//Ajout d'un loader personalisé le temps du lazy load
.ill_img {
	&:before{
		content:'';
		background-size: 100%;
		opacity: .5;
		position: absolute;
		width: 10%;
		height: 10%;
		top: 50%;
		left: 45%;
	}
}

//Masquage du loader pour les pages spécifiques
.cart_product_line,
.cover-module,
.image-image-text,
.insideImg:nth-of-type(2){
	.imgLoaded, .watched{
		&:before{
			background: none !important;
		}
	}
}