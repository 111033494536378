.product_galery_container {
    position: relative;
    width: 53%;
    max-width: 1007px;

    @media screen and (max-width: 900px) {
        width: 60%;
    }

    .eclat_rect_img {
        width: 28px;
        height: 137px;
        top: 39px;
    }

    .swiper-slide.has_video {
        overflow-y: hidden;
        width: calc(50% - 3.5px);
        height: 0;
        padding-bottom: 59.5%;
        align-self: baseline;

        .block_vid {
            position: relative;
            height: 0;
            padding-bottom: 124.5%;
            width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    .caract_container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 4.5rem;
        right: 0;
        transform: translateX(-50%);
        z-index: 15;

        >img.eclat_caracteristique {
            width: 80px;
            height: 80px;
            right: 21px;
        }

        >.eclat_caracteristique:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

.product_galery_list {
    position: relative;
    @include flexbox(row, flex-start, center, wrap);
    column-gap: 7px;
    margin-top: 18px;

    @media (max-width: 1450px) {
        max-width: 731px;
    }

    .product_galery_item:not(:first-child) {
        width: calc(50% - 3.5px);
        padding-bottom: 60.25%;

        &:first-child {
            @media (max-width: 1450px) {
                padding-bottom: 121%;
            }
        }

        &.galery_text {
            padding-bottom: 0;
            padding-top: 0;
            height: 100%;
        }
    }

    .product_galery_itemnth:nth-child(2) {
        padding-bottom: calc(60% - 1px);
    }
}

.product_galery_item {
    position: relative;
    width: 100%;
    padding-bottom: 120.6%;

    @media screen and (max-width: 1600px) {
        padding-bottom: 120.8%;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff url(../svg/three_dots.svg) no-repeat center;
        background-size: 20% auto;
        opacity: 1;
        z-index: 0;
    }

    &.galery_text:after {
        display: none;
    }

    img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        cursor: url(../img/zoom_cursor.png), auto;
        vertical-align: bottom;
    }
}

body.product_page {

    // Hide image loader on zoombox only
    #zoom_box_wrapper {
        .product_galery_item::after {
            display: none;
        }
    }

    // Make loader smaller on the first image (large)
    .product_galery_item:first-child {
        &:after {
            background-size: 10%;
        }
    }
}

.galery_text {
    padding: 2rem;
    max-height: 560px;

    @media screen and (max-width: 1366px) {
        padding: 1rem;
        line-height: 0.9rem;
    }

    @media screen and (max-width: 1024px) {
        padding: 1rem;
    }

    .full_description {
        max-width: 300px;
        margin: 0 auto;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        a {
            text-decoration: underline;
        }

        @media screen and (max-width: 1600px) {
            line-height: 1rem;
        }

        @media screen and (max-width: 1300px) {
            font-size: 80%;
            line-height: 0.9rem;
        }

        @media screen and (max-width: 1125px) {
            font-size: .75rem;
            line-height: 0.7rem;
        }
    }
}