.home-module.image-image-image {
    padding: 0;

    .slide_video {
        display: flex;
        width: 100%;
        height: 0;
        padding-bottom: 125%;

        iframe {
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%!important;
            z-index: 1;
        }

        .block_redirect {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
            top: 0;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
}
.home-module.image-image-image .insideImg {
    margin: 1rem auto 0;
    @include flexbox(row, space-between, unset, unset);

    .home-column {
        position: relative;
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        img {
            width: 100%;
            height: auto;
        }

        .wrapper_img {
            position: relative;
            width: 60%;
            padding-top: 5.3rem;
            transition: all .3s ease-in-out;

            @media screen and (max-width: 1600px) {
                padding-top: 3rem;
            }

            @media screen and (max-width: 1390px) {
                padding-top: 2rem;
            }

            @media screen and (max-width: 960px) {
                padding-top: 1.3rem;
            }

            &:after {
                content: attr(data-title);
                display: block;
                opacity: 0;
                text-align: center;
                transition: all .3s ease-in-out;
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                width: 100%;
                text-decoration: underline;
            }
        }

        .wrapper_img:hover {
            width: 74%;
            padding-top: 0;

            &:after {
                opacity: 1;
                margin-top: 1rem;
                transition: all .3s ease-in-out;
            }
        }
    }

    /* title + cta visible on image hover ***/
    .holder_cta {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        p{
            color: $black;
        }
    }

}
.cms_page .home-module.image-image-image .insideImg {
    .home-column {
        width: auto;

        &:nth-child(2) {
            margin: 0 30px;
        }
    }
}