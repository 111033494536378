input[type=email] ,
input[type=password] ,
input[type=text] ,
textarea,
input {
    outline: 0;
    padding: 1rem;
    border: 1px solid $borderColor;
    border-radius: 0;
            
    &:focus {
        border: 1px solid $primaryColor;
    }
            
    @include wshopFont($primaryFont, 14px, 21px, unset, 400, unset, none, $primaryColor);

    &::placeholder {
        @include wshopFont($primaryFont, 14px, 21px, unset, 400, unset, none, $primaryPlaceHolder);
    }
}

[type="checkbox"]:checked+label::before,
[type="checkbox"]:not(:checked)+label::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid $borderColor;
    background: #fff;
    margin: 0 .5rem 0 0;
    border-radius: 0;
}

[type="checkbox"]:checked+label::after,
[type="checkbox"]:not(:checked)+label::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 2px;
    background: $black;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
}

[type="checkbox"]:not(:checked)+label::after {
    opacity: 0;
}

[type="checkbox"]:checked+label::after {
    opacity: 1;
}

#cookiesLightbox {
    font-family: $primaryFont;
    background-color: $primaryColor;

    .cookiesLightbox_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
    }

    .cookiesLightbox_accept_btn {
        grid-column: 1 / span 2;
        grid-row: 5;
        padding-top: 10px;

        #js_cookie_accept {
            width: 100%;
            height: 40px;
            background-color: $primaryColor;
            border: 1px solid $white;
            align-self: end;

            &:not(:hover) {
                background-color: $primaryColor;
                color: $white;
            }

            &:hover {
                background-color: $white;
    
                span {
                    color: $primaryColor;
                }
            }

            span {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        button.w-loader {
            width: 100%;
            height: 40px;
            top: unset;
            bottom: 0;
            background-color: $white;
            color: transparent;
            border: 1px solid $white;
        }
    }

    .cookiesLightbox_refuse_btn {
        grid-column: 1 / span 2;
        grid-row: 4;
        padding-top: 1.9rem;
        margin-left: 0;

        #js_cookie_refuse {
            width: 100%;
            height: 40px;
            background-color: $primaryColor;
            border: 1px solid $white;
            align-self: end;

            &:not(:hover) {
                background-color: $primaryColor;
                color: $white;
            }

            &:hover {
                background-color: $white;
    
                span {
                    color: $primaryColor;
                }
            }

            span {
                color: $white;
                font-size: 16px;
                font-weight: 400;
            }
        }

        button.w-loader {
            width: 100%;
            height: 40px;
            top: unset;
            bottom: 0;
            background-color: $primaryColor;
            color: transparent;
            border: 1px solid $white;

            span {
                display: none;
            }
        }
    }

    .cookiesLightbox_content {
        grid-column: 1 / span 2;
        grid-row: 1;
        padding-bottom: 0.3rem;
        line-height: 21px;
        margin-bottom: .8rem;
        color: $white;
    }

    .cookiesLightbox_custom_btn {
        grid-column: 1 / span 2;
        grid-row: 2;
        margin: 0;

        .w-submit-button.w-loader.loading {
            display: none;
        }

        .toggleCookiePrefs,
        .toggleCookiePrefs.opened {
            background-color: $primaryColor;
            border: none;
            margin-top: 0;
            height: 23px;
            padding: 0;

            span {
                color: $white;
                font-family: $primaryFont;
                font-size: 16px;
                text-decoration: underline;
                text-align: left;
                display: block;
                text-transform: none;
                font-weight: 400;
                letter-spacing: .5px;
            }

            &:after {
                margin: 0 0 0 .63rem;
                border-color: $white;
            }
        }

        .toggleCookiePrefs.opened::after {
            display: none;
        }

        button.w-loader {
            width: 100%;
            height: 40px;
            top: unset;
            bottom: 0;
            background-color: $primaryColor;
            color: transparent;
            border: 1px solid $white;

            span {
                display: none;
            }
        }
    }

    .cookiesLightbox_preferences {
        grid-column: 1 / span 2;
        grid-row: 3;
        width: 100%;

        .toggleCookieBloc {
            border-width: 0 2px 2px 0;
        }

        .cookiesInfos {
            .cookieBlocInfo {
                color: $white;
            }
        }
    }

    .w-submit-button {
        font-weight: 900;
        border: 1px solid $primaryColor;
    }

    h3 {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    a {
        color: $white;
    }

    ul {
        li {
            h4 {
                color: $white;
            }

            .toggleCookieBloc {
                border-color: $white;
            }
        }

        #cookiePref02,
        #cookiePref03 {
            .icon {
                position: relative;
                top: -0.1rem;
                display: inline-block !important;
                background-color: $white !important;
                margin-left: 0 !important;
            }
        }
    }
}

.w-password-input {
    & ~ .w-class-pwd-creation {
        gap: 6px;

        ul {
            gap: 6px;

            .w-pwd-req {
                display: flex;
                align-items: center;
                gap: 10px;

                &::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                }

                &.w-pwd-req-error {
                    &::before {
                        content: '';
                        width: 12px;
                        height: 12px;
                    }
                }

                &#capital {
                    order: -1;
                }
            }

            & + h3 {
                margin-top: 0.8rem !important;
            }
        }

        progress {
            --bar-color: #231F20;
            width: 100%;
            height: 6px;
            appearance: none;
            background-color: $primaryColor;
            border: none;
            border-radius: 5px;
            overflow: hidden;

            &.strength-0,
            &.strength-1,
            &.strength-2,
            &.strength-3,
            &.strength-4,
            &.strength-5 {
               --bar-color: #231F20;
            }

            &::-webkit-progress-bar {
                background-color: $lightGrey;
            }

            &::-webkit-progress-value {
                background-color: var(--bar-color);
                border-radius: 5px;
            }

            &::-moz-progress-bar {
                background-color: var(--bar-color);
                border-radius: 5px;
            }
        }
    }

    &.w-focused {
        & ~ .w-class-pwd-creation {
            opacity: 1;
            visibility: visible;
            display: flex !important;
        }
    }
}

.w-visibility-toggle {
    width: auto;
    padding: 0;
    text-align: right;
    background: transparent !important;
}