@import '../../_app/_src/scss/desktop/mixins/typeFace';

$helveticaneueltstd: (
    normal: (light,regular,semibold,bold,heavy)
);

@include typeFace('helveticaneueltstd', $helveticaneueltstd);

/***** From views/main.php ****/
$primaryFont: 'futura-pt', sans-serif;
$secondaryFont: 'helveticaneueltstd', sans-serif;

$price_font_size_desktop: 15px;


/**
** TODO
**
** Create font level-classes for repetitive style format
** ex: titles -> lvl1 : FS 3.75rem / lvl2 : FS 1.6rem
**/