$navWidth: 250px;                               /* Left nav bar width */
$mainWidth: calc(100% - (#{$navWidth} - 1px));          /* Main content width */
$headerHeight: 3rem;                            /* Header height and main padding top */
$headerHeightSmall: 5rem;

$arrowSize: .875rem;

$mainPadding: 9.375rem;
$mainPaddingMedium: 2.5rem;

$menuRightWidth: 422px;


