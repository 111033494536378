.home-module.slider-module {
    padding: 2rem $mainPadding;

    @include medium {
        padding: 2rem $mainPaddingMedium;
    }

    .swiper-container{
        &:first-child:last-child(n + 3),
        &:last-child(n + 3) ~ *{
            left: 0;
        }
    }
}

/***** SWIPER *****/
.home-module.slider-module {
    position: relative;
}

/* Swiper navigation */
.home-gondole-button {
    @extend .swiper_arrow; 
    position: absolute;
    top: 40%;

    &.home-gondole-button-prev {
        @extend .arrowRight; 
        right: calc(#{$mainPadding} - 3rem);

        @include medium {
            right: calc(#{$mainPaddingMedium} - 1.5rem);
        }
    }
    
    &.home-gondole-button-next { 
        @extend .arrowLeft;    
        left: calc(#{$mainPadding} - 3rem);

        @include medium {
            left: calc(#{$mainPaddingMedium} - 1.5rem);
        }
    }

    &.swiper-button-disabled{
        opacity: 0;
    }
}
