.reassurance_wrapper {
    border-bottom: 1px solid $borderColor;
    border-top: 1px solid $borderColor;

    ul {
        height: 100%;
        @include flexbox(row, space-between, unset, unset); 
    }

    li {
        width: 33.5%;
        height: 100%;
        padding: 2rem 0;

        &:not(:first-child) {
            border-left: 1px solid $borderColor;
        }

        &:nth-child(4) {
            display: none;
        }

        div {
            width: fit-content;
            margin: 0 auto;
            text-align: left;
            line-height: 1.25;
        }

        strong {
            font-weight: 900;
        }

        p {
            font-size: .75rem;
            font-weight: 200;
        }
    }
}
