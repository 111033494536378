.store_locator {
    .main_wrapper {
        margin-bottom: 6rem;
    }

    #abox {
        .wrap_btn_abox {
            display: none;
        }
    }

    .page_upper {
        max-width: 53%;
        margin: 27px auto 0px auto;

        .page_upper_text {
            padding: 0;

            .page_upper_title {
                padding-bottom: 26px;
                text-align: center;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
        }
    }

    input[type="text"]#search_cp {
        width: 100%;
        padding: 0;
        padding: .6rem 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;

        &::placeholder {
            font-family: $primaryFont;
            font-size: 16px;
        }

        &:focus {
            border-bottom: 1px solid $borderColor;
        }
    }

    div.ou {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin: 20px 0 20px 0;
    }

    .button.primary {
        max-width: 335px;
        @include wshopFont($primaryFont, 16px, normal, 0.5px, 400, uppercase, none, $primaryColor);
    }

    /** MAP + RESULTS ********/
    .wrapper_retailers_map {
        padding: 59px clamp(20px, 1.04167vw, 20px) 0;
        width: 100%;
        display: flex;
    }

    .store_loc_left .holder_imgs {
        margin-top: 7px;
    }

    /* MAP  ********/
    #retailers_map {
        height: 550px;
    }

    #retailers_map,
    .store_loc_left {
        max-width: 890px;
        width: 100%;
    }

    /* RESULTS *******/
    .liste_recherche_distributeur {
        padding: 1.8rem 0 0 6.8rem;
        min-width: 550px;

        @media screen and (max-width: 1700px) {
            padding: 1.8rem 0 0 2rem;
        }

        .primary_navbar {
            padding-right: 10px;

            .navbar_tab{
                margin-right: 28px;
                padding-right: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                opacity: 0.5;
    
                @include medium {
                    margin-right: 15px;
                    padding-right: 0;
                }
    
                &.active{
                    opacity: 1;
    
                    &::after {
                        display: none;
                    }
                }
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .resultat_recherche {
        display: none !important;
    }

    .elem_liste_distributeur {
        position: relative;
        margin: 0 0 40px 0;
    }

    // Ajout d'un éclat Outlet/Filtre en présence de filtre sur une boutique
    .elem_list_contents {
        &.with_filter {
            position: relative;
            
            &::after {
                display: block;
                width: fit-content;
                padding: 2px 5px;
                margin-left: 1.7rem;
                margin-top: 7px;
                border: 1px solid $primaryColor;
                content: attr(data-filter);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                background-color: $white;
                text-transform: capitalize;
            }

            &.opened {
                &::after {
                    position: absolute;
                    top: 53px;
                    left: 28px;
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
    }

    /* Result / Store detail ****/
    .nom_store {
        display: none;
    }

    .detail_store {
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
    }

    .pays_store {
        display: none;
    }

    .rue_store {
        padding-right: .5rem;
    }

    .phone_store {
        padding-top: .3rem;
        width: 100%;
    }

    .content_store {
        padding-left: 1.7rem;
        display: inline-block;
        cursor: pointer;

        .puce {
            &::before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                margin-right: .5rem;
            }
            
            &.store_open .open_hour span {
                color: #00A752;
            }

            &.store_open::before {
                background: #00A752;
            }
            
            &.store_close::before {
                background: red;
            }

            &.store_close {
                color: red;
                justify-content: flex-start;
            }

            .open_hour {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                font-style: italic;

                span {
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                }
            }
            
        }

        .store_pics {
            display: none;
        }
    }
    // .content_store,
    .detail_store {
        &::before {
            position: absolute;
            left: 0;
            top: 3px;
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 1px solid $black;
        }

        &.actif {
            &::before {
                box-shadow: inset 0 0 0 .125em #fff;
                background-color: $black;
                @-moz-document url-prefix() {
                    box-shadow: inset 0 0 0 .128em #fff;
                }
            }
        }
    } 

    .store_more {
        cursor: pointer;
        position: absolute;
        bottom: -3px;
        right: 0;
        text-decoration: underline;
        text-align: right;
        min-width: 90px;

        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        .less {
            display: none;
        }

        &.opened {
            .less {
                display: block;
            }
            .more {
                display: none;
            }
        }
    }

    .details_dropdown {
        display: none;
        padding: 2.3rem 68px 0 0;
        animation: shade .3s ease;
        min-width: 328px;
        max-width: 550px;

        @media screen and (max-width: 1120px) {
            max-width: 450px;
        }
        
        .less {
            display: none;
        }
        
        .detail_link {
            font-size: 15px;
            max-width: none;
        }
        
        .horaire {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            span + span {
                display: block;
                text-align: right;
                width: auto;
                white-space: nowrap;
                margin-left: 20px;
            }

            & > span {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }

            &:not(.current_horaire) {
                padding-bottom: 10px;
            }

            &.current_horaire {
                padding-bottom: 9px;
            }

            .currently_closed,
            .currently_open {
                margin-right: auto;
            }
        }

        .detail_link.rodierButton {
            background-color: $primaryColor;
            margin-top: 6px;

            span {
                color: $white;
            }

            &:hover {
                background-color: $white;

                span {
                    color: $primaryColor;
                }
            }
        }
    }


    // STORE PICS APPEND ON LIST ITEM CLICK
    .holder_imgs {
        display: flex;
        justify-content: space-between;
        .wrapper_img {
            &:not(:first-of-type):not(:nth-of-type(2)) {
                display: none;
            }
            &:first-of-type {
                max-width: 542px;
                width: 100%;
                margin-right: 7px;
            }
            &:nth-of-type(2) {
                max-width: 340px;
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;

                @include medium {
                    object-fit: cover;
                }
            }
        }
    }

    .primary_navbar {
        flex-wrap: nowrap;
        align-items: flex-start;
        width: fit-content;

        &::after {
            background-color: $navBar;
        }

        &::before {
            display: none;
        }

        .navbar_tab.active::after {
            background-color: $navBar_active;
        }

    }

    .liste_distributeur_wrapper {
        padding-top: 0;
        padding-right: 20px;
        max-height: 830px;
        
        @media screen and (max-width: 1024px) {
            max-height: 664px;
        }

        .os-scrollbar.os-scrollbar-vertical {
            width: 2px;
        }

        .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
            min-height: 120px;
            max-height: 120px;
        }
    }
}

body.satellite_store_details{
    .masgasin_pages{
        display: flex;
        flex-wrap: wrap;
        min-width: 1020px;
        width: 100%;
        .locator_heading{
            width: 100%;
        }
        h1.title {
            text-transform: uppercase;
            text-align: left;
            color: #000;
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }
        #distribute_section_a{
            width: 25%;
            .distribute_info{
                font-size: .875rem;
                h1, .mag_info{
                    font-weight: 700;
                    font-size: 1rem;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                }
                .distribute_adress{
                    font-weight: 700;
                    margin-bottom: .6rem;
                    line-height: 1.5;
                }
                table.time_table{
                    font-weight: 700;
                    tr td{
                        line-height: 1.5;
                    }
                }
                .store_close{
                    display: none;
                }
                .open_hour{
                    margin-top: 1rem;
                    display: block;
                    span{
                        color: green;
                    }
                }
            }
            .horaire {
                padding: .05rem 0;
            }
            .button.btn_send_info.btn_blue,
            .btn_store_container.btn_tunnel,
            .store_direction.btn_store_container{
                margin-top: 1rem;
            }
        }
        #distribute_section_b{
            width: 70%;
            margin-left: 5%;
            @media screen and (max-width: 1440px){
                width: 65%;
                margin-left: 10%;
            }
            @media screen and (max-width: 1300px){
                width: 60%;
                margin-left: 15%;
            }
            @media screen and (max-width: 1200px){
                width: 65%;
                margin-left: 10%;
            }
            #store_detail_map {
                width: 100%;
                height: 35rem;
            }
        }
        .descrip_avis_ctn,
        .store_img,
        .product_associations_wrapper.gondole{
            display: none;
        }
    }
    #ligthbox_send_store_information{
        padding: 2rem 2.5rem;
        width: 600px;
        .input_label{
            padding: 0.5rem 0;
        }
        form.w-form.w-sendshop-form p{
            margin-bottom: 0.5rem;
        }
        .header_lightbox{
            .popup_title{
                display: flex;
                justify-content: center;
                font-weight: 700;
                font-size: 1.1rem;
                letter-spacing: .05rem;
                text-transform: uppercase;
            }
            & + p.input_label{
                margin: 1rem 0 0.75rem;
                font-weight: 700;
                font-size: 1rem;
            }
        }
        .w-form-line.w-submit{
            width: 100%;
            max-width: 100%;
        }
        .w-btn-wrapper{
            margin: 0 auto;
            max-width: 15.875rem;
            .w-submit-button{
                border-radius: 5px;
                border: 1px solid black;
            }
        }
    }

}

// Map Markers
.store_locator #retailers_map .gm-style {
    .gm-style-iw-c {
        border-radius: 0;
        padding: 1.7rem !important;
        box-shadow: none;

        .gm-style-iw-chr {
            display: none;
        }

        .gm-style-iw-d {
            overflow: auto !important;
        }
    }

    .gm-style-iw-tc {
        display: none;
    }
}

.store_locator .info_popup {
    .adresse {
        @include wshopFont($primaryFont, 16px, normal, 0.5px, 400, unset, none, $primaryColor);
    }

    .name {
        @include wshopFont($primaryFont, 16px, 15px, 0.5px, 400, unset, none, $primaryColor);
    }
}