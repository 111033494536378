.ill_img {
    display: inline-block;
    vertical-align: top;
    background: url(../svg/loader.svg) center no-repeat;
}
.ill_img > img {
    vertical-align: top;
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.ill_img.loaded > img {
    opacity: 1;
}
