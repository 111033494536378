/******************************************************************/
/* TEXT PAGES *****************************************************/
/******************************************************************/
// page max-size
.order_cgv,
.footer_legalmentions,
.satellite_politique-confidentialite {
    max-width: 1920px;
    margin: 0 auto;
}

.page_text {
    max-width: unset;
    width: auto;
    margin: 0 0 5rem;
    padding: 0 clamp(20px, fs-vw(20, 1920), 20px);
    padding-top: 1.9rem;
    @include wshopFont($primaryFont, 16px, 1.5, unset, normal, unset, unset, $primaryColor);
    
    h1.title {
        @include wshopFont($primaryFont, 25px, 1, .5px, 400,unset, unset, $primaryColor);
        margin-bottom: 22px;

        small {
            font-size: 1.5625rem;
            padding-bottom: 1.25rem;
        }
    }
    
    h2 {
        @include wshopFont($primaryFont, 1rem, 1.5, .5px, 900,unset, unset, $primaryColor);
    }
    
    strong {
        font-weight: bold;
    }
    
    .row {

        &:not(:last-child) {
            padding-bottom: 2rem;
        }

        .content_inside {
            text-align: left;

            strong {
                &::before {
                    content: '';
                    height: 15px;
                    display: block;
                }
            }
        }
    }
}

body.order_cgv {
    .row {
        &:not(:last-child) {
            padding-bottom: 0px;
        }
    }
}

/******************************************************************/
/* SATELLITE PAGES ************************************************/
/******************************************************************/
.page_satellite {
    /* General */
    @extend .articleFontStyle;

    
    /* Sat Nav text bloc */
    .satellite_title {
        @include wshopFont($primaryFont, 3rem, 1, unset, 400, unset, none, $primaryColor);
        padding-bottom: 2rem;
        text-transform: uppercase;

        span {
            font-size: 25px;
            font-weight: 400;
        }
    }

    .satellite_nav_bar.no_text {
        .tabsatellite {
            padding-bottom: 0;
            
            h2 {
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                padding-bottom: 0;
                color: rgba($primaryColor, 0.5);
            }

            &:not(:last-child) {
                margin-right: 63px;
            }

            &::after {
                display: none !important;
            }

            &.active {
                h2 {
                    color: $primaryColor;
                }
            }
        }
    }

    .sat_block_text {
        span {
            @include wshopFont($primaryFont, 1.6rem, 1.5, .5px, 400,unset, none, $primaryColor);
            padding-bottom: 1.25rem;
            display: block;
        }
    }

    h2,
    .sat_block_text span {
        padding-bottom: 1rem;
        line-height: unset;
    }
    
    p {
        font-size: 16px;
        line-height: 20.51px;
        letter-spacing: 0.5px;
        color: $primaryColor;

        strong {
            font-weight: 400;
        }

        &:first-child strong {
            font-weight: 400;
        }
    }

    strong {
        font-weight: 400;
        letter-spacing: 0;
    }

    .holder_img{
        img {
            width: 100%;
        }
    }

    .navbar_cnt {
        font-size: .75rem;
        display: none;
        text-align: justify;

        &.actif {
            display: block;
        }
    }

    /* Holds satellite content and tabs if there are */
    .satellite_tab {
        display: none;
        
        &.actif {
            display: block;
        }
    }
    .satellite_content {
        padding: 6rem 0 2rem;

        .sat_block_text {
            p {
                max-width:500px;
                text-align: justify;
            }
        }


    }
    
    .horizontal_content {
        @include flexbox(row, space-between, unset, unset);
    }
}

/* CONSEIL / GUIDE D'ENTRETIEN *****************/

.conseil {

    /* Content inside conseil tabs */
    [data-tab="entretien"] {
        .horizontal_content {            
            >* {
                max-width: 50%;
                width: 100%;
            }

            &:not(:last-of-type) {
                padding-bottom: 2.5rem;
            }
            
            &:nth-of-type(2n+1) .sat_block_text {
                padding-left: 5rem;
            }
            
            &:nth-of-type(2n) .sat_block_text {
                padding-right: 5rem;
            }
        }
        
        .sat_block_text {
            @include flexbox(column, center, flex-start, unset);
        }
    }

    /* Onglet "Spécificités matières" */
    [data-tab="matieres"] {
        .content_sidenav {
            position: relative;
            max-width: 20%;
            width: 100%;

            &::after {
                position: absolute;
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background-color: $borderColor;
                top: 0;
                left: 100%;
            }

            > ul {
                width: 100%;
            }

            .sidenav_tab {
                position: relative;
                padding: 0 2rem 1rem 0;
                cursor: pointer;
                font-size: 17px;

                &.actif {
                    font-weight: bold;
                    &::after {
                        position: absolute;
                        content: "";
                        display: block;
                        top: 0;
                        left: 100%;
                        width: 1px;
                        height: 100%;
                        background-color: $primaryColor;
                        z-index: 1;
                    }
                }

                h3 {
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }

        .content_tabs_holder {
            max-width: 80%;
            width: 100%;
            padding-left: 2rem;
        }
        .content_tab {
            display: none;
            &.actif{display: block;}
        }

        .sat_block_text {
            &:not(:first-of-type) {
                padding-top: 1.25rem;
            }
        }
    }

    /* ONGLET ASTUCES */
    [data-tab="astuces"] {
        .astuce_unorganized {
            padding-top: .5rem;
        }
        .horizontal_content {
            > * {
                max-width: 50%;
                width: 100%;

                &.sat_block_text {
                    padding-right: 4.25rem;
                }
            }
        }
        .holder_two_img {
            padding-left: 1.25rem;
            img {
                width: 100%;
            }
            img:first-of-type {
                padding-top: 3.75rem;
            }
            img:last-of-type {
                padding-top: 2.5rem;
            }
        }
    }

    .page_upper_text {
        padding: 2rem 6.3065%;

        @media screen and (max-width: 1440px) {
            padding: 2rem 3.554%;
        }

        @media screen and (max-width: 1024px) {
            padding: 2rem 3.178%;
        }

        .satellite_title span {
            font-size: 38px;
            line-height: normal;
            letter-spacing: .5px;
            font-weight: 700;
        }

        .nav_holder {
            display: flex;
            flex-wrap: wrap;

            .satellite_nav_bar {
                width: 100%;
                justify-content: center;
                margin-bottom: 36px;

                .tabsatellite.navbar_tab {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: .5px;
                    text-transform: uppercase;
                    padding: 12px 0;

                    &:not(:last-child) {
                        margin-right: 82px;

                        @media screen and (max-width: 1061px) {
                            margin-right: 50px;
                        }
                    }

                    &::after {
                        top: 76%;
                    }

                    h2 {
                        font-size: inherit;
                        font-weight: inherit;
                        padding: 0;
                    }
                }
            }
            
            .navbar_cnt_wrapper {
                width: 50%;
                padding: 9%;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1024px) {
                    padding: 0 4% 0 0;
                }

                .navbar_cnt {
                    font-size: 15px;
                    line-height: normal;
                    letter-spacing: .5px;
                    font-weight: 400;
                    text-transform: unset;
                    text-decoration: none;
                }
            }

            .holder_img {
                width: 50%;
                height: auto;
            }
        }
    }

    .satellite_content.satellite_lower {
        padding: 2rem 2rem 6rem 2rem;

        .sat_block_text {
            h2{
                font-size: 38px;
                line-height: normal;
                letter-spacing: .5px;
                font-weight: 700;
                text-transform: unset;
                text-decoration: none;
                padding-bottom: 1.25rem;
            }

            p,
            li,
            h3,
            h4 {
                font-size: 15px;
                line-height: normal;
                letter-spacing: .5px;
                font-weight: 400;
                text-transform: unset;
                text-decoration: none;
            }

            h3,
            h4 {
                font-weight: 700;
            }
        }
    }
}

/* GUIDE TAILLES *****************/
.guide_tailles {
    .satellite_content {
        margin: 0 auto;
        justify-content: center;
        align-items: flex-start;
        padding: 6rem 6.375rem 2rem;

        @include medium {
            padding: 6rem $mainPaddingMedium 2rem;
        }
    }
    
    .satellite_content_left {
        max-width: 52.9%;
        width: 100%;
        padding-right: 6.375rem;
        padding-top: 2rem;

        @media screen and (max-width: 1669px) {
            max-width: 46.8%;
        }

        @media screen and (max-width: 1500px) {
            max-width: 47.6%;
        }

        @media screen and (max-width: 1440px) {
            max-width: 53.3%;
        }

        @media screen and (max-width: 1300px) {
            max-width: 55.3%;
        }
    }
    
    .satellite_content_right {
        max-width: 45%;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .nav_holder {
        position: relative;
        max-width: 80%;
        width: 100%;

        .satellite_nav_bar {
            max-width: 50%;
            width: 100%;
        }
    }

    table {
        max-width: 100%;
        width: 100%;
        margin-top: 1.25rem;
        &:not(:last-of-type) {
            margin-bottom: 3.125rem;
        }
        tr {
            td {
                font-size: 16px;
                padding: .5rem;
                text-align: center;

                &:first-of-type {
                    text-align: left;
                    border-left: 1px solid $borderColor;
                }
                
                border-right: 1px solid $borderColor;
                border-bottom: 1px solid $borderColor;
            }
            &:first-of-type {
                td {
                    border-top: 1px solid $borderColor;
                }
            }
        }
    }

    @media screen and(max-width:1600px) {
        table {
            tr td {
                padding: .5rem;
            }
        }
    }
}

/* PERSONAL SHOPPER *****************/
.personal_shopper {
    .horizontal_content {
        > * {
            max-width: 50%;
            width: 100%;
        }
    }
    .sat_block_text {
        padding-left: 5rem;
        @include flexbox(column, center, unset, wrap);

        .personal_questions {
            li {
                position: relative;
                line-height: 1.5;
                font-size: .75rem;
                font-style: italic;
            }
        }
    }
}

/***** PLAN DU SITE *****/

.page_satellite.sitemap {
    width: fit-content;
    margin: 8rem auto 5rem;
    
    h2 {
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    .satellite_content {
        width: 100%;
        padding: 4rem 0 0;

        .sitemap_section {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 4rem;
            padding: 0 2rem;

            &:nth-child(10) {
                display: none;
            }

            .big_title_charte {
                font-size: 25px;
            }

            h3 {
                width: 100%;
                margin: 0 0 2.5rem;
            }

            .new_sitemap_border {
                margin: 0 0 1.5rem;
                width: 25%;

                @media screen and (max-width: 768px) {
                    width: 33%;
                }
            }
        }
    }
}

.sitemap_section {
    margin: 0 auto;

    h3 {
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: .5px;
    }

    .new_sitemap_border {
        margin: 2rem 0;
    }

    .new_sitemap_subcategory {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: .5rem;
    }
    
    .plan_titre_objet,
    .ss_cat_name_univers {
        font-size: 16px;
        line-height: 2;
    }
}

.satellite_politique-confidentialite, .misc_cookiesinfo  {
    h1.title {
        width: 68%;
    }
}
.footer_sitemap{
    @media screen and (min-width:0\0) {
        .sitemap{
            .satellite_content{
                display:flex;
                flex-direction:column;
                align-items:center;
                .sitemap_section {
                    width:280px;
                }
            }
        }
    }

    #site_global_wrap {
		padding-top: 0;

        .page_satellite.sitemap {
            margin: 7rem auto 5rem;
        }
	}

    .new_sitemap_subcategory {
        word-break: break-word;
        padding-right: 1rem;
    }

    .sitemap_section {
        padding: 0 clamp(20px, 1.04167vw, 20px)!important;
    }
}

body.satellite_lexique_maille{
    #breadcrumbs{
        display: none;
    }
    h1.title{
        font-size: 3.7rem;
    }
    .content_sat .content_inside{
        line-height: 1.15;
        margin-top: 1.1rem;
        font-size: 0.9rem;
        strong{
            font-weight: 900!important;
            font-size: 1rem;
            &:not(:first-child){
                margin-top: 0.4rem;
                display: inline-block;
            }
        }
    }
}

.satellite_politique-confidentialite {
    #appContainer {
        .main_wrapper {
            .wrapper_content_sat {
                h1.title {
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    #cookiesLightbox {
        &.displayCookies {
            display: block!important;
        }

        &.hideCookies {
            display: none!important;
        }

        .w-form-line.w-submit button.w-loader.hideLoader {
            z-index: -1;
            opacity: 0;
        }
    }
}