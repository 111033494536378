.page_rayon .filter_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0 clamp(20px, 1.04167vw, 20px);
    opacity: 0;

    & + .sub_filter {
        display: none;
    }

    .bloc_sub_categ {
        width: 76%;
        display: flex;
        flex-wrap: wrap;
            
        @media screen and (max-width: 1366px) {
            width: 74%;
        }

        &.views {
            width: 12%;

            @media screen and (max-width: 1366px) {
                width: 13%;
            }

            @media screen and (max-width: 1080px) {
                width: 11%;
            }
        }

        .views {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h2 {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                width: 52px;

                #viewPerTwo {
                    &:after {
                        content: " ";
                        display: block;
                        width: 1px;
                        height: 15px;
                        background-color: $primaryColor;
                        position: absolute;
                        left: 17px;
                        top: 3px;
                    }
                }
                
                li {
                    display: flex;
                    width: calc(50% - 8px);
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin-left: 8px;
                    position: relative;
                    cursor: pointer;
                    opacity: .5;

                    & + li:before {
                        content: " ";
                        display: block;
                        height: 15px;
                        width: 1px;
                        background-color: $primaryColor;
                        position: absolute;
                        left: -9px;
                        top: 3px;
                    }

                    &.is-active {
                        font-weight: 400;
                        opacity: 1;
                    }
                }
            }
        }

        .sub_categ_wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            #slideCateg {
                width: 100%;
                margin: 0 auto;
                height: 16px;

                @media screen and (max-width: 1440px) {
                    margin-left: 20px;
                }

                .swiper-wrapper {
                    display: flex;
                    margin: auto;

                    .swiper-slide {
                        width: auto;
                        display: inline-block;
                        margin-right: 20px;

                        &.current {
                            .sub_cat_name_title {
                                opacity: 1;
                            }
                        }

                        .sub_cat_name_title {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            cursor: pointer;
                        }

                        input {
                            display: none;
                        }

                        .subcat_check {
                            &:before, 
                            &:after {
                                content: none;
                            }
                        }

                        &.sub_cat_name {
                            display: flex;
                            align-items: center;
                            margin-right: 60px;
                            
                            input:checked + label .sub_cat_name_title {
                                display: inline;
                                color: $primaryColor;
                            }
                        }
                    }
                }

                &.non-active {
                    .swiper-wrapper {
                        justify-content: center;
                        
                        .swiper-slide {
                            &:last-child {
                                padding-right: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }

                &:has(input:checked),
                &:hover {
                    .sub_cat_name {
                        .sub_cat_name_title {
                            color: rgba(35, 31, 32, 0.5);
                        }

                        &:checked,
                        &:hover {
                            .sub_cat_name_title {
                                color: $primaryColor;
                            }
                        }
                    }
                }

                .swiper-button-next.slideCateg,
                .swiper-button-prev.slideCateg {
                    display: none;
                    top: 0;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    background: $white;
                    z-index: 5;
                    transform: none;
                    margin: 0;

                    &::before,
                    &:after {
                        content: none;
                    }

                    &:before {
                        content: " ";
                        display: block;
                        width: 11px;
                        height: 11px;
                        background-image: url(../svg/fp_cross.svg) !important;
                        background-repeat: no-repeat;
                        background-size: contain !important;
                        transform: none;
                        top: 3px;
                        position: inherit;
                    }

                    @media screen and (max-width: 1440px) {
                        display: block;
                    }
                    
                    &.swiper-button-disabled {
                        display: none;
                    }
                }

                .swiper-button-next.slideCateg {
                    width: 20px;
                    left: unset;
                    right: 0;
                    transform: rotate(180deg);

                    &:before {
                        left: unset;
                        bottom: 3px;
                        top: unset;
                    }
                }

                
                &.non-active .swiper-button-next.slideCateg,
                &.non-active .swiper-button-prev.slideCateg {
                    display: none;
                }
            }
        }
    }

    #trigger_filtre {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-left: 0;
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        cursor: pointer;
        width: 12%;
        height: 44px;

        @media screen and (max-width: 1366px) {
            width: 13%;
        }

        @media screen and (max-width: 1080px) {
            width: 15%;
        }
    }

    #rayon_sticky_block {
        width: 457px;
        height: 100vh;
        min-height: 100vh;
        position: fixed;
        top: 0;
        right: -100%;
        transition: right 0.3s ease-in-out;
        position: static;
        background-color: transparent;

        .fake_space {
            display: block;
            width: 100%;
            height: 40px;
            background-color: transparent;
        }

        section.filtre {
            padding-top: calc(3.16rem - 40px);
            background-color: $white;
            height: 100vh;
        }

        .os-content {
            overflow: hidden;
        }

        .title_wrapper,
        #filter_sticky {
            padding-left: 38px;
        }

        .lb-v-scrollbar {
            right: 1px;
        }

        & > .lb-v-scrollbar {
            right: -6px;
        }

        &.actif {
            right: -18px;
            z-index: 100;

            section.filtre {
                overflow-y: scroll;
            }
        }

        &.filters {
            position: fixed !important;

            .lb-wrap {
                position: absolute;
                right: -1rem;

                .lb-content {
                    margin-left: -1rem;
                }
            }
        }

        .filter_wrapper {
            border: none;
            margin-bottom: 30px;
        }

        .title_wrapper {
            display: flex;
            margin-top: 2.75rem;
            margin-bottom: 28px;

            .close_pop {
                display: inline-block;
                width: 25px;
                height: 26px;
                position: relative;
                top: 7px;
                left: 36px;
                cursor: pointer;
                
                span {
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(25, 5, 5);

                    img {
                        display: none;
                    }
                }
            }

            .title {
                display: inline-block;
                width: 340px;
                height: 26px;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                text-transform: uppercase;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
        }

        #filter_sticky {
            width: 381px;
            height: auto;
            min-height: auto;

            #bloc_wrapper {
                width: 391px;
                min-height: 65vh;
                max-height: 65vh;

                .bloc_one {
                    width: 345px;

                    .filter_wrapper {
                        padding: 0;
                    }
                }

                &.os-host-overflow {
                    .bloc_one {
                        padding-left: 1rem;
                    }

                    .os-scrollbar.os-scrollbar-vertical {
                        background-color: $menuColor
                    }

                    .os-scrollbar-handle {
                        background: $primaryColor;
                    }
                }

                .os-scrollbar-track {
                    background-color: $fadeGreyline !important;
                    border-radius: 0;

                    .os-scrollbar-handle {
                        background: $primaryColor !important;
                        border-radius: 0;
                    }
                }
            }

            .os-theme-dark>.os-scrollbar-vertical, 
            .os-theme-light>.os-scrollbar-vertical {
                width: 6px;
            }

            .bloc_two {
                height: 150px;

                @media screen and (max-width: 1370px) {
                    margin-bottom: 3.5rem;
                }

                .block-btn-filter {
                    background-color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 101;
                }
            }

            .filter_name {
                width: 100%;
                margin-bottom: 11px;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            .selected_options {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                margin-left: 1px;
            }

            #filter_trier,
            #filter_caract {
                .filter_name {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;

                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: normal;
                        letter-spacing: 0.5px;
                    }
                }

                .selected_options {
                    input {
                        display: none;
                    }

                    .container,
                    .form_itm.charact {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        width: 100%;

                        .tick_box {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 15px;
                            height: 15px;
                            border: 1px solid #CCC;
                            background-color: $white;
                            margin-right: 10px;
                        }

                        &:before {
                            content: none;
                        }

                        &.actif .tick_box {
                            &:after {
                                content: "";
                                width: 13px;
                                height: 13px;
                                background-color: $black;
                            }
                        }

                        span {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 11px;
                            letter-spacing: 0.5px;
                            cursor: pointer;
                            text-transform: lowercase;
                        }
                    }
                }
            }

            #filter_caract {
                margin-bottom: 17px;

                .charact {
                    margin-bottom: 17px;
                }

                .form_itm.charact {
                    [type=checkbox]:checked {
                        & + label {
                            &::after {
                                content: none;
                            }

                            span.tick_box {
                                &::after {
                                    content: "";
                                    width: 13px;
                                    height: 13px;
                                    background-color: $black;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .select_charact + .filter_name {
                    margin-top: 17px;
                }
            }

            #filter_trier {
                margin-bottom: 17px;

                .tri_price {
                    margin-bottom: 17px;
                }
            }

            #filter_sizes {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 8px;
                width: 345px;

                .selected_options {
                    margin-bottom: 11px;
                    column-gap: 20.5px;

                    .form_itm {
                        margin: 0 0 12px;
                        cursor: pointer;

                        &:first-child(6n + 1) {
                            margin-left: 0;
                        }

                        &:last-child,
                        &:nth-child(6n) {
                            margin-right: 0;
                        }

                        input {
                            display: none;
                        }

                        label {
                            position: relative;
                            width: 30px;
                            height: 30px;
                            border: 1px solid transparent;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            text-transform: uppercase;

                            &:before,
                            &:after {
                                content: none;
                                display: none;
                            }

                            span {
                                color: #272525;
                                opacity: .5;
                            }
                        }

                        input[type=checkbox]:checked + label {
                            span {
                                position: relative;
                                color: $primaryColor;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            #filter_color {
                max-width: 300px;
                margin-bottom: 31px;

                .filter_name {
                    margin-bottom: 11px;
                }

                .selected_options {
                    gap: 10.9px;
                }

                .form_itm {
                    width: 26px;
                    height: 26px;
                    cursor: pointer;

                    input {
                        display: none;

                        &[type=checkbox]:checked + label {
                            border: 1px solid $primaryColor;
                        }
                    }

                    label {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        span {
                            width: 15.9px;
                            height: 15.9px;
                            display: block;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            border-radius: 50%;
                        }

                        .adjustSize {
                            width: 16px;
                            height: 16px;
                        }

                        &:after,
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            #filter_price {
                margin: 0 auto 30px;

                .filter_checkbox_hide {
                    display: none;
                }

                .wrapper_filtre {
                    margin-left: 3rem;
                    margin-top: -0.45rem;

                    #slider-range,
                    #range-price .vue-slider {
                        width: 217px !important;
                    }

                    .ui-slider {
                        position: relative;
                        text-align: left;
                        z-index: 0;

                        &.close {
                            .ui-slider-handle:first-of-type {
                                .handler_price {
                                    span {
                                        padding-right: 100%;
                                    }
                                }
                            }

                            .ui-slider-handle:nth-of-type(2) {
                                .handler_price {
                                    span {
                                        padding-left: 100%;
                                    }
                                }
                            }
                        }

                        .ui-slider-handle {
                            position: absolute;
                            z-index: 2;
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 400;
                            outline: none;
                            width: 50px;
                            margin: -4px 0 0 -25px;

                            .handler_price {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                color:#272525;
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: .5px;
                                text-transform: uppercase;

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 9px;
                                    height: 9px;
                                    background-color: black;
                                    border-radius: 50%;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .ui-slider-range {
                            position: absolute;
                            z-index: 1;
                            display: block;
                            border: 0;
                            height: 2px;
                        }
                    }

                    .ui-slider-horizontal {
                        height: 2px;
                        background-color: #F5F5F5;
                        margin-top: .6rem;
                    }

                    .vue-slider {
                        height: 2px !important;

                        .vue-slider-rail {
                            background-color: #f5f5f5;
                            
                            .vue-slider-process {
                                background-color: $primaryColor;
                            }
                        }
                    }

                    .vue-slider-dot {
                        width: 9px;
                        height: 9px;
                        cursor: pointer;

                        &:nth-of-type(2) .vue-slider-dot-tooltip-inner span {
                            text-indent: -50%;
                        }

                        &:nth-of-type(3) .vue-slider-dot-tooltip-inner span {
                            text-indent: 50%;
                        }

                        .vue-slider-dot-handle {
                            background-color: $primaryColor;
                            width: 9px;
                            height: 9px;
                            box-shadow: inherit;
                            margin-top: .05rem;
                            border-radius: 100%;
                        }

                        .vue-slider-dot-tooltip-inner {
                            background-color: transparent;

                            span {
                                color:  $primaryColor;
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 15px;
                                display: inline-block;

                                &:after {
                                    content:'€';
                                    width: 1rem;
                                    height: 1rem;
                                    position: relative;
                                }
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .block-btn-filter,
        .block_selected_filter {
            width: 100%;

            .form_submit {
                width: 380px;
                height: 40px;
                margin: 0 auto;
                position: relative;
                margin-bottom: 5px;

                button.button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 100%;
                    border: none;
                    padding: 0;
                    width: 380px;
                    height: 40px;
                    border: 1px solid $primaryColor;

                    span {
                        color: $primaryColor;
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }

                    &:hover {
                        border: 1px solid $primaryColor;
                        span {
                            color: $white;
                        }
                    }
                }

                .loader {
                    width: 100%;
                    max-width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                & + .form_submit {
                    background: $black;
                    border: unset;

                    button.button {
                        background: none;
                        border: unset;

                        span {
                            color: $white;
                            text-align: center;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                            cursor: pointer;
                        }

                        &:hover {
                            background: $white;

                            span {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
              
        .block-btn-filter {
            .form_submit + .form_submit {
                margin-bottom: 20px;
                background-color: transparent;

                button.button {
                    span {
                        color: $primaryColor;
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-transform: initial;
                        position: relative;

                        &:after {
                            content: " ";
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background-color: $primaryColor;
                            bottom: 2px;
                            left: 0;
                        }
                    }
                }
            }
        }
            
        @media screen and (max-width: 1024px) {
            .block-btn-filter {
                bottom: -6rem;
            }
        }
    }

    #content_obj {
        width: 100%;
    }
}

.dynasearchwrapper {
    #search_page {
        #filter_trier {
            .selected_options {
                input:checked + label .tick_box {
                    &:after {
                        content: "";
                        width: 13px;
                        height: 13px;
                        background-color: $black;
                    }
                }
            }
        }

        #content_obj {
            .pager_wrapper_rayon {
                .loader_scroll {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: unset;

                    .button.primary {
                        font-size: 16px;
                        width: auto;
                        text-transform: initial;
                        border: none;
                        border-bottom: 1px solid $primaryColor;
                        margin-top: .75rem;
                        padding: .5rem 0 0;

                        &:hover {
                            background-color: $white;
                            color: $primaryColor;

                            span {
                                color: $primaryColor;
                            }
                        }

                        &.loader {
                            display: none;
                        }
                    }
                }
            }
        }

        .btnAddBasketWrapper {
            .bloc_add_alert_confirmation {
                p {
                    margin-bottom: 0!important;
                }
            }
        }
    }

    #rayon_sticky_block {
        #filter_sticky {
            #bloc_wrapper .bloc_one {
                padding-left: 2% !important;
            }

            .bloc_two {
                padding-left: 4.9%;
            }
        }

        .title_wrapper .title {
            padding-left: 1.7%;
        }

        .lb-wrap {
            right: -2rem !important;
        }
    }
}