/****************************************/
/**          COMMON                    **/
/****************************************/

.remove_prod {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0;
    right: -2rem;
    @extend .bgSprite;
    @extend .cross;

    span {
        display: none;
    }
}

/** PRODUCT *********/

.cart_product_line {
    position: relative;
    margin-bottom: 3rem;
    display: flex;
    padding-bottom: 0.3rem;

    /* PRODUCT PIC *****/
    .cart_product_pic {
        padding-right: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }

    /* PRODUCT DESC ******/
    .cart_product_desc {
        max-width: 230px;
        max-height: 156px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .wrap_titre_prix {
            .cart_product_title{
                font-size: 15px;
            }

            .line_product_desc {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
        }
    }

    .wrapper_cart_product_desc {
        font-size: .75rem;
        display: flex;
        flex-wrap: wrap;
        p.max_stock {
            order: 5;
            position: absolute;
            bottom: -0.7rem;
            margin-left: -0.5rem;
        }
    }

    .wrap_titre_prix {
        order: 1;
        width: 100%;
    }

    // .cart_product_brand_line == Rodier
    .cart_product_brand {
        display: none;
    }

    .cart_product_title {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: .5rem;

        @media screen and (max-width: 1024px) {
            font-size: 0.95rem;
        }
    }

    .cart_product_sizecol {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        &.color {
            position: relative;
            order: 2;
            font-size: 0;

            .color_bullet {
                display: inline-block !important;
                border: 1px solid #D1D1CF;
            }
        }

        &.size {
            order: 3;
            padding-left: 1rem;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;

            label {
                padding-right: 5px;
            }
        }

        &.quantity {
            order: 4;
            display: flex;
            width: 100%;

            label {
                display: inline-block;
                padding-right: .4rem;
                
                span {
                    display: inline-block;
                    font-size: 15px;
                }
            }
        }
    }

    .wrapper_cart_product_price {
        display: flex;
        flex-wrap: wrap;
        justify-self: flex-start;
        align-items: flex-end;
        margin-top: .5rem;
        position: relative;

        .pricetag {
            margin-top: 5px;

            .price {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            &+.pricetag {
                margin-left: 1rem;
                padding-left: 0;

                .price {
                    color: rgba(35, 31, 32, .5);
                }
            }
        }

        &:after {
            position: absolute;
            content: "";
            display: inline-block;
            width: 1rem;
            height: 2px;
            background-color: $primaryColor;
            top: 0;
            left: 0;
        }
    }


    /* PRODUCT CTA *****/
    .cart_product_modify {
        .product_mod {
            span {
                font-size: 15px;
                display: none;
            }
        }

        .product_mod,
        .product_towish,
        // .product_del,
        // .remove_wish,
        .wishToBasket {
            position: absolute;
            text-decoration: underline;
        }

        .product_mod {
            top: 100%;
            left: 0;
        }

        .product_towish {
            top: 100%;
        }

        .product_del,
        .remove_wish {
            @extend .remove_prod;
        }

        .remove_prod {
            right: .3rem;

            @media screen and (max-width: 1024px) {
                top:-5px;
            }
        }
    }
}

/* PRESALE */

.precoBasket {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 100;
    margin: -.5rem 0 2rem;
    display: none;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
        font-size: 10px;
        font-weight: 900;
        background-color: $primaryColor;
        color: white;
        border-radius: 50%;
        margin-right: .3rem;
    }
}

.line_ssTotal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 1.6rem;
    font-size: .75rem;

    .basket_total {
        width: 150px;
        text-align: right;
    }

    &.line_ssPart {
        margin-bottom: 2rem;
    }
}

/****************************************/
/**          CART ROLLOVER            **/
/****************************************/

#cart_top {
    .cart {
        display: block;
        padding-bottom: 2rem;

        >*:not(:last-child) {
            padding-bottom: .5rem;
        }
    }
    .sub_menu_title {
        margin: 0;

        span {
            font-size: 1.6rem;
            display: inline !important;
        }
    }

    nav.sub_menu .sub_menu_content{
        height: 100vh;
    }
}

#show_top_cart {

    /* Set height for lionbars lib to init scrollbar ****/
    #cart_list {
        width: 390px !important;
        height: calc(77vh - 163px);

        @media (max-height: 900px) {
            height: calc(71vh - 163px);
        }

        .lb-wrap {
            width: 110%;
            overflow-x: hidden !important;
            scrollbar-width: none;
        }

        .lb-v-scrollbar-slider {
            left: 1px;
        }

        .lb-h-scrollbar {
            display: none;
        }

        .product-link {
            padding-right: 2rem;

            .remove_prod {
                right: 0;
            }
        }

        @include medium {
            width: 100% !important;
        }

        &:has(.os-scrollbar) {
            .remove_prod {
                right: 22px;
            }
        }
    }

    /* Each product row in cart list ****/
    .product-link {
        position: relative;
        @include flexbox(row, flex-start, center, unset);
        margin-bottom: 1rem;

        .cart_link {
            padding-right: .54rem;

            .cart_thumb_holder {
                width: 130px;
                height: 156px;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .cart_product {
        @include flexbox(row, flex-start, center, wrap);
        padding-top: 1rem;

        /* Titles **/
        .title {
            @include wshopFont($primaryFont, 1rem, 1.5, .5px, 900,unset, unset, $primaryColor);
        }
        .sub_title {
            @include wshopFont($primaryFont, .75rem, unset, unset, normal, unset, unset, $primaryColor);
        }

        /* Each first-level element inside cart_product is flexed so each must have width and order props **/
        >*:not(:last-child) {
            margin-bottom: .625rem;
        }

        .wrap_top_info {
            width: 100%;
            order: 1;
        }

        .sub_taille {
            order: 3;
            width: auto;
            font-size: .75rem;
        }

        .sub_couleur {
            position: relative;
            width: auto;
            font-size: .75rem;
            padding-right: .66rem;

            span {
                display: block;
                width: 16px;
                height: 16px;
                line-height: 15px;
                border-radius: 50%;

                &:before,
                &:after {
                    display: none;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &:before {
                    content: attr(title);
                    font-size: .75rem;
                    font-weight: 900;
                    text-transform: capitalize;
                    white-space: nowrap;
                    padding: .2rem;
                    bottom: 1.5rem;
                    border: 2px solid $primaryColor;
                    background: white;
                }

                &:after {
                    content: '';
                    bottom: 1rem;
                    width: 10px;
                    height: 9px;
                    background: transparent url("../img/arrow_down_tooltip.png") no-repeat center;
                    background-size: contain;
                    border-top: 2px solid $white;
                }
            }
        }

        .sub_quantity {
            order: 5;
            font-size: .75rem;
            margin-top: .5rem;
        }

        .wrapper_price {
            position: relative;
            width: 100%;
            padding-top: 0.25rem;

            span {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
            }

            &::before {
                position: absolute;
                top: -5px;
                content: "";
                display: block;
                width: .75rem;
                height: 2px;
                margin: 0 0 .5rem;
                background-color: $primaryColor;
            }
        }

        .modify_prod {
            display: none;
        }
    }

    /* Cart totals ****/
    .wrap_tot_panier {
        padding-top: 1.25rem;
        position: sticky;
        bottom: 0;
        z-index: 1;
        background: $white;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 19px;

        .form_submit {
            a {
                span {
                    font-size: 16px;
                }
            }
        }
    }

    .cart_panier_total {
        width: 388px;
        @include flexbox(row, flex-start, baseline, wrap);

        label,
        .titre_tot {
            padding-right: .625rem;
            @include wshopFont($primaryFont, 16px, unset, .5px, 400,unset, unset, $primaryColor);
            text-transform: uppercase
        }

        .pricetag {
            font-size: $price_font_size_desktop;
        }
    }

    /* Cart Form Submit ****/
    .form_submit {
        padding-top: 1.25rem;
        .button {
            display: block;
        }
    }

    .mention_livraison {display: none;}
}

.w-coupon-form {
    .w-form-line.w-submit .w-submit-button.w-loader {
        background-color: #3c3c3c;
        background-size: 50% auto;

        span {
            font-size: 0;
            border-bottom: 0;
        }
    }

    .w-input-note {
        margin: 0;
    }
}

.dragHandle,
.dragOverlay {
    display: none;
}

.cart.step_2 .bill_line.total_produit_dropdown {
    padding: 0 !important;

    .cart_product_line .wrapper_cart_product_desc {
        align-items: flex-end;
    }

    .wrap_titre_prix {
        position: relative;
        margin-bottom: .5rem;
        padding-bottom: .5rem;
    }

    .color_bullet {
        margin-bottom: 0;
    }
}

.cart_product_subtitle.line_product_desc {
    text-transform: lowercase;
}

.cart_main_table.wishlist.dropper  {
    .cart_product_line .cart_product_sizecol.color .color_bullet {
        margin-bottom: 0;
    }
}

/****************************************/
/* SELECTION OFFERTE */
/****************************************/
#selection-offer {
    .selection-offer-header p {
        display: inline-block;
        font-size: 25px;
        font-weight: 900;
    }

    #wrap_lion {
        p.titleOfferedProd {
            margin: .5rem 0;

            span {
                margin-top: .2rem;
            }
        }
    }
}