body.category {
    width: 100%;
    min-width: 1007px;

    .page_upper {
        .holder_img {
            @media screen and (max-width: 1382px) {
                width: 45% !important;
                max-width: 45% !important;
            }

            @media screen and (max-width: 1301px) {
                width: 40% !important;
                max-width: 40% !important;
            }
        }
    }
}

.page_upper_text {
    font-size: 0.75rem;
    line-height: 1.3;
}

body.cms_page .page_upper .holder_img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    img {
        display: none;
    }
}

.wrapper_bandeau {
    /**** BANDEAU NAV *****/
    .bandeau_nav {
        /**** NAV TITLE *****/
        h1 {
            margin-bottom: 2rem;
            @include wshopFont($primaryFont, 3rem, unset, 0.5px, 900, unset, unset, $primaryColor);

            .collection_name {
                font-size: 25px;
                font-weight: 400;
                left: -0.5%;
                text-transform: uppercase;

                @media screen and (max-width: 1680px) {
                    left: -0.25%;
                }

                @media screen and (max-width: 1366px) {
                    left: -0.5%;
                }

                @media screen and (max-width: 1280px) {
                    left: -1%;
                }

                @media screen and (max-width: 1157px) {
                    left: -0.5%;
                }

                @media screen and (max-width: 1024px) {
                    left: -0.4%;
                }
            }

            @include medium {
                font-size: 2.75rem;

                .collection_name {
                    font-size: 25px;
                }
            }
        }

        .collection_name {
            font-size: 2.8rem;
            position: relative;
            display: flex;
            align-items: center;
        }

        .bloc_categ {
            .wrapper_items {
                .cats_see_all {
                    display: none;
                }

                .swiper_style_container {
                    width: 100%;
                }
            }
        }

        /* NAV LIST -- WITHOUT SWIPER ***********************/
        .bloc_sub_categ,
        .bloc_categ {
            .wrapper_items p {
                white-space: nowrap;

                @media screen and (max-width: 1382px) {
                    font-size: 13px;
                }
            }

            //Cat
            .bloc_itm {
                padding-bottom: 14px;
                border-bottom: 1px solid $borderColor;
                display: flex;
                align-items: flex-end;

                .itm_name {
                    margin-top: 0.5rem;
                    margin-left: 1rem;
                    width: auto;

                    p {
                        width: auto;
                        white-space: nowrap;

                        a {
                            white-space: nowrap;
                            max-width: 205px;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media screen and (max-width: 1382px) {
                                font-size: 13px;
                            }
                        }
                    }

                    &.btn_select {
                        font-weight: 900;
                        white-space: nowrap;

                        &::after {
                            content: '';
                            display: block;
                            height: 1px;
                            animation: grow 0.3s linear forwards;
                            background-color: $primaryColor;
                            transform: translateY(15px);
                        }
                    }
                }
            }
        }

        /** Wrapping items and see_all button WHEN more than 3 cats **/
        .wrapper_items {
            width: 100%;
            font-size: 0.875rem;
            display: flex;
            justify-content: flex-start;

            h2,
            p {
                display: flex;
            }

            .cats_see_all {
                display: flex;
                align-items: flex-end;
                padding-bottom: 14px;
                border-bottom: 1px solid #ccc;

                &.highlight {
                    font-weight: 900;
                    border-bottom: 1px solid #000;
                }
            }
        }

        /* WITH SWIPER **********************
        // with_swiper there's an overflow on the swiper itself so you need to add border-bottom differently**/
        .bloc_categ.with_swiper,
        .sub_categ.with_swiper {
            .wrapper_items {
                position: relative;

                &::after {
                    content: '';
                    background: $borderColor;
                    display: block;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .bloc_itm {
                padding-bottom: 0;
                margin: 0 auto;

                .itm_name {
                    width: auto;
                    white-space: nowrap;

                    &:not(:last-of-type) {
                        margin-right: 4vmin;
                    }

                    &:last-of-type {
                        margin-right: 2vmin;
                    }
                }
            }

            .cats_see_all {
                margin-right: 7px;
                white-space: nowrap;

                &.highlight {
                    position: relative;
                    z-index: 1;
                    border: 0;

                    &::after {
                        content: '';
                        display: block;
                        height: 1px;
                        width: 100%;
                        background-color: $primaryColor;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }
        }

        /* cats swiper */
        .swiper_style_container {
            position: relative;
            width: 85%;
            padding: 0 1.5rem;
        }

        .catsSwiper {
            .itm_name {
                padding-bottom: 14px;
                text-align: center;
                display: flex;
                align-items: flex-end;

                // make <a> fit swiper-slide
                > a {
                    display: block;
                    white-space: nowrap;
                }
            }

            .bloc_itm {
                border: 0;

                .btn_select {
                    position: relative;

                    &::after {
                        content: '';
                        display: block;
                        height: 1px;
                        width: 100%;
                        background-color: $primaryColor;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        animation: none !important;
                        transform: none !important;
                    }
                }
            }
        }

        .bloc_categ .catsSwiper .bloc_itm .itm_name {
            margin-right: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
    
    p {
        text-align: justify;
    }
}

.cats-button-prev,
.cats-button-next {
    cursor: pointer;
    bottom: 0.3rem;
    position: absolute;
    width: 1rem;
    height: 1rem;
    transform: translateY(-60%);
    @extend .bgSprite;
}

.cats-button-prev {
    @extend .arrowLeft;
    left: 0;
}

.cats-button-next {
    @extend .arrowRight;
    right: 0;
}

.list_item_nav {
    width: fit-content;
    padding-bottom: 14px;
    @include flexbox(row, space-between, center, wrap);
    border-bottom: 1px solid $borderColor;

    .itm_name {
        font-size: 0.75rem;

        &:not(:last-child) {
            margin-right: 2rem;

            @include medium {
                margin-right: 1rem;
            }
        }

        &.btn_select {
            position: relative;
            font-weight: 900;

            &::after {
                position: absolute;
                bottom: 0;
                content: '';
                display: block;
                height: 1px;
                animation: grow 0.3s linear forwards;
                background-color: $primaryColor;
                transform: translateY(15px);
            }
        }
    }
}

/* DESC */
.sub_categ_desc {
    margin-top: 14px;
    font-size: 0.75rem;
    line-height: 1.3;
    text-align: justify;
}

/***** BANDEAU IMAGE *****/
.wrapper_bandeau.sscat img {
    height: auto;
    object-fit: cover;
}