@import '../components/product/product_infos'; 
@import '../components/product/product_infos_details'; 
@import '../components/product/product_galery'; 
@import '../components/product/product_assoc'; 
@import '../components/product/product_visited_articles'; 

.page_produit .prod_top_wrapper {
    @include flexbox(row, flex-start, flex-start, nowrap); 
    position: relative;
    margin-bottom: 60px;
}

#modbox,
#errbox,
#pdvBox {
    display: none;
}


/****************************************************/
/*PRODUCT PRICE *************************************/

.no_cents {
    display: none;
}
.currency {
    padding-left: .0625rem;
}

.item_price, .wrapper_price, .product_prices, .wrapper_cart_product_price, .bill_line  {
    @include flexbox(row, flex-start, center, nowrap);
    .price_tag,
    .pricetag {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;

        &+.price_tag,
        &+.pricetag {
            font-size: 16px;
            padding-left: .875rem;
            display: flex;
            align-items: center;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: normal;
            color: $couleur_prix_remisee;
            position: relative;

            &::before {
                content: '';
                width: calc(100% - 14px);
                height: 1px;
                background-color: $couleur_prix_remisee;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .eclat_rond,
    .eclatProd,
    .eclat_gondole_home {
        margin-left: .875rem;
    }

    #link_paiement_3fois{
        margin-left: 1rem;
    }
}

.product_infos_container li.navbar_tab a.thumblnk span{
    text-transform: none;
}

.product_page{
    .page_wrapper {
        overflow: unset;
    }

    #abox {
        .close_pop span {
            background-image: none!important;
        }
    }

    #sendfriendbox{
        top: 100px;
        transform: inherit;
        overflow: hidden;

        #content_sendfriendbox{
            #sendfriend_form_buttons{
                    position: relative;
                    overflow: hidden;

                    .loader{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
            }
            #product_sendfriend_nok{
                margin: auto;
                width: 70%;
            }
        }

        #wrapper_sendfriendform {
            .close_pop {
                span {
                    display: none;
                }
            }
        }
    }
}

.page_produit {
    .product_cms_bloc {
        .image-text{
            margin: 80px 0 41px;

            .cms-page-module-img {
                position: relative;
                width: 53%;
                max-width: 1007px;
            }

            .cms-page-module-contents {
                width: 47%;
                padding: 93px 55px 0 112px;

                @media screen and(max-width: 1440px) {
                    padding: 6.5% 3% 0 3%;
                }
            }
        }
    }

    .image-droit {
        max-width: 1920px;
        margin: 0 auto 40px;

        .cms-page-module-contents {
            padding: 0 7.5rem;
        }
    }
}